import _ from "lodash";
import countryList from "i18n-iso-countries";
import React from "react";
import { BSON } from "realm-web";
import {
  CommoditiesDocument,
  CommodityOrder,
  CommodityPrice,
  CommoditySupplier,
  CommodityTimeline,
  MinimumCalendarCommodity,
  MinimumCalendarCommodityOrder,
  StockTransferOrder,
  TimelineEntry
} from "../model/commodities.types";
import { CustomCommoditiesDocument, SelectedCommoditiesDocument } from "../components/configurator/CustomTypes";
import dbCommodityService from "../services/dbServices/dbCommodityService";
import diffUtils from "./diffUtils";
import { OrdersDocument, pricingCommodities } from "../model/orders.types";
import { ARCHIVE, ORDERORDERCOMMODITIES, PRODUCTION, PRODUCTIONQUEUE, WAITING } from "./orderUtils";
import userService from "../services/userService";
import {
  T_CAPSULE,
  T_CUSTOM,
  T_LIQUID,
  T_POWDER,
  T_SERVICE,
  T_SOFTGEL,
  T_TABLET
} from "../components/order/OrderHelper";
import authenticationService from "../services/authenticationService";
import {
  AvailableStockMap,
  CommodityUsage,
  EMORequiredMap,
  ExtendedEMCommodity,
  StockMap,
  UsageMap
} from "../model/customTypes.types";
import { ExternalManufacturerOrdersDocument } from "../model/externalManufacturerOrders.types";
import { EM_OPEN } from "../components/externalManufacturers/ExternalManufacturerHelper";
import { MinimumCalendarPackaging, PackagingsDocument } from "../model/packagings.types";
import { MinimumCalendarPackagingOrder, PackagingOrderDocument } from "../model/packagingOrders.types";
import { LocalStock } from "../components/commodities/CustomTypes";
import { DataContext } from "../context/dataContext";
import dateUtils from "./dateUtils";
import { CustomOrder } from "../components/order/CustomTypes";
import baseUtils from "./baseUtils";
import { COMMODITIES, UpdateAction } from "../services/dbService";

export const CUSTOMER = "customer";

export const INTERNALCODE_OPTIONS = [
  { value: "internal_code_10000", label: "10000" },
  { value: "internal_code_20000", label: "20000" },
  { value: "internal_code_30000", label: "30000" },
  { value: "internal_code_40000", label: "40000" },
  { value: "internal_code_50000", label: "50000" },
  { value: "internal_code_60000", label: "60000 (Reserved)", isDisabled: true },
  { value: "internal_code_70000", label: "70000" },
  { value: "internal_code_80000", label: "80000" },
  { value: "internal_code_90000", label: "90000" }
];

export const ID_OPTIONS = [
  { value: "identifierRange10000", label: "10000" },
  { value: "identifierRange20000", label: "20000 (Reserved)", isDisabled: true },
  { value: "identifierRange30000", label: "30000" },
  { value: "identifierRange40000", label: "40000" },
  { value: "identifierRange50000", label: "50000" },
  { value: "identifierRange60000", label: "60000 (Reserved)", isDisabled: true },
  { value: "identifierRange70000", label: "70000" },
  { value: "identifierRange80000", label: "80000" },
  { value: "identifierRange90000", label: "90000" }
];

export const TITLEKEYS: Array<keyof { title: { de: string; en: string }; subtitle: { de: string; en: string } }> = [
  "title",
  "subtitle"
];

export const ROOTKEYS: Array<keyof TimelineEntry> = [
  "approved",
  "form",
  "category",
  "density",
  "type",
  "article_number",
  "identifier",
  "color",
  "hs_code",
  "internal_code",
  "organic",
  "organic_code",
  "note",
  "cas_number",
  "solvent",
  "toxic_amount",
  "country"
];

export const ROOTLISTKEYS: Array<keyof { properties: Array<BSON.ObjectId>; allergens: Array<BSON.ObjectId> }> = [
  "properties",
  "allergens"
];

const PRICEKEYS: Array<
  keyof {
    moq: number;
    price: number;
    note: string;
    deliverytime: number;
    purchasePrice: number | null;
    purchaseCurrency: string;
    incoterm: string;
  }
> = ["moq", "price", "purchasePrice", "purchaseCurrency", "incoterm", "note", "deliverytime"];

export const ORDERKEYS: Array<
  keyof {
    orderquantity: number;
    supplier: BSON.ObjectId | null;
    shelflife: number;
    incoterm: string;
    destination: BSON.ObjectId | string | null;
    port_shipment: string;
    port_destination: string;
    notify: string;
    deliverytime: number;
    carrier: string;
    packages: string;
    totalPrice: number;
    purchasePrice: number;
    currency: string;
    person: BSON.ObjectId | null;
    orderPDF: string | null;
    delivered: Date | null;
    ordered: Date | null;
    notes: string;
    asap: boolean;
  }
> = [
  "orderquantity",
  "supplier",
  "shelflife",
  "incoterm",
  "destination",
  "port_shipment",
  "port_destination",
  "notify",
  "deliverytime",
  "carrier",
  "packages",
  "totalPrice",
  "purchasePrice",
  "currency",
  "person",
  "orderPDF",
  "delivered",
  "ordered",
  "notes",
  "asap"
];

export const FILLER_COMMODITIES = ["5e6934b3aabd7e11ed06e7a2", "5e693761a8942807a217f235", "5e693651a8942807a217f234"];

export enum CommodityType {
  PURCHASED = "purchased",
  SOFTGEL = "softgel",
  SERVICE = "service"
}

/**
 * Updates a commodity and saves a timeline object with all made changes to it.
 * @param commodityPre Commodity before update
 * @param commodityPost Commodity after update
 * @returns Result of the query
 */
async function updateCommodityWithTimeline(commodityPre: CommoditiesDocument, commodityPost: CommoditiesDocument) {
  const timeline: CommodityTimeline = {
    person: new BSON.ObjectId(authenticationService.getUserDataID().toString()),
    date: new Date(),
    pre: {},
    post: {}
  };
  const update: any = {};
  diffForRootValues(commodityPre, commodityPost, timeline, update);
  diffForSuppliers(commodityPre.suppliers, commodityPost.suppliers, timeline, update);
  diffUtils.diffForStock(commodityPre, commodityPost, timeline, update);
  diffForActiveSubstances(commodityPre, commodityPost, timeline, update);
  diffUtils.diffForSpecifications(commodityPre, commodityPost, timeline, update);

  // Check forecast for changes, the changes are not documented in timeline since it is logged inside forecast
  if (JSON.stringify(commodityPre.forecast) !== JSON.stringify(commodityPost.forecast)) {
    update.forecast = commodityPost.forecast;
  }

  if (_.isEmpty(update)) return true;

  return await dbCommodityService.updateCommodity(commodityPre._id, update, timeline);
}

/**
 * Check all root values of the commodity for differences.
 * @param commodityPre Commodity before update
 * @param commodityPost Commodity after update
 * @param timeline Timeline entry that contains the diff
 * @param update Update object for MongoDB
 */
function diffForRootValues(
  commodityPre: CommoditiesDocument,
  commodityPost: CommoditiesDocument,
  timeline: CommodityTimeline,
  update: any
) {
  for (let title of TITLEKEYS) {
    let titleUpdated = false;
    if (JSON.stringify(commodityPre[title]) !== JSON.stringify(commodityPost[title])) {
      titleUpdated = true;
      timeline.pre[title] = { de: commodityPre[title].de, en: commodityPre[title].en };
      timeline.post[title] = { de: commodityPost[title].de, en: commodityPost[title].en };
      update[title] = { de: commodityPost[title].de, en: commodityPost[title].en };
    }
  }

  // Check all flat root values for changes
  for (let value of ROOTKEYS) {
    // @ts-ignore
    const preValue = commodityPre[value];
    // @ts-ignore
    const postValue = commodityPost[value];
    if (
      (!preValue && postValue) ||
      (preValue && !postValue) ||
      (preValue && postValue && preValue.toString() !== postValue.toString())
    ) {
      timeline.pre[value] = preValue;
      timeline.post[value] = postValue;
      update[value] = postValue;
    }
  }

  // Check all ObjectId lists for changes
  for (let value of ROOTLISTKEYS) {
    const valuesOld = commodityPre[value].map(v => v.toString());
    const valuesNew = commodityPost[value].map(v => v.toString());
    let valChanged = false;
    for (let val of commodityPost[value]) {
      if (!valuesOld.includes(val.toString())) {
        if (!timeline.post[value]) {
          timeline.post[value] = [];
        }
        timeline.post[value]!.push(val);
        valChanged = true;
      }
    }
    for (let val of commodityPre[value]) {
      if (!valuesNew.includes(val.toString())) {
        if (!timeline.pre[value]) {
          timeline.pre[value] = [];
        }
        timeline.pre[value]!.push(val);
        valChanged = true;
      }
    }
    if (valChanged) {
      update[value] = commodityPost[value];
    }
  }
}

/**
 * Check all suppliers for differences.
 * @param suppliersPre Suppliers before update
 * @param suppliersPost Suppliers after update
 * @param timeline Timeline entry that contains the diff
 * @param update Update object for MongoDB
 */
function diffForSuppliers(
  suppliersPre: Array<CommoditySupplier>,
  suppliersPost: Array<CommoditySupplier>,
  timeline: CommodityTimeline,
  update: any
) {
  // Check all suppliers for updates
  let supplierUpdated = false;
  const suppliersOld = suppliersPre.map(s => s._id.toString());
  for (let supplier of suppliersPost) {
    if (!suppliersOld.includes(supplier._id.toString())) {
      if (!timeline.post.prices) {
        timeline.post.prices = [];
      }
      for (let price of supplier.prices) {
        timeline.post.prices.push({ supplier: supplier._id, id: price._id });
        supplierUpdated = true;
      }
    } else {
      const supplierPre = suppliersPre.find(s => s._id.toString() === supplier._id.toString())!;
      for (let price of supplier.prices) {
        const pricePre = supplierPre.prices.find(p => p._id.toString() === price._id.toString());
        let changePre: any = { supplier: supplier._id, id: price._id, price: price.price, moq: price.moq };
        const changePost: any = { supplier: supplier._id, id: price._id, price: price.price, moq: price.moq };
        let changed = false;
        if (pricePre) {
          for (let value of PRICEKEYS) {
            if (price[value] !== pricePre[value]) {
              changed = true;
              changePost[value] = price[value];
              changePre[value] = pricePre[value];
            }
          }
        } else {
          changePre = null;
          changed = true;
        }
        if (changed) {
          if (changePre && !timeline.pre.prices) {
            timeline.pre.prices = [];
          }
          if (!timeline.post.prices) {
            timeline.post.prices = [];
          }
          if (changePre) timeline.pre.prices!.push(changePre);
          timeline.post.prices.push(changePost);
          supplierUpdated = true;
        }
      }
    }
  }
  const suppliersNew = suppliersPost.map(s => s._id.toString());
  for (let supplier of suppliersPre) {
    if (!suppliersNew.includes(supplier._id.toString())) {
      if (!timeline.pre.prices) {
        timeline.pre.prices = [];
      }
      for (let price of supplier.prices) {
        timeline.pre.prices.push({ supplier: supplier._id, id: price._id, price: price.price, moq: price.moq });
        supplierUpdated = true;
      }
    } else {
      const pricesNew = suppliersPost
        .find(s => s._id.toString() === supplier._id.toString())!
        .prices.map(p => p._id.toString());
      for (let price of supplier.prices) {
        if (!pricesNew.includes(price._id.toString())) {
          if (!timeline.pre.prices) {
            timeline.pre.prices = [];
          }
          timeline.pre.prices.push({
            supplier: supplier._id,
            id: price._id,
            price: price.price,
            moq: price.moq
          });
          supplierUpdated = true;
        }
      }
    }
  }
  if (supplierUpdated) {
    update.suppliers = suppliersPost;
  }
}

/**
 * Prepare suppliers for updating a commodities document
 * @param commodityPre Commodity before update.
 * @param commodityPost Commodity after update.
 * @returns { {suppliers: Array<CommoditySupplier>, timeline: CommodityTimeline} } Updated suppliers and timeline
 */
function prepareSuppliersUpdate(commodityPre: CommoditiesDocument, commodityPost: CommoditiesDocument) {
  const timeline: CommodityTimeline = {
    person: userService.getUserId(),
    date: new Date(),
    pre: {},
    post: {}
  };
  let update: any = {};
  diffForSuppliers(commodityPre.suppliers, commodityPost.suppliers, timeline, update);
  return { update, timeline };
}

/**
 * Prepare the timeline for a commodity order
 * @param commodityOrderPre Commodity order before change. Undefined if order was created
 * @param commodityOrderPost Commodity order after change. Undefined if order was deleted
 * @returns { CommodityTimeline } filled timeline if changes were made, empty if not
 */
function prepareCommodityOrderTimeline(
  commodityOrderPre?: CommodityOrder,
  commodityOrderPost?: CommodityOrder
): CommodityTimeline {
  const timeline: CommodityTimeline = {
    person: userService.getUserId(),
    date: new Date(),
    pre: {},
    post: {}
  };
  if (!commodityOrderPre && commodityOrderPost) {
    if (!timeline.post.orders) timeline.post.orders = [];
    timeline.post.orders.push({
      _id: commodityOrderPost._id,
      supplier: commodityOrderPost.supplier,
      destination: commodityOrderPost.destination,
      orderquantity: commodityOrderPost.orderquantity,
      totalPrice: commodityOrderPost.totalPrice,
      purchasePrice: commodityOrderPost.purchasePrice,
      currency: commodityOrderPost.currency,
      warehouseDestination: commodityOrderPost.warehouseDestination,
      arrivedAtWarehouse: commodityOrderPost.arrivedAtWarehouse,
      stockTransferOrder: commodityOrderPost.stockTransferOrders
    });
  } else if (!commodityOrderPost && commodityOrderPre) {
    if (!timeline.pre.orders) timeline.pre.orders = [];
    timeline.pre.orders.push({
      _id: commodityOrderPre._id,
      supplier: commodityOrderPre.supplier,
      destination: commodityOrderPre.destination,
      orderquantity: commodityOrderPre.orderquantity,
      totalPrice: commodityOrderPre.totalPrice,
      purchasePrice: commodityOrderPre.purchasePrice,
      currency: commodityOrderPre.currency,
      warehouseDestination: commodityOrderPre.warehouseDestination,
      arrivedAtWarehouse: commodityOrderPre.arrivedAtWarehouse,
      stockTransferOrder: commodityOrderPre.stockTransferOrders
    });
  } else if (commodityOrderPre && commodityOrderPost) {
    const changePre: any = {
      _id: commodityOrderPre._id,
      supplier: commodityOrderPre.supplier,
      destination: commodityOrderPre.destination,
      orderquantity: commodityOrderPre.orderquantity,
      totalPrice: commodityOrderPre.totalPrice,
      purchasePrice: commodityOrderPre.purchasePrice,
      currency: commodityOrderPre.currency,
      warehouseDestination: commodityOrderPre.warehouseDestination,
      arrivedAtWarehouse: commodityOrderPre.arrivedAtWarehouse,
      stockTransferOrder: commodityOrderPre.stockTransferOrders
    };
    const changePost: any = {
      _id: commodityOrderPost._id,
      supplier: commodityOrderPost.supplier,
      destination: commodityOrderPost.destination,
      orderquantity: commodityOrderPost.orderquantity,
      totalPrice: commodityOrderPost.totalPrice,
      purchasePrice: commodityOrderPost.purchasePrice,
      currency: commodityOrderPost.currency,
      warehouseDestination: commodityOrderPost.warehouseDestination,
      arrivedAtWarehouse: commodityOrderPost.arrivedAtWarehouse,
      stockTransferOrder: commodityOrderPost.stockTransferOrders
    };
    let hasChanged = false;
    // special check for sto's, reason: stockTransferOrder is not existing for type CommodityOrder
    if (!_.isEqual(changePre.stockTransferOrder, changePost.stockTransferOrder)) {
      hasChanged = true;
    } else {
      for (let key of ORDERKEYS) {
        if (
          commodityOrderPre[key] &&
          commodityOrderPost[key] &&
          commodityOrderPre[key]!.toString() !== commodityOrderPost[key]!.toString()
        ) {
          hasChanged = true;
          changePre[key] = commodityOrderPre[key];
          changePost[key] = commodityOrderPost[key];
        } else if (commodityOrderPre[key] && !commodityOrderPost[key]) {
          hasChanged = true;
          changePre[key] = commodityOrderPre[key];
        } else if (!commodityOrderPre[key] && commodityOrderPost[key]) {
          hasChanged = true;
          changePost[key] = commodityOrderPost[key];
        }
      }
    }
    const relatedOrdersPre = commodityOrderPre.orders.map(oPre => oPre.toString());
    const relatedOrdersPost = commodityOrderPost.orders.map(oPost => oPost.toString());
    if (!_.isEqual(relatedOrdersPre, relatedOrdersPost)) {
      changePre.orders = commodityOrderPre.orders;
      changePost.orders = commodityOrderPost.orders;
    }
    if (hasChanged) {
      if (!timeline.pre.orders) timeline.pre.orders = [];
      if (!timeline.post.orders) timeline.post.orders = [];
      timeline.pre.orders.push(changePre);
      timeline.post.orders.push(changePost);
    }
  }
  return timeline;
}

/**
 * Check active substances for differences.
 * @param commodityPre Commodity before update
 * @param commodityPost Commodity after update
 * @param timeline Timeline entry that contains the diff
 * @param update Update object for MongoDB
 */
function diffForActiveSubstances(
  commodityPre: CommoditiesDocument,
  commodityPost: CommoditiesDocument,
  timeline: CommodityTimeline,
  update: any
) {
  let activeSubstancesUpdated = false;
  const activeSubstancesOld = commodityPre.activesubstance.map(as => as._id.toString());
  for (let activeSubstance of commodityPost.activesubstance) {
    if (!activeSubstancesOld.includes(activeSubstance._id.toString())) {
      if (!timeline.post.activesubstance) {
        timeline.post.activesubstance = [];
      }
      activeSubstancesUpdated = true;
      timeline.post.activesubstance.push(activeSubstance);
    } else {
      const activeSubstancePre = commodityPre.activesubstance.find(
        as => as._id.toString() === activeSubstance._id.toString()
      )!;
      if (activeSubstance.value !== activeSubstancePre.value) {
        if (!timeline.pre.activesubstance) {
          timeline.pre.activesubstance = [];
        }
        if (!timeline.post.activesubstance) {
          timeline.post.activesubstance = [];
        }
        activeSubstancesUpdated = true;
        timeline.pre.activesubstance.push(activeSubstancePre);
        timeline.post.activesubstance.push(activeSubstance);
      }
    }
  }
  const activeSubstancesNew = commodityPost.activesubstance.map(as => as._id.toString());
  for (let activeSubstance of commodityPre.activesubstance) {
    if (!activeSubstancesNew.includes(activeSubstance._id.toString())) {
      if (!timeline.pre.activesubstance) {
        timeline.pre.activesubstance = [];
      }
      activeSubstancesUpdated = true;
      timeline.pre.activesubstance.push(activeSubstance);
    }
  }
  if (activeSubstancesUpdated) {
    update.activesubstance = commodityPost.activesubstance;
  }
}

/**
 * Searches and filters the given commodities with the given search query
 * @param commodities Array of commodities to filter
 * @param searchQuery Search query
 * @returns {Array<CustomCommoditiesDocument | SelectedCommoditiesDocument>} filtered array of commodities
 */
function doCommoditySearch(
  commodities: Array<CustomCommoditiesDocument | SelectedCommoditiesDocument>,
  searchQuery: string
): Array<CustomCommoditiesDocument | SelectedCommoditiesDocument> {
  return commodities.filter(entry => {
    return (
      entry.title.en?.toLowerCase().includes(searchQuery) ||
      entry.subtitle.en?.toLowerCase().includes(searchQuery) ||
      entry.identifier.includes(searchQuery) ||
      entry.internal_code.includes(searchQuery) ||
      entry.hs_code.includes(searchQuery) ||
      entry.organic_code.includes(searchQuery) ||
      (entry.cas_number && entry.cas_number.includes(searchQuery)) ||
      entry.title.de?.toLowerCase().includes(searchQuery) ||
      entry.subtitle.de?.toLowerCase().includes(searchQuery) ||
      entry.category?.de.toLowerCase().includes(searchQuery) ||
      entry.category?.en.toLowerCase().includes(searchQuery) ||
      entry.properties.some((prop: any) => prop.en?.toLowerCase().includes(searchQuery)) ||
      entry.allergens.some((alg: any) => alg.en?.toLowerCase().includes(searchQuery)) ||
      entry.activesubstance.some((substance: any) => substance.en?.toLowerCase().includes(searchQuery)) ||
      entry.properties.some((prop: any) => prop.de?.toLowerCase().includes(searchQuery)) ||
      entry.allergens.some((alg: any) => alg.de?.toLowerCase().includes(searchQuery)) ||
      entry.activesubstance.some((substance: any) => substance.de?.toLowerCase().includes(searchQuery)) ||
      (entry.country && countryList.getName(entry.country, "en")?.toLowerCase().includes(searchQuery)) ||
      (entry.country && countryList.getName(entry.country, "de")?.toLowerCase().includes(searchQuery))
    );
  });
}

/**
 * Get the lowest price from commodity suppliers
 * @param commodity commodity document
 * @returns { { price: number | undefined, moq: number | undefined } } lowest price with  moq
 */
function getLowestPrice(commodity: CustomCommoditiesDocument | SelectedCommoditiesDocument | CommoditiesDocument): {
  price: number | undefined;
  moq: number | undefined;
} {
  let price, moq;
  for (let supplier of commodity.suppliers) {
    for (let sPrice of supplier.prices) {
      let sPriceNum = Math.ceil(sPrice.price * 100) / 100;
      if (!price || sPriceNum < price) {
        price = sPriceNum;
        moq = sPrice.moq;
      }
    }
  }
  return { price, moq };
}

/**
 * Get the lowest delivery time from commodity suppliers
 * @param commodity commodity document
 * @returns {number | undefined } the lowest delivery time from all suppliers
 */
function getLowestDeliveryTime(
  commodity: CustomCommoditiesDocument | SelectedCommoditiesDocument | CommoditiesDocument
): number | undefined {
  let deliveryTime;
  for (let supplier of commodity.suppliers) {
    for (let sPrice of supplier.prices) {
      if (!deliveryTime || sPrice.deliverytime < deliveryTime) deliveryTime = sPrice.deliverytime;
    }
  }
  return deliveryTime;
}

/**
 * Get the lowest delivery time from commodity suppliers
 * @param commodity commodity document
 * @returns { {  deliveryTime: number|undefined, moq: number|undefined, price: number|undefined } } the price, moq and deliverytime of the fastest Supplier
 */
function getFastestPrice(commodity: CustomCommoditiesDocument | SelectedCommoditiesDocument | CommoditiesDocument): {
  deliveryTime: number | undefined;
  moq: number | undefined;
  price: number | undefined;
} {
  let deliveryTime;
  let deliveryPrice;
  let deliveryMoq;
  for (let supplier of commodity.suppliers) {
    for (let sPrice of supplier.prices) {
      if (!deliveryTime || sPrice.deliverytime < deliveryTime) {
        deliveryTime = sPrice.deliverytime;
        deliveryMoq = sPrice.moq;
        deliveryPrice = sPrice.price;
      }
    }
  }
  return {
    price: deliveryPrice,
    moq: deliveryMoq,
    deliveryTime: deliveryTime
  };
}

/**
 * Get all stock for a location
 * @param commodity commodity document
 * @param manufacturer manufacturer id
 * @param expiryLimit Optional, if set stock that expires before the date is ignored
 * @returns {number} highest stock amount
 */
function getAllStock(
  commodity: CommoditiesDocument | CustomCommoditiesDocument | SelectedCommoditiesDocument,
  manufacturer: BSON.ObjectId,
  expiryLimit?: Date
): number {
  let stock = 0;
  for (let stockEntry of commodity.stock) {
    if (
      manufacturer.toString() === stockEntry.location.toString() &&
      stockEntry.supplier !== CUSTOMER &&
      stockEntry.amount > 0 &&
      !stockEntry.disabled &&
      (!expiryLimit || expiryLimit < stockEntry.expiry)
    ) {
      stock += stockEntry.amount;
    }
  }
  return stock;
}

/**
 * Receive the amount of stock that is actually in transit outgoing from a specific manufacturer
 * @param commodity the related commodity
 * @param manufacturer the manufacturer where the commodity has been sent
 * @returns {number} the amount of stock that is actually in transit
 */
function getTransitAmount(
  commodity: CommoditiesDocument | CustomCommoditiesDocument,
  manufacturer: BSON.ObjectId | string
): number {
  return commodity.orders
    ? commodity.orders.reduce((accumulator, order) => {
        if (order.stockTransferOrders && order.warehouseDestination?.toString() === manufacturer.toString()) {
          return (
            order.stockTransferOrders.reduce((stoAccumulator, sto) => {
              if (sto.created && !sto.delivered) {
                // sto is on transit
                return sto.amount + stoAccumulator;
              }
              return stoAccumulator;
            }, 0) + accumulator
          );
        }
        return accumulator;
      }, 0)
    : 0;
}

/**
 * Use sto`s to calculate the amount of the specific commodity that are reserved in other warehouse or in transit now and the amount of directly ordered commodities
 * @param commodity the specific commodity
 * @param manufacturer the manufacturer destination
 * @returns {{orderedStoAmount: number, directlyOrderedAmount: number }} object containing the corresponding amounts
 */
function getOrderedAmount(
  commodity: CommoditiesDocument | CustomCommoditiesDocument,
  manufacturer: BSON.ObjectId | string
): { orderedStoAmount: number; directlyOrderedAmount: number } {
  let orderedStoAmount = 0;
  let directlyOrderedAmount = 0;

  commodity.orders?.forEach(order => {
    if (order.stockTransferOrders && order.stockTransferOrders.length > 0) {
      orderedStoAmount += order.stockTransferOrders.reduce((stoAccumulator, sto) => {
        if (sto.destination.toString() === manufacturer.toString() && !sto.delivered) {
          // state: sto is on warehouse or in transit and the sto destination is current manufacturer destination
          return stoAccumulator + sto.amount;
        }
        return stoAccumulator;
      }, 0);
    } else if (
      (!order.stockTransferOrders || order.stockTransferOrders.length === 0) &&
      !order.delivered &&
      order.destination &&
      order.destination.toString() === manufacturer.toString()
    ) {
      directlyOrderedAmount += order.orderquantity;
    }
  });

  return { orderedStoAmount: orderedStoAmount, directlyOrderedAmount: directlyOrderedAmount };
}

/**
 * Function to calculate the external ordered amount of a commodity related to a specific manufacturer and a related order
 * @param context Data context
 * @param order the related order
 * @param manufacturerDestination the related manufacturer
 * @returns {number} the external ordered amount
 */
function getOrderedAmountFromExternalManufacturer(
  context: React.ContextType<typeof DataContext>,
  order: CommodityOrder,
  manufacturerDestination: BSON.ObjectId | string
): number {
  if (
    !order.delivered &&
    order.destination &&
    order.destination.toString() === manufacturerDestination &&
    order.relatedEMOrders
  ) {
    return order.relatedEMOrders.reduce((accumulator, emOrder) => {
      const emo = context.externalManufacturerOrders.find(e => e._id.toString() === emOrder.toString());
      if (emo) {
        return accumulator + emo.amount;
      } else {
        return accumulator;
      }
    }, 0);
  }
  return 0;
}

/**
 * Use sto`s to get the amount stock of the commodity that is actual in the warehouse but reserved to be transferred or in transfer to another manufacturer
 * @param commodity the specific commodity
 * @param warehouse the warehouse where the commodity is waiting for further transit
 * @returns {{reservedOrderSTOMapping: Array<Array<string | number>>,reservedAmount: number}} an object containing an orderSTOMapping for order to reserved sto amount and the overall reseverd sto amount
 */
function getReservedStoAmount(
  commodity: CommoditiesDocument | CustomCommoditiesDocument,
  warehouse: BSON.ObjectId | string
): { reservedOrderSTOMapping: Array<Array<string | number>>; reservedAmount: number } {
  const relatedOverallOrders = [] as Array<Array<string | number>>; // collection of the related overall orders -> Mapping between OrderId and sto amount
  const reservedAmount = commodity.orders
    ? commodity.orders.reduce((accumulator, order) => {
        if (order.stockTransferOrders) {
          return (
            accumulator +
            order.stockTransferOrders.reduce((stoAccumulator, sto) => {
              if (
                sto.destination.toString() !== warehouse.toString() &&
                order.warehouseDestination === warehouse.toString() &&
                !sto.delivered &&
                !sto.created
              ) {
                sto.orderIds.forEach(relatedOrderId => {
                  relatedOverallOrders.push([relatedOrderId.toString(), sto.amount]);
                });

                // state: sto is on warehouse or in transit and the sto destination going from this warehouse to another manufacturer
                return stoAccumulator + sto.amount;
              }
              return stoAccumulator;
            }, 0)
          );
        }
        return accumulator;
      }, 0)
    : 0;
  return { reservedOrderSTOMapping: relatedOverallOrders, reservedAmount: reservedAmount };
}

/**
 * Calculates the currently stocked amounts of the commodity.
 * @param commodity Commodity whose stock values shall be calculated
 * @param context Data context
 * @param localStock Stock of the commodity together with the selected manufacturer
 * @returns { { stock: number, ordered: number, reserved: number, available: number } } Stock values
 */
function calculateStockValues(
  commodity: CommoditiesDocument,
  context: React.ContextType<typeof DataContext>,
  localStock: LocalStock
): { stock: number; ordered: number; reserved: number; available: number } {
  const manufacturer = localStock.manufacturer._id.toString();
  // Collect the stock of the currently selected manufacturer
  const stock =
    localStock.localStock.reduce((sum, b) => sum + (b.amount > 0 && b.supplier !== CUSTOMER ? b.amount : 0), 0) -
    getTransitAmount(commodity, localStock.manufacturer._id);

  // Collect the amount of already ordered commodities
  let ordered = 0;
  const { orderedStoAmount, directlyOrderedAmount } = getOrderedAmount(commodity, localStock.manufacturer._id);
  ordered += orderedStoAmount;
  ordered += directlyOrderedAmount;

  let emOrdered = 0;
  if (commodity.orders) {
    for (let i = 0; i < commodity.orders.length; i++) {
      const o = commodity.orders[i];
      emOrdered += getOrderedAmountFromExternalManufacturer(context, o, localStock.manufacturer._id);
    }
  }
  // Collect the amount of commodities that are needed for current orders
  let reserved = 0;
  const { reservedOrderSTOMapping, reservedAmount } = getReservedStoAmount(commodity, localStock.manufacturer._id);
  reserved += reservedAmount;

  const ordersMan = context.orders.filter(
    o =>
      [ORDERORDERCOMMODITIES, WAITING, PRODUCTIONQUEUE, PRODUCTION].includes(o.state) &&
      o.settings.manufacturer.toString() === manufacturer
  );

  for (let i = 0; i < ordersMan.length; i++) {
    const o = ordersMan[i];
    const comPrice = o.calculations[0].prices.find(p => p._id.toString() === commodity._id.toString());

    if (comPrice && !(comPrice.supplier === "customer")) {
      const relatedReservedSTO = reservedOrderSTOMapping.find(rSto => rSto[0] === o._id.toString());
      const potentialDuplicationAmount = relatedReservedSTO ? (relatedReservedSTO[2] as number) : 0;
      reserved += calculateUsage(o, comPrice, commodity.type).raw - potentialDuplicationAmount;
    }
  }
  return { stock, ordered, reserved, available: stock + ordered - reserved - emOrdered };
}

/**
 * Get stock statistics for a manufacturer
 * @param commodity Commodity whose stock values shall be calculated
 * @param location id of the relevant manufacturer
 * @param context data context
 * @param order optional, order document. If given customer stock is calculated specifically for this order and order is excluded from reserved calculation
 * @returns {{ stock: number, customerStock: number, ordered: number, emOrdered: number, reserved: number, available: number }} values for commodity stock
 */
function getStockValuesForLocation(
  commodity: CommoditiesDocument | CustomCommoditiesDocument,
  location: string,
  context: React.ContextType<typeof DataContext>,
  order?: OrdersDocument | CustomOrder
): { stock: number; customerStock: number; ordered: number; emOrdered: number; reserved: number; available: number } {
  // Collect the stock of the currently selected manufacturer
  const stock =
    commodity.stock
      .filter(s => s.location.toString() === location && !s.disabled && s.supplier !== CUSTOMER)
      .reduce((sum, b) => sum + (b.amount > 0 ? b.amount : 0), 0) - getTransitAmount(commodity, location);

  // If order is given get specific customer stock for this order, otherwise get total customer stock
  const customerStock = commodity.stock
    .filter(
      s =>
        s.location.toString() === location &&
        !s.disabled &&
        (order ? s.orders && s.orders.some(o => o.toString() === order._id.toString()) : s.supplier === CUSTOMER)
    )
    .reduce((sum, b) => sum + (b.amount > 0 ? b.amount : 0), 0);
  // Collect the amount of already ordered commodities
  let ordered = 0;
  const { orderedStoAmount, directlyOrderedAmount } = getOrderedAmount(commodity, location);
  ordered += orderedStoAmount;
  ordered += directlyOrderedAmount;
  let emOrdered = 0;
  if (commodity.orders) {
    for (let i = 0; i < commodity.orders.length; i++) {
      const o = commodity.orders[i];
      emOrdered += getOrderedAmountFromExternalManufacturer(context, o, location);
    }
  }

  // Collect the amount of commodities that are needed for current orders
  let reserved = 0;
  const { reservedOrderSTOMapping, reservedAmount } = getReservedStoAmount(commodity, location);
  reserved += reservedAmount;

  const ordersMan = context.orders.filter(
    o =>
      [ORDERORDERCOMMODITIES, WAITING, PRODUCTIONQUEUE, PRODUCTION].includes(o.state) &&
      o.settings.manufacturer.toString() === location
  );
  for (let i = 0; i < ordersMan.length; i++) {
    const o = ordersMan[i];
    // Skip order for reserved calculation if one was given
    if (order && o._id.toString() === order._id.toString()) continue;

    const comPrice = o.calculations[0].prices.find(
      p => p._id.toString() === commodity._id.toString() && p.supplier !== CUSTOMER
    );
    if (comPrice) {
      const relatedReservedSTO = reservedOrderSTOMapping.find(
        rSto => rSto[0] === o._id.toString() && rSto[1] === commodity._id.toString()
      );
      const potentialDuplicationAmount = relatedReservedSTO ? (relatedReservedSTO[2] as number) : 0;
      reserved += calculateUsage(o, comPrice, commodity.type).raw - potentialDuplicationAmount;
    }
  }
  return { stock, customerStock, ordered, emOrdered, reserved, available: stock + ordered - reserved - emOrdered };
}

/**
 * Get stock statistics for all commodities and a manufacturer
 * @param commodities List of commodities whose stock values shall be calculated
 * @param location id of the relevant manufacturer
 * @param context data context
 * @param onlyAvailable flag to only return available stock
 * @returns { T } values for commodity stock, as AvailableStockMap or StockMap
 */
function getStockValues<T extends { [id: string]: any }>(
  commodities: Array<CommoditiesDocument | CustomCommoditiesDocument>,
  location: string,
  context: React.ContextType<typeof DataContext>,
  onlyAvailable?: boolean
): T {
  const stockMap: StockMap = {};
  for (let i = 0; i < commodities.length; i++) {
    const commodity = commodities[i];
    const commodityId = commodity._id.toString();
    // Init
    stockMap[commodityId] = { stock: 0, customerStock: 0, ordered: 0, emOrdered: 0, reserved: 0, available: 0 };

    // Collect the stock of the currently selected manufacturer
    stockMap[commodityId].stock = commodity.stock
      .filter(s => s.location.toString() === location && !s.disabled && s.supplier !== CUSTOMER)
      .reduce((sum, b) => sum + (b.amount > 0 ? b.amount : 0), 0);
    // If order is given get specific customer stock for this order, otherwise get total customer stock
    stockMap[commodityId].customerStock = commodity.stock
      .filter(s => s.location.toString() === location && !s.disabled)
      .reduce((sum, b) => sum + (b.amount > 0 ? b.amount : 0), 0);
    // Collect the amount of already ordered commodities
    if (commodity.orders) {
      for (let i = 0; i < commodity.orders.length; i++) {
        const o = commodity.orders[i];
        if (o.destination && o.destination.toString() === location && !o.delivered) {
          stockMap[commodityId].ordered += o.orderquantity;
          if (o.relatedEMOrders) {
            for (let j = 0; j < o.relatedEMOrders.length; j++) {
              const emo = context.externalManufacturerOrders.find(
                e => e._id.toString() === o.relatedEMOrders![j].toString()
              );
              if (emo) stockMap[commodityId].emOrdered += emo.amount;
            }
          }
        }
      }
    }
    stockMap[commodityId].available =
      stockMap[commodityId].stock + stockMap[commodityId].ordered - stockMap[commodityId].emOrdered;
  }

  // Collect the amount of commodities that are needed for current orders
  const ordersMan = context.orders.filter(
    o =>
      [ORDERORDERCOMMODITIES, WAITING, PRODUCTIONQUEUE, PRODUCTION].includes(o.state) &&
      o.settings.manufacturer.toString() === location
  );
  for (let i = 0; i < ordersMan.length; i++) {
    const order = ordersMan[i];
    for (let j = 0; j < order.calculations[0].prices.length; j++) {
      const price = order.calculations[0].prices[j];
      // Some commodities may not be included, e.g. services etc.
      if (!stockMap[price._id.toString()]) continue;
      let amount = price.amount * (1 + price.buffer / 100) * order.calculations[0].units;
      if (![T_POWDER, T_LIQUID].includes(order.settings.type)) amount *= order.settings.perUnit;
      if ([T_CAPSULE, T_TABLET, T_POWDER, T_LIQUID].includes(order.settings.type)) amount /= 1000 * 1000;
      else if ([T_CUSTOM, T_SOFTGEL].includes(order.settings.type)) amount /= 1000;
      stockMap[price._id.toString()].reserved += amount;
      stockMap[price._id.toString()].available -= amount;
    }
  }
  if (onlyAvailable) {
    const values = Object.entries(stockMap);
    const newMap: AvailableStockMap = {};
    for (let i = 0; i < values.length; i++) {
      const [key, value] = values[i];
      newMap[key] = value.available;
    }
    return newMap as T;
  }
  return stockMap as T;
}

/**
 * Generate a map that contains the usage of all commodities inside the given orders.
 * @param orders Orders that should be checked for usage
 * @param commodities Commodities including commodityOrders
 * @param manufacturer Selected Manufacturer for usage, stock, order calculation
 * @returns { UsageMap } Contains the usage of all commodities inside the given orders
 */
function getUsageMap(
  orders: Array<OrdersDocument>,
  commodities: Array<CommoditiesDocument>,
  manufacturer: "" | { value: string; label: string }
): UsageMap {
  const ordersFiltered = orders.filter(
    o =>
      [ORDERORDERCOMMODITIES, WAITING, PRODUCTIONQUEUE, PRODUCTION, ARCHIVE].includes(o.state) &&
      (!manufacturer || o.settings.manufacturer.toString() === manufacturer.value)
  );
  const commodityMap: { [commodityId: string]: CommoditiesDocument } = {};
  const usageMap: UsageMap = {};
  for (let i = 0; i < ordersFiltered.length; i++) {
    const order = ordersFiltered[i];
    const orderAge = order.targetDate ? dateUtils.getDaysBetween(order.targetDate, new Date()) : null;
    for (let j = 0; j < order.calculations[0].prices.length; j++) {
      const price = order.calculations[0].prices[j];
      if (!usageMap[price._id.toString()])
        usageMap[price._id.toString()] = {
          usage: 0,
          required: { orders: 0, amount: 0 },
          ordered: { orders: 0, amount: 0 }
        };
      let oAmount = 0;
      let oCount = 0;
      let com: CommoditiesDocument | undefined = commodityMap[price._id.toString()];
      if (!com) {
        com = commodities.find(c => c._id.toString() === price._id.toString());
        if (com) commodityMap[com._id.toString()] = com;
      }
      let comOrders =
        com && com.orders
          ? com.orders.filter(
              c =>
                !c.delivered &&
                (!manufacturer || (c.destination ? c.destination.toString() === manufacturer.value : true))
            )
          : undefined;
      if (comOrders) {
        oAmount = comOrders.reduce((a, b) => a + b.orderquantity, 0);
        oCount = comOrders.length;
      }
      let amount = price.amount * (1 + price.buffer / 100) * order.calculations[0].units;
      if (![T_POWDER, T_LIQUID].includes(order.settings.type)) amount *= order.settings.perUnit;
      else amount /= 1000000;
      if ([T_CAPSULE, T_TABLET].includes(order.settings.type)) amount /= 1000000;
      else if ([T_CUSTOM, T_SOFTGEL].includes(order.settings.type)) amount /= 1000;
      if (orderAge && orderAge < 365) usageMap[price._id.toString()].usage += amount;
      if (order.state !== ARCHIVE) {
        usageMap[price._id.toString()].ordered = { orders: oCount, amount: oAmount };
        const r = usageMap[price._id.toString()].required;
        usageMap[price._id.toString()].required = { orders: r.orders + 1, amount: r.amount + amount };
      }
    }
  }
  return usageMap;
}

/**
 * Generate a object that contains the usage of a given commodities for a manufacturer
 * @param orders all Orders
 * @param commodity Commodity that needs to be checked
 * @param manufacturer Selected Manufacturer for usage, stock, order calculation
 * @param expiredBatches List of expired batch ids
 * @returns { CommodityUsage } Contains the usage of the Commodity of a manufacturer
 */
function getCommodityUsage(
  orders: Array<OrdersDocument>,
  commodity: CommoditiesDocument,
  manufacturer: BSON.ObjectId,
  expiredBatches?: Array<string>
): CommodityUsage {
  const ordersFiltered =
    manufacturer && orders.filter(o => o.settings.manufacturer.toString() === manufacturer.toString());
  const usageMap: CommodityUsage = {
    requiredAmount: 0,
    orderedAmount: 0
  };
  let oAmount = 0;
  let comOrders = commodity.orders && commodity.orders.filter(c => !c.delivered);
  if (comOrders) {
    comOrders = comOrders.filter(cO => cO.destination && cO.destination.toString() === manufacturer.toString());
    oAmount = comOrders.reduce((a, b) => a + (b.orderquantity > 0 ? b.orderquantity : 0), 0);
    usageMap.orderedAmount = oAmount;
  }
  for (let i = 0; i < ordersFiltered.length; i++) {
    const order = ordersFiltered[i];
    if (!order.calculations[0].prices.some(p => p._id.toString() === commodity._id.toString())) continue;
    let requiredAmount = 0;
    if ([ORDERORDERCOMMODITIES, WAITING, PRODUCTIONQUEUE, PRODUCTION].includes(order.state)) {
      for (let price of order.calculations[0].prices) {
        if (price._id.toString() === commodity._id.toString() && price.supplier !== CUSTOMER) {
          let amount = price.amount * (1 + price.buffer / 100) * order.calculations[0].units;
          if (![T_POWDER, T_LIQUID].includes(order.settings.type)) amount *= order.settings.perUnit;
          else amount /= 1000 * 1000;
          if ([T_CAPSULE, T_TABLET].includes(order.settings.type)) amount /= 1000 * 1000;
          else if ([T_CUSTOM, T_SOFTGEL].includes(order.settings.type)) amount /= 1000;
          requiredAmount = amount;
        }
      }
      if (expiredBatches && order.usedBatches && order.usedBatches.length > 0) {
        for (let j = 0; j < order.usedBatches.length; j++) {
          const uB = order.usedBatches[j];
          if (expiredBatches.includes(uB.lot.toString())) {
            // If a reserved batch is expired we shall subtract that amount since it is already handled and is not required
            requiredAmount -= uB.used;
          }
        }
      }
    }
    usageMap.requiredAmount = usageMap.requiredAmount += requiredAmount;
  }
  return usageMap;
}

/**
 * Get a map that contains the amount of commodities required by external manufacturer orders
 * @param orders List of external manufacturer orders
 * @returns { EMORequiredMap } Map with required amount and associated orders for commodities
 */
function getEMORequiredMap(orders: Array<ExternalManufacturerOrdersDocument>): EMORequiredMap {
  const requiredMap: EMORequiredMap = {};
  for (let i = 0; i < orders.length; i++) {
    const order = orders[i];
    if (order.state === EM_OPEN) {
      if (!requiredMap[order.commodityId.toString()]) {
        requiredMap[order.commodityId.toString()] = { orders: 1, amount: order.amount };
      } else {
        requiredMap[order.commodityId.toString()].orders += 1;
        requiredMap[order.commodityId.toString()].amount += order.amount;
      }
    }
  }
  return requiredMap;
}

/**
 * Calculates the usage of the referenced commodity in relation to the referenced order.
 * @param order Order, needed for settings
 * @param price Price from order calculation
 * @param commodityType Type of the commodity
 * @param quantity If true the order quantity of the price is used for calculation
 * @returns { { raw: number, unit: string } } Usage as raw number (in kg / unit) and calculated unit (g / kg / unit)
 */
function calculateUsage(
  order: OrdersDocument,
  price: pricingCommodities | undefined,
  commodityType: string | null,
  quantity?: boolean
): { raw: number; unit: string } {
  if (!price)
    return { raw: 0, unit: `0${commodityType === "" ? "kg" : commodityType === T_SERVICE ? "unit(s)" : "tsd."}` };
  let amount;
  let amountRaw;
  let unit;
  if (quantity && price.orderquantity) {
    amount = amountRaw = price.orderquantity;
  } else {
    amount = amountRaw = price.amount * (1 + price.buffer / 100) * order.calculations[0].units;

    if (![T_POWDER, T_LIQUID].includes(order.settings.type)) {
      amount = amountRaw *= order.settings.perUnit;
    }
    if ([T_CAPSULE, T_TABLET, T_POWDER, T_LIQUID].includes(order.settings.type)) {
      amount = amountRaw /= 1000 * 1000;
    } else if ([T_CUSTOM, T_SOFTGEL].includes(order.settings.type)) {
      amount = amountRaw /= 1000;
    }
  }
  if (commodityType === "") {
    amount = amountRaw;
    unit = " kg";
    if (amountRaw < 1) {
      amount *= 1000;
      unit = " g";
    } else if (amountRaw > 1000) {
      amount /= 1000;
      unit = " t";
    }
  } else if (commodityType === T_SERVICE) {
    unit = " unit(s)";
  } else {
    unit = " tsd.";
  }
  return { raw: amountRaw, unit: (Math.round(amount * 100) / 100).toLocaleString() + unit };
}

/**
 * Resolves the correct unit for the given amount and commodity type
 * @param amount Amount that should be resolved
 * @param commodityType Type of the commodity
 * @param noLocale If set the number is not transformed into locale string
 * @returns { string } Amount and unit of the stock entry
 */
function resolveStockUnit(amount: number, commodityType: string | null, noLocale?: boolean): string {
  let amt = amount;
  let unit: string;
  if (!commodityType || commodityType === "") {
    unit = " kg";
    if (Math.abs(amount) > 10000) {
      amt /= 1000;
      unit = " t";
    } else if (Math.abs(amount) < 1 && amount !== 0) {
      amt *= 1000;
      unit = " g";
    }
  } else if (commodityType === T_SERVICE) {
    unit = " unit(s)";
  } else {
    unit = " tsd.";
  }
  let result = Math.round(amt * 100) / 100;
  if (noLocale) return result + unit;
  return result.toLocaleString() + unit;
}

/**
 * Retrieves the latest price update from the given suppliers
 * @param suppliers Suppliers with _id and commodity prices
 * @returns { Date | null} the latest price or null if no price was found
 */
function findLatestPriceUpdate(
  suppliers: Array<{
    _id: BSON.ObjectId;
    prices: Array<CommodityPrice>;
  }>
): Date | null {
  const prices = suppliers.map(s => s.prices.map(p => p.date)).reduce((prices, sp) => prices.concat(sp), []);
  const latestPrice = prices.reduce((date, up) => (up && up.getTime() > date!.getTime() ? up : date), new Date(0));
  return latestPrice && latestPrice.getTime() > 0 ? latestPrice : null;
}

/**
 * Get color according to category
 * @param category category name
 * @returns { string } color as string
 */
function getCategoryColor(category: string): string {
  switch (category.trim()) {
    case "amino acids":
      return "orange";
    case "enzyms":
      return "purple";
    case "agents":
      return "#28a745";
    case "minerals":
      return "blue";
    case "botanical extracts":
      return "pink";
    case "mushrooms":
      return "brown";
    case "probiotics":
      return "yellow";
    case "various":
      return "grey";
    case "vitamins":
      return "red";
    default:
      return "lightgray";
  }
}

/**
 * Check if the commodity type is matching the show type.
 * @param commodity Commodity that should be checked
 * @param shownType Type that is wanted
 * @returns { boolean } Indicating whether the commodity got the wanted type or not
 */
function hasCorrectType(commodity: CommoditiesDocument, shownType: string): boolean {
  switch (shownType) {
    case "custom":
      return commodity.type === CommodityType.PURCHASED || (!commodity.type && commodity.form === "custom");
    case "services":
      return commodity.type === CommodityType.SERVICE;
    case "softgels":
      return commodity.type === CommodityType.SOFTGEL;
    case "commodities":
      return !commodity.type && commodity.form !== "custom";
    default:
      return false;
  }
}

/**
 * Get the type of a commodity
 * @param commodity Commodity that should be checked
 * @returns { string } type of the commodity
 */
function getCommodityType(commodity: CommoditiesDocument | ExtendedEMCommodity | MinimumCalendarCommodity): string {
  if (commodity.type === CommodityType.PURCHASED || (!commodity.type && commodity.form === "custom")) return "custom";
  if (commodity.type === CommodityType.SERVICE) return "service";
  if (commodity.type === CommodityType.SOFTGEL) return "softgel";
  if (!commodity.type && commodity.form !== "custom") return "commodities";
  return "unknown";
}

/**
 * Determines if the given commodity id is from a filler commodity.
 * @param _id ID of the commodity as ObjectId or string
 * @returns { boolean } True if is filler, False if not
 */
function isFiller(_id: BSON.ObjectId | string): boolean {
  return FILLER_COMMODITIES.includes(_id.toString());
}

/**
 * Resolve the correct icon for the referenced forecast direction.
 * @param forecast Direction of a forecast
 * @returns { string } CSS classes for the forecast icon
 */
function resolveForecastIconClasses(forecast: string): string {
  switch (forecast) {
    case "up":
      return "fas fa-angle-double-up text-danger";
    case "down":
      return "fas fa-angle-double-down text-success";
    case "neutral":
      return "fas fa-minus text-muted";
    default:
      return "fas fa-question text-muted";
  }
}

/**
 * Resolve the delivery date by adding the delivery time to today.
 * @param commodityOrder Commodity order whose delivery date should be resolved
 * @returns { Date } Today plus the delivery time in days
 */
function resolveDeliveryDate(commodityOrder: CommodityOrder | MinimumCalendarCommodityOrder): Date {
  const dt = +commodityOrder.deliverytime;
  if (Object.prototype.toString.call(commodityOrder.deliverytime) === "[object Date]" && dt > 1) {
    // @ts-ignore
    return commodityOrder.deliverytime;
  }
  const date = new Date(commodityOrder.created.getTime());
  date.setDate(date.getDate() + dt);
  return date;
}

/**
 * Determines whether the given document is commodity or packaging.
 * @param doc Document to be checked
 * @returns { boolean } True if commodity, false if packaging
 */
function isCommodity(
  doc: CommoditiesDocument | MinimumCalendarCommodity | PackagingsDocument | MinimumCalendarPackaging
): doc is CommoditiesDocument {
  return "density" in doc;
}

/**
 * Determines whether the given document is commodity order or packaging order.
 * @param doc Document to be checked
 * @returns { boolean } True if commodity order, false if packaging order
 */
function isCommodityOrder(
  doc: CommodityOrder | MinimumCalendarCommodityOrder | PackagingOrderDocument | MinimumCalendarPackagingOrder
): doc is CommodityOrder {
  return "orderquantity" in doc;
}

/**
 * Checks if a commodity can be considered as approved or not
 * @param commodity the related commodity
 * @returns { boolean } flag indicating if a commodity can be considered as approved or not
 */
function isCommodityApproved(commodity: CommoditiesDocument | CustomCommoditiesDocument): boolean {
  return commodity.approved === undefined || commodity.approved;
}

/**
 * Calculate the allocated stock (reserved via reserve material modal) for a commodity
 * @param commodityId id of the commodity to get allocated stock for
 * @param batchId id of the specific batch to get allocated stock for
 * @param orders list of all orders
 * @returns { Array<{order: OrdersDocument; allocated: number}> } List of orders with allocated amount
 */
function calculateAllocatedStock(
  commodityId: BSON.ObjectId | string,
  batchId: BSON.ObjectId | string,
  orders: Array<OrdersDocument>
): Array<{ order: OrdersDocument; allocated: number }> {
  const relevantOrders = orders.filter(
    o => [ORDERORDERCOMMODITIES, WAITING, PRODUCTIONQUEUE, PRODUCTION].includes(o.state) && o.usedBatches
  );
  let allocatedStock = [];
  for (let i = 0; i < relevantOrders.length; i++) {
    const order = relevantOrders[i];
    const allocated = order.usedBatches!.reduce(
      (sum, uB) =>
        sum +
        (uB.commodityId &&
        uB.commodityId.toString() === commodityId.toString() &&
        uB.id.toString() === batchId.toString()
          ? uB.used
          : 0),
      0
    );
    if (allocated > 0) allocatedStock.push({ order, allocated });
  }
  return allocatedStock;
}

/**
 * Calculate the allocated stock (reserved via reserve material modal) for a commodity
 * @param commodityId id of the commodity to get allocated stock for
 * @param batchId id of the specific batch to get allocated stock for
 * @param orders list of all orders
 * @param excludedOrderId optional, optional order id to exclude from calculation
 * @returns { number } Total allocated amount
 */
function calculateTotalAllocatedStock(
  commodityId: BSON.ObjectId | string,
  batchId: BSON.ObjectId | string,
  orders: Array<OrdersDocument>,
  excludedOrderId?: BSON.ObjectId | string
): number {
  const relevantOrders = orders.filter(
    o =>
      [ORDERORDERCOMMODITIES, WAITING, PRODUCTIONQUEUE, PRODUCTION].includes(o.state) &&
      o.usedBatches &&
      (!excludedOrderId || o._id.toString() !== excludedOrderId.toString())
  );
  return relevantOrders.reduce(
    (sum, o) =>
      sum +
      o.usedBatches!.reduce(
        (sum2, uB) =>
          sum2 +
          (uB.commodityId &&
          uB.commodityId.toString() === commodityId.toString() &&
          uB.id.toString() === batchId.toString()
            ? uB.used
            : 0),
        0
      ),
    0
  );
}

/**
 * Check the given orders for different manufacturers.
 * @param orders Orders that should be checked for different manufacturers
 * @param removedOrders Orders that should be ignored
 * @returns { boolean } Indicates whether different manufacturers are inside the orders or not
 */
export function checkOrdersForDifferentManufacturers(
  orders: Array<OrdersDocument>,
  removedOrders?: Array<string>
): boolean {
  let differentManufacturers = false;
  let firstManufacturer;
  for (let i = 0; i < orders.length; i++) {
    const o = orders[i];
    if (removedOrders?.includes(o._id.toString())) continue;
    if (!firstManufacturer) firstManufacturer = o.settings.manufacturer.toString();
    if (o.settings.manufacturer.toString() !== firstManufacturer) {
      differentManufacturers = true;
      break;
    }
  }
  return differentManufacturers;
}

/**
 * Get an update object for commodity orders if a commodity is removed or replaced
 * @param commodityId id of the removed/replaced commodity
 * @param order custom order document
 * @param commodities list of commodities
 * @returns { UpdateAction | null } update action or null if no update necessary
 */
export function getCommodityOrderUpdate(
  commodityId: string,
  order: CustomOrder,
  commodities: Array<CommoditiesDocument>
): UpdateAction | null {
  const commodity: CommoditiesDocument | null = baseUtils.getDocFromCollection(commodities, commodityId);
  if (!commodity) return null;
  const commodityOrder = commodity.orders
    ? commodity.orders.find(comOrd => comOrd.orders.some(o => o.toString() === order._id.toString()))
    : null;
  if (!commodityOrder) return null;
  // Update required
  const update: UpdateAction = {
    collection: COMMODITIES,
    filter: { _id: commodity._id },
    pull: { "orders.$[o].orders": order._id },
    arrayFilters: [{ "o.orders": order._id }]
  };
  const stockTransferOrder: StockTransferOrder | undefined = commodityOrder?.stockTransferOrders?.find(
    sto => sto.destination.toString() === order.settings.manufacturer._id.toString()
  );
  // Update stock transfer order as well
  if (stockTransferOrder) {
    // @ts-ignore
    update.pull["orders.$[o].stockTransferOrders.$[sto].orderIds"] = order._id;
    update.arrayFilters?.push({ "sto.orderIds": order._id });
  }
  return update;
}

// eslint-disable-next-line
export default {
  updateCommodityWithTimeline,
  calculateAllocatedStock,
  calculateTotalAllocatedStock,
  calculateStockValues,
  calculateUsage,
  doCommoditySearch,
  findLatestPriceUpdate,
  getLowestPrice,
  getLowestDeliveryTime,
  getFastestPrice,
  getAllStock,
  getCategoryColor,
  getUsageMap,
  getCommodityUsage,
  getCommodityOrderUpdate,
  getEMORequiredMap,
  getStockValuesForLocation,
  getStockValues,
  hasCorrectType,
  isCommodity,
  isCommodityOrder,
  isCommodityApproved,
  getCommodityType,
  prepareCommodityOrderTimeline,
  prepareSuppliersUpdate,
  resolveDeliveryDate,
  resolveForecastIconClasses,
  resolveStockUnit,
  isFiller
};
