import React, { useCallback, useRef, useState } from "react";
import { Overlay, Popover } from "react-bootstrap";
import { toAbsoluteUrl } from "../../../_metronic";
import i18n from "../../../translations/i18n";
import { Picklist } from "../../../model/warehouse/movement.types";

interface MovementContextMenuProps {
  file: Picklist | undefined;
}

export const MovementContextMenu: React.FC<MovementContextMenuProps> = ({ file }) => {
  const overlayRef: React.RefObject<HTMLSpanElement> = useRef(null);
  const [show, setShow] = useState(false);

  const handleShow = useCallback((e: React.MouseEvent<HTMLSpanElement>) => {
    e.stopPropagation();
    setShow(prevShow => !prevShow);
  }, []);

  const handleHide = useCallback(() => setShow(false), []);

  // TODO AC-689 re-enable options for picklist generation and add functionality
  return (
    <>
      {file ? (
        <a className="kt-user-card-v2" href={file.path} target={"_blank"}>
          <img src={toAbsoluteUrl("/media/files/pdf.svg")} style={{ height: "20px" }} />
        </a>
      ) : (
        <>
          <span className="pointer" ref={overlayRef}>
            <img
              src={toAbsoluteUrl("/media/files/pdf.svg")}
              alt="pdf"
              style={{ height: 20, opacity: "25%" }}
              onClick={handleShow}
            />
          </span>
          <Overlay rootClose show={show} onHide={handleHide} target={overlayRef.current} placement="bottom-start">
            <Popover id="dropdown-popover">
              <div className="dropdown-menu show" aria-labelledby="dropdownMenuLink">
                <span className="dropdown-item text-black cursorhand disabled">
                  {i18n.t("warehouse:picklistCurrentMovement")}
                </span>
                <span className="dropdown-item text-black cursorhand disabled">
                  {i18n.t("warehouse:picklistMultipleMovements", { amount: 10 })}
                </span>
                <span className="dropdown-item text-black cursorhand disabled">
                  {i18n.t("warehouse:picklistMultipleMovements", { amount: 25 })}
                </span>
                <span className="dropdown-item text-black cursorhand disabled">
                  {i18n.t("warehouse:picklistAllMovements")}
                </span>
              </div>
            </Popover>
          </Overlay>
        </>
      )}
    </>
  );
};
