import _ from "lodash";
import { BSON } from "realm-web";
import React, { useCallback, useRef, useState } from "react";
import { Overlay, Popover } from "react-bootstrap";
import { toast } from "react-toastify";
import i18n from "../../../../translations/i18n";
import { useWarehouseDispatch, WarehouseActionType } from "../../../../context/warehouseContext";
import { WarehouseActionNumber } from "../../../../utils/warehouseActionUtils";
import { Delivery, DeliveryState, DeliveryTimelineEntry } from "../../../../model/warehouse/delivery.types";
import userService from "../../../../services/userService";
import dbService, { DELIVERY, UpdateAction } from "../../../../services/dbService";
import { useDataContext } from "../../../../context/dataContext";

interface DeliveryContextMenuProps {
  delivery: Delivery;
}

export const DeliveryContextMenu: React.FC<DeliveryContextMenuProps> = ({ delivery }) => {
  const dataContext = useDataContext();
  const { updateDocumentInContext } = dataContext;
  const dispatch = useWarehouseDispatch();

  const overlayRef: React.RefObject<HTMLSpanElement> = useRef(null);

  const [show, setShow] = useState(false);
  const [saving, setSaving] = useState(false);

  const handleShow = useCallback((e: React.MouseEvent<HTMLSpanElement>) => {
    e.stopPropagation();
    setShow(prevShow => !prevShow);
  }, []);

  const handleHide = useCallback(() => setShow(false), []);

  const handleTriggerAction = useCallback(
    (number: WarehouseActionNumber) => {
      setShow(false);
      dispatch({
        type: WarehouseActionType.TRIGGER_ACTION,
        payload: { actionNumber: number, deliveryId: delivery._id.toString() }
      });
    },
    [delivery._id]
  );

  const handleAddTrackingNumber = useCallback(
    () => handleTriggerAction(WarehouseActionNumber.ADD_TRACKING_INFORMATION),
    []
  );

  const handleDelivery = useCallback(async () => {
    if (saving) return;
    setSaving(true);
    try {
      const deliveryDate = new Date();
      const pre: Partial<Delivery> = {};
      const post: Partial<Delivery> = {};
      if (delivery.state !== DeliveryState.DELIVERED) {
        pre.state = delivery.state;
        post.state = DeliveryState.DELIVERED;
      }
      if (!_.isEqual(delivery.deliveryDate, deliveryDate)) {
        if (delivery.deliveryDate !== undefined) pre.deliveryDate = delivery.deliveryDate; // avoid null value in case of undefined
        post.deliveryDate = deliveryDate;
      }
      const timelineEntry: DeliveryTimelineEntry = {
        _id: new BSON.ObjectId(),
        date: new Date(),
        person: userService.getUserSnapshot(),
        payload: {},
        diff: { pre, post }
      };
      const action: UpdateAction = {
        collection: DELIVERY,
        filter: { _id: new BSON.ObjectId(delivery._id.toString()) },
        update: { state: DeliveryState.DELIVERED, deliveryDate },
        push: { timeline: timelineEntry }
      };
      const result = await dbService.transaction([action]);
      if (result) {
        toast.success(i18n.t("warehouse:deliveryDeliveredSuccess"));
        await updateDocumentInContext(DELIVERY, delivery._id);
        handleHide();
      } else {
        toast.error(i18n.t("warehouse:deliveryDeliveredError"));
      }
    } finally {
      setSaving(false);
    }
  }, [delivery, saving]);

  return (
    <>
      <span className="kt-user-card-v2__email mt-0" ref={overlayRef}>
        <button className="btn btn-sm btn-danger py-0 my-0" style={{ width: 110 }} onClick={handleShow}>
          {delivery.state === DeliveryState.DELIVERED ? i18n.t("warehouse:arrived") : i18n.t("warehouse:open")}
        </button>
      </span>
      <Overlay rootClose show={show} onHide={handleHide} target={overlayRef.current} placement="bottom-start">
        <Popover id="dropdown-popover">
          <div className="dropdown-menu show" aria-labelledby="dropdownMenuLink">
            <span className="dropdown-item text-black cursorhand" onClick={handleAddTrackingNumber}>
              {i18n.t("warehouse:trackingNumber")}
            </span>
            <span
              className={"dropdown-item " + (saving ? "disabled" : "cursorhand text-black")}
              onClick={handleDelivery}
            >
              {i18n.t("warehouse:delivered")}
            </span>
          </div>
        </Popover>
      </Overlay>
    </>
  );
};
