import React from "react";
import { Accordion } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { RouteComponentProps } from "react-router-dom";
import { toAbsoluteUrl } from "../../_metronic";
import CompanyWidget from "../common/CompanyWidget";
import PersonWidget from "../common/PersonWidget";
import { OrderWidget } from "../listings/common/BaseListingComponents";
import config from "../../config/config.json";
import { DataContext } from "../../context/dataContext";
import { Invoice, OrdersDocument } from "../../model/orders.types";
import accessUtils, { ACTIONS } from "../../utils/accessUtils";
import baseUtils from "../../utils/baseUtils";
import invoiceUtils, { I_CANCELED, I_OPEN, I_PAID, I_PARTLYPAID } from "../../utils/invoiceUtils";
import orderUtils from "../../utils/orderUtils";
import dateUtils from "../../utils/dateUtils";

interface FinanceOverviewRowProps extends RouteComponentProps<{}, {}, {}> {
  order: OrdersDocument;
  context: React.ContextType<typeof DataContext>;
  status: string;
  onPayInvoice: (order: OrdersDocument, invoice: any) => void;
  activeKey: string;
}

const FinanceOverviewRow: React.FunctionComponent<FinanceOverviewRowProps> = ({
  order,
  context,
  status,
  onPayInvoice,
  activeKey,
  history
}) => {
  const { userdata, companies } = context;
  const company = baseUtils.getDocFromCollection(companies, order.createdFor);
  const invoices = invoiceUtils.sortInvoices(order).filter(i => invoiceUtils.matchesInvoiceStatus(i, status));
  if (invoices.length === 0) return null;
  const owner = baseUtils.getDocFromCollection(userdata, order.createdFrom);

  const getProgressColor = (invoice: Invoice) => {
    let progressColor = "bg-success";
    let progress = "100%";
    const dueDate = orderUtils.getInvoiceDue(invoice.dueIn, invoice.invoiceDate);
    const remainingDays = dateUtils.getDaysUntil(dueDate);
    if (invoice && invoice.state !== I_PAID) {
      if (remainingDays > 0) {
        progress = "25%";
      } else if (remainingDays <= 0 && remainingDays >= -7) {
        progressColor = "bg-warning";
      } else if (remainingDays < -7) {
        progressColor = "bg-danger";
      }
    }
    return [progress, progressColor];
  };

  const canPay = accessUtils.canPerformAction(ACTIONS.INVOICEPAY);
  const totalAmount = order.invoices!.reduce(
    (sum, i) =>
      sum +
      (!i.cancelation
        ? i.totalGross + i.reminder.reduce((interest, r) => interest + (r.position ? r.position.total : 0), 0)
        : 0),
    0
  );

  const calculatePartialPaymentSize = (part: number) => {
    return part / totalAmount;
  };

  const totalProgress =
    order.invoices!.reduce(
      (done, i) =>
        done +
        ([I_PAID, I_CANCELED].includes(i.state)
          ? 1
          : i.state === I_PARTLYPAID
          ? calculatePartialPaymentSize(i.totalGross)
          : 0),
      0
    ) / order.invoices!.length;

  return (
    <>
      <Accordion.Toggle
        as={"tr"}
        className={
          "kt-datatable__row table-hover d-table-row " +
          (activeKey === order._id.toString() ? "accordion-selection" : "collapsed")
        }
        eventKey={order._id.toString()}
      >
        <td className="kt-datatable__cell d-table-cell">
          <span className="svg-icon svg-icon-rotate svg-icon-2x svg-icon-dark align-self-center">
            <SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Angle-right.svg")} />
          </span>
        </td>
        <td className="kt-datatable__cell d-table-cell">
          <OrderWidget document={order} prefix={"Order " + orderUtils.resolveOrderDescription(order)[1] + "-"} />
        </td>
        <td className="kt-datatable__cell d-table-cell">
          <span>
            <CompanyWidget company={company} type={"company"} />
          </span>
        </td>
        <td className="kt-datatable__cell d-table-cell">
          <span>
            <div className="kt-user-card-v2">
              <div className="kt-user-card-v2__details">
                <span className="kt-user-card-v2__name">{baseUtils.formatEuro(totalAmount)}</span>
                <span className="kt-user-card-v2__email">
                  over {order.invoices!.filter(i => !i.cancelation).length} invoices
                </span>
              </div>
            </div>
          </span>
        </td>
        <td className="kt-datatable__cell d-table-cell">
          <span>
            <div className="progress">
              <div
                className="progress-bar bg-success"
                role="progressbar"
                style={{ width: totalProgress * 100 + "%" }}
              />
            </div>
          </span>
        </td>
        <td className="kt-datatable__cell d-table-cell">
          <span>
            <PersonWidget person={owner} />
          </span>
        </td>
      </Accordion.Toggle>
      <tr>
        <td colSpan={6}>
          <Accordion.Collapse eventKey={order._id.toString()}>
            <div className="kt-portlet__body kt-portlet__body--fit" style={{ backgroundColor: "#fafafa" }}>
              <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--loaded overflow-auto mx-4 my-2">
                <table className="kt-datatable__table d-table">
                  <thead className="kt-datatable__head" style={{ display: "table-header-group" }}>
                    <tr className="kt-datatable__row d-table-row">
                      <th className="kt-datatable__cell d-table-cell" style={{ width: "15%" }}>
                        <span>Nr / Type</span>
                      </th>
                      <th className="kt-datatable__cell d-table-cell" style={{ width: "15%" }}>
                        <span>Total brutto</span>
                      </th>
                      <th className="kt-datatable__cell d-table-cell" style={{ width: "10%" }}>
                        <span>File</span>
                      </th>
                      <th className="kt-datatable__cell d-table-cell" style={{ width: "10%" }}>
                        <span>State</span>
                      </th>
                      <th className="kt-datatable__cell d-table-cell" style={{ width: "10%" }}>
                        <span>Due / Date</span>
                      </th>
                      <th className="kt-datatable__cell d-table-cell" style={{ width: "10%" }}>
                        <span>Progress</span>
                      </th>
                      <th className="kt-datatable__cell d-table-cell" style={{ width: "10%" }}>
                        <span>Action</span>
                      </th>
                      <th className="kt-datatable__cell d-table-cell" style={{ width: "20%" }}>
                        <span>Invoice issuer</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {invoices.map(invoice => {
                      const [progress2, progressColor2] = getProgressColor(invoice);
                      const dueDate = orderUtils.getInvoiceDue(invoice.dueIn, invoice.invoiceDate);
                      const remainingDays = dateUtils.getDaysUntil(dueDate);
                      const invoiceIssuer = baseUtils.getDocFromCollection(userdata, invoice.person);
                      return (
                        <React.Fragment key={invoice._id.toString()}>
                          <tr className="kt-datatable__row table-hover d-table-row">
                            <td className="kt-datatable__cell d-table-cell">
                              <span>
                                <div className="kt-user-card-v2">
                                  <div className="kt-user-card-v2__details">
                                    <span className="kt-user-card-v2__name">RE-{invoice.invoiceNumber}</span>
                                  </div>
                                </div>
                              </span>
                            </td>
                            <td className="kt-datatable__cell d-table-cell">
                              <span>
                                <div className="kt-user-card-v2">
                                  <div className="kt-user-card-v2__details">
                                    <span className="kt-user-card-v2__name">
                                      {baseUtils.formatEuro(invoice.totalGross)}
                                    </span>
                                    <span className="kt-user-card-v2__email">
                                      {invoiceUtils.resolveInvoiceType(invoice)}
                                    </span>
                                  </div>
                                </div>
                              </span>
                            </td>
                            <td className="kt-datatable__cell d-table-cell">
                              <span>
                                <div className="kt-widget4__pic kt-widget4__pic--icon pointer">
                                  <img
                                    src={toAbsoluteUrl("/media/icons/pdf_icon.png")}
                                    alt="Invoice PDF"
                                    style={{ width: "30px" }}
                                    onClick={() =>
                                      window.open((process.env.REACT_APP_MEDIAHUB_BASE || "") + invoice.path, "_blank")
                                    }
                                  />
                                </div>
                              </span>
                            </td>
                            <td className="kt-datatable__cell d-table-cell">
                              <span>
                                {invoice.state === I_PAID ? (
                                  <span className="kt-badge kt-badge--success kt-badge--inline kt-badge--pill">
                                    Paid
                                  </span>
                                ) : invoice.state === I_PARTLYPAID ? (
                                  <span className="kt-badge kt-badge--success kt-badge--inline kt-badge--pill">
                                    Partially Paid
                                  </span>
                                ) : invoice.state === I_OPEN ? (
                                  <span className="kt-badge kt-badge--success kt-badge--inline kt-badge--pill">
                                    Open
                                  </span>
                                ) : invoice.state === I_CANCELED ? (
                                  <span className="kt-badge kt-badge--light kt-badge--inline kt-badge--pill">
                                    Canceled
                                  </span>
                                ) : null}
                              </span>
                            </td>
                            <td className="kt-datatable__cell d-table-cell">
                              <span>
                                <div className="kt-user-card-v2">
                                  <div className="kt-user-card-v2__details">
                                    <span className="kt-user-card-v2__name">{baseUtils.formatDate(dueDate)}</span>
                                    <span className="kt-user-card-v2__email">
                                      {![I_PAID, I_CANCELED].includes(invoice.state) &&
                                        (remainingDays > 0 ? "in " : "overdue since ") +
                                          Math.round(Math.abs(remainingDays)) +
                                          "d"}
                                    </span>
                                  </div>
                                </div>
                              </span>
                            </td>
                            <td className="kt-datatable__cell d-table-cell">
                              <span>
                                <div className="progress">
                                  <div
                                    className={"progress-bar " + progressColor2}
                                    role="progressbar"
                                    style={{ width: progress2 }}
                                  />
                                </div>
                              </span>
                            </td>
                            <td className="kt-datatable__cell d-table-cell">
                              <span>
                                {invoice.state === I_PAID ? (
                                  <span className="text-success kt-font-bolder">
                                    <i className="fa fa-check mr-1" />
                                    Paid
                                  </span>
                                ) : invoice.cancelation ? (
                                  <span className="text-warning kt-font-bolder">Cancelation</span>
                                ) : (
                                  <button
                                    className={"btn btn-success btn-sm" + (canPay ? "" : " disabled")}
                                    type="button"
                                    onClick={canPay ? () => onPayInvoice(order, invoice) : undefined}
                                    disabled={!canPay}
                                  >
                                    Invoice paid
                                  </button>
                                )}
                              </span>
                            </td>
                            <td className="kt-datatable__cell d-table-cell">
                              <span>
                                <PersonWidget person={invoiceIssuer} />
                              </span>
                            </td>
                          </tr>
                          {invoice.payments.length > 0 &&
                            invoice.payments.map(p => (
                              <tr key={p._id.toString()} className="kt-datatable__row table-hover d-table-row">
                                <td className="kt-datatable__cell d-table-cell">
                                  <div className="kt-user-card-v2">
                                    <div className="kt-user-card-v2__details">
                                      <span className="kt-user-card-v2__name">Payment</span>
                                    </div>
                                  </div>
                                </td>
                                <td className="kt-datatable__cell d-table-cell">
                                  <span>
                                    <div className="kt-user-card-v2">
                                      <div className="kt-user-card-v2__details">
                                        <span className="kt-user-card-v2__name">{baseUtils.formatEuro(p.amount)}</span>
                                        {p.amount !== invoice.totalGross && (
                                          <span className="kt-user-card-v2__email">Partial Payment</span>
                                        )}
                                      </div>
                                    </div>
                                  </span>
                                </td>
                                <td className="kt-datatable__cell d-table-cell">
                                  <span>
                                    <div className="kt-widget4__pic kt-widget4__pic--icon pointer">
                                      <img
                                        src={toAbsoluteUrl("/media/icons/pdf_icon.png")}
                                        alt="Invoice PDF"
                                        style={{ width: "30px", opacity: p.path ? 1 : 0.4 }}
                                        onClick={
                                          p.path
                                            ? () =>
                                                window.open(
                                                  (process.env.REACT_APP_MEDIAHUB_BASE || "") + p.path,
                                                  "_blank"
                                                )
                                            : undefined
                                        }
                                      />
                                    </div>
                                  </span>
                                </td>
                                <td className="kt-datatable__cell d-table-cell" />
                                <td className="kt-datatable__cell d-table-cell">
                                  <span>
                                    <div className="kt-user-card-v2">
                                      <div className="kt-user-card-v2__details">
                                        <span className="kt-user-card-v2__name">{baseUtils.formatDate(p.date)}</span>
                                        <span className="kt-user-card-v2__email" />
                                      </div>
                                    </div>
                                  </span>
                                </td>
                                <td className="kt-datatable__cell d-table-cell">
                                  <span>
                                    <div className="progress">
                                      <div
                                        className={"progress-bar " + progressColor2}
                                        role="progressbar"
                                        style={{ width: progress2 }}
                                      />
                                    </div>
                                  </span>
                                </td>
                                <td className="kt-datatable__cell d-table-cell">
                                  <span>
                                    {invoice.state === I_PAID ? (
                                      <span className="text-success kt-font-bolder">
                                        <i className="fa fa-check mr-1" />
                                        Paid
                                      </span>
                                    ) : (
                                      <span className="text-warning kt-font-bolder">Partially Paid</span>
                                    )}
                                  </span>
                                </td>
                                <td className="kt-datatable__cell d-table-cell">
                                  <span>
                                    <PersonWidget person={invoiceIssuer} />
                                  </span>
                                </td>
                              </tr>
                            ))}
                          {invoice.reminder.length > 0 &&
                            invoice.reminder.map(r => (
                              <tr key={r._id.toString()} className="kt-datatable__row table-hover d-table-row">
                                <td className="kt-datatable__cell d-table-cell">
                                  <div className="kt-user-card-v2">
                                    <div className="kt-user-card-v2__details">
                                      <span className="kt-user-card-v2__name">Reminder</span>
                                    </div>
                                  </div>
                                </td>
                                <td className="kt-datatable__cell d-table-cell">
                                  <span>
                                    <div className="kt-user-card-v2">
                                      <div className="kt-user-card-v2__details">
                                        <span className="kt-user-card-v2__name">
                                          {r.position ? baseUtils.formatEuro(r.position.total) : ""}
                                        </span>
                                        <span className="kt-user-card-v2__email">Interest: {r.interest}%</span>
                                      </div>
                                    </div>
                                  </span>
                                </td>
                                <td className="kt-datatable__cell d-table-cell">
                                  <span>
                                    <div className="kt-widget4__pic kt-widget4__pic--icon pointer">
                                      <img
                                        src={toAbsoluteUrl("/media/icons/pdf_icon.png")}
                                        alt="Invoice PDF"
                                        style={{ width: "30px" }}
                                        onClick={() =>
                                          window.open((process.env.REACT_APP_MEDIAHUB_BASE || "") + r.path, "_blank")
                                        }
                                      />
                                    </div>
                                  </span>
                                </td>
                                <td className="kt-datatable__cell d-table-cell" />
                                <td className="kt-datatable__cell d-table-cell">
                                  <span>
                                    <div className="kt-user-card-v2">
                                      <div className="kt-user-card-v2__details">
                                        <span className="kt-user-card-v2__name">{baseUtils.formatDate(r.date)}</span>
                                        <span className="kt-user-card-v2__email" />
                                      </div>
                                    </div>
                                  </span>
                                </td>
                                <td className="kt-datatable__cell d-table-cell">
                                  <span>
                                    <div className="progress">
                                      <div
                                        className={"progress-bar " + progressColor2}
                                        role="progressbar"
                                        style={{ width: progress2 }}
                                      />
                                    </div>
                                  </span>
                                </td>
                                {r.position ? (
                                  <td className="kt-datatable__cell d-table-cell">
                                    <span>
                                      {invoice.state === I_PAID ? (
                                        <span className="text-success kt-font-bolder">
                                          <i className="fa fa-check mr-1" />
                                          Paid
                                        </span>
                                      ) : invoice.cancelation ? (
                                        <span className="text-warning kt-font-bolder">
                                          <i className="fa fa-exclamation mr-1" />
                                          Cancelation
                                        </span>
                                      ) : (
                                        <button
                                          className={"btn btn-success btn-sm" + (canPay ? "" : " disabled")}
                                          type="button"
                                          onClick={canPay ? () => onPayInvoice(order, invoice) : undefined}
                                          disabled={!canPay}
                                        >
                                          Invoice paid
                                        </button>
                                      )}
                                    </span>
                                  </td>
                                ) : (
                                  <td />
                                )}
                                <td className="kt-datatable__cell d-table-cell">
                                  <span>
                                    <PersonWidget person={invoiceIssuer} />
                                  </span>
                                </td>
                              </tr>
                            ))}
                          {invoice.cancelation && (
                            <tr
                              key={invoice.cancelation.cancelationNumber}
                              className="kt-datatable__row table-hover d-table-row"
                            >
                              <td className="kt-datatable__cell d-table-cell">
                                <div className="kt-user-card-v2">
                                  <div className="kt-user-card-v2__details">
                                    <span className="kt-user-card-v2__name">Cancelation</span>
                                  </div>
                                </div>
                              </td>
                              <td className="kt-datatable__cell d-table-cell">
                                <span>
                                  <div className="kt-user-card-v2">
                                    <div className="kt-user-card-v2__details">
                                      <span className="kt-user-card-v2__name">
                                        {baseUtils.formatEuro(-invoice.totalGross)}
                                      </span>
                                      <span className="kt-user-card-v2__email">Cancelation</span>
                                    </div>
                                  </div>
                                </span>
                              </td>
                              <td className="kt-datatable__cell d-table-cell">
                                <span>
                                  <div className="kt-widget4__pic kt-widget4__pic--icon pointer">
                                    <img
                                      src={toAbsoluteUrl("/media/icons/pdf_icon.png")}
                                      alt="Invoice PDF"
                                      style={{ width: "30px" }}
                                      onClick={() =>
                                        window.open(
                                          (process.env.REACT_APP_MEDIAHUB_BASE || "") + invoice.cancelation!.path,
                                          "_blank"
                                        )
                                      }
                                    />
                                  </div>
                                </span>
                              </td>
                              <td className="kt-datatable__cell d-table-cell" />
                              <td className="kt-datatable__cell d-table-cell">
                                <span>
                                  <div className="kt-user-card-v2">
                                    <div className="kt-user-card-v2__details">
                                      <span className="kt-user-card-v2__name">
                                        Date: {baseUtils.formatDate(invoice.cancelation.date)}
                                      </span>
                                      <span className="kt-user-card-v2__email" />
                                    </div>
                                  </div>
                                </span>
                              </td>
                              <td className="kt-datatable__cell d-table-cell">
                                <span>
                                  <div className="progress">
                                    <div
                                      className={"progress-bar " + progressColor2}
                                      role="progressbar"
                                      style={{ width: progress2 }}
                                    />
                                  </div>
                                </span>
                              </td>
                              <td className="kt-datatable__cell d-table-cell" />
                              <td className="kt-datatable__cell d-table-cell">
                                <span>
                                  <PersonWidget person={invoiceIssuer} />
                                </span>
                              </td>
                            </tr>
                          )}
                        </React.Fragment>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </Accordion.Collapse>
        </td>
      </tr>
    </>
  );
};

export default FinanceOverviewRow;
