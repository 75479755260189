import React, { PureComponent, useContext } from "react";
import { BSON } from "realm-web";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";
import { toAbsoluteUrl } from "../../_metronic";
import { SupplierFile, SuppliersDocument } from "../../model/suppliers.types";
import { DataContext } from "../../context/dataContext";
import fileUtils from "../../utils/fileUtils";
import baseUtils from "../../utils/baseUtils";
import dbService, { SUPPLIERS } from "../../services/dbService";
import userService from "../../services/userService";
import dateUtils from "../../utils/dateUtils";
import config from "../../config/config.json";

interface SupplierDocumentsProps {
  supplier: SuppliersDocument;
}

interface SupplierDocumentsState {}

class SupplierDocuments extends PureComponent<SupplierDocumentsProps, SupplierDocumentsState> {
  /**
   * Upload a supplier file
   * @param path path to the file
   * @param title title of the file
   */
  handleUpload = async (path: string, title: string) => {
    const collection = dbService.getDBCollection(SUPPLIERS);
    const file: SupplierFile = {
      _id: new BSON.ObjectId(),
      date: new Date(),
      path: path,
      title: title,
      person: userService.getUserId()
    };
    try {
      const result = await collection?.updateOne({ _id: this.props.supplier._id }, { $push: { files: file } });
      if (result && result.modifiedCount) {
        toast.success("Document uploaded successfully");
      } else toast.error("Document could not be uploaded");
    } catch (e) {
      console.error(e);
      toast.error("An unexpected error occurred: " + e.message);
    } finally {
      this.setState({ saving: false });
    }
  };

  /**
   * Remove a file from supplier files
   * @param file file to remove
   */
  handleDelete = async (file: SupplierFile) => {
    const collection = dbService.getDBCollection(SUPPLIERS);
    try {
      const result = await collection?.updateOne({ _id: this.props.supplier._id }, { $pull: { files: file } });
      if (result && result.modifiedCount) {
        toast.success("Document successfully removed");
      } else toast.error("Document could not be deleted");
    } catch (e) {
      console.error(e);
      toast.error("An unexpected error occurred: " + e.message);
    } finally {
      this.setState({ saving: false });
    }
  };

  render() {
    const { supplier } = this.props;
    const files = supplier.files ? supplier.files : [];
    return (
      <div className="kt-notification" style={{ minHeight: "300px" }}>
        <div className="kt-widget4">
          <SupplierDocumentUpload supplier={supplier} onUpload={this.handleUpload} />
          {files.map(file => (
            <SupplierDocument key={file._id.toString()} file={file} onDelete={() => this.handleDelete(file)} />
          ))}
        </div>
      </div>
    );
  }
}

interface SupplierDocumentProps {
  file: SupplierFile;
  onDelete: () => void;
}

const SupplierDocument: React.FunctionComponent<SupplierDocumentProps> = ({ file, onDelete }) => {
  const context = useContext(DataContext);
  const uploader = baseUtils.getDocFromCollection(context.userdata, file.person);
  return (
    <div className="kt-widget4__item">
      <div className="kt-widget4__pic kt-widget4__pic--icon">
        <img src={toAbsoluteUrl("/media/icons/pdf_icon.png")} alt="" />
      </div>
      <div className="kt-widget4__info">
        <div>
          <a href={file.path} target="_blank" rel="noopener noreferrer" className="kt-widget4__username mr-2">
            {file.title}
          </a>
        </div>
        <p className="kt-widget4__text">
          Uploaded by {uploader ? uploader.prename + " " + uploader.surname : "Unknown"}
        </p>
        <p className="kt-widget4__text">{dateUtils.getTimeAgo(file.date)}</p>
      </div>
      <button className="btn btn-secondary btn-sm p-2" onClick={onDelete}>
        <i className="fa fa-trash text-secondary px-1" />
      </button>
    </div>
  );
};

interface SupplierDocumentUploadProps {
  supplier: SuppliersDocument;
  onUpload: (path: string, title: string) => void;
}

interface SupplierDocumentUploadState {
  show: boolean;
  uploading: boolean;
  message: string | JSX.Element;
  note: string | JSX.Element;
  file: File | null;
  title: string;
}

class SupplierDocumentUpload extends PureComponent<SupplierDocumentUploadProps, SupplierDocumentUploadState> {
  ref: any;
  constructor(props: SupplierDocumentUploadProps) {
    super(props);
    this.ref = React.createRef();
    this.state = {
      show: false,
      uploading: false,
      message: "Click here to upload any PDF document",
      note: "max. file size 128 MB",
      file: null,
      title: ""
    };
  }

  handleClose = () => {
    // reset value of input field to always trigger on change
    this.ref.current.value = "";
    this.setState({ show: false, title: "" });
  };

  handleTitle = (e: React.ChangeEvent<HTMLInputElement>) => this.setState({ title: e.target.value });

  handleSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target && e.target.files ? e.target.files[0] : null;
    if (file && file.size > 128 * 1000 * 1000) {
      this.ref.current.value = "";
      this.setState({
        file: null,
        message: (
          <span className="text-danger">File too large: {Math.round((file.size / 1000 / 1000) * 100) / 100} MB.</span>
        )
      });
    } else {
      this.setState({ file: file, show: true });
    }
  };

  handleUploadFile = () => {
    const { supplier } = this.props;
    const { file, title } = this.state;
    if (!file) return;
    this.setState({
      message: "Uploading...",
      note: "This may take a few seconds",
      uploading: true
    });
    const xhr = new XMLHttpRequest();
    let isodate = new Date().toISOString();
    isodate = isodate.split(":").join("-");
    isodate = isodate.split(".").join("-");
    const fileType = fileUtils.getFileExtension(file.name);
    const fileName = "Document-" + supplier._id.toString() + "-" + isodate + "." + fileType;
    xhr.open("POST", (process.env.REACT_APP_UPLOAD_ENDPOINT || "") + fileName, true);
    xhr.setRequestHeader("Content-Type", "application/octate-stream");
    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          this.setState({
            message: (
              <span className="text-success">
                File successfully uploaded!&nbsp;
                <i className="far fa-check-circle text-success" />
              </span>
            ),
            note: "More files to upload? Click to continue",
            uploading: false
          });
          this.props.onUpload((process.env.REACT_APP_MEDIAHUB_FILE_BASE || "") + xhr.responseText, title);
          this.handleClose();
        } else {
          this.setState({
            message: <span className="text-danger">Sorry! We can't upload your file at the moment.</span>,
            note: "This may be an error. Feel free to report it.",
            uploading: false
          });
        }
      }
    };
    xhr.send(file);
  };

  render() {
    const { supplier } = this.props;
    const { note, message, show, uploading, title } = this.state;
    return (
      <>
        <Modal show={show} id="uploadSupplierDocumentModal" size={"sm"} onHide={this.handleClose} centered>
          <Modal.Header closeButton>
            <Modal.Title>Upload Document for {supplier.name}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group">
              <label className="text-dark" htmlFor="title">
                Document title
              </label>
              <input className="form-control" value={title} id={"title"} onChange={this.handleTitle} />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              className={"btn btn-secondary " + (uploading && "disabled")}
              disabled={uploading}
              onClick={this.handleClose}
            >
              Close
            </button>
            <button
              type="button"
              className={
                "btn btn-success " + ((uploading || title.trim() === "" || title.trim().length < 5) && "disabled")
              }
              disabled={uploading || title.trim() === "" || title.trim().length < 5}
              onClick={this.handleUploadFile}
            >
              {uploading && (
                <div className="button-splash-spinner d-inline pr-3 pl-0 mx-0">
                  <svg className="button-splash-spinner" viewBox="0 0 50 50">
                    <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5" />
                  </svg>
                </div>
              )}
              Upload Document
            </button>
          </Modal.Footer>
        </Modal>
        <div className="kt-widget4__item">
          <div className="kt-widget4__info">
            <div className="dropzone dropzone-default dz-clickable dz-hover" onClick={() => this.ref.current.click()}>
              <div className="dropzone-msg dz-message needsclick">
                <h3 className="dropzone-msg-title">{message}</h3>
                <span className="dropzone-msg-desc">{note}</span>
              </div>
            </div>
          </div>
        </div>
        <input type="file" ref={this.ref} accept=".pdf" style={{ display: "none" }} onChange={this.handleSelectFile} />
      </>
    );
  }
}

export default SupplierDocuments;
