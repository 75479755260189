import React, { PureComponent } from "react";
import { toast } from "react-toastify";
import { BSON } from "realm-web";
import { Modal } from "react-bootstrap";
import { toAbsoluteUrl } from "../../../_metronic";
import { ConformityDeclaration, PackagingsDocument, PackagingSpecification } from "../../../model/packagings.types";
import { DataContext } from "../../../context/dataContext";
import dateUtils from "../../../utils/dateUtils";
import dbService, { PACKAGINGS } from "../../../services/dbService";
import { SuppliersDocument } from "../../../model/suppliers.types";
import fileUtils from "../../../utils/fileUtils";
import baseUtils from "../../../utils/baseUtils";

interface PackagingDocumentsProps {
  packaging: PackagingsDocument;
  context: React.ContextType<typeof DataContext>;
}

interface PackagingDocumentsState {}

class PackagingDocuments extends PureComponent<PackagingDocumentsProps, PackagingDocumentsState> {
  /**
   * Handle an uploaded specification and updates the packaging document
   * @param path path to the file
   * @param supplierId id of the supplier
   */
  handleUpload = async (path: string, supplierId: BSON.ObjectId) => {
    const collection = dbService.getDBCollection(PACKAGINGS);
    const specification: PackagingSpecification = {
      id: supplierId,
      date: new Date(),
      path: path,
      type: "specification"
    };
    try {
      const result = await collection?.updateOne(
        { _id: this.props.packaging._id },
        { $push: { specifications: specification } }
      );
      if (result && result.modifiedCount) {
        toast.success("Specification uploaded successfully");
      } else toast.error("Specification could not be uploaded");
    } catch (e) {
      console.error(e);
      toast.error("An unexpected error occurred: " + e.message);
    } finally {
      this.setState({ saving: false });
    }
  };

  /**
   * Delete a specification from a packaging document
   * @param specification the specification to delete
   */
  handleDelete = async (specification: PackagingSpecification | ConformityDeclaration) => {
    const collection = dbService.getDBCollection(PACKAGINGS);
    try {
      const result = await collection?.updateOne(
        { _id: this.props.packaging._id },
        { $pull: { specifications: specification } }
      );
      if (result && result.modifiedCount) {
        toast.success("Specification removed successfully");
      } else toast.error("Specification could not be deleted");
    } catch (e) {
      console.error(e);
      toast.error("An unexpected error occurred: " + e.message);
    } finally {
      this.setState({ saving: false });
    }
  };

  render() {
    const { packaging, context } = this.props;
    const files = packaging.specifications ? packaging.specifications : [];
    return (
      <div className="kt-notification" style={{ minHeight: "300px" }}>
        <div className="kt-widget4">
          <PackagingSpecificationUpload packaging={packaging} context={context} onUpload={this.handleUpload} />
          {files.map(file => (
            <PackagingDocumentsEntry
              key={file.id.toString() + file.date.toString()}
              entry={file}
              context={context}
              onDelete={() => this.handleDelete(file)}
            />
          ))}
        </div>
      </div>
    );
  }
}

interface PackagingDocumentsEntryProps {
  entry: PackagingSpecification | ConformityDeclaration;
  context: React.ContextType<typeof DataContext>;
  onDelete: () => void;
}

interface PackagingDocumentsEntryState {}

class PackagingDocumentsEntry extends PureComponent<PackagingDocumentsEntryProps, PackagingDocumentsEntryState> {
  render() {
    const { entry, onDelete, context } = this.props;
    const { suppliers } = context;
    const supplier = suppliers.find(s => s._id.toString() === entry.id.toString());
    return (
      <div className="kt-widget4__item">
        <div className="kt-widget4__pic kt-widget4__pic--icon">
          <img src={toAbsoluteUrl("/media/icons/pdf_icon.png")} alt="" />
        </div>
        <div className="kt-widget4__info">
          <div>
            <a href={entry.path} target="_blank" rel="noopener noreferrer" className="kt-widget4__username mr-2">
              Specification - {supplier ? supplier.name : "Unknown"}
            </a>
          </div>
          <p className="kt-widget4__text">{dateUtils.getTimeAgo(entry.date)}</p>
        </div>
        <button className="btn btn-danger btn-sm p-2" onClick={onDelete}>
          <i className="flaticon2-cross px-1" />
        </button>
      </div>
    );
  }
}

interface PackagingSpecificationUploadProps {
  packaging: PackagingsDocument;
  context: React.ContextType<typeof DataContext>;
  onUpload: (path: string, supplierId: BSON.ObjectId) => void;
}

interface PackagingSpecificationUploadState {
  show: boolean;
  uploading: boolean;
  message: string | JSX.Element;
  note: string | JSX.Element;
  selectedSupplier: SuppliersDocument | null;
  file: File | null;
}

class PackagingSpecificationUpload extends PureComponent<
  PackagingSpecificationUploadProps,
  PackagingSpecificationUploadState
> {
  ref: any;
  constructor(props: PackagingSpecificationUploadProps) {
    super(props);
    this.ref = React.createRef();
    this.state = {
      show: false,
      uploading: false,
      message: "Click here to upload a specification file",
      note: "After uploading a file (*.pdf) the supplier can be selected",
      file: null,
      selectedSupplier: null
    };
  }

  componentDidMount() {
    const { packaging, context } = this.props;
    if (packaging.suppliers && packaging.suppliers.length > 0) {
      const supplier = context.suppliers.find(s => s._id.toString() === packaging.suppliers[0]._id.toString());
      if (supplier) this.setState({ selectedSupplier: supplier });
    }
  }

  handleSupplierChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const supplier = this.props.context.suppliers.find(s => s._id.toString() === e.target.value);
    if (supplier) this.setState({ selectedSupplier: supplier });
  };

  handleClose = () => {
    // reset value of input field to always trigger on change
    this.ref.current.value = "";
    this.setState({ show: false });
  };

  handleSelectFile = (e: React.ChangeEvent<HTMLInputElement>) =>
    this.setState({ file: e.target && e.target.files ? e.target.files[0] : null, show: true });

  handleUploadFile = () => {
    const { file, selectedSupplier } = this.state;
    if (!file || !selectedSupplier) return;
    this.setState({
      message: "Uploading...",
      note: "This may take a few seconds",
      uploading: true
    });
    const xhr = new XMLHttpRequest();
    let isodate = new Date().toISOString();
    isodate = isodate.split(":").join("-");
    isodate = isodate.split(".").join("-");
    const fileType = fileUtils.getFileExtension(file.name);
    const fileName =
      "Specifications-" +
      baseUtils.encodeString(selectedSupplier.name) +
      "-" +
      selectedSupplier._id.toString() +
      "-" +
      isodate +
      "." +
      fileType;
    xhr.open("POST", (process.env.REACT_APP_UPLOAD_ENDPOINT || "") + fileName, true);
    xhr.setRequestHeader("Content-Type", "application/octate-stream");
    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          this.setState({
            message: (
              <span className="text-success">
                Specification successfully uploaded!&nbsp;
                <i className="far fa-check-circle text-success" />
              </span>
            ),
            note: "More files to upload? Click to continue",
            uploading: false
          });
          this.props.onUpload(
            (process.env.REACT_APP_MEDIAHUB_FILE_BASE || "") + xhr.responseText,
            selectedSupplier._id
          );
          this.handleClose();
        } else {
          this.setState({
            message: <span className="text-danger">Sorry! We can't upload your file at the moment.</span>,
            note: "This may be an error. Feel free to report it.",
            uploading: false
          });
        }
      }
    };
    xhr.send(file);
  };

  render() {
    const { packaging, context } = this.props;
    const { note, message, show, selectedSupplier, uploading } = this.state;
    const { suppliers } = context;
    const supplierList = packaging.suppliers
      .map(s => suppliers.find(s2 => s._id.toString() === s2._id.toString()))
      .filter(e => !!e);
    return (
      <>
        <Modal show={show} id="uploadSpecificationModal" onHide={this.handleClose} centered>
          <Modal.Header closeButton>
            <Modal.Title>Upload Specifications</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group">
              <label htmlFor="supplierSelect">Upload specifications for</label>
              <select
                className="form-control"
                id="supplierSelect"
                onChange={this.handleSupplierChange}
                value={selectedSupplier ? selectedSupplier._id.toString() : "Not set"}
                disabled={supplierList.length < 2}
              >
                {supplierList.map(supplier => (
                  <option value={supplier!._id.toString()} key={supplier!._id.toString()}>
                    {supplier!.name}
                  </option>
                ))}
              </select>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              className={"btn btn-secondary " + (uploading && "disabled")}
              disabled={uploading}
              onClick={this.handleClose}
            >
              Close
            </button>
            <button
              type="button"
              className={"btn btn-success " + (uploading && "disabled")}
              disabled={uploading}
              onClick={this.handleUploadFile}
            >
              {uploading && (
                <div className="button-splash-spinner d-inline pr-3 pl-0 mx-0">
                  <svg className="button-splash-spinner" viewBox="0 0 50 50">
                    <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5" />
                  </svg>
                </div>
              )}
              Upload Specifications
            </button>
          </Modal.Footer>
        </Modal>
        <div className="kt-widget4__item">
          <div className="kt-widget4__info">
            <div className="dropzone dropzone-default dz-clickable dz-hover" onClick={() => this.ref.current.click()}>
              <div className="dropzone-msg dz-message needsclick">
                <h3 className="dropzone-msg-title">{message}</h3>
                <span className="dropzone-msg-desc">{note}</span>
              </div>
            </div>
          </div>
        </div>
        <input type="file" ref={this.ref} accept=".pdf" style={{ display: "none" }} onChange={this.handleSelectFile} />
      </>
    );
  }
}

export default PackagingDocuments;
