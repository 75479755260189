import _ from "lodash";
import React from "react";
import { BSON } from "realm-web";
import i18n from "../../translations/i18n";
import { ActivesubstancesDocument } from "../../model/activesubstances.types";
import { calculation, OrdersDocument, Setting } from "../../model/orders.types";
import { PackagingsDocument } from "../../model/packagings.types";
import Packaging from "../../translations/de/packaging.json";
import PackagingEN from "../../translations/en/packaging.json";
import { CustomerData, CustomOrder, InvoicePosition } from "../../components/order/CustomTypes";
import { UserdataDocument } from "../../model/userdata.types";
import invoiceUtils, { I_REMINDER } from "../invoiceUtils";
import { PackagingTypes } from "../../components/configurator/configuratorConstants";
import { SuppliersDocument } from "../../model/suppliers.types";
import { CompaniesDocument } from "../../model/companies.types";
import { DataContext } from "../../context/dataContext";
import calculationUtils from "../calculationUtils";
import {
  T_CAPSULE,
  T_CUSTOM,
  T_LIQUID,
  T_POWDER,
  T_SERVICE,
  T_SOFTGEL,
  T_TABLET
} from "../../components/order/OrderHelper";
import baseUtils from "../baseUtils";
import orderUtils, { OFFER, REQUESTPENDING } from "../orderUtils";
import offerPDFGeneration from "./offerPDFGeneration";
import { T_NONBINDINGOFFERPDF, T_OFFERPDF, T_PRODUCTSPECIFICATION } from "../timelineUtils";
import dateUtils from "../dateUtils";
import { StickerForms } from "../../components/packaging/PackagingHelper";

export interface ExtendedPackaging extends PackagingsDocument {
  amount: number;
  supplier: BSON.ObjectId | "accumulatedstock" | "custom" | "customer" | "ownstock";
}

/**
 * Uploads the given html to the server to turn it into a PDF and returns the path where it was saved.
 * @param html Document that should be transformed
 * @returns { string } Path if processing was successful, empty string if not
 */
async function uploadAndReturnPath(html: string): Promise<string> {
  const res = await fetch(process.env.REACT_APP_MEDIAHUB_PROCESS_PDF || "", {
    method: "POST",
    headers: { "content-type": "application/json; charset=utf-8" },
    body: html
  });
  if (res) {
    return res.text();
  }
  return "";
}

/**
 * Creates offer PDF and uploads it
 * @param order the order that the offer is created for
 * @param context DataContext
 * @param isProductSpecification flag whether the offer is non-binding or binding
 * @param responsible optional, if set the given userdata is referenced on the offer
 * @returns { result: boolean, path: string } object that contains a flag whether the pdf could be created and the path to the pdf
 */
async function createOfferPDF(
  order: CustomOrder,
  context: React.ContextType<typeof DataContext>,
  isProductSpecification?: boolean,
  responsible?: UserdataDocument
) {
  const productSpecification =
    isProductSpecification !== undefined ? isProductSpecification : [OFFER, REQUESTPENDING].includes(order.state);
  const settings: Setting = {
    type: order.settings.type,
    perUnit: order.settings.perUnit,
    id: order.settings.id,
    manufacturer: order.settings.manufacturer._id,
    productionMachine: order.settings.productionMachine
  };
  const data = JSON.stringify({
    html: offerPDFGeneration.createOffer(
      order,
      settings,
      order.createdFor,
      order.calculations[0].packagings.length === 0,
      false,
      context,
      productSpecification,
      false,
      responsible
    ),
    fileName:
      "AN-" +
      order.identifier +
      "_V" +
      orderUtils.getPDFVersion(order, isProductSpecification ? T_PRODUCTSPECIFICATION : T_OFFERPDF) +
      "_" +
      dateUtils.timeStampDate() +
      ".pdf"
  });
  let path;
  try {
    path = await uploadAndReturnPath(data);
  } catch (e) {
    return { result: false, message: e.message };
  }
  return { result: true, path: path };
}

/**
 * Creates non-binding offer PDF and uploads it
 * @param order the order that the offer is created for
 * @param context DataContext
 * @returns { result: boolean, path: string } object that contains a flag whether the pdf could be created and the path to the pdf
 */
async function createNonBindingOfferPDF(order: CustomOrder, context: React.ContextType<typeof DataContext>) {
  const settings: Setting = {
    type: order.settings.type,
    perUnit: order.settings.perUnit,
    id: order.settings.id,
    manufacturer: order.settings.manufacturer._id,
    productionMachine: order.settings.productionMachine
  };
  const data = JSON.stringify({
    html: offerPDFGeneration.createOffer(
      order,
      settings,
      order.createdFor,
      order.calculations[0].packagings.length === 0,
      false,
      context,
      false,
      true
    ),
    fileName:
      "AN-" +
      order.identifier +
      "_V" +
      orderUtils.getPDFVersion(order, T_NONBINDINGOFFERPDF) +
      "_" +
      dateUtils.timeStampDate() +
      ".pdf"
  });
  let path;
  try {
    path = await uploadAndReturnPath(data);
  } catch (e) {
    return { result: false, message: e.message };
  }
  return { result: true, path: path };
}

/**
 * Get the amount of active substance.
 * @param substance: Active substance as defined in commodity document
 * @param amount: Amount of commodity that is used
 * @returns { number } active substance amount
 */
function getActiveSubstanceAmount(substance: { _id: BSON.ObjectId; value: number }, amount: number) {
  return (
    (parseFloat(substance.value.toString().split(",").join(".")) / 100) *
    parseFloat(amount.toString().split(",").join("."))
  );
}

/**
 * Get the NRV value of the active substance inside the commodity
 * @param substance: Active substance as defined in commodity document
 * @param substances: Active substances
 * @param amount: Amount of commodity that is used
 * @returns { string }: NRV in percentage
 */
function getActiveSubstanceNRVPercentage(
  substance: { _id: BSON.ObjectId; value: number },
  substances: Array<ActivesubstancesDocument>,
  amount: number
) {
  for (let i = 0; i < substances.length; i++) {
    if (substances[i]._id.toString() === substance._id.toString()) {
      if (substances[i].nrv) {
        return ((substance.value * amount) / substances[i].nrv!).toFixed(2) + "%";
      } else {
        return "";
      }
    }
  }
  return "";
}

/**
 * Get the accumulated weight of all used commodities in the given calculation.
 * @param calculation: Calculation whose weight should be calculated
 * @returns { number } Weight of the calculation
 */
function getCommodityWeight(calculation: calculation) {
  if (calculation.prices) return calculation.prices.reduce((a, b) => a + +b.amount, 0);
  return 0;
}

/**
 * Returns the first packaging matching the given type in the given list of packaging.
 * @param type: Type of packaging that is looked for
 * @param packaging: List of packagings
 * @returns { PackagingsDocument | null } The first matching document or null
 */
export function findPackagingByType(type: string, packaging: Array<PackagingsDocument>) {
  return packaging.find(p => p.packaging_type === type);
}

/**
 * Get packaging translation
 * @param key key to get the translation for
 * @param lang optional language parameter
 * @returns {string} translation for packaging
 */
function getTranslation(key: string | null | undefined, lang?: string) {
  const translation = lang === "en" ? PackagingEN : Packaging;
  if (!key) return "";
  return _.get(translation, key, _.upperFirst(key));
}

/**
 * Get html representation for a customer
 * @param customer customer data
 * @param vatID optional id of customer
 * @returns {string} html representation of the customer data
 */
function getCustomerHTML(customer: CustomerData, vatID?: string) {
  let html = `<b>${customer.name}</b><br>`;
  if (customer.additionalAddress.length > 0) {
    html += customer.additionalAddress + "<br>";
  }
  html += `${customer.street}<br>${customer.zip} ${customer.city}<br>${customer.country}`;
  if (vatID) html += `UStIdNr / VAT-ID: ${vatID}`;
  return html;
}

/**
 * Get html representation for any company/supplier
 * @param company company or supplier document
 * @returns {string} html representation of the company/supplier data
 */
function getCompanyHTML(company: SuppliersDocument | CompaniesDocument) {
  return `<b>${company.name}</b><br>${company.address[0].street} ${company.address[0].streetnr}<br>${company.address[0].zip} ${company.address[0].city}<br>${company.address[0].country}`;
}

/**
 * Get html representation for the responsible of an order
 * @param user the responsible for an order
 * @param noImg flag if image should be hidden
 * @returns {string} html representation of the user data
 */
function getResponsibleHTML(user: UserdataDocument, noImg?: boolean) {
  let html =
    '<table style="margin-right:0px; float:right;"><tr style="margin-right:0px;"><td style="text-align: right; font-size:16px; margin-right:15px;">';
  html += "<b>" + user.prename + " " + user.surname + "</b>";
  html += '<br><span style="font-size:13px">';
  html += (user.email[0] || "-") + "</span>";
  html += "<br>";
  html += '<span style="font-size:13px;">' + (user.telephone[0] || "-");
  html += '</span></td><td style="margin-right:0px;">';
  if (!noImg)
    html +=
      '<img style="border-radius:100px; height:50px; width:50px; margin-right:0px; width:50px; margin-left:5px" src="' +
      user.img_url +
      '"/>';
  html += "</td></tr></table>";
  return html;
}

/**
 * Get description for the unit field
 * @param unit the selected unit
 * @param specificLanguage specific language to use
 * @returns {string} the resolved unit description
 */
function getUnitDescription(unit: string, specificLanguage?: { lng: string }) {
  switch (unit) {
    case "bottle":
      return i18n.t("packaging:bottles", specificLanguage);
    case "bag":
      return i18n.t("packaging:bag", specificLanguage);
    case "capsule":
      return `${i18n.t("offer:thousand")} ${i18n.t("pdf:capsules", specificLanguage)}`;
    case "item":
      return i18n.t("order:units", specificLanguage);
    case "hour":
      return i18n.t("pdf:hours", specificLanguage);
    case "flatrate":
      return i18n.t("pdf:flatrate", specificLanguage);
    case "kg":
      return "KG";
    default:
      return "";
  }
}

/**
 * Get html representation of position data
 * @param position the invoice position
 * @param i the index of the position
 * @param lot optional lot number
 * @param specificLanguage the specific language to use
 * @returns {string} html representation of the position
 */
function getPositionHTML(position: InvoicePosition, i: number, lot?: string, specificLanguage?: { lng: string }) {
  let html = '<tr style="width:100%"><td><b>' + (i + 1) + "</b></td>";
  html += "<td>" + position.description;
  if (lot && position.type === "position") {
    html += `<div>${i18n.t("order:lotNumber")}: <b>` + lot + "</b></div>";
  }
  if (position.type === I_REMINDER) {
    html +=
      "</td><td colspan='3' style='text-align: right'><span style='white-space: nowrap;'>" +
      baseUtils.formatEuro(+position.price) +
      "</span><span>";
  } else {
    html += "</td><td>" + position.amount;
    html += "</td><td>";
    html += getUnitDescription(position.unit, specificLanguage);
    html += "</td><td><span style='white-space: nowrap;'>" + baseUtils.formatEuro(+position.price);
  }
  if (+position.vat > 0) {
    html += "</span></td><td>" + (Math.round(+position.vat * 100) / 100).toString() + "%";
  } else {
    html += "</span></td><td>0%";
  }
  if (+position.discount > 0) {
    html += "</td><td>" + (Math.round(+position.discount * 100) / 100).toString() + "%";
  } else {
    html += "</td><td>";
  }
  html +=
    "</td><td><span style='white-space: nowrap;'><b>" +
    invoiceUtils.getTotalWithDiscount(position).toLocaleString("de-DE", {
      style: "currency",
      currency: "EUR"
    });
  html += "</b></span></td></tr>";
  return html;
}

/**
 * Get the html representation of vat listing
 * @param vatList the vat listing
 * @param specificLanguage a specific language to use
 * @returns {string} the html representation of the vat listing
 */
function getVatHTML(vatList: Array<{ vat: string; value: number; total: number }>, specificLanguage?: { lng: string }) {
  let html = "";
  vatList.forEach(
    vat =>
      (html +=
        `<tr style="width: 100%"><td>${i18n.t("pdf:vat", specificLanguage)} ` +
        vat.vat +
        `% (${i18n.t("pdf:from", specificLanguage)} ` +
        vat.total.toLocaleString("de-DE", {
          style: "currency",
          currency: "EUR"
        }) +
        ` ${i18n.t("pdf:net", specificLanguage)})</td>` +
        '<td  style="text-align:right"><b>' +
        vat.value.toLocaleString("de-DE", {
          style: "currency",
          currency: "EUR"
        }) +
        "</b></td></tr>")
  );

  return html;
}

/**
 * Get HTML representation of the subtotal amount
 * @param subTotal the subtotal value
 * @param specificLanguage specific language to use
 * @returns {string} html representation for subtotal amount
 */
function getSubtotalHTML(subTotal: number, specificLanguage?: { lng: string }) {
  return (
    `<br><br><hr><table cellpadding="5" cellspacing="0" style="font-size:17px; width:100%; margin-top:20px;"><tbody><tr><td>${i18n.t(
      "pdf:subtotalNet",
      specificLanguage
    )}</td><td style="text-align:right"><b>` +
    subTotal.toLocaleString("de-DE", {
      style: "currency",
      currency: "EUR"
    }) +
    "</b></td></tr>"
  );
}

/**
 * Get HTML representation of the total amount
 * @param total: the total value
 * @param payments: Optional, contains already made payments
 * @param cancelation: Optional, indicates whether the underlying invoice is a cancelation or not
 * @param specificLanguage a specific language to use
 * @returns {string} html representation for total amount
 */
function getTotalHTML(
  total: number,
  payments?: Array<{ value: number; date: Date; note: string }>,
  cancelation?: boolean,
  specificLanguage?: { lng: string }
) {
  let html =
    `<tr><td><b>${i18n.t("order:totalAmount", specificLanguage)}</b></td>` +
    '<td style="text-align:right"><b><u>' +
    total.toLocaleString("de-DE", {
      style: "currency",
      currency: "EUR"
    }) +
    "</u></b></td></tr>";
  if (payments && !cancelation) {
    payments.forEach(
      p =>
        (html += `<tr><td>${i18n.t("order:paymentFrom", specificLanguage)} ${baseUtils.formatDate(
          p.date
        )}</td><td style="text-align:right"><b>${baseUtils.formatEuro(-p.value)}</b></td></tr>`)
    );
    html +=
      `<tr><td><b>${i18n.t("order:remainingAmount", specificLanguage)}</b></td>` +
      '<td style="text-align:right"><b><u>' +
      baseUtils.formatEuro(total - payments.reduce((sum, p) => sum + p.value, 0)) +
      "</u></b></td></tr>";
  }
  html += "</tbody></table>";
  return html;
}

/**
 * Get HTML for reverse charge information
 * @param reverseCharge flag for reverse charge
 * @param customerName name of the customer
 * @param vatID optional vat id of the customer
 * @param specificLanguage specific language to use
 * @returns {string} html representation of reverse charge information
 */
function getReverseChargeHTML(
  reverseCharge: boolean,
  customerName: string,
  vatID?: string,
  specificLanguage?: { lng: string }
) {
  let html = "";
  if (reverseCharge) {
    html += `${i18n.t("template:reverseChargeNotice", specificLanguage)}`;
    html +=
      `<br><span style="font-size:14px">${i18n.t("template:PLFVATTemplate", specificLanguage)} ` + customerName + ": ";
    if (vatID) {
      html += "<b>" + vatID + ".</b>";
    } else {
      html += `<b>${i18n.t("invoice:notSpecified", specificLanguage)}</b>`;
    }
    html += "</span><br>";
  }
  return html;
}

/**
 * Get packaging description
 * @param packaging list of packaging
 * @param lng  language
 * @returns {string} html representation of packaging
 */
function getPackagingDescription(packaging: Array<ExtendedPackaging>, lng: string) {
  let html = "";
  html += '<table cellpadding="5" cellspacing="0" style="font-size:15px; width:100%; background-color:#fafafa">';
  html += `<tbody><tr><td><b>${i18n.t("pdf:package", { lng })}</b></td><td>`;
  const bottle = packaging.find(pack => pack.packaging_type === PackagingTypes.BOTTLE);
  const liquidBottle = packaging.find(pack => pack.packaging_type === PackagingTypes.LIQUIDBOTTLE);
  const blister = packaging.find(pack => pack.packaging_type === PackagingTypes.BLISTER);
  const bag = packaging.find(pack => pack.packaging_type === PackagingTypes.BAG);
  if (bottle) {
    html += `${i18n.t("packaging:" + bottle.packaging_type, { lng })}, ${bottle.packaging_volume}ml, ${i18n.t(
      "packaging:" + bottle.packaging_material!,
      { lng }
    )}, ${i18n.t("packaging:" + bottle.packaging_color!, { lng })}, ${bottle.packaging_neck}`;
  } else if (liquidBottle) {
    html += `${i18n.t("packaging:" + liquidBottle.packaging_type, { lng })}, ${
      liquidBottle.packaging_volume
    }ml, ${i18n.t("packaging:" + liquidBottle.packaging_material!, { lng })}, ${i18n.t(
      "packaging:" + liquidBottle.packaging_color!,
      { lng }
    )}, ${liquidBottle.packaging_neck}`;
  } else if (blister) {
    html += `${i18n.t("packaging:" + blister.packaging_type, { lng })}, ${blister.blister_capsules} ${i18n.t(
      "pdf:capsules",
      {
        lng
      }
    )}/${i18n.t("packaging:blister", { lng })}`;
  } else if (bag) {
    html += `${i18n.t("packaging:" + bag.packaging_type, { lng })}, ${bag.bag_volume}ml, ${i18n.t(
      "packaging:" + bag.bag_material!,
      { lng }
    )}, ${i18n.t("packaging:" + bag.bag_color, { lng })}`;
  }
  html += "</td></tr>";

  const lid = packaging.find(pack => pack.packaging_type === PackagingTypes.LID);
  const pipette = packaging.find(pack => pack.packaging_type === PackagingTypes.PIPETTE);
  const sprayPump = packaging.find(pack => pack.packaging_type === PackagingTypes.SPRAYPUMP);
  if (lid || pipette || sprayPump) {
    html += `<tr><td><b>${i18n.t("packaging:lid", { lng })}</b></td><td>`;
    if (lid) {
      html += `${i18n.t("packaging:" + lid.lid_material, { lng })}, ${i18n.t("packaging:" + lid.lid_color, {
        lng
      })}, ${i18n.t("packaging:" + lid.lid_shape, { lng })}, ${lid.lid_size}`;
    } else if (pipette) {
      html += `${i18n.t("packaging:" + pipette.packaging_type, { lng })}, ${i18n.t(
        "packaging:" + pipette.packaging_color,
        { lng }
      )}, ${pipette.packaging_height}mm , ${pipette.packaging_neck}`;
    } else if (sprayPump) {
      html += `${i18n.t("packaging:" + "sprayPump", { lng })}, ${i18n.t("packaging:" + sprayPump.packaging_color, {
        lng
      })}, DIN ${sprayPump.packaging_norm}, ${sprayPump.packaging_height}mm , ${sprayPump.packaging_neck}`;
    }
    html += "</td></tr>";
  }

  const sleeve = packaging.find(pack => pack.packaging_type === PackagingTypes.SLEEVE);
  if (sleeve) {
    html += `<tr><td><b>${i18n.t("pdf:sealing", { lng })}</b></td><td>`;
    html += `${i18n.t("pdf:sleeveFor", { lng })} ${sleeve.sleeve_size}mm, ${
      sleeve.sleeve_print ? `${i18n.t("pdf:printed", { lng })}` : `${i18n.t("pdf:withoutPrint", { lng })}`
    }`;
    html += "</td></tr>";
  }

  const label = packaging.find(pack => pack.packaging_type === PackagingTypes.LABEL);
  const multilayer = packaging.find(pack => pack.packaging_type === PackagingTypes.MULTILAYER_LABEL);
  if (label || multilayer) {
    html += `<tr><td><b>${i18n.t("pdf:label", { lng })}</b></td><td>`;
    if (label) {
      html += `${i18n.t("packaging:" + label.packaging_type, { lng })}, ${i18n.t("packaging:" + label.label_quality, {
        lng
      })}, ${label.label_width}mm x ${label.label_height}mm`;
    } else if (multilayer) {
      html += `${i18n.t("packaging:" + multilayer.packaging_type, { lng })}, ${i18n.t(
        "packaging:" + multilayer.label_quality,
        { lng }
      )}, ${multilayer.label_width}mm x ${multilayer.label_height}mm`;
    }
    html += "</td></tr>";
  }

  const box = packaging.find(pack => pack.packaging_type === PackagingTypes.BOX);
  if (box) {
    html += `<tr><td><b>${i18n.t("pdf:foldedBox", { lng })}</b></td><td>`;
    html += `${i18n.t("packaging:" + box.packaging_type, { lng })} ${i18n.t("pdf:inSuitableSize", {
      lng
    })}, ${i18n.t("packaging:" + box.box_quality, { lng })}`;
    html += "</td></tr>";
  }

  const sticker = packaging.find(pack => pack.packaging_type === PackagingTypes.STICKER);
  if (sticker) {
    html += `<tr><td><b>${i18n.t("pdf:sticker", { lng })}</b></td><td>`;
    html += `${i18n.t("packaging:" + sticker.form, { lng })}, ${i18n.t("packaging:" + sticker.quality, { lng })}, ${
      sticker.form === StickerForms.ROUND
        ? `⌀ ${sticker.diameter} mm`
        : `${sticker.packaging_width}mm x ${sticker.packaging_height}mm`
    }`;
    html += "</td></tr>";
  }

  const spoon = packaging.find(pack => pack.packaging_type === PackagingTypes.SPOON);
  if (spoon) {
    html += `<tr><td><b>${i18n.t("pdf:spoon", { lng })}</b></td><td>`;
    html += `${i18n.t("packaging:" + spoon.packaging_color, { lng })}, ${spoon.capacity}g`;
    html += "</td></tr>";
  }

  const silicaGelBag = packaging.find(pack => pack.packaging_type === PackagingTypes.SILICAGELBAG);
  if (silicaGelBag) {
    html += `<tr><td><b>${i18n.t("pdf:silicaGelBag", { lng })}</b></td><td>`;
    html += `${silicaGelBag.weight}g`;
    html += "</td></tr>";
  }

  const packageInsert = packaging.find(pack => pack.packaging_type === PackagingTypes.PACKAGEINSERT);
  if (packageInsert) {
    html += `<tr><td><b>${i18n.t("pdf:packageInsert", { lng })}</b></td><td>${i18n.t("pdf:inSuitableSize", {
      lng
    })}</td></tr>`;
  }

  html += "</tbody></table></td></tr></table>";
  return html;
}

/**
 * Get packaging description
 * @param packaging list of packaging
 * @param lng language
 * @returns {string} html representation of packaging
 */
function getOfferPackagingDescription(packaging: Array<ExtendedPackaging>, lng: string) {
  let html = "";
  html += '<table  style="font-size:15px; width:100%; background-color:#fafafa">';
  const bottle = packaging.find(pack => pack.packaging_type === PackagingTypes.BOTTLE);
  const liquidBottle = packaging.find(pack => pack.packaging_type === PackagingTypes.LIQUIDBOTTLE);
  const blister = packaging.find(pack => pack.packaging_type === PackagingTypes.BLISTER);
  const bag = packaging.find(pack => pack.packaging_type === PackagingTypes.BAG);

  html += `<table cellpadding="5" cellspacing="0" style="font-size:15px; width:100%; background-color:#fafafa" ><tr style="background-color:#cccccc"><td style="width: 20%;"><b>${i18n.t(
    "pdf:name"
  )}</b></td><td><b style="white-space:nowrap; width:43%"></b></td><td style="width: 37%;"><b>${i18n.t(
    "pdf:additionalInformation"
  )}</b></td></tr>`;
  html += `<tbody><tr><td><b>${i18n.t("pdf:package", { lng })}</b></td><td>`;

  if (bottle) {
    html += `${i18n.t("packaging:" + bottle.packaging_type, { lng })}, ${bottle.packaging_volume}ml, ${i18n.t(
      "packaging:" + bottle.packaging_material!,
      { lng }
    )}, ${i18n.t("packaging:" + bottle.packaging_color!, { lng })}, ${bottle.packaging_neck}`;
  } else if (liquidBottle) {
    html += `${i18n.t("packaging:" + liquidBottle.packaging_type, { lng })}, ${
      liquidBottle.packaging_volume
    }ml, ${i18n.t("packaging:" + liquidBottle.packaging_material!, { lng })}, ${i18n.t(
      "packaging:" + liquidBottle.packaging_color!,
      { lng }
    )}, ${liquidBottle.packaging_neck}`;
  } else if (blister) {
    html += `${i18n.t("packaging:" + blister.packaging_type, { lng })}, ${blister.blister_capsules} ${i18n.t(
      "pdf:capsules",
      {
        lng
      }
    )}/${i18n.t("packaging:blister", { lng })}`;
  } else if (bag) {
    html += `${i18n.t("packaging:" + bag.packaging_type, { lng })}, ${bag.bag_volume}ml, ${i18n.t(
      "packaging:" + bag.bag_material!,
      { lng }
    )}, ${i18n.t("packaging:" + bag.bag_color, { lng })}`;
  }
  html += "</td>";
  if (
    (bottle && bottle.supplier === "customer") ||
    (liquidBottle && liquidBottle.supplier === "customer") ||
    (blister && blister.supplier === "customer") ||
    (bag && bag.supplier === "customer")
  ) {
    html += `<td><b>${i18n.t("pdf:providedByCustomer", { lng })}</b></td>`;
  } else {
    html += `<td><b></b></td>`;
  }

  const lid = packaging.find(pack => pack.packaging_type === PackagingTypes.LID);
  const pipette = packaging.find(pack => pack.packaging_type === PackagingTypes.PIPETTE);
  const sprayPump = packaging.find(pack => pack.packaging_type === PackagingTypes.SPRAYPUMP);
  if (lid || pipette || sprayPump) {
    html += `<tr><td><b>${i18n.t("packaging:lid", { lng })}</b></td><td>`;
    if (lid) {
      html += `${i18n.t("packaging:" + lid.lid_material, { lng })}, ${i18n.t("packaging:" + lid.lid_color, {
        lng
      })}, ${i18n.t("packaging:" + lid.lid_shape, { lng })}, ${lid.lid_size}`;
    } else if (pipette) {
      html += `${i18n.t("packaging:" + pipette.packaging_type, { lng })}, ${i18n.t(
        "packaging:" + pipette.packaging_color,
        { lng }
      )}, ${pipette.packaging_height}mm , ${pipette.packaging_neck}`;
    } else if (sprayPump) {
      html += `${i18n.t("packaging:" + "sprayPump", { lng })}, ${i18n.t("packaging:" + sprayPump.packaging_color, {
        lng
      })}, DIN ${sprayPump.packaging_norm}, ${sprayPump.packaging_height}mm , ${sprayPump.packaging_neck}`;
    }
    html += "</td>";
    if (
      (lid && lid.supplier === "customer") ||
      (pipette && pipette.supplier === "customer") ||
      (sprayPump && sprayPump.supplier === "customer")
    ) {
      html += `<td><b>${i18n.t("pdf:providedByCustomer", { lng })}</b></td>`;
    } else {
      html += `<td><b></b></td>`;
    }
    html += `</tr>`;
  }

  const sleeve = packaging.find(pack => pack.packaging_type === PackagingTypes.SLEEVE);
  if (sleeve) {
    html += `<tr><td><b>${i18n.t("pdf:sealing", { lng })}</b></td><td>`;
    html += `${i18n.t("pdf:sleeveFor", { lng })} ${sleeve.sleeve_size}mm, ${
      sleeve.sleeve_print ? `${i18n.t("pdf:printed", { lng })}` : `${i18n.t("pdf:withoutPrint", { lng })}`
    }`;
    html += "</td>";
    if (sleeve && sleeve.supplier === "customer") {
      html += `<td><b>${i18n.t("pdf:providedByCustomer", { lng })}</b></td>`;
    } else {
      html += `<td><b></b></td>`;
    }
    html += `</tr>`;
  }

  const label = packaging.find(pack => pack.packaging_type === PackagingTypes.LABEL);
  const multilayer = packaging.find(pack => pack.packaging_type === PackagingTypes.MULTILAYER_LABEL);
  if (label || multilayer) {
    html += `<tr><td><b>${i18n.t("pdf:label", { lng })}</b></td><td>`;
    if (label) {
      html += `${i18n.t("packaging:" + label.packaging_type, { lng })}, ${i18n.t("packaging:" + label.label_quality, {
        lng
      })}, ${label.label_width}mm x ${label.label_height}mm`;
    } else if (multilayer) {
      html += `${i18n.t("packaging:" + multilayer.packaging_type, { lng })}, ${i18n.t(
        "packaging:" + multilayer.label_quality,
        { lng }
      )}, ${multilayer.label_width}mm x ${multilayer.label_height}mm`;
    }
    html += "</td>";
    if ((label && label.supplier === "customer") || (multilayer && multilayer.supplier === "customer")) {
      html += `<td><b>${i18n.t("pdf:providedByCustomer", { lng })}</b></td>`;
    } else {
      html += `<td><b></b></td>`;
    }
    html += `</tr>`;
  }

  const box = packaging.find(pack => pack.packaging_type === PackagingTypes.BOX);
  if (box) {
    html += `<tr><td><b>${i18n.t("pdf:foldedBox", { lng })}</b></td><td>`;
    html += `${i18n.t("packaging:" + box.packaging_type, { lng })} ${i18n.t("pdf:inSuitableSize", {
      lng
    })}, ${i18n.t("packaging:" + box.box_quality, { lng })}`;
    html += "</td>";
    if (box && box.supplier === "customer") {
      html += `<td><b>${i18n.t("pdf:providedByCustomer", { lng })}</b></td>`;
    } else {
      html += `<td><b></b></td>`;
    }
    html += `</tr>`;
  }

  const sticker = packaging.find(pack => pack.packaging_type === PackagingTypes.STICKER);
  if (sticker) {
    html += `<tr><td><b>${i18n.t("pdf:sticker", { lng })}</b></td><td>`;
    html += `${i18n.t("packaging:" + sticker.form, { lng })}, ${i18n.t("packaging:" + sticker.quality, { lng })}, ${
      sticker.form === StickerForms.ROUND
        ? `⌀ ${sticker.diameter} mm`
        : `${sticker.packaging_width}mm x ${sticker.packaging_height}mm`
    }`;
    html += "</td>";
    if (sticker && sticker.supplier === "customer") {
      html += `<td><b>${i18n.t("pdf:providedByCustomer", { lng })}</b></td>`;
    } else {
      html += `<td><b></b></td>`;
    }
    html += `</tr>`;
  }

  const spoon = packaging.find(pack => pack.packaging_type === PackagingTypes.SPOON);
  if (spoon) {
    html += `<tr><td><b>${i18n.t("pdf:spoon", { lng })}</b></td><td>`;
    html += `${i18n.t("packaging:" + spoon.packaging_color, { lng })}, ${spoon.capacity}g`;
    html += "</td>";
    if (spoon && spoon.supplier === "customer") {
      html += `<td><b>${i18n.t("pdf:providedByCustomer", { lng })}</b></td>`;
    } else {
      html += `<td><b></b></td>`;
    }
    html += `</tr>`;
  }

  const silicaGelBag = packaging.find(pack => pack.packaging_type === PackagingTypes.SILICAGELBAG);
  if (silicaGelBag) {
    html += `<tr><td><b>${i18n.t("pdf:silicaGelBag", { lng })}</b></td><td>`;
    html += `${silicaGelBag.weight}g`;
    html += "</td>";
    if (silicaGelBag && silicaGelBag.supplier === "customer") {
      html += `<td><b>${i18n.t("pdf:providedByCustomer", { lng })}</b></td>`;
    } else {
      html += `<td><b></b></td>`;
    }
    html += `</tr>`;
  }

  const packageInsert = packaging.find(pack => pack.packaging_type === PackagingTypes.PACKAGEINSERT);
  if (packageInsert) {
    html += `<tr><td><b>${i18n.t("pdf:packageInsert", { lng })}</b></td><td>${i18n.t("pdf:inSuitableSize", {
      lng
    })}</td>`;
    if (packageInsert && packageInsert.supplier === "customer") {
      html += `<td><b>${i18n.t("pdf:providedByCustomer", { lng })}</b></td>`;
    } else {
      html += `<td><b></b></td>`;
    }
    html += `</tr>`;
  }

  html += "</tbody></table></td></tr></table>";
  return html;
}

/**
 * Get html representation for the recipe
 * @param order an order document
 * @param settings order settings
 * @param context data context
 * @param customDatasheetText optional data sheet text
 * @param bulkFlag flag if it is a bulk
 * @param givenLanguage language the pdf needs to be created in
 * @returns {string} html representation of recipe
 */
function getRecipeDescription(
  order: CustomOrder | OrdersDocument,
  settings: Setting,
  context: React.ContextType<typeof DataContext>,
  customDatasheetText?: string,
  bulkFlag?: boolean,
  givenLanguage?: "en" | "de"
) {
  const calculations = order.calculations;
  const { commodities, activesubstances, capsules } = context;
  const language = givenLanguage ? givenLanguage : i18n.language === "en" ? "en" : "de";
  const selectedCapsule =
    settings.type === T_CAPSULE
      ? capsules.find(cap => cap._id.toString() === order.settings.id.toString()) || null
      : null;
  const bulk = bulkFlag || calculations[0].packagings.length === 0;
  let html = "";
  if (![T_CUSTOM, T_SOFTGEL, T_SERVICE].includes(settings.type)) {
    html += `<h4 style="margin-bottom:5px">${i18n.t("pdf:recipe")}</h4>`;
  } else if ([T_CUSTOM, T_SOFTGEL, T_SERVICE].includes(order.settings.type) && !customDatasheetText) {
    html += `<h4 style="margin-bottom:5px">${i18n.t("pdf:product")}</h4>`;
  } else {
    return customDatasheetText;
  }

  if (![T_CUSTOM, T_SOFTGEL, T_SERVICE].includes(settings.type)) {
    html += `<table cellpadding="5" cellspacing="0" style="font-size:15px; width:100%; background-color:#fafafa" ><tr style="background-color:#cccccc"><td><b>${i18n.t(
      "pdf:name"
    )}</b></td><td><b style="white-space:nowrap;">${i18n.t(
      "common:amount"
    )}</b></td><td style="min-width: 150px;"><b>${i18n.t(
      "pdf:activeSubstance"
    )}</b></td><td><b style="white-space:nowrap;">${i18n.t(
      "common:amount"
    )}</b></td><td><b style="white-space:nowrap;">%NRV</b></td></tr>`;
  } else if ([T_CUSTOM, T_SOFTGEL, T_SERVICE].includes(order.settings.type)) {
    html += `<table cellpadding="5" cellspacing="0" style="font-size:15px; width:100%; background-color:#fafafa" ><tr style="background-color:#cccccc"><td><b>${i18n.t(
      "pdf:name"
    )}</b></td><td><b style="white-space:nowrap;">${i18n.t("common:amount")}</b></td></tr>`;
  }

  html += "<tbody>";
  let sortedPrices = calculations[0].prices;
  sortedPrices = _.orderBy(sortedPrices, x => x.amount, ["desc"]);

  for (let j = 0; j < sortedPrices.length; j++) {
    if (!["5e693761a8942807a217f235", "5e693651a8942807a217f234"].includes(sortedPrices[j]._id.toString())) {
      const commodityUsed = commodities.find(c => c._id.toString() === sortedPrices[j]._id.toString())!;
      html +=
        '<tr style="width:100%"><td><b>' +
        commodityUsed.title[language] +
        "</b><br>" +
        commodityUsed.subtitle[language] +
        "</td>";

      if (![T_CUSTOM, T_SOFTGEL, T_SERVICE].includes(settings.type)) {
        html +=
          "<td>" +
          calculationUtils.formatAmount(
            sortedPrices[j].amount,
            2,
            false,
            [T_CAPSULE, T_TABLET].includes(settings.type)
          ) +
          "</td><td>";
        if (commodityUsed.activesubstance) {
          for (let m = 0; m < commodityUsed.activesubstance.length; m++) {
            const activeSubstance = activesubstances.find(
              as => as._id.toString() === commodityUsed.activesubstance[m]._id.toString()
            );
            html += (activeSubstance ? activeSubstance.name[language] : "Unknown") + "<br>";
          }
        }
        html += "</td><td>";
        if (commodityUsed.activesubstance) {
          for (let m = 0; m < commodityUsed.activesubstance.length; m++) {
            html +=
              calculationUtils.formatAmount(
                getActiveSubstanceAmount(commodityUsed.activesubstance[m], sortedPrices[j].amount),
                2,
                false,
                [T_CAPSULE, T_TABLET].includes(settings.type)
              ) + "<br>";
          }
        }
        html += "</td><td>";
        if (commodityUsed.activesubstance) {
          for (let m = 0; m < commodityUsed.activesubstance.length; m++) {
            html +=
              getActiveSubstanceNRVPercentage(
                commodityUsed.activesubstance[m],
                activesubstances,
                sortedPrices[j].amount
              ) + "<br>";
          }
        }
        html += "</td></tr>";
      } else if ([T_CUSTOM, T_SOFTGEL].includes(settings.type) && !bulk) {
        html += "<td>" + sortedPrices[j].amount * settings.perUnit + ` ${i18n.t("pdf:pieces")}</td></tr>`;
      } else if (settings.type === T_SERVICE) {
        html += "<td>" + sortedPrices[j].amount + ` ${i18n.t("pdf:pieces")}</td></tr>`;
      } else if ([T_CUSTOM, T_SOFTGEL].includes(settings.type) && bulk) {
        html += "<td>" + "Bulk</td></tr>";
      }
    }
  }
  const commodityTotalWeight = sortedPrices.reduce((a, b) => a + b.amount, 0);

  if (settings.type === T_CAPSULE && selectedCapsule) {
    html += `<tr style="width:100%"><td style="width:100%; border-top: 3px solid rgba(125,125,125,0.5);"><b>${i18n.t(
      "pdf:totalFillingQuantityPerCapsule"
    )}</b></td><td style="border-top: 3px solid rgba(125,125,125,0.5);">`;
    html +=
      calculationUtils.formatAmount(commodityTotalWeight, 2, false, true) +
      '</td><td style="border-top: 3px solid rgba(125,125,125,0.5);"></td><td style="border-top: 3px solid rgba(125,125,125,0.5);"></td><td style="border-top: 3px solid rgba(125,125,125,0.5);"></td></tr><tr><td>';
    html +=
      `<b>${i18n.t("pdf:capsule")} ` +
      selectedCapsule.capsule_size +
      ", " +
      selectedCapsule.capsule_material[language] +
      ", " +
      selectedCapsule.capsule_color[language];
    html += '</b></td><td style="width:100%;">';
    html += calculationUtils.formatAmount(selectedCapsule.capsule_weight, 2, false, true);
    html += "</td><td></td><td></td><td></td></tr>";
    html += `<tr><td style="width:100%"><b>${i18n.t("pdf:totalWeightPerCapsule")}</b></td><td>`;
    html += calculationUtils.formatAmount(commodityTotalWeight + selectedCapsule.capsule_weight, 2, false, true);
    html += "</td><td></td><td></td><td></td></tr>";
    if (bulk) {
      html += `<tr><td style="width:100%"><b>${i18n.t("pdf:totalWeightPerKCapsules")}</b></td><td>`;
    } else {
      html += `<tr><td style="width:100%"><b>${i18n.t("pdf:totalWeightPerUnit")}</b></td><td>`;
    }
    html += calculationUtils.formatAmount(
      (commodityTotalWeight + selectedCapsule.capsule_weight) * settings.perUnit,
      2
    );
    html += "</td><td></td><td></td><td></td></tr>";
  } else if (settings.type === T_TABLET) {
    html += `<tr style="width:100%"><td style="width:100%; border-top: 3px solid rgba(125,125,125,0.5);"><b>${i18n.t(
      "pdf:totalWeightPerTablet"
    )}</b></td><td style="border-top: 3px solid rgba(125,125,125,0.5);">`;
    html +=
      calculationUtils.formatAmount(commodityTotalWeight, 2) +
      '</td><td style="border-top: 3px solid rgba(125,125,125,0.5);"></td><td style="border-top: 3px solid rgba(125,125,125,0.5);"></td><td style="border-top: 3px solid rgba(125,125,125,0.5);"></td></tr>';
    if (bulk) {
      html += `<tr><td style="width:100%"><b>${i18n.t("pdf:totalWeightPerKTablets")}</b></td><td>`;
    } else {
      html += `<tr><td style="width:100%"><b>${i18n.t("pdf:totalWeightPerUnit")}</b></td><td>`;
    }
    html += calculationUtils.formatAmount(commodityTotalWeight * settings.perUnit, 2);
    html += "</td><td></td><td></td><td></td></tr>";
  } else if (settings.type === T_POWDER) {
    html += `<tr style="width:100%"><td style="width:100%; border-top: 3px solid rgba(125,125,125,0.5);"><b>${i18n.t(
      "pdf:totalWeightPerUnit"
    )}</b></td><td style="border-top: 3px solid rgba(125,125,125,0.5);">`;
    html += calculationUtils.formatAmount(commodityTotalWeight, 2);
    html +=
      '</td><td style="border-top: 3px solid rgba(125,125,125,0.5);"></td><td style="border-top: 3px solid rgba(125,125,125,0.5);"></td><td style="border-top: 3px solid rgba(125,125,125,0.5);"></td></tr>';
  } else if (settings.type === T_LIQUID) {
    html += `<tr style="width:100%"><td style="width:100%; border-top: 3px solid rgba(125,125,125,0.5);"><b>${i18n.t(
      "pdf:totalWeightPerUnit"
    )}</b></td><td style="border-top: 3px solid rgba(125,125,125,0.5);">`;
    html += calculationUtils.formatAmount(commodityTotalWeight, 2);
    html +=
      '</td><td style="border-top: 3px solid rgba(125,125,125,0.5);"></td><td style="border-top: 3px solid rgba(125,125,125,0.5);"></td><td style="border-top: 3px solid rgba(125,125,125,0.5);"></td></tr>';
  }
  html += "</tbody>";
  html += "</table>";
  return html;
}

/**
 * Get information about order settings
 * @param order an orders document
 * @param settings order settings
 * @param context data context
 * @param bulkFlag optional bulk flag
 * @param givenLanguage language the pdf needs to be created in
 * @returns {string} html representation of the order settings
 */
function getSettingsDescription(
  order: OrdersDocument | CustomOrder,
  settings: Setting,
  context: React.ContextType<typeof DataContext>,
  bulkFlag?: boolean,
  givenLanguage?: "en" | "de"
) {
  const { capsules } = context;
  const selectedCapsule = baseUtils.getDocFromCollection(capsules, settings.id);
  const bulk = bulkFlag || order.calculations[0].packagings.length === 0;
  const language = givenLanguage ? givenLanguage : i18n.language === "en" ? "en" : "de";
  let html = '<br><span style="font-size:16px;">';
  if (settings.type === "capsule" && selectedCapsule && !bulk) {
    html +=
      settings.perUnit +
      ` ${i18n.t("pdf:capsules")} (` +
      selectedCapsule.capsule_size +
      ", " +
      selectedCapsule.capsule_material[language] +
      ", " +
      selectedCapsule.capsule_color[language] +
      ")";
  } else if (settings.type === T_CAPSULE && selectedCapsule && bulk) {
    html +=
      `${i18n.t("pdf:bulkCapsules")} (` +
      selectedCapsule.capsule_size +
      ", " +
      selectedCapsule.capsule_material[language] +
      ")";
  } else if (settings.type === T_TABLET && !bulk) {
    html += settings.perUnit + ` ${i18n.t("pdf:tablets")}`;
  } else if (settings.type === T_TABLET && bulk) {
    html += `${i18n.t("pdf:bulkTablets")}`;
  } else if (settings.type === T_POWDER) {
    html += `${i18n.t("pdf:powder")}`;
  } else if (settings.type === T_LIQUID) {
    html += `${i18n.t("pdf:liquid")}`;
  } else if (order.settings.type === T_CUSTOM || order.settings.type === T_SOFTGEL) {
    html += order.settings.perUnit + ` ${i18n.t("pdf:pieces")}`;
  } else if ((settings.type === T_CUSTOM || settings.type === T_SOFTGEL) && bulk) {
    html += "Bulkware";
  }
  html += "</span>";
  return html;
}

/**
 * Get html for payment info
 * @param type invoice type
 * @param dueIn amount of days the invoice is due in
 * @param specificLanguage specific language to use
 * @returns {string} html string with info about when the invoice has to be paid
 */
function getPaymentHTML(type: string, dueIn: number, specificLanguage?: { lng: string }) {
  let html = "";
  if (dueIn === -1 && type === "orderConfirmation") {
    html += `<br><br><span style="font-size:16px"><b>${i18n.t(
      "order:paymentInAdvance",
      specificLanguage
    )}</b></span><br><br>`;
  } else if (dueIn === 0 && type !== "cancellation" && type !== "reminder" && type !== "orderConfirmation") {
    html += `<br><br><span style="font-size:16px"><b>${i18n.t(
      "order:payableImmediately",
      specificLanguage
    )}.</b></span><br><br>`;
  } else if (dueIn === 0 && type !== "cancellation" && type === "orderConfirmation") {
    html += `<br><br><span style="font-size:16px"><b>${i18n.t(
      "order:payableUponDelivery",
      specificLanguage
    )}</b></span><br><br>`;
  } else if (type !== "cancellation") {
    if (dueIn > 0) {
      html +=
        `<br><br><span style="font-size:16px"><b>${i18n.t("order:payableWithin", specificLanguage)} ` +
        dueIn +
        ` ${i18n.t("order:daysFromDateOfInvoice", specificLanguage)}` +
        "</b></span><br><br>";
    } else {
      html +=
        `<br><br><span style="font-size:16px"><b>${i18n.t("order:paymentWithin4Days", specificLanguage)}` +
        "</b></span><br><br>";
    }
  }
  return html;
}

/**
 * Renders the address into the HTML format needed for the pdf
 * @param name supplier company name
 * @param street supplier address street
 * @param city supplier address city
 * @param country supplier address country
 * @param vat supplier vat
 * @param person supplier contact person name
 * @returns {string} html representation of packaging
 */
function renderAddress(name: string, street: string, city: string, country: string, vat: string, person: string) {
  const indent = "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;";
  return `<div >${indent}${name}<br>${indent}${street}<br>${indent}${city}<br>${indent}${country}<br>${indent}Vat registration number: ${vat}<br>${indent}Executive Director: ${person}</div>`;
}

/**
 * Gets the supplier contact HTML object according to the given manufacturer
 * @param manufacturer Name of the manufacturer
 * @returns {string} html representation of packaging
 */
function getDeliveryAddress(manufacturer: string) {
  switch (manufacturer) {
    case "PLF PRODUCTION CZ":
      return renderAddress(
        "Revi Plus S.R.O",
        "Želetická 706/9",
        "41201 Litomerice",
        "Czech Republic",
        " CZ25018671",
        "Michael Revaj"
      );
    case "PLF PRODUCTION DE-PS":
      return renderAddress(
        "H. + M. Kunz GmbH Ultra-tec",
        "Blocksbergstraße 166a",
        "66955 Pirmasens ",
        "Deutschland ",
        "DE263292958",
        "Horst Kunz"
      );
    case "PLF PRODUCTION SVN":
      return renderAddress(
        "BIO PAK Nutraceuticals Contract Manufacturing LTD",
        "Savska loka 22",
        "4000 Kranj",
        "Slowenien",
        "SI90897455",
        "Tomaž Sitar"
      );
    case "PLF PRODUCTION HUN":
      return renderAddress(
        "Bioextra Co. LTD.",
        "Lőrinci str. 20-22",
        "H-3000 Hatvan ",
        "Hungary ",
        " -",
        "Réka Hanyecz"
      );
    case "PLF FILLING DE-GE":
      return renderAddress(
        "Private Label Factory Deutschland GmbH",
        "Philipp-Reis-Str. 7",
        "45659 Recklinghausen",
        "Deutschland",
        "DE317701381",
        "Christoph Stachelek"
      );
    case "HANSA VITAL":
      return renderAddress(
        "Hansa Vital Supplements GmbH",
        "Gustav-Kunst-Straße 2-16",
        "20539 Hamburg",
        "Deutschland",
        "DE350891180",
        "Daniel Dies"
      );
    case "CONTRACT MANUFACTURER LABS":
      return renderAddress(
        "Contract Manufacturer Labs S.L.",
        "Ponent 78 – Nave C6 Poligono Industrial Can Mascaró ",
        "08756 La Palma de Cervello ",
        "Spain",
        " -",
        "Jorge G. Frias"
      );
    case "LEGOSAN AB":
      return renderAddress(
        "Legosan ABO",
        "Ymergatan 13",
        "SE-692 35 Kumla",
        "Sweden",
        "SE556354635601",
        "Mikael Hellman"
      );
    default:
  }
}

export default {
  findPackagingByType,
  getActiveSubstanceAmount,
  getCommodityWeight,
  uploadAndReturnPath,
  getTranslation,
  getCustomerHTML,
  getResponsibleHTML,
  getUnitDescription,
  getPositionHTML,
  getVatHTML,
  getSubtotalHTML,
  getTotalHTML,
  getReverseChargeHTML,
  getPackagingDescription,
  getOfferPackagingDescription,
  getCompanyHTML,
  getRecipeDescription,
  getSettingsDescription,
  getPaymentHTML,
  getDeliveryAddress,
  renderAddress,
  createOfferPDF,
  createNonBindingOfferPDF
};
