import _ from "lodash";
import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import { BSON } from "realm-web";
import { toast } from "react-toastify";
import i18n from "../../../../translations/i18n";
import { BaseActionModalProps } from "../../../../model/warehouse/common.types";
import { useWarehouseContext } from "../../../../context/warehouseContext";
import { useDataContext } from "../../../../context/dataContext";
import ErrorOverlayButton from "../../../common/ErrorOverlayButton";
import { Input } from "../../../common/Input";
import DeliveryCard from "./DeliveryCard";
import { Delivery, DeliveryState } from "../../../../model/warehouse/delivery.types";
import EditDeliveryCard from "./EditDeliveryCard";
import { getNumericValue } from "../../../../utils/baseUtils";
import { SelectOption } from "../../../../model/common.types";
import { PackagingUnitDefinition } from "../../../../model/configuration/warehouseConfiguration.types";
import { resolveTranslation } from "../../../../utils/translationUtils";
import dbService from "../../../../services/dbService";

enum Views {
  READ_CODE,
  EDIT,
  OVERVIEW
}

const BookInternalDeliveryModal: React.FC<BaseActionModalProps> = ({ show, onHide }) => {
  const { delivery } = useDataContext();
  const warehouseContext = useWarehouseContext();

  const [code, setCode] = useState<string>("");
  const [step, setStep] = useState<Views>(Views.READ_CODE);
  const [saving, setSaving] = useState<boolean>(false);
  const [bookInDeliveryEdit, setBookInDeliveryEdit] = useState<Delivery | undefined>(undefined);

  const originialDelivery = useMemo(() => {
    return delivery.find(d => d.identifier.toString() === code.trim());
  }, [code, delivery]);

  useEffect(() => {
    setCode("");
    setStep(Views.READ_CODE);
  }, [show]);

  useEffect(() => {
    if (!code || !originialDelivery) setBookInDeliveryEdit(undefined);
    if (originialDelivery) setBookInDeliveryEdit(_.cloneDeep(originialDelivery));
  }, [originialDelivery, show, code, step === Views.READ_CODE]);

  const editedMovedAmount = useMemo(
    () =>
      bookInDeliveryEdit?.deliveries.reduce(
        (sum1, d) =>
          sum1 +
          d.movedPackagingUnits.reduce(
            (sum2, mpU) => sum2 + (mpU.quantity ? mpU.quantity : 0) * mpU.amountPerPu.value,
            0
          ),
        0
      ),
    [bookInDeliveryEdit]
  );

  const buttonTooltips = useMemo(() => {
    const errorsBookIn = [];
    const errorsEdit = [];
    const warnings = [];
    if (!bookInDeliveryEdit) {
      errorsBookIn.push(i18n.t("warehouse:noDeliveryFound"));
      errorsEdit.push(i18n.t("warehouse:noDeliveryFound"));
    } else {
      if (bookInDeliveryEdit.state === DeliveryState.DELIVERED) {
        errorsBookIn.push(i18n.t("warehouse:deliveryAlreadyDelivered"));
        errorsEdit.push(i18n.t("warehouse:deliveryAlreadyDelivered"));
      }
      if (bookInDeliveryEdit.deliveries.some(d => d.movedPackagingUnits.some(mpu => mpu.quantity === null)))
        errorsBookIn.push(i18n.t("warehouse:internalDeliveryNeedsEdit"));
      if (bookInDeliveryEdit.deliveries.some(d => d.movedPackagingUnits.some(mpu => mpu.quantity === 0)))
        errorsBookIn.push(i18n.t("warehouse:pUQuantityMissing"));
      if (bookInDeliveryEdit.deliveries.some(d => d.movedPackagingUnits.some(mpu => mpu.amountPerPu.value === 0)))
        errorsBookIn.push(i18n.t("warehouse:amountPerPUError"));
      for (let i = 0; i < bookInDeliveryEdit.deliveries.length; i++) {
        const currentDeliveryPart = bookInDeliveryEdit.deliveries[i];
        if (
          currentDeliveryPart &&
          currentDeliveryPart.movedPackagingUnits.reduce(
            (sum, pu) => sum + (pu.quantity ? pu.quantity : 0) * pu.amountPerPu.value,
            0
          ) !== currentDeliveryPart.movedAmount.value
        )
          warnings.push(
            i18n.t("warehouse:movedAmountNotAddingUp", {
              lot: `${currentDeliveryPart.batch.lot}`,
              content: `${resolveTranslation(currentDeliveryPart.batch.content.details.title)} ${
                currentDeliveryPart?.batch.content.details.subtitle
                  ? resolveTranslation(currentDeliveryPart.batch.content.details.subtitle)
                  : ""
              }`
            })
          );
      }
    }
    return { errorsBookIn, errorsEdit, warnings };
  }, [bookInDeliveryEdit]);

  const handleChangeStep = useCallback(
    (nextStep: Views) => {
      if (nextStep === Views.READ_CODE) setBookInDeliveryEdit(_.cloneDeep(originialDelivery));
      setStep(nextStep);
    },
    [originialDelivery]
  );

  const handleChangeCode = useCallback((e: ChangeEvent<HTMLInputElement>) => setCode(e.currentTarget.value), []);

  const handleChangePackagingInputs = useCallback(
    (deliveryId: string, pUId: string, e: React.ChangeEvent<HTMLInputElement>) => {
      if (!bookInDeliveryEdit?.deliveries) return;
      const editDeliveries = _.clone(bookInDeliveryEdit.deliveries);
      const key = e.target.name;
      const changeDelivery = editDeliveries.find(d => d._id.toString() === deliveryId);
      const pU = changeDelivery?.movedPackagingUnits.find(pui => pui._id.toString() === pUId);
      if (pU) {
        if (key === "quantity") pU.quantity = Number(parseInt(e.target.value));
        else if (key === "amountPerUnit") pU.amountPerPu.value = Number(getNumericValue(e));
        setBookInDeliveryEdit({
          ...bookInDeliveryEdit,
          deliveries: editDeliveries
        });
      }
    },
    [bookInDeliveryEdit]
  );

  const handleChangePackagingUnit = useCallback(
    (deliveryId: string, pUId: string, e: SelectOption<PackagingUnitDefinition>) => {
      if (!bookInDeliveryEdit?.deliveries) return;
      const editDeliveries = _.clone(bookInDeliveryEdit.deliveries);
      const changeDelivery = editDeliveries.find(d => d._id.toString() === deliveryId);
      const pU = changeDelivery?.movedPackagingUnits.find(pui => pui._id.toString() === pUId);
      const packagingUnit = warehouseContext.configuration?.values.packagingUnitDefinitions.find(
        pu => pu._id.toString() === e.value
      );
      if (changeDelivery && pU !== undefined && packagingUnit) {
        pU.puSnapshot = e.data;
        setBookInDeliveryEdit({
          ...bookInDeliveryEdit,
          deliveries: editDeliveries
        });
      }
    },
    [bookInDeliveryEdit]
  );

  const handleAddPackagingRow = useCallback(
    (deliveryId: string) => {
      if (!bookInDeliveryEdit?.deliveries) return;
      const editDeliveries = _.clone(bookInDeliveryEdit.deliveries);
      const changeDelivery = editDeliveries.find(d => d._id.toString() === deliveryId);
      if (changeDelivery && warehouseContext.configuration) {
        changeDelivery.movedPackagingUnits.push({
          _id: new BSON.ObjectId(),
          puSnapshot: warehouseContext.configuration.values.packagingUnitDefinitions[0],
          quantity: 0,
          weight: { value: 0, unit: "kg" },
          amountPerPu: { value: 0, unit: "kg" }
        });
        setBookInDeliveryEdit({
          ...bookInDeliveryEdit,
          deliveries: editDeliveries
        });
      }
    },
    [bookInDeliveryEdit]
  );

  const handleRemovePackagingRow = useCallback(
    (deliveryId: string, pUId: string) => {
      if (!bookInDeliveryEdit?.deliveries) return;
      const editDeliveries = _.clone(bookInDeliveryEdit.deliveries);
      const changeDelivery = editDeliveries.find(d => d._id.toString() === deliveryId);
      if (changeDelivery) {
        changeDelivery.movedPackagingUnits = changeDelivery.movedPackagingUnits.filter(
          pui => pui._id.toString() !== pUId
        );
        setBookInDeliveryEdit({
          ...bookInDeliveryEdit,
          deliveries: editDeliveries
        });
      }
    },
    [bookInDeliveryEdit]
  );

  const handleBookIn = useCallback(async () => {
    setSaving(true);
    try {
      const res = await dbService.callFunction("bookInInternalDelivery", [bookInDeliveryEdit], true);
      if (res) {
        toast.success(i18n.t("warehouse:bookInternalDeliverySuccess"));
      } else {
        toast.error(i18n.t("warehouse:bookInternalDeliveryError"));
      }
    } catch (e) {
      console.error(e);
      toast.error(`${i18n.t("warehouse:bookInternalDeliveryError")}: ${e.message}`);
    } finally {
      setSaving(false);
      onHide();
    }
  }, [bookInDeliveryEdit]);

  const handleBookInButton = useCallback(() => {
    step === Views.EDIT ? handleChangeStep(Views.OVERVIEW) : handleBookIn();
  }, [step, handleChangeStep, handleBookIn]);

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title as={"h5"}>
          <b>{i18n.t("warehouse:bookInternalDelivery")}</b>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {step === Views.READ_CODE && (
          <>
            <div className="px-2">
              <h4 className="mb-5 font-weight-bold text-black">{i18n.t("warehouse:code")}</h4>
              <Input
                className="form-control form-control-solid"
                placeholder={i18n.t("warehouse:code")}
                value={code}
                onInput={handleChangeCode}
              />
            </div>
            {bookInDeliveryEdit &&
              bookInDeliveryEdit.deliveries.map(bid => {
                return <DeliveryCard key={bid._id.toString()} deliveryPart={bid} />;
              })}
          </>
        )}
        {step === Views.EDIT && (
          <div className="px-2">
            <h4 className="mb-5 font-weight-bold text-black">{i18n.t("warehouse:correctDelivery")}</h4>
            {bookInDeliveryEdit &&
              bookInDeliveryEdit.deliveries.map(bid => {
                return (
                  <EditDeliveryCard
                    key={bid._id.toString()}
                    deliveryPart={bid}
                    onChangePUInputs={handleChangePackagingInputs}
                    onAddPackagingRow={handleAddPackagingRow}
                    onRemovePackagingRow={handleRemovePackagingRow}
                    onChangePackagingUnit={handleChangePackagingUnit}
                  />
                );
              })}
          </div>
        )}
        {step === Views.OVERVIEW && (
          <>
            <div className="px-2">
              <h4 className="mb-5 font-weight-bold text-black">{i18n.t("common:overview")}</h4>
            </div>
            {bookInDeliveryEdit &&
              bookInDeliveryEdit.deliveries.map(bid => {
                return (
                  <DeliveryCard
                    key={bid._id.toString()}
                    deliveryPart={bid}
                    newMovedAmount={editedMovedAmount}
                    overview={true}
                  />
                );
              })}
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <button type="button" className="btn btn-secondary " onClick={onHide}>
          {i18n.t("common:close")}
        </button>
        <ErrorOverlayButton
          buttonText={step === Views.READ_CODE ? i18n.t("common:edit") : i18n.t("common:back")}
          className="btn btn-secondary"
          errors={step === Views.READ_CODE ? buttonTooltips.errorsEdit : []}
          onClick={() => (step !== Views.EDIT ? handleChangeStep(Views.EDIT) : handleChangeStep(Views.READ_CODE))}
          disabled={saving}
        />
        <ErrorOverlayButton
          buttonText={step === Views.EDIT ? i18n.t("common:next") : i18n.t("warehouse:bookInGoods")}
          className={step === Views.EDIT ? "btn btn-secondary" : "btn btn-success"}
          errors={buttonTooltips.errorsBookIn}
          warnings={buttonTooltips.warnings}
          onClick={handleBookInButton}
          disabled={saving}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default BookInternalDeliveryModal;
