import React, { useMemo } from "react";
import Select from "react-select";
import baseUtils, { formatNumValue } from "../../../../utils/baseUtils";
import i18n from "../../../../translations/i18n";
import { resolveTranslation } from "../../../../utils/translationUtils";
import { PackagingUnit } from "../../../../model/warehouse/batch.types";
import { useWarehouseContext } from "../../../../context/warehouseContext";
import { SelectOption } from "../../../../model/common.types";
import { OutgoingSnapshot } from "../../../../model/warehouse/delivery.types";
import { PackagingUnitDefinition } from "../../../../model/configuration/warehouseConfiguration.types";
import { Input } from "../../../common/Input";

interface EditDeliveryCardProps {
  deliveryPart: OutgoingSnapshot;
  onChangePUInputs: (deliveryId: string, pUId: string, e: React.ChangeEvent<HTMLInputElement>) => void;
  onAddPackagingRow: (deliveryId: string) => void;
  onRemovePackagingRow: (deliveryId: string, pUId: string) => void;
  onChangePackagingUnit: (deliveryId: string, pUId: string, e: SelectOption<PackagingUnitDefinition>) => void;
}

const EditDeliveryCard: React.FC<EditDeliveryCardProps> = ({
  deliveryPart,
  onAddPackagingRow,
  onRemovePackagingRow,
  onChangePUInputs,
  onChangePackagingUnit
}) => {
  const { batch, movedPackagingUnits, movedAmount } = deliveryPart;

  return (
    <div
      className="card card-custom card-fit card-border my-3 bg-light border-none"
      style={{
        boxShadow: "0px 0px 30px 0px rgba(0,0,0,0.05)"
      }}
    >
      <div className="card-header card-header-transparent">
        <div className="row">
          <div className="col-9 card-title d-block my-3" style={{ lineHeight: 1.2 }}>
            <span className="navi-text font-weight-bold font-size-h5 text-black">
              {baseUtils.truncateString(resolveTranslation(batch.content.details.title), 30)}&nbsp;
              <small>{baseUtils.truncateString(resolveTranslation(batch.content.details.subtitle), 30)}</small>
            </span>
            <br />
            <span className="navi-text font-weight-medium font-size-lg text-black">
              {i18n.t("warehouse:lot")}: {batch.lot}
            </span>
            <br />
            <span className="navi-text font-weight-medium font-size-lg text-black">
              {i18n.t("warehouse:amount")}: {formatNumValue(movedAmount)}
            </span>
          </div>
        </div>
      </div>
      <div className="card card-custom">
        <div className="card-body card-body-warehouse" style={{ border: "none" }}>
          {movedPackagingUnits.map(pui => {
            return (
              <DeliveryCardPURow
                key={pui._id.toString()}
                deliveryId={deliveryPart._id.toString()}
                pU={pui}
                numberOfPU={movedPackagingUnits.length}
                onChangePUInputs={onChangePUInputs}
                onRemovePackagingRow={onRemovePackagingRow}
                onChangePackagingUnit={onChangePackagingUnit}
              />
            );
          })}
        </div>
      </div>
      <div className="card-footer card-footer-warehouse" style={{ paddingTop: 0, paddingBottom: 0 }}>
        <div className="row justify-content-end">
          <button className="btn btn-text text-black" onClick={() => onAddPackagingRow(deliveryPart._id.toString())}>
            {i18n.t("warehouse:addVPE")}
          </button>
        </div>
      </div>
    </div>
  );
};

interface DeliveryCardPURowProps {
  deliveryId: string;
  pU: PackagingUnit;
  numberOfPU: number;
  onChangePUInputs: (deliveryId: string, pUId: string, e: React.ChangeEvent<HTMLInputElement>) => void;
  onRemovePackagingRow: (deliveryId: string, pUId: string) => void;
  onChangePackagingUnit: (deliveryId: string, pUId: string, e: SelectOption<PackagingUnitDefinition>) => void;
}

const DeliveryCardPURow: React.FC<DeliveryCardPURowProps> = ({
  deliveryId,
  pU,
  numberOfPU,
  onChangePUInputs,
  onRemovePackagingRow,
  onChangePackagingUnit
}) => {
  const warehouseContext = useWarehouseContext();

  const pUSelects: Array<SelectOption<PackagingUnitDefinition>> = useMemo(() => {
    if (warehouseContext.configuration)
      return warehouseContext.configuration.values.packagingUnitDefinitions.map(pu => {
        return {
          value: pu._id.toString(),
          label: resolveTranslation(pu.label),
          data: pu
        };
      });
    else return [];
  }, [warehouseContext.configuration?.values.packagingUnitDefinitions]);

  const pUTypeSelected: SelectOption<PackagingUnitDefinition> = useMemo(() => {
    return { value: pU.puSnapshot._id.toString(), label: resolveTranslation(pU.puSnapshot.label), data: pU.puSnapshot };
  }, [pU.puSnapshot]);

  const handleChangePUType = (e: any) => {
    onChangePackagingUnit(deliveryId, pU._id.toString(), e);
  };

  return (
    <div className="form-group row mb-3">
      <div className="col-6">
        <div className="input-group">
          <Input
            className="form-control form-control-solid"
            placeholder={i18n.t("warehouse:pUQuantity")}
            value={pU.quantity ? pU.quantity : 0}
            type="number"
            min={0}
            name="quantity"
            onChange={e => onChangePUInputs(deliveryId, pU._id.toString(), e)}
          />
          <div className="input-group-append">
            <div style={{ minWidth: "100px" }}>
              <Select
                className="select-warehouse border-radius-left-0"
                classNamePrefix="select-warehouse border-radius-left-0"
                onChange={handleChangePUType}
                value={pUTypeSelected}
                options={pUSelects}
              />
            </div>
          </div>
        </div>
      </div>
      <div className={numberOfPU > 1 ? "col-5" : "col-6"}>
        <div className="input-group">
          <Input
            className="form-control form-control-solid"
            style={{ minWidth: "25%" }}
            type="number"
            min={0}
            value={pU.amountPerPu.value}
            placeholder={i18n.t("warehouse:amountPerPU")}
            name="amountPerUnit"
            onChange={e => onChangePUInputs(deliveryId, pU._id.toString(), e)}
          />
          <div className="input-group-append">
            <div className="btn btn-secondary" style={{ backgroundColor: "#F3F6F9" }}>
              {pU.amountPerPu.unit}/{resolveTranslation(pU.puSnapshot.label)}
            </div>
          </div>
        </div>
      </div>
      {numberOfPU > 1 && (
        <div className="col-1 p-0 text-center">
          <button className="btn btn-text" onClick={() => onRemovePackagingRow(deliveryId, pU._id.toString())}>
            <i className="fa fa-trash" />
          </button>
        </div>
      )}
    </div>
  );
};

export default EditDeliveryCard;
