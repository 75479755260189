import _ from "lodash";
import { BSON } from "realm-web";
import React, { PureComponent } from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { CustomOrder } from "../CustomTypes";
import { Orders, OrderState } from "../../../model/orders.types";
import orderUtils from "../../../utils/orderUtils";
import dbService from "../../../services/dbService";
import userService from "../../../services/userService";
import dbGeneralService from "../../../services/dbServices/dbGeneralService";
import {
  T_CALLCREATED,
  T_CALLED,
  T_DATASHEET,
  T_FILE,
  T_OFFER,
  T_OFFERCONFIRMATION,
  T_OFFERPDF,
  T_REPORTPDF
} from "../../../utils/timelineUtils";

interface CreateCallModalProps {
  order: CustomOrder;
  disabled?: boolean;
}

interface CreateCallModalState {
  show: boolean;
  loading: boolean;
  selectedCall: string;
}

class CreateCallModal extends PureComponent<CreateCallModalProps, CreateCallModalState> {
  constructor(props: CreateCallModalProps) {
    super(props);
    this.state = {
      show: false,
      loading: false,
      selectedCall: this.selectInitialCall(props)
    };
  }

  /**
   * Select the initial call
   * @param props
   * @returns {string} the id of a call or empty string
   */
  selectInitialCall = (props: CreateCallModalProps): string => {
    const { order } = props;
    const calls = order.contract;
    if (!calls || calls.length === 0) return "";
    calls.sort((a, b) => {
      if (!a.date) return 1;
      if (!b.date) return 1;
      return a.date.valueOf() - b.date.valueOf();
    });
    const filteredCalls = calls.filter(c => !c.called && !c.finished);
    return filteredCalls.length > 0 ? filteredCalls[0]._id.toString() : "";
  };

  componentDidUpdate(prevProps: Readonly<CreateCallModalProps>) {
    if (!_.isEqual(prevProps.order.contract, this.props.order.contract)) {
      this.setState({ selectedCall: this.selectInitialCall(this.props) });
    }
  }

  handleShow = () => this.setState({ show: true });
  handleClose = () => (this.state.loading ? {} : this.setState({ show: false }));

  handleSelectCall = (id: string) => this.setState({ selectedCall: id === this.state.selectedCall ? "" : id });

  handleCreateCall = async () => {
    this.setState({ loading: true });
    const { order } = this.props;
    const { selectedCall } = this.state;
    if (selectedCall.trim() === "" || !selectedCall) return;
    const sCall = order.contract!.find(c => c._id.toString() === selectedCall)!;
    const calculation = order.calculations[0];
    const commoditiesDelivered = calculation.prices.every(price => !!price.delivered);
    const packagingDelivered = calculation.packagings.every(price => !!price.delivered);
    const callTimeline = order.timeline.filter(t =>
      [T_OFFER, T_OFFERPDF, T_REPORTPDF, T_DATASHEET, T_FILE, T_OFFERCONFIRMATION].includes(t.type)
    );
    callTimeline.push({
      _id: new BSON.ObjectId(),
      type: T_CALLED,
      date: new Date(),
      person: userService.getUserId(),
      contractId: order._id,
      contractIdentifier: order.identifier
    });
    const targetDate = sCall.date ? sCall.date : orderUtils.calculateEarliestDeliveryDate(order);
    const callCalculation = _.cloneDeep(calculation);
    const units = +sCall.value;
    callCalculation.units = units;
    callCalculation.info = {
      unitprice: calculation.info.unitprice,
      unitpricenaked: calculation.info.unitpricenaked,
      unitmargin: calculation.info.unitmargin,
      totalprice: units * calculation.info.unitprice,
      totalmargin: units * calculation.info.unitmargin,
      percentmargin: calculation.info.percentmargin
    };
    if (calculation.info.marginBuffer !== null && calculation.info.marginBuffer !== undefined)
      callCalculation.info.marginBuffer = calculation.info.marginBuffer;
    if (calculation.info.customCalculation) callCalculation.info.customCalculation = calculation.info.customCalculation;
    if (calculation.info.standardCalculation)
      callCalculation.info.standardCalculation = calculation.info.standardCalculation;

    const call: Orders = {
      identifier: -1,
      title: order.title,
      subtitle: order.subtitle,
      note: order.note,
      state: commoditiesDelivered && packagingDelivered ? OrderState.PRODUCTION_QUEUE : OrderState.ORDER_COMMODITIES,
      createdOn: new Date(),
      createdFrom: order.createdFrom._id,
      createdFor: order.createdFor._id,
      priority: order.priority,
      targetDate,
      version: 0,
      delivery: null,
      usedBatches: null,
      usedPackagingBatches: null,
      fulfillment: {
        lot: await dbGeneralService.callGenerateLotNumberFunction(),
        exp: new Date(0), // set an obviously invalid date
        shippingNote: "",
        shippingGroups: []
      },
      product: order.product,
      reorder: false,
      recipe: order.recipe,
      contract: [],
      timeline: callTimeline,
      calculations: [callCalculation],
      contractInformation: {
        contractId: order._id,
        callId: new BSON.ObjectId(selectedCall)
      },
      settings: {
        type: order.settings.type,
        perUnit: order.settings.perUnit,
        id: order.settings.id,
        manufacturer: order.settings.manufacturer._id
      }
    };
    if (order.settings.filler) call.settings.filler = order.settings.filler._id.toString();
    if (order.offerIdentifier) call.offerIdentifier = order.offerIdentifier;
    if (order.fulfillment?.shelfLife) call.fulfillment!.shelfLife = order.fulfillment.shelfLife;
    const contractTimelineEntry = {
      _id: new BSON.ObjectId(),
      type: T_CALLCREATED,
      units: units,
      date: new Date(),
      person: userService.getUserId()
    };
    try {
      const result = await dbService.callFunction("createContractCall", [
        call,
        order._id,
        selectedCall,
        contractTimelineEntry
      ]);
      if (result) {
        toast.success("Call successfully created");
        this.setState({ loading: false, show: false });
        window.location.href = "/order/" + result.toString();
      } else toast.error("Call could not be created");
    } catch (e) {
      console.error(e);
      toast.error("An unexpected error occurred: " + e.message);
    } finally {
      this.setState({ loading: false });
    }
  };

  render() {
    const { order, disabled } = this.props;
    const { show, loading, selectedCall } = this.state;
    const calls = order.contract;
    return (
      <>
        <button
          className={"btn btn-sm btn-upper btn-success " + (disabled && "disabled")}
          disabled={disabled}
          onClick={this.handleShow}
        >
          Create call
        </button>
        <Modal show={show} onHide={this.handleClose} centered name={"createCallModal"}>
          <Modal.Header closeButton>
            <Modal.Title>
              <i className="kt-font-brand flaticon2-pie-chart mr-2" />
              Create Contract Call
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className={"overflow-auto"} style={{ maxHeight: "80vh" }}>
            {calls && calls.length > 0 ? (
              <>
                <h4 className="text-dark">Select a call</h4>
                <table className={"table "}>
                  <thead>
                    <tr>
                      <th style={{ minWidth: "100px" }}>Amount</th>
                      <th>Planned</th>
                      <th className="text-center" style={{ maxWidth: "33%" }}>
                        Status
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {calls.map(c => {
                      const id = c._id.toString();
                      const cellStyle = c.called || c.finished ? { opacity: "0.5" } : {};
                      return (
                        <tr
                          key={id}
                          className={
                            c.called || c.finished
                              ? "table-light my-3 "
                              : "table-hover my-3 " + (id === selectedCall && "table-primary")
                          }
                          onClick={() => (c.called || c.finished ? {} : this.handleSelectCall(id))}
                        >
                          <td className="font-weight-bold py-3" style={cellStyle}>
                            {c.value}
                          </td>
                          <td className="py-3" style={cellStyle}>
                            {c.date
                              ? c.date.toLocaleDateString("de-DE", {
                                  year: "numeric",
                                  month: "long",
                                  day: "numeric"
                                })
                              : "-"}
                          </td>
                          <td
                            className="py-3 align-middle text-center"
                            style={c.called || c.finished ? { opacity: "0.5" } : {}}
                          >
                            {c.called || c.finished ? (
                              <span className="kt-badge kt-badge--inline kt-badge--danger kt-badge--pill">
                                Already called
                              </span>
                            ) : (
                              <span className="kt-badge kt-badge--inline kt-badge--success kt-badge--pill">Open</span>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </>
            ) : (
              <h4 className={"text-center text-danger"}>No calls configured</h4>
            )}
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-secondary" onClick={this.handleClose}>
              Close
            </button>
            <button
              className={"btn btn-success " + (loading || selectedCall.trim() === "" ? "disabled" : "")}
              disabled={loading || selectedCall.trim() === ""}
              onClick={this.handleCreateCall}
            >
              {loading && (
                <div className="button-splash-spinner d-inline pr-3 pl-0 mx-0">
                  <svg className="button-splash-spinner" viewBox="0 0 50 50">
                    <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5" />
                  </svg>
                </div>
              )}
              Create Call
            </button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default CreateCallModal;
