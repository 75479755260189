import React, { PureComponent, useEffect, useState } from "react";
import { OverlayTrigger, Popover, Table, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";
import CreateCommodityOrderModal from "../modals/CreateCommodityOrderModal";
import DeliverCommodityOrderModal from "../modals/DeliverCommodityOrderModal";
import CompanyWidget from "../../common/CompanyWidget";
import ManufacturerWidget from "../../common/ManufacturerWidget";
import config from "../../../config/config.json";
import { DataContext } from "../../../context/dataContext";
import baseUtils from "../../../utils/baseUtils";
import { CommoditiesDocument, CommodityOrder, StockTransferOrder } from "../../../model/commodities.types";
import commodityUtils from "../../../utils/commodityUtils";
import { OrdersDocument } from "../../../model/orders.types";
import { OrderWidget } from "../../listings/common/BaseListingComponents";
import EmOrderWidget from "../../externalManufacturers/orders/EMOrderWidget";
import TransferCommodityModal from "../modals/TransferCommodityModal";
import stoUtils from "../../../utils/stoUtils";
import { getRawbidsOrderStateDescription, getRawbidsSupplierId } from "../../../utils/rawbidsUtils";

interface CommodityCommodityOrdersProps {
  commodity: CommoditiesDocument;
  context: React.ContextType<typeof DataContext>;
  size: "full" | "small";
  onTabChange?: (tab: string) => void;
  order?: OrdersDocument;
}

interface CommodityCommodityOrdersState {}

class CommodityCommodityOrders extends PureComponent<CommodityCommodityOrdersProps, CommodityCommodityOrdersState> {
  /**
   * Calculate the width of the progress bar
   * @param order Commodity order whose process should be calculated
   * @param allSTODone Flag that indicates that all deliveries to manufacturers are done
   * @returns { string } Represents the percentage of the progress bar width
   */
  getProgressBarWidth = (order: CommodityOrder, allSTODone: boolean): string => {
    if (order.delivered && allSTODone) {
      return "100%";
    }
    if (order.arrivedAtWarehouse && order.stockTransferOrders?.every(sto => sto.created)) {
      return "66%";
    }
    if (order.arrivedAtWarehouse) {
      return "50%";
    }
    if (!order.ordered) {
      return "0%";
    }
    if (order.ordered && !order.delivered) {
      let eta = new Date(order.ordered);
      eta.setDate(eta.getDate() + order.deliverytime);
      let timeUntilEta = eta.getTime() - order.ordered.getTime();
      let timeSinceOrdered = new Date().getTime() - order.ordered.getTime();

      return (timeSinceOrdered / (timeUntilEta !== 0 ? timeUntilEta : 1)) * 100 + "%";
    }

    return "33%";
  };

  render() {
    const { commodity, context, onTabChange, order, size } = this.props;
    let orders = commodity.orders;
    if (orders && size === "small")
      orders = orders.filter(
        o => !o.delivered || (o.stockTransferOrders && o.stockTransferOrders.some(sto => !sto.delivered))
      );
    return (
      <>
        {orders && orders.length > 0 ? (
          <div className="table-responsive">
            <Table>
              <thead>
                <tr>
                  <th style={{ width: size === "full" ? "10%" : "16%" }}>Amount</th>
                  <th style={{ width: size === "full" ? "21%" : "41%" }}>Supplier</th>
                  {size === "full" && (
                    <>
                      <th style={{ width: "10%" }}>Price</th>
                      <th style={{ width: "10%" }}>Incoterm</th>
                      <th style={{ width: "20%" }}>Manufacturer</th>
                    </>
                  )}
                  <th style={{ width: "13%" }}>Progress</th>
                  {size === "full" && <th style={{ width: "6%" }}>Ord. PDF</th>}
                  <th style={{ width: size === "full" ? "10%" : "15%" }} />
                </tr>
              </thead>
              <tbody>
                {orders.map(o => {
                  const supplier = o.supplier
                    ? baseUtils.getDocFromCollection(context.suppliers, o.supplier!.toString())
                    : null;
                  const manufacturer = o.destination
                    ? baseUtils.getDocFromCollection(context.manufacturers, o.destination!.toString())
                    : null;
                  const cOrder =
                    o.orders && o.orders.length === 1
                      ? baseUtils.getDocFromCollection(context.orders, o.orders[0].toString())
                      : null;
                  const eta = o.ordered ? new Date(o.ordered.getTime()) : null;
                  if (eta) eta.setDate(eta.getDate() + +o.deliverytime);
                  const allSTODone = !o.stockTransferOrders || !o.stockTransferOrders.some(sto => !sto.delivered);
                  return (
                    <tr
                      key={o._id.toString()}
                      className={o.delivered ? "" : "table-hover"}
                      style={{ opacity: o.delivered && allSTODone ? 0.3 : 1 }}
                    >
                      <td className="align-middle">
                        <AmountCell cOrder={cOrder} commodity={commodity} o={o} context={context} />
                      </td>
                      <td className="align-middle">
                        {supplier ? (
                          <CompanyWidget
                            company={supplier}
                            type="supplier"
                            isRawbidsSupplier={supplier._id.toString() === getRawbidsSupplierId(context.general)}
                          />
                        ) : (
                          "Unknown"
                        )}
                      </td>
                      {size === "full" && (
                        <>
                          <td className="align-middle">
                            <span className="font-weight-bold">
                              {baseUtils.formatEuro(o.totalPrice / o.orderquantity)} / {!commodity.type ? "kg" : "tsd"}
                            </span>
                            <br />
                            <span className="text-muted">{baseUtils.formatEuro(o.totalPrice)} total</span>
                          </td>
                          <td className="align-middle">{o.incoterm ? o.incoterm.toUpperCase() : "Not set"}</td>
                          <td className="align-middle">
                            {manufacturer ? <ManufacturerWidget manufacturer={manufacturer} /> : "Unknown"}
                          </td>
                        </>
                      )}
                      <OverlayTrigger
                        placement="top"
                        show={o.rawbidsOrder ? undefined : false}
                        overlay={
                          o.rawbidsOrder ? (
                            <Tooltip id="rawbidsOrderInformation">
                              Rawbids Status: {getRawbidsOrderStateDescription(o.rawbidsOrder.snapshot)}
                            </Tooltip>
                          ) : (
                            <></>
                          )
                        }
                      >
                        <td className="align-middle">
                          <div className="progress" style={{ height: 5 }}>
                            <div
                              className="progress-bar bg-success"
                              role="progressbar"
                              style={{
                                width: this.getProgressBarWidth(o, allSTODone)
                              }}
                            />
                          </div>
                          {size === "full" ? (
                            <small>
                              {o.stockTransferOrders?.some(sto => sto.created && !sto.delivered) ? (
                                <div>
                                  On the way to production <br />
                                  Amount:{" "}
                                  {commodityUtils.resolveStockUnit(
                                    o.stockTransferOrders?.reduce((ac, sto) => {
                                      return ac + sto.amount;
                                    }, 0),
                                    commodity.type
                                  )}
                                </div>
                              ) : (
                                o.arrivedAtWarehouse && (
                                  <div>At Warehouse: {baseUtils.formatDate(o.arrivedAtWarehouse)}</div>
                                )
                              )}
                              {o.delivered ? (
                                <div>Delivered: {baseUtils.formatDate(o.delivered)}</div>
                              ) : (
                                <>
                                  <div>
                                    Ordered:{" "}
                                    {o.ordered ? baseUtils.formatDate(o.ordered) : baseUtils.formatDate(o.created)}
                                  </div>
                                  <div>
                                    ETA:{" "}
                                    {!o.ordered && o.asap
                                      ? "ASAP"
                                      : baseUtils.formatDate(eta ? eta : commodityUtils.resolveDeliveryDate(o))}
                                  </div>
                                </>
                              )}
                            </small>
                          ) : o.stockTransferOrders?.some(sto => sto.created && !sto.delivered) ? (
                            <small>in transit</small>
                          ) : (
                            o.arrivedAtWarehouse && <small>at Warehouse </small>
                          )}
                        </td>
                      </OverlayTrigger>
                      {size === "full" && (
                        <td className="align-middle text-center">
                          <div className="kt-widget4__pic kt-widget4__pic--icon">
                            {o.orderPDF ? (
                              <a
                                href={(process.env.REACT_APP_MEDIAHUB_BASE || "") + "pdf-files/" + o.orderPDF}
                                target="_blank"
                              >
                                <img
                                  src={process.env.PUBLIC_URL + "/media/icons/pdf_icon.png"}
                                  alt="orderPDF"
                                  style={{ width: 30 }}
                                />
                              </a>
                            ) : (
                              <img
                                src={process.env.PUBLIC_URL + "/media/icons/pdf_icon.png"}
                                alt="orderPDF"
                                style={{
                                  width: 30,
                                  opacity: 0.25
                                }}
                              />
                            )}
                          </div>
                        </td>
                      )}
                      <td className="align-middle text-right">
                        {o.delivered && allSTODone ? (
                          <div>
                            {o.lot ? <span className="mr-2">Batch: {o.lot}</span> : <i className="fa fa-check" />}
                          </div>
                        ) : o.arrivedAtWarehouse ? (
                          o.stockTransferOrders?.some(sto => !sto.created) ? (
                            <TransferCommodityModal
                              commodity={commodity}
                              commodityOrder={o}
                              stockTransferOrders={o.stockTransferOrders}
                              context={context}
                            />
                          ) : (
                            o.stockTransferOrders && (
                              <DestinationCell
                                stockTransferOrders={o.stockTransferOrders}
                                context={context}
                                customCommodity={!!commodity.type}
                              />
                            )
                          )
                        ) : (
                          <div className="btn-group">
                            <CreateCommodityOrderModal commodity={commodity} context={context} commodityOrder={o} />
                            <DeliverCommodityOrderModal commodity={commodity} context={context} commodityOrder={o} />
                          </div>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        ) : (
          <div className="m-4 text-center text-muted">No orders planned</div>
        )}
        <div className="kt-separator kt-separator--space-lg kt-separator--fit kt-separator--border-solid" />
        <div className="text-right">
          <CreateCommodityOrderModal commodity={commodity} context={context} order={order} />
          {size === "small" && onTabChange && (
            <button className="btn btn-success ml-2" onClick={() => onTabChange("commodityOrders")}>
              Show All
            </button>
          )}
        </div>
      </>
    );
  }
}

interface AmountCellProps {
  cOrder: OrdersDocument | null;
  commodity: CommoditiesDocument;
  o: CommodityOrder;
  context: React.ContextType<typeof DataContext>;
}

const AmountCell: React.FunctionComponent<AmountCellProps> = ({ cOrder, commodity, o, context }) => {
  const [showPopover, setShowPopover] = useState(false);

  let setTimeoutConst: any = null;

  useEffect(() => {
    return () => {
      if (setTimeoutConst) {
        clearTimeout(setTimeoutConst);
      }
    };
  });

  const handleMouseEnter = () => {
    setTimeoutConst = setTimeout(() => {
      setShowPopover(true);
    }, 0);
  };

  const handleMouseLeave = () => {
    clearTimeout(setTimeoutConst);
    setShowPopover(false);
  };

  return (
    <>
      <span className="font-weight-bold">{commodityUtils.resolveStockUnit(o.orderquantity, commodity.type)}</span>
      <br />
      <span className="text-muted">
        for{" "}
        {cOrder && !(o.relatedEMOrders && o.relatedEMOrders.length > 0) ? (
          <Link className={"kt-link kt-font-dark"} to={"/order/" + cOrder._id.toString()}>
            AT-{cOrder.identifier}
          </Link>
        ) : (o.orders && o.orders.length > 1) ||
          (o.relatedEMOrders && o.relatedEMOrders.length + (o.orders ? o.orders.length : 0) > 1) ? (
          <OverlayTrigger
            placement="top"
            show={showPopover}
            overlay={
              <Popover id={"relatedOrders"} onMouseEnter={() => setShowPopover(true)} onMouseLeave={handleMouseLeave}>
                <Popover.Content>
                  <>
                    {o.orders && o.orders.length > 0 && (
                      <div className="text-left mb-4">
                        <h6 className="kt-font-dark">Orders</h6>
                        {o.orders.map(ord => {
                          const ordDoc = baseUtils.getDocFromCollection(context.orders, ord);
                          if (ordDoc)
                            return <OrderWidget key={ordDoc._id.toString()} document={ordDoc} prefix={"AT-"} />;
                          return <React.Fragment key={ord.toString()} />;
                        })}
                      </div>
                    )}
                    {o.relatedEMOrders && o.relatedEMOrders.length > 0 && (
                      <div>
                        <h6 className="kt-font-dark">External Orders</h6>
                        {o.relatedEMOrders.map(ord => {
                          const ordDoc = baseUtils.getDocFromCollection(context.externalManufacturerOrders, ord);
                          if (ordDoc) return <EmOrderWidget order={ordDoc} internal={true} />;
                          return <></>;
                        })}
                      </div>
                    )}
                  </>
                </Popover.Content>
              </Popover>
            }
          >
            <span className="kt-font-dark kt-link" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>{`${
              o.orders.length + (o.relatedEMOrders ? o.relatedEMOrders.length : 0)
            } orders`}</span>
          </OverlayTrigger>
        ) : (
          "stock"
        )}
      </span>
    </>
  );
};

interface DestinationCellProps {
  stockTransferOrders: Array<StockTransferOrder>;
  customCommodity: boolean;
  context: React.ContextType<typeof DataContext>;
}

interface DestinationCellState {
  showPopover: boolean;
}

export class DestinationCell extends PureComponent<DestinationCellProps, DestinationCellState> {
  constructor(props: DestinationCellProps) {
    super(props);
    this.state = { showPopover: false };
  }

  handleShowPopover = () => this.setState({ showPopover: true });
  handleHidePopover = () => this.setState({ showPopover: false });

  render() {
    const { stockTransferOrders, context, customCommodity } = this.props;
    const { showPopover } = this.state;

    if (stockTransferOrders.length === 1) {
      const { destination, extendedOrders } = stoUtils.resolveDestinationAndOrders(
        stockTransferOrders[0],
        context.manufacturers,
        context.orders
      );
      return (
        <div className="row text-left">
          <div className="col-12 align-self-center">
            <div className="kt-user-card-v2__details">
              <span className="kt-user-card-v2__name">{destination.name}</span>
            </div>
            {extendedOrders.map((o, idx) => (
              <React.Fragment key={o._id.toString()}>
                <Link className="kt-user-card-v2__name d-inline-block kt-link" to={"/order/" + o._id.toString()}>
                  AT-{o.identifier}
                </Link>
                {idx !== extendedOrders.length - 1 && ", "}
              </React.Fragment>
            ))}
          </div>
        </div>
      );
    }

    return (
      <OverlayTrigger
        placement="top"
        show={showPopover}
        overlay={
          <Popover id="destinations" onMouseEnter={this.handleShowPopover} onMouseLeave={this.handleHidePopover}>
            <Popover.Content style={{ minWidth: "450px" }}>
              <div className="text-left mb-4">
                <h6 className="kt-font-dark">Destinations</h6>
                {stockTransferOrders.map(sto => {
                  const { destination, extendedOrders } = stoUtils.resolveDestinationAndOrders(
                    sto,
                    context.manufacturers,
                    context.orders
                  );
                  if (destination)
                    return (
                      <div key={sto._id.toString()} className="row mt-2">
                        <div className="col-6">
                          <div className="kt-user-card-v2__details">
                            <span className="kt-user-card-v2__name">{destination.name}</span>
                          </div>
                          {extendedOrders.map((o, idx) => (
                            <React.Fragment key={o._id.toString()}>
                              <Link
                                className="kt-user-card-v2__name d-inline-block kt-link"
                                to={"/order/" + o._id.toString()}
                              >
                                AT-{o.identifier}
                              </Link>
                              {idx !== extendedOrders.length - 1 && ", "}
                            </React.Fragment>
                          ))}
                        </div>
                        <div className={(sto.delivered || sto.created ? "col-2 " : "col-5 ") + "align-self-center"}>
                          {sto.amount} {customCommodity ? "tsd" : "kg"}
                        </div>
                        {(sto.delivered || sto.created) && (
                          <div className="col-3 align-self-center">
                            {sto.delivered ? baseUtils.formatDate(sto.delivered) : baseUtils.formatDate(sto.created!)}
                          </div>
                        )}
                        <div className="col-1 align-self-center">
                          <OverlayTrigger
                            overlay={
                              <Tooltip id={"commodity-invalid"}>
                                <span className="text-info">
                                  <b>
                                    {sto.delivered
                                      ? "Commodity has been delivered"
                                      : sto.created
                                      ? "Commodity is on the way to production"
                                      : "Commodity is at warehouse"}
                                  </b>
                                </span>
                                <br />
                              </Tooltip>
                            }
                            placement={"top"}
                          >
                            <i
                              className={
                                sto.delivered ? "fa fa-check" : sto.created ? "fa fa-truck" : "fa fa-warehouse"
                              }
                            />
                          </OverlayTrigger>
                        </div>
                      </div>
                    );
                  return null;
                })}
              </div>
            </Popover.Content>
          </Popover>
        }
      >
        <div
          className="kt-font-dark text-left"
          onMouseEnter={this.handleShowPopover}
          onMouseLeave={this.handleHidePopover}
        >
          {stockTransferOrders.length} destinations
        </div>
      </OverlayTrigger>
    );
  }
}

export default CommodityCommodityOrders;
