import _ from "lodash";
import React from "react";
import { Link } from "react-router-dom";
import config from "../../config/config.json";
import { DataContext } from "../../context/dataContext";
import { Invoice, OrdersDocument } from "../../model/orders.types";
import accessUtils, { ACTIONS } from "../../utils/accessUtils";
import baseUtils from "../../utils/baseUtils";
import invoiceUtils, { I_PAID } from "../../utils/invoiceUtils";
import orderUtils from "../../utils/orderUtils";

interface FinanceDetailsRowProps {
  invoice: Invoice;
  order: OrdersDocument;
  onPayInvoice: (order: OrdersDocument, invoice: any) => void;
  context: React.ContextType<typeof DataContext>;
}

const FinanceDetailsRow: React.FunctionComponent<FinanceDetailsRowProps> = ({
  invoice,
  order,
  context,
  onPayInvoice
}) => {
  const { userdata, companies } = context;
  const company = baseUtils.getDocFromCollection(companies, order.createdFor);
  const owner = baseUtils.getDocFromCollection(userdata, order.createdFrom);
  const total = invoiceUtils.getGrossTotalWithReminders(invoice);
  let reminder = invoice.reminder;
  reminder = _.orderBy(reminder, r => r.date, "desc");
  const pending = invoiceUtils.getGrossPending(invoice, invoice.state, total);

  const canPay = accessUtils.canPerformAction(ACTIONS.INVOICEPAY);

  /**
   * Resolves the display name of the invoice state.
   * @returns { string } Display name
   */
  const resolveState = () => {
    switch (invoice.state) {
      case "open":
      case "canceled":
      case "paid":
        return _.upperFirst(invoice.state);
      case "partlyPaid":
        return "Partially paid";
      default:
        return "unknown";
    }
  };

  return (
    <tr key={invoice._id.toString()} className={"kt-datatable__row d-table-row nopadding " + invoice.state}>
      <td className="kt-datatable__cell d-table-cell">
        <div className="kt-user-card-v2">
          <div className="kt-user-card-v2__details">
            <a
              href={(process.env.REACT_APP_MEDIAHUB_BASE || "") + invoice.path}
              target="_blank"
              className="kt-user-card-v2__name kt-link"
            >
              {"RE-" + invoice.invoiceNumber}
            </a>
          </div>
        </div>
      </td>
      <td className="kt-datatable__cell d-table-cell">
        <div className="kt-user-card-v2">
          <div className="kt-user-card-v2__details">
            <Link to={"/order/" + order._id.toString()} className="kt-user-card-v2__name kt-link">
              {"AT-" + order.identifier}
            </Link>
          </div>
        </div>
      </td>
      <td className="kt-datatable__cell d-table-cell">
        <div className="kt-user-card-v2">
          <div className="kt-user-card-v2__details">
            <Link to={"/order/" + order._id.toString()} className="kt-user-card-v2__name kt-link">
              {order.title.length <= 25 ? order.title : order.title.slice(0, 25)}
            </Link>
          </div>
        </div>
      </td>
      <td className="kt-datatable__cell d-table-cell">
        <div className="kt-user-card-v2">
          <div className="kt-user-card-v2__details">
            {company ? (
              <Link to={"/company/" + company._id.toString()} className="kt-user-card-v2__name kt-link">
                {company.name.length <= 25 ? company.name : company.name.slice(0, 25)}
              </Link>
            ) : (
              "Unknown company"
            )}
          </div>
        </div>
      </td>
      <td className="kt-datatable__cell d-table-cell">
        <div className="kt-user-card-v2">
          <div className="kt-user-card-v2__details">
            <span className="kt-user-card-v2__name">{baseUtils.formatDate(invoice.invoiceDate)}</span>
          </div>
        </div>
      </td>
      <td className="kt-datatable__cell d-table-cell">
        <div className="kt-user-card-v2">
          <div className="kt-user-card-v2__details">
            <span className="kt-user-card-v2__name">
              {baseUtils.formatDate(orderUtils.getInvoiceDue(invoice.dueIn, invoice.invoiceDate))}
            </span>
          </div>
        </div>
      </td>
      <td className="kt-datatable__cell d-table-cell">
        <div className="kt-user-card-v2">
          <div className="kt-user-card-v2__details">
            <span className="kt-user-card-v2__name">{resolveState()}</span>
          </div>
        </div>
      </td>
      <td className="kt-datatable__cell d-table-cell">
        <div className="kt-user-card-v2">
          <div className="kt-user-card-v2__details">
            <span className="kt-user-card-v2__name">{reminder.length > 0 ? reminder.length : "-"}</span>
          </div>
        </div>
      </td>
      <td className="kt-datatable__cell d-table-cell">
        <div className="kt-user-card-v2">
          <div className="kt-user-card-v2__details">
            <span className="kt-user-card-v2__name">
              {reminder.length > 0 ? baseUtils.formatDate(reminder[0].date) : "-"}
            </span>
          </div>
        </div>
      </td>
      <td className="kt-datatable__cell d-table-cell">
        <div className="kt-user-card-v2">
          <div className="kt-user-card-v2__details">
            <span className="kt-user-card-v2__name kt-font-boldest">{baseUtils.formatEuro(total)}</span>
          </div>
        </div>
      </td>
      <td className="kt-datatable__cell d-table-cell">
        <div className="kt-user-card-v2">
          <div className="kt-user-card-v2__details">
            <span className="kt-user-card-v2__name">{baseUtils.formatEuro(pending)}</span>
          </div>
        </div>
      </td>
      <td className="kt-datatable__cell d-table-cell">
        <div className="kt-user-card-v2">
          <div className="kt-user-card-v2__details">
            <span className="kt-user-card-v2__name">
              {owner ? owner.prename + " " + owner.surname : "Unknown owner"}
            </span>
          </div>
        </div>
      </td>
      <td className="kt-datatable__cell d-table-cell">
        {invoice.state === I_PAID ? (
          <span className="text-success kt-font-bolder">
            <i className="fa fa-check mr-1" />
            Paid
          </span>
        ) : invoice.cancelation ? (
          <span className="text-warning kt-font-bolder">Cancelled</span>
        ) : (
          <button
            className={"btn btn-xs btn-success w-100" + (canPay ? "" : " disabled")}
            onClick={canPay ? () => onPayInvoice(order, invoice) : undefined}
            disabled={!canPay}
          >
            Pay
          </button>
        )}
      </td>
    </tr>
  );
};

export default FinanceDetailsRow;
