import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { CustomOrder } from "../CustomTypes";
import { useDataContext } from "../../../context/dataContext";
import { OrdersDocument } from "../../../model/orders.types";
import { ORDERS } from "../../../services/dbService";
import dbOrderService from "../../../services/dbServices/dbOrderService";
import userService from "../../../services/userService";
import { CREATEINVOICE } from "../../../utils/orderUtils";
import { T_RETURNTOCREATEINVOCIE } from "../../../utils/timelineUtils";
import ErrorOverlayButton from "../../common/ErrorOverlayButton";

interface ReturnToCreateInvoiceModalProps {
  order: CustomOrder | OrdersDocument;
}

const ReturnToCreateInvoiceModal: React.FunctionComponent<ReturnToCreateInvoiceModalProps> = ({ order }) => {
  const context = useDataContext();

  const [show, setShow] = useState(false);
  const [saving, setSaving] = useState(false);
  const [reason, setReason] = useState("");

  const handleShow = () => {
    setShow(true);
    setReason("");
  };
  const handleClose = () => setShow(false);

  const handleReturnToCreateInvoice = async () => {
    const timelineEntry = {
      type: T_RETURNTOCREATEINVOCIE,
      reason: reason,
      date: new Date(),
      person: userService.getUserId()
    };
    setSaving(true);
    try {
      const result = await dbOrderService.switchState(order._id, CREATEINVOICE, timelineEntry);
      if (result && result.modifiedCount) {
        toast.success("Order successfully updated");
        handleClose();
        context.updateDocumentInContext(ORDERS, order._id);
      } else toast.error("Order could not be updated");
    } catch (e) {
      console.error(e);
      toast.error("An unexpected error occurred: " + e.message);
    } finally {
      setSaving(false);
    }
  };

  const validateData = () => {
    const errors: Array<string> = [];
    if (reason.trim().length < 20) errors.push("Please enter a reason with at least 20 characters.");
    return errors;
  };

  return (
    <>
      <button className={"btn btn-sm btn-upper btn-secondary"} onClick={handleShow}>
        Return To previous step
      </button>
      <Modal show={show} onHide={handleClose} size={"sm"} centered name={"ReturnToCreateInvoiceModal"}>
        <Modal.Header closeButton>
          <Modal.Title>
            <i className="kt-font-brand fa fa-info mr-2" />
            Return to previous step
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group form-group-last">
            <label>Please justify the Reset</label>
            <textarea onChange={e => setReason(e.target.value)} className="form-control" value={reason} rows={3} />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-secondary" onClick={handleClose}>
            Close
          </button>
          <ErrorOverlayButton
            errors={validateData()}
            saving={saving}
            className="btn btn-secondary"
            buttonText="Confirm"
            onClick={handleReturnToCreateInvoice}
          />
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ReturnToCreateInvoiceModal;
