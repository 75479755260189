import { BSON } from "realm-web";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import i18n from "../../../../translations/i18n";
import ErrorOverlayButton from "../../../common/ErrorOverlayButton";
import { BaseActionModalProps } from "../../../../model/warehouse/common.types";
import { DataContext } from "../../../../context/dataContext";
import baseUtils from "../../../../utils/baseUtils";
import { Batch, BatchLocation, PackagingUnit } from "../../../../model/warehouse/batch.types";
import { useWarehouseContext } from "../../../../context/warehouseContext";
import { getBatchIdFromSelectedEntries, isSelectedCommodityEntries } from "../../../../utils/warehouseActionUtils";
import { SelectedCommodityEntryType } from "../../../../utils/warehouseUtils";
import SendModalSelection from "./SendModalSelection";
import SendModalConfirmation from "./SendModalConfirmation";
import { SelectOption } from "../../../../model/common.types";
import dbService, { BATCH, OUTGOING } from "../../../../services/dbService";
import { Destination, DestinationType, Outgoing } from "../../../../model/warehouse/outgoing.types";
import userService from "../../../../services/userService";
import { getBatchSnapshot } from "../../../../utils/batchUtils";
import { WarehouseTypes } from "../../../../model/configuration/warehouseConfiguration.types";
import SendModalPuReassignment from "./SendModalPuReassignment";
import { Warehouse } from "../../../../model/warehouse/customTypes.types";

enum SendModalStep {
  SELECTION,
  PU_REASSIGNMENT,
  CONFIRMATION
}

const getDefaultState = (): SendModalState => {
  return { saving: false, step: SendModalStep.SELECTION, amount: "0", destination: undefined, newPuAmounts: {} };
};

interface SendModalProps extends BaseActionModalProps {
  selectedBatch: Batch;
  selectedLocation: BatchLocation;
}

interface SendModalState {
  saving: boolean;
  step: SendModalStep;
  amount: string;
  newPuAmounts: { [key: string]: string };
  destination?: SelectOption<Warehouse>;
}

const SendModal: React.FC<SendModalProps> = ({ show, onHide, selectedLocation, selectedBatch }) => {
  const { universalBarcode, updateDocumentInContext } = useContext(DataContext);

  const [state, setState] = useState<SendModalState>(getDefaultState());
  const { saving, step, amount, destination, newPuAmounts } = state;

  useEffect(() => {
    if (show) {
      setState(getDefaultState());
      const newPuAmounts: { [key: string]: string } = {};
      for (let i = 0; i < selectedLocation.packagingUnits.length; i++) {
        const pU = selectedLocation.packagingUnits[i];
        newPuAmounts[pU._id.toString()] = "0";
      }
      setState(prevState => ({ ...prevState, newPuAmounts }));
    }
  }, [show, selectedLocation]);

  const handleChangeAmount = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setState(prevState => ({ ...prevState, amount: e.target.value }));
  }, []);

  const handleBack = useCallback(() => setState(prevState => ({ ...prevState, step: prevState.step - 1 })), []);
  const handleNext = useCallback(() => setState(prevState => ({ ...prevState, step: prevState.step + 1 })), []);

  const handleSelectDestination = useCallback(
    (e: SelectOption<Warehouse>) => setState(prevState => ({ ...prevState, destination: e })),
    []
  );

  const handleSetNewPuAmount = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setState(prevState => ({ ...prevState, newPuAmounts: { ...prevState.newPuAmounts, [name]: value } }));
  }, []);

  const handleSend = useCallback(async () => {
    if (!destination || !destination.data.physicalWarehouse) return;
    try {
      setState(prevState => ({ ...prevState, saving: true }));
      const pUKeys = Object.keys(newPuAmounts);
      const numAmount = Number(amount);
      const destinationObject: Destination = {
        _id: destination.data.physicalWarehouse._id,
        address: destination.data.physicalWarehouse.address,
        type: DestinationType.WAREHOUSE
      };

      const movedPu: Array<PackagingUnit> = [];
      for (let i = 0; i < pUKeys.length; i++) {
        const key = pUKeys[i];
        const pU = selectedLocation.packagingUnits.find(pU => pU._id.toString() === key);
        // If we can't find the PU anymore something strange happened and we should abort
        if (!pU) {
          console.error("PU was not found. Aborting.");
          toast.error("PU was not found. Aborting.");
          return;
        }
        const amountPu = Number(newPuAmounts[key]);
        if (amountPu === 0) continue;
        movedPu.push({ ...pU, quantity: amountPu });
      }

      const outgoing: Outgoing = {
        _id: new BSON.ObjectId(),
        created: new Date(),
        person: userService.getUserSnapshot(),
        batch: getBatchSnapshot(selectedBatch),
        destination: destinationObject,
        movedAmount: { value: numAmount, unit: selectedLocation.amountAtLocation.unit },
        movedPackagingUnits: movedPu
      };

      const res = await dbService.callFunction(
        "sendCommodities",
        [numAmount, newPuAmounts, selectedBatch, selectedLocation, outgoing],
        true
      );
      if (res) {
        toast.success(i18n.t("warehouse:sendSuccess"));
        // Fire and forget
        await updateDocumentInContext(BATCH, selectedBatch._id);
        await updateDocumentInContext(OUTGOING, outgoing._id);
        onHide();
      } else {
        toast.error(i18n.t("warehouse:errorSend"));
      }
    } catch (e) {
      console.error(e);
      toast.error(e);
    } finally {
      setState(prevState => ({ ...prevState, saving: false }));
    }
  }, [state, onHide, universalBarcode, selectedLocation, selectedBatch]);

  const totalAmount = useMemo(() => selectedLocation?.amountAtLocation.value || 0, [selectedLocation]);

  const errors = useMemo(() => {
    const errors: Array<string> = [];
    if (Number(amount) > totalAmount) errors.push(i18n.t("warehouse:errorExceedingAmount"));
    else if (amount === "0") errors.push(i18n.t("warehouse:errorAmountZero"));
    if (!destination) errors.push(i18n.t("warehouse:errorDestinationMissing"));
    if (
      selectedLocation.location.warehouseArea.type === WarehouseTypes.DIRECTLYMANAGED &&
      step === SendModalStep.PU_REASSIGNMENT
    ) {
      let totalAssigned = 0;
      const pUAmountKeys = Object.keys(newPuAmounts);
      for (let i = 0; i < pUAmountKeys.length; i++) {
        const key = pUAmountKeys[i];
        const pU = selectedLocation.packagingUnits.find(pU => pU._id.toString() === key);
        if (!pU) continue;
        const amountPerPu = pU.amountPerPu.value;
        totalAssigned += Number(newPuAmounts[key]) * amountPerPu;
        if (pU.quantity && pU.quantity < Number(newPuAmounts[key]))
          errors.push(i18n.t("warehouse:errorPuAmountExceeded"));
      }
      if (totalAssigned !== Number(amount)) errors.push(i18n.t("warehouse:amountsNotMatchingError"));
    }
    return errors;
  }, [amount, totalAmount, selectedLocation, step, destination, newPuAmounts]);

  if (!show) return null;

  return (
    <Modal show={show} onHide={onHide} centered dialogClassName="sendModalDialog" name="sendModal">
      <Modal.Header closeButton>
        <Modal.Title as="h5">
          <b>{i18n.t("warehouse:send")}</b>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {step === SendModalStep.SELECTION && (
          <SendModalSelection
            selectedBatch={selectedBatch}
            selectedAmount={totalAmount}
            selectedLocation={selectedLocation}
            amount={amount}
            selectedDestination={destination}
            onSelectedDestination={handleSelectDestination}
            onChangeAmount={handleChangeAmount}
          />
        )}
        {step === SendModalStep.PU_REASSIGNMENT && (
          <SendModalPuReassignment
            selectedLocation={selectedLocation}
            amount={amount}
            newPuAmounts={newPuAmounts}
            onSetNewPuAmount={handleSetNewPuAmount}
          />
        )}
        {step === SendModalStep.CONFIRMATION && (
          <SendModalConfirmation selectedBatch={selectedBatch} amount={amount} selectedDestination={destination} />
        )}
      </Modal.Body>
      <Modal.Footer>
        <ErrorOverlayButton
          saving={saving}
          buttonText={i18n.t("common:close")}
          className="btn btn-secondary"
          onClick={onHide}
        />
        {step > 0 && (
          <ErrorOverlayButton
            saving={saving}
            buttonText={i18n.t("common:back")}
            className="btn btn-secondary"
            onClick={handleBack}
          />
        )}
        {step < 2 && (
          <ErrorOverlayButton
            saving={saving}
            errors={errors}
            buttonText={i18n.t("common:next")}
            className="btn btn-success"
            onClick={handleNext}
          />
        )}
        {step === SendModalStep.CONFIRMATION && (
          <ErrorOverlayButton
            saving={saving}
            buttonText={i18n.t("warehouse:send")}
            className="btn btn-success"
            onClick={handleSend}
          />
        )}
      </Modal.Footer>
    </Modal>
  );
};

const SendModalWrapper: React.FC<BaseActionModalProps> = props => {
  const { batch } = useContext(DataContext);
  const { selectedEntries } = useWarehouseContext();

  const selectedBatch: Batch | null = useMemo(() => {
    if (selectedEntries.length === 0 || !isSelectedCommodityEntries(selectedEntries)) return null;
    const batchId = getBatchIdFromSelectedEntries(selectedEntries);
    if (!batchId) return null;
    return baseUtils.getDocFromCollection(batch, batchId);
  }, [selectedEntries, batch]);

  const selectedLocation = useMemo(() => {
    if (selectedBatch) {
      const locations: Array<string> = [];
      for (let i = 0; i < selectedEntries.length; i++) {
        const sE = selectedEntries[i];
        if (sE.type === SelectedCommodityEntryType.BATCH_LOCATION && sE.locationId) locations.push(sE.locationId);
      }
      for (let i = 0; i < selectedBatch.locations.length; i++) {
        const l = selectedBatch.locations[i];
        if (locations.includes(l._id.toString())) return l;
      }
    }
  }, [selectedEntries, selectedBatch]);

  if (!props.show || !selectedBatch || !selectedLocation) return null;

  return <SendModal {...props} selectedBatch={selectedBatch} selectedLocation={selectedLocation} />;
};

export default SendModalWrapper;
