import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import Select from "react-select";
import i18n from "../../../translations/i18n";
import { BaseActionModalProps } from "../../../model/warehouse/common.types";
import { useWarehouseDispatch, WarehouseActionType } from "../../../context/warehouseContext";
import { useDataContext } from "../../../context/dataContext";
import ErrorOverlayButton from "../../common/ErrorOverlayButton";
import { Input } from "../../common/Input";
import { resolveTranslation } from "../../../utils/translationUtils";
import { IndividualBarcode, UniversalBarcode } from "../../../model/warehouse/barcode.types";
import { BasicSelectOption } from "../../../model/common.types";
import { WarehouseActionNumber } from "../../../utils/warehouseActionUtils";

export enum CodeType {
  BATCH_BARCODE = "batchBarcode",
  PICKLIST_QRCODE = "picklistQRCode"
}

const ReadCodeModal: React.FC<BaseActionModalProps> = ({ show, onHide }) => {
  const dispatch = useWarehouseDispatch();
  const { batch, universalBarcode, individualBarcode, movement } = useDataContext();

  const [code, setCode] = useState<string>("");
  const [codeType, setCodeType] = useState<BasicSelectOption>({
    value: CodeType.BATCH_BARCODE,
    label: i18n.t("warehouse:batchBarcode")
  });

  useEffect(() => {
    if (show) {
      setCode("");
      setCodeType({
        value: CodeType.BATCH_BARCODE,
        label: i18n.t("warehouse:batchBarcode")
      });
    }
  }, [show]);

  const barcodeBatch = useMemo(() => {
    if (codeType.value !== CodeType.BATCH_BARCODE || !code) return;
    let barcode: IndividualBarcode | UniversalBarcode | undefined;
    if (code.includes("_")) {
      const ib = individualBarcode.find(ib => ib._id.toString() === code.split("_")[0]);
      if (ib && code.substring(code.indexOf("_") + 1) === ib.packageNumber.toString()) {
        barcode = ib;
      }
    } else {
      barcode = universalBarcode.find(ub => ub._id.toString() === code);
    }
    if (barcode)
      return batch.find(b => {
        return b._id.toString() === barcode!.batch;
      });
  }, [codeType, code, individualBarcode, universalBarcode, batch]);

  const picklistMovements = useMemo(() => {
    if (codeType.value !== CodeType.PICKLIST_QRCODE || !code || code.length !== 24) return; // picklist code is BSON.ObjectId, which is 24 characters
    return movement.filter(m => m.file?._id.toString() === code);
  }, [codeType, code, movement]);

  const errors = useMemo(() => {
    const errors = [];
    if (codeType.value === CodeType.BATCH_BARCODE) {
      if (!barcodeBatch) errors.push(i18n.t("warehouse:batchNotFoundError"));
    } else if (codeType.value === CodeType.PICKLIST_QRCODE) {
      if (!picklistMovements || picklistMovements.length === 0) errors.push(i18n.t("warehouse:movementNotFoundError"));
    }
    return errors;
  }, [codeType, barcodeBatch, picklistMovements]);

  const handleChangeCodeType = useCallback((value: any) => {
    setCode("");
    setCodeType(value);
  }, []);

  const handleChangeCode = useCallback((e: ChangeEvent<HTMLInputElement>) => setCode(e.currentTarget.value), []);

  const handleReadCode = useCallback(() => {
    // Search batch
    if (codeType.value === CodeType.BATCH_BARCODE && barcodeBatch) {
      dispatch({
        type: WarehouseActionType.SEARCH,
        payload: { query: barcodeBatch.lot }
      });
    } else if (codeType.value === CodeType.PICKLIST_QRCODE && picklistMovements && picklistMovements.length > 0) {
      const movementIds = picklistMovements.map(m => m._id.toString());
      dispatch({
        type: WarehouseActionType.TRIGGER_ACTION,
        payload: { actionNumber: WarehouseActionNumber.FINISH_MOVEMENTS, movementIds, keepAction: true }
      });
    }
    onHide();
  }, [codeType, barcodeBatch, picklistMovements]);

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title as={"h5"}>
          <b>{i18n.t("warehouse:readCode")}</b>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="px-2">
          <div className="row mb-4">
            <div className="col-4">
              <span className="text-black d-block mt-2" style={{ fontWeight: 500 }}>
                {i18n.t("warehouse:codeType")}
              </span>
            </div>
            <div className="col-8">
              <div>
                <Select
                  className="select-warehouse"
                  classNamePrefix="select-warehouse"
                  onChange={handleChangeCodeType}
                  value={codeType}
                  options={[
                    { value: CodeType.BATCH_BARCODE, label: i18n.t("warehouse:batchBarcode") },
                    {
                      value: CodeType.PICKLIST_QRCODE,
                      label: i18n.t("warehouse:picklistQRCode")
                    }
                  ]}
                />
              </div>
            </div>
          </div>
          <Input
            className="form-control form-control-solid"
            name="readCode"
            placeholder={
              codeType.value === CodeType.BATCH_BARCODE
                ? i18n.t("warehouse:barcodeNumber")
                : i18n.t("warehouse:qrCodeNumber")
            }
            value={code}
            onInput={handleChangeCode}
          />
        </div>
        {barcodeBatch && (
          <div className="mt-5 px-2 text-black">
            <h5>{i18n.t("warehouse:batch")}</h5>
            <div>
              {i18n.t("warehouse:commodity")}: {resolveTranslation(barcodeBatch.content.details.title)} -
              {resolveTranslation(barcodeBatch.content.details.subtitle)}
            </div>
            <div>
              {i18n.t("warehouse:lot")}: {barcodeBatch.lot}
            </div>
          </div>
        )}
        {picklistMovements && picklistMovements.length > 0 && (
          <div className="mt-5 px-2 text-black">
            <h5>
              {i18n.t("warehouse:picklist")} {picklistMovements[0].file?.title}
            </h5>
            <div>{i18n.t("warehouse:movements", { amount: picklistMovements.length })}</div>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <button type="button" className="btn btn-secondary " onClick={onHide}>
          {i18n.t("common:close")}
        </button>
        <ErrorOverlayButton
          buttonText={i18n.t("warehouse:readCode")}
          className="btn btn-success"
          errors={errors}
          onClick={handleReadCode}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default ReadCodeModal;
