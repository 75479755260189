import _ from "lodash";
import { BSON } from "realm-web";
import React, { PureComponent } from "react";
import { Modal, Table } from "react-bootstrap";
import { toast } from "react-toastify";
import i18n from "i18next";
import CustomerDataComponent from "./common/CustomerData";
import PositionRow from "./common/PositionRow";
import { CustomerData, CustomOrder, InvoiceData, InvoicePosition } from "../CustomTypes";
import OrderHelper from "../OrderHelper";
import PDFPreview from "../../common/PDFPreview";
import config from "../../../config/config.json";
import { DataContext } from "../../../context/dataContext";
import dbGeneralService from "../../../services/dbServices/dbGeneralService";
import dbOrderService, { I_INTEREST } from "../../../services/dbServices/dbOrderService";
import userService from "../../../services/userService";
import accessUtils, { CREATELOCATIONS } from "../../../utils/accessUtils";
import baseUtils, { getNumericValue } from "../../../utils/baseUtils";
import dateUtils from "../../../utils/dateUtils";
import invoiceUtils, {
  I_CANCELATION,
  I_CANCELED,
  I_DUNNING_PERIOD,
  I_FINALINVOICE,
  I_INVOICE,
  I_OPEN,
  I_PARTIALINVOICE,
  I_PAYMENTINADVANCE,
  I_REMINDER
} from "../../../utils/invoiceUtils";
import invoiceGeneration from "../../../utils/pdf/invoiceGeneration";
import pdfUtils from "../../../utils/pdf/pdfUtils";
import { T_INVOICECANCELED, T_INVOICECREATED, T_INVOICEREMINDER, T_REMINDER } from "../../../utils/timelineUtils";
import notificationService, {
  R_INVOICECANCELLATION,
  R_INVOICECREATED,
  R_INVOICEREMINDER
} from "../../../services/notificationService";
import PaymentTargetSelect from "../../common/PaymentTargetSelect";
import BioNumberSelection from "./common/BioNumberSelection";
import { Invoice, PDFContent } from "../../../model/orders.types";
import DateInput from "../../common/DateInput";
import { ORDERS } from "../../../services/dbService";
import orderUtils from "../../../utils/orderUtils";
import LanguageSelectionDropdown from "../../common/LanguageSelectionDropdown";

interface CreateInvoiceModalProps {
  order: CustomOrder;
  disabled?: boolean;
  extendedTitle?: boolean;
  invoice?: Invoice;
  type?: "cancelation" | "reminder";
  reminderButton?: boolean;
  context: React.ContextType<typeof DataContext>;
}

interface CreateInvoiceModalState {
  show: boolean;
  uploading: boolean;
  reverseCharge: boolean;
  language: string;
  invoiceType: "cancelation" | "reminder" | "invoice";
  partial: boolean;
  customerData: CustomerData;
  invoice: Invoice;
  reminderLevel: string;
  positions: Array<InvoicePosition>;
  error: boolean;
}

class InvoiceModal extends PureComponent<CreateInvoiceModalProps, CreateInvoiceModalState> {
  constructor(props: CreateInvoiceModalProps) {
    super(props);

    this.state = {
      show: false,
      uploading: false,
      reverseCharge: false,
      error: false,
      partial: false,
      language: i18n.language,
      invoiceType: props.type || "invoice",
      customerData: {
        name: "",
        street: "",
        additionalAddress: "",
        zip: "",
        city: "",
        country: ""
      },
      invoice: {
        _id: new BSON.ObjectId(),
        invoiceNumber: 0,
        partial: false,
        state: I_OPEN,
        deliveryDate: new Date(),
        invoiceDate: new Date(),
        person: userService.getUserId(),
        path: "",
        pdfData: {
          customer: "",
          address: {
            street: "",
            additionalAddress: "",
            zip: "",
            city: "",
            country: ""
          },
          content: {
            title: "",
            subtitle: "",
            header: "",
            paymentInfo: "",
            footer: "",
            language: ""
          }
        },
        reverseCharge: false,
        vatID: "",
        positions: [],
        total: 0,
        totalGross: 0,
        payments: [],
        reminder: [],
        dueIn: -1,
        bioNumber: ""
      },
      positions: [],
      reminderLevel: "0"
    };
  }

  componentDidMount() {
    this.initializeInvoiceData();
  }

  componentDidUpdate(prevProps: Readonly<CreateInvoiceModalProps>, prevState: Readonly<CreateInvoiceModalState>) {
    if (
      (this.state.show && !_.isEqual(prevProps.order, this.props.order)) ||
      (this.state.show && !_.isEqual(prevProps.invoice, this.props.invoice)) ||
      (!prevState.show && this.state.show)
    ) {
      this.initializeInvoiceData();
    }
  }

  /**
   * Initialize invoice data, e.g. from existing entries if required
   */
  initializeInvoiceData = () => {
    const { invoiceType } = this.state;
    const data = this.getInvoiceData(this.props);
    if (!data) {
      toast.error("Data could not be loaded");
      this.setState({ error: true });
      return;
    }
    let [invoice, positions] = data;
    // Set different title and text for reminder and cancellation
    const invoiceNumber = invoice.invoiceNumber.toString();
    if (invoiceType === I_REMINDER) {
      const dunningDeadLine = new Date();
      dunningDeadLine.setDate(dunningDeadLine.getDate() + 7 * I_DUNNING_PERIOD);
      const lastReminder = invoice.reminder.slice(-1);
      const reminderTemplate =
        lastReminder.length !== 0 && lastReminder[0].level >= 1
          ? "template:invoiceTextReminderWithDebtCollectionWarning"
          : "template:invoiceTextReminder";
      invoice.reminder.push({
        _id: new BSON.ObjectId(),
        date: new Date(),
        level: 0,
        interest: 0,
        content: {
          title: invoice.pdfData.content.title,
          subtitle: invoice.pdfData.content.subtitle || "",
          paymentInfo: i18n.t("template:orderConfirmationPaymentNote"),
          footer: i18n.t("template:orderConfirmationNote"),
          header: i18n
            .t(reminderTemplate)
            .replace("{invoiceNumber}", invoiceNumber)
            .replace("{invoiceDate}", baseUtils.formatDate(invoice.invoiceDate))
            .replace("{today}", baseUtils.formatDate(new Date()))
            .replace("{dunningDeadline}", baseUtils.formatDate(dunningDeadLine))
        },
        path: "",
        person: userService.getUserId()
      });
    } else if (invoiceType === I_CANCELATION) {
      invoice.cancelation = {
        cancelationNumber: 0,
        date: new Date(),
        content: {
          ...invoice.pdfData.content
        },
        path: "",
        person: userService.getUserId()
      };
      invoice.cancelation.content.title = i18n
        .t("template:invoiceTitleCancellation")
        .replace("{invoiceNumber}", invoiceNumber)
        .replace("{title}", invoice.pdfData.content.title);
      invoice.cancelation.content.header = i18n
        .t("template:invoiceTextCancellation")
        .replace("{invoiceNumber}", invoiceNumber);
      invoice.cancelation.content.paymentInfo = "-";
      this.negatePositions(positions);
    }
    const reminderLevel = this.hasReminderWithDunningFees() ? "1" : "0";
    if (invoiceType === I_REMINDER && reminderLevel === "1") {
      const { invoice: i, positions: p } = this.generateReminderPosition(+reminderLevel, invoice, positions);
      invoice = i;
      positions = p;
    }
    this.setState({
      reverseCharge: invoice.reverseCharge,
      customerData: { ...invoice.pdfData.address, name: invoice.pdfData.customer },
      invoice,
      positions,
      reminderLevel
    });
  };

  /**
   * Check if the invoice got a reminder with dunning fees.
   * @returns { boolean } True if there is one
   */
  hasReminderWithDunningFees = () => {
    const { invoice } = this.props;
    return invoice ? invoice.reminder.some(r => !!r.position) : false;
  };

  /**
   * Get default invoice data or data from existing entry
   * @param props the invoice modal properties
   * @returns {[ Invoice, Array<InvoicePosition>]} tuple with all necessary data or undefined
   */
  getInvoiceData = (props: CreateInvoiceModalProps): [Invoice, Array<InvoicePosition>] | undefined => {
    const { order, type, invoice, context } = props;

    if (type) {
      if (!invoice || ![I_REMINDER, I_CANCELATION].includes(type)) {
        toast.error("No existing timeline entry or unknown type passed");
        console.error("No existing timeline entry or unknown type passed", invoice, type);
        return;
      }
      return this.getDataAndPositions(invoice);
    } else {
      const cd = OrderHelper.getCustomerData(order);
      const invoice: Invoice = {
        _id: new BSON.ObjectId(),
        invoiceNumber: 0,
        state: I_OPEN,
        person: userService.getUserId(),
        deliveryDate: new Date(),
        invoiceDate: new Date(),
        partial: false,
        pdfData: {
          customer: cd.name,
          address: {
            street: cd.street,
            additionalAddress: cd.additionalAddress,
            zip: cd.zip,
            city: cd.city,
            country: cd.country
          },
          content: {
            title: order.title,
            subtitle: order.subtitle || "",
            header: i18n.t("template:invoiceText"),
            paymentInfo: i18n.t("template:orderConfirmationPaymentNote"),
            footer: i18n.t("template:orderConfirmationNote"),
            language: i18n.language
          }
        },
        dueIn:
          order.createdFor && order.createdFor.paymentTarget !== undefined && order.createdFor.paymentTarget >= 0
            ? order.createdFor.paymentTarget
            : 0,
        positions: [],
        reverseCharge: false,
        vatID: "",
        path: "",
        total: 0,
        totalGross: 0,
        payments: [],
        reminder: [],
        bioNumber: ""
      };
      return [invoice, [invoiceUtils.getDefaultPosition(order, context)]];
    }
  };

  /**
   * Get the invoice data and the translated position (for usage in input fields).
   * @param entry Invoice whose positions should be translated
   * @returns { [Invoice, Array<InvoicePosition>] } Copy of the invoice and translated positions
   */
  getDataAndPositions = (entry: Invoice): [Invoice, Array<InvoicePosition>] => {
    const positions = entry.positions.map(p => {
      return {
        id: new BSON.ObjectId(p._id.toString()),
        type: p.type,
        amount: p.amount.toString(),
        description: p.description,
        unit: p.unit,
        price: p.price.toString(),
        total: p.total.toString(),
        vat: p.vat.toString(),
        discount: p.discount.toString()
      };
    });
    for (let i = 0; i < entry.reminder.length; i++) {
      const r = entry.reminder[i];
      if (r.position) {
        positions.push({
          id: r._id,
          type: T_REMINDER,
          description: r.position.description,
          amount: "1",
          unit: "item",
          price: r.position.price.toString(),
          total: r.position.total.toString(),
          vat: "0",
          discount: "0"
        });
      }
    }
    return [_.cloneDeep(entry), positions];
  };

  /**
   * Use the properties and state to create the invoice pdf HTML
   * @param subTotal total amount without VAT
   * @param vatList list with values for all VATs
   * @param total total amount with VAT
   * @param draft flag for draft
   * @returns { string } html representation for invoice pdf
   */
  doCreatePDF = (
    subTotal: number,
    vatList: Array<{ vat: string; value: number; total: number }>,
    total: number,
    draft?: boolean
  ) => {
    const { order } = this.props;
    const { customerData, invoice, invoiceType, positions, reverseCharge } = this.state;
    return invoiceGeneration.createInvoice(
      order,
      customerData,
      invoice,
      positions,
      reverseCharge,
      subTotal,
      vatList,
      total,
      invoiceType !== I_INVOICE ? invoiceType : invoice.dueIn === -1 ? I_PAYMENTINADVANCE : I_FINALINVOICE,
      order.fulfillment ? order.fulfillment.lot : undefined,
      draft,
      invoice.payments.length > 0
        ? invoice.payments.map(p => {
            return { date: p.date, note: p.note, value: p.amount };
          })
        : undefined
    );
  };

  /**
   * Get the total amount of all positions with discount included
   * @returns {number} total amount of all positions with discount included without VAT
   */
  getSubTotal = () => {
    const { positions } = this.state;
    return invoiceUtils.getSubtotal(positions);
  };

  /**
   * Calculate the amount and total amount for all existing vat values
   * @returns { Array<{vat: string, value: number, total: number}>} Objects with vat values and the respective (total) amount
   */
  getVatAmount = () => {
    const { positions } = this.state;
    return invoiceUtils.getVatAmount(positions);
  };

  handleShow = () => this.setState({ show: true, reminderLevel: "0" });

  handleClose = () => {
    this.setState({ show: false });
  };
  handleReverseCharge = () => {
    const { reverseCharge, positions } = this.state;
    const positionsNew = positions.map(p => {
      p.vat = reverseCharge ? "7" : "0";
      return p;
    });
    this.setState({ reverseCharge: !reverseCharge, positions: positionsNew });
  };

  handleLanguageChange = async () => {
    const invoice = _.cloneDeep(this.state.invoice);
    const positions = _.cloneDeep(this.state.positions);
    const { invoiceType } = this.state;
    const { order, context } = this.props;
    invoice.pdfData.content = {
      title: invoice.pdfData.content.title,
      subtitle: invoice.pdfData.content.subtitle,
      header: i18n.t("template:invoiceText"),
      paymentInfo: i18n.t("template:orderConfirmationPaymentNote"),
      footer: i18n.t("template:orderConfirmationNote"),
      language: i18n.language
    };
    if (invoice.cancelation) {
      invoice.cancelation!.content = {
        ...invoice.pdfData.content,
        title: i18n
          .t("template:invoiceTitleCancellation")
          .replace("{invoiceNumber}", invoice.invoiceNumber.toString())
          .replace("{title}", invoice.pdfData.content.title),
        header: i18n.t("template:invoiceTextCancellation").replace("{invoiceNumber}", invoice.invoiceNumber.toString()),
        paymentInfo: "-",
        footer: i18n.t("template:orderConfirmationNote")
      };
    }
    if (invoiceType === "reminder") {
      const dunningDeadLine = new Date();
      dunningDeadLine.setDate(dunningDeadLine.getDate() + 7 * I_DUNNING_PERIOD);
      const reminderTemplate =
        invoice.reminder[invoice.reminder.length - 1].level >= 1
          ? "template:invoiceTextReminderWithDebtCollectionWarning"
          : "template:invoiceTextReminder";
      invoice.reminder[invoice.reminder.length - 1].content = {
        ...invoice.pdfData.content,
        header: i18n
          .t(reminderTemplate)
          .replace("{invoiceNumber}", invoice.invoiceNumber.toString())
          .replace("{invoiceDate}", baseUtils.formatDate(invoice.invoiceDate))
          .replace("{today}", baseUtils.formatDate(new Date()))
          .replace("{dunningDeadline}", baseUtils.formatDate(dunningDeadLine))
      };
    }
    for (let i = 0; i < positions.length; i++) {
      let position = positions[i];
      switch (position.type) {
        case "freeposition":
          positions[i].description = i18n.t("invoice:description");
          break;
        case "position" || "service":
          const description = orderUtils.getProductDescription(order, context);
          positions[i].description = description;
          invoice.positions[i] && (invoice.positions[i].description = description);
          break;
        case "reminder":
          const dueDate = new Date(invoice.invoiceDate);
          positions[i].description =
            (position.price === "0"
              ? I_INTEREST + "% " + `${i18n.t("invoice:latePaymentInterest")}`
              : `${i18n.t("invoice:dunningFees")}` +
                "(40€ + " +
                I_INTEREST +
                "% " +
                `${i18n.t("invoice:latePaymentInterest")}`) +
            `${i18n.t("invoice:from")}` +
            " " +
            baseUtils.formatDate(dueDate) +
            " - " +
            baseUtils.formatDate(new Date());
          break;
      }
    }
    this.setState({ invoice, positions, language: i18n.language });
  };

  /**
   * Handle upload of pdf
   * @param subTotal total amount without VAT
   * @param vatList list with values for all VATs
   * @param total total amount with VAT
   */
  handleUpload =
    (subTotal: number, vatList: Array<{ vat: string; value: number; total: number }>, total: number) => async () => {
      const { context, order } = this.props;
      const { invoiceType } = this.state;
      const invoiceData = _.cloneDeep(this.state.invoice);
      this.setState({ uploading: true });
      if (invoiceType === I_INVOICE) {
        // Get new invoice number if necessary
        invoiceData.invoiceNumber = await dbGeneralService.getNextInvoiceNr();
      } else if (invoiceType === I_CANCELATION) {
        invoiceData.cancelation!.cancelationNumber = await dbGeneralService.getNextInvoiceNr();
      }
      this.setState({ invoice: invoiceData });
      const pdfResult = await this.createInvoicePDF(subTotal, vatList, total, invoiceData);
      if (!pdfResult.result || !pdfResult.path) {
        toast.error("Invoice creation failed: " + pdfResult.message);
        this.setState({ uploading: false });
        return;
      }
      window.open((process.env.REACT_APP_MEDIAHUB_BASE || "") + pdfResult.path, "_blank");
      const invoice = this.generateInvoiceObject(pdfResult.path);
      // Timeline entry
      let timelineEntry: any;
      timelineEntry = {
        id: new BSON.ObjectId(),
        type:
          invoiceType === I_REMINDER
            ? T_INVOICEREMINDER
            : invoiceType === I_CANCELATION
            ? T_INVOICECANCELED
            : T_INVOICECREATED,
        date: new Date(),
        person: new BSON.ObjectId(userService.getUserData()._id),
        invoiceNumber: invoiceData.invoiceNumber,
        invoiceId: invoice._id
      };

      try {
        let result;
        if (invoiceType === I_REMINDER && invoice.reminder.length > 0) {
          invoice.reminder[invoice.reminder.length - 1].path = pdfResult.path!;
          timelineEntry.reminderId = invoice.reminder[invoice.reminder.length - 1]._id;
          result = await dbOrderService.addInvoiceReminder(
            order._id,
            invoice._id,
            timelineEntry,
            invoice.reminder[invoice.reminder.length - 1]
          );
        } else if (invoiceType === I_CANCELATION) {
          invoice.cancelation!.path = pdfResult.path!;
          invoice.cancelation!.cancelationNumber = invoiceData.cancelation!.cancelationNumber;
          result = await dbOrderService.addInvoiceCancelation(
            order._id,
            invoice._id,
            timelineEntry,
            invoice.cancelation!
          );
        } else {
          result = await dbOrderService.addInvoice(order._id, timelineEntry, invoice);
        }
        if (result) {
          toast.success("Invoice created successfully");
          this.setState({ uploading: false, show: false });
          if (invoiceType === I_REMINDER) notificationService.notify(R_INVOICEREMINDER, order._id);
          else if (invoiceType === I_CANCELATION) notificationService.notify(R_INVOICECANCELLATION, order._id);
          else notificationService.notify(R_INVOICECREATED, order._id);
          await context.updateDocumentInContext(ORDERS, order._id);
        } else toast.error("Adding of timeline entry failed. " + pdfResult.path);
      } catch (e) {
        toast.error("An unexpected error occurred: " + e.message);
      } finally {
        this.setState({ uploading: false });
      }
    };

  /**
   * Generate the invoice object that is added to the order.
   * @param pdfPath Path of the PDF
   * @returns { Invoice } Build invoice object
   */
  generateInvoiceObject = (pdfPath: string) => {
    const { order } = this.props;
    const { customerData, invoice: invoiceData, reverseCharge, positions, partial, invoiceType } = this.state;
    const { content } = invoiceData.pdfData;
    const person = userService.getUserId();
    let total = 0;
    let totalGross = 0;
    let invoice: Invoice | undefined;
    if (invoiceType === I_INVOICE) {
      invoice = {
        _id: new BSON.ObjectId(),
        invoiceNumber: invoiceData.invoiceNumber,
        partial,
        state: I_OPEN,
        deliveryDate: invoiceData.deliveryDate,
        invoiceDate: invoiceData.invoiceDate,
        person,
        path: pdfPath,
        pdfData: {
          customer: customerData.name,
          address: {
            street: customerData.street,
            additionalAddress: customerData.additionalAddress,
            zip: customerData.zip,
            city: customerData.city,
            country: customerData.country
          },
          content: {
            title: content.title,
            subtitle: content.subtitle,
            header: content.header,
            paymentInfo: content.paymentInfo,
            footer: content.footer,
            language: content.language
          }
        },
        reverseCharge,
        vatID: invoiceData.vatID,
        positions: positions.map(p => {
          total += invoiceUtils.getTotalWithDiscount(p);
          totalGross += invoiceUtils.getTotalWithVatAndDiscount(p);
          return {
            _id: p.id,
            type: p.type,
            description: p.description,
            amount: +p.amount,
            unit: p.unit,
            price: +p.price,
            total: +p.total,
            vat: +p.vat,
            discount: +p.discount
          };
        }),
        total: total,
        totalGross: totalGross,
        payments: [],
        reminder: [],
        dueIn: invoiceData.dueIn,
        bioNumber: invoiceData.bioNumber
      };
    } else {
      invoice = order.invoices?.find(i => i.invoiceNumber === invoiceData.invoiceNumber);
      if (!invoice) return invoiceData;
      if (invoiceType === I_CANCELATION) {
        invoice.cancelation = {
          cancelationNumber: 0,
          date: new Date(),
          path: pdfPath,
          person,
          content: invoiceData.cancelation!.content
        };
        invoice.state = I_CANCELED;
      } else if (invoiceType === I_REMINDER) {
        invoice.reminder.push(invoiceData.reminder[invoiceData.reminder.length - 1]);
      }
    }
    return invoice;
  };

  /**
   * Create the order confirmation pdf
   * @param subTotal total amount without VAT
   * @param vatList list with values for all VATs
   * @param total total amount with VAT
   * @param invoice the invoice data
   * @returns { result: boolean, path?: string, message?: string} Result of pdf creation with path or error message
   */
  createInvoicePDF = async (
    subTotal: number,
    vatList: Array<{ vat: string; value: number; total: number }>,
    total: number,
    invoice: Invoice
  ) => {
    const { type } = this.props;
    const { reminderLevel } = this.state;
    const data = JSON.stringify({
      html: this.doCreatePDF(subTotal, vatList, total),
      fileName:
        `${
          type === I_REMINDER
            ? reminderLevel === "0"
              ? "Zahlungserinnerung"
              : "Mahnung"
            : type === I_CANCELATION
            ? "Stornorechnung"
            : "Rechnung"
        }-` +
        invoice.invoiceNumber +
        "_" +
        dateUtils.timeStampDate() +
        ".pdf"
    });
    let path;
    try {
      path = await pdfUtils.uploadAndReturnPath(data);
    } catch (e) {
      return { result: false, message: e.message };
    }
    return { result: true, path: path };
  };

  /**
   * Negate the position price values for cancellation
   * @param positions list of positions
   * @returns { Array<InvoicePosition> } list of positions with negated price values
   */
  negatePositions = (positions: Array<InvoicePosition>) => {
    for (let i = 0; i < positions.length; i++) {
      const position = positions[i];
      position.price = (+position.price * -1).toString();
      position.total = (+position.total * -1).toString();
    }
    return positions;
  };

  handleCustomerChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const customerData = { ...this.state.customerData };
    const key = e.target.name as keyof CustomerData;
    customerData[key] = e.target.value;
    this.setState({ customerData });
  };

  handleInvoiceChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>,
    number?: boolean
  ) => {
    const invoice = { ...this.state.invoice };
    const key = e.target.name as keyof InvoiceData;
    // @ts-ignore
    invoice[key] = number ? +e.target.value : e.target.value;
    this.setState({ invoice });
  };

  handlePDFDataChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { invoiceType } = this.state;
    const invoice = { ...this.state.invoice };
    const key = e.target.name as keyof PDFContent;
    const value: string = e.target.value;
    if (invoiceType === I_CANCELATION && invoice.cancelation) {
      invoice.cancelation!.content[key] = value;
    } else if (invoiceType === I_REMINDER && invoice.reminder.length > 0) {
      invoice.reminder[invoice.reminder.length - 1].content[key] = value;
    } else {
      invoice.pdfData.content[key] = value;
    }
    this.setState({ invoice });
  };

  handleTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    let { invoiceType, partial } = this.state;
    const value = e.target.value;
    if (value === I_FINALINVOICE) {
      invoiceType = I_INVOICE;
      partial = false;
    } else if (value === I_PARTIALINVOICE) {
      partial = true;
    } else if (value === I_CANCELATION) {
      invoiceType = I_CANCELATION;
    } else if (value === I_REMINDER) {
      invoiceType = I_REMINDER;
    }
    this.setState({ invoiceType, partial });
  };

  /**
   * Generate the reminder positions for the given invoice
   * @param val Reminder level
   * @param invoice Invoice that should be updated
   * @param positions Positions that should be updated
   * @returns { { invoice: Invoice, positions: Array<InvoicePosition> } } Altered invoice and positions
   */
  generateReminderPosition = (val: number, invoice: Invoice, positions: Array<InvoicePosition>) => {
    if (invoice.reminder.length > 0) {
      const r = invoice.reminder[invoice.reminder.length - 1];
      if (r.position) {
        positions = positions.filter(p => p.id.toString() !== r.position!._id.toString());
      }
      r.level = val;
      // If the reminder is set to no interest, ensure that there is no position
      if (val === 0) {
        delete r.position;
      } else {
        r.interest = I_INTEREST;
        const dueDate = new Date(invoice.invoiceDate);
        dueDate.setDate(dueDate.getDate() + invoice.dueIn);
        // Reminder interest is based upon the days the invoice is overdue
        const reminderInterest = +(
          ((invoice.totalGross * (I_INTEREST / 100)) / 365) *
          Math.ceil(dateUtils.getDaysBetween(dueDate, new Date()))
        ).toFixed(2);
        const hasFees = this.hasReminderWithDunningFees();
        if (hasFees) r.level = 2;
        r.position = {
          _id: new BSON.ObjectId(),
          total: reminderInterest + (hasFees ? 0 : 40),
          price: reminderInterest + (hasFees ? 0 : 40),
          description:
            (hasFees
              ? I_INTEREST + "% " + `${i18n.t("invoice:latePaymentInterest")}`
              : `${i18n.t("invoice:dunningFees")}` +
                "(40€ + " +
                I_INTEREST +
                "% " +
                `${i18n.t("invoice:latePaymentInterest")}`) +
            `${i18n.t("invoice:from")}` +
            " " +
            baseUtils.formatDate(dueDate) +
            " - " +
            baseUtils.formatDate(new Date())
        };
        const position = r.position!;
        const pos: InvoicePosition = {
          id: position._id,
          vat: "0",
          type: I_REMINDER,
          amount: "1",
          total: position.total.toString(),
          discount: "0",
          unit: "item",
          price: position.price.toString(),
          description: position.description
        };
        positions.push(pos);
      }
    }
    return { invoice, positions };
  };

  handleReminderLevelChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const invoiceState = _.cloneDeep(this.state.invoice);
    const positionsState = _.cloneDeep(this.state.positions);
    const { invoice, positions } = this.generateReminderPosition(+e.target.value, invoiceState, positionsState);
    this.setState({ invoice, positions, reminderLevel: e.target.value });
  };

  handleDateBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
    const invoice = { ...this.state.invoice };
    const key = e.target.name as keyof Invoice;
    // @ts-ignore
    invoice[key] = e.target.valueAsDate;
    this.setState({ invoice });
  };

  handlePositionValueChange = (id: BSON.ObjectId) => (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const positions = _.cloneDeep(this.state.positions);
    const position = positions.find(p => p.id.toString() === id.toString())!;
    const key = e.target.name as keyof InvoicePosition;
    if (["description", "unit"].includes(key)) {
      // @ts-ignore
      position[key] = e.target.value;
    } else {
      const value = getNumericValue(e as React.ChangeEvent<HTMLInputElement>, true);
      if (!value) return;
      // @ts-ignore
      position[key] = value;
    }
    // Recalculate total if amount or price changed
    if (["amount", "price"].includes(key)) {
      position.total = (Math.round(+position.amount * +position.price * 100) / 100).toString();
    }
    this.setState({ positions });
  };

  handlePositionRemove = (id: BSON.ObjectId) => {
    const positions = _.cloneDeep(this.state.positions);
    if (positions.length < 2) return;
    this.setState({ positions: positions.filter(p => p.id.toString() !== id.toString()) });
  };

  handleAddFreePosition = () => {
    const { reverseCharge } = this.state;
    const positions = [...this.state.positions];
    positions.push({
      id: new BSON.ObjectId(),
      type: "freeposition",
      description: `${i18n.t("invoice:description")}`,
      amount: "0",
      unit: "item",
      price: "0",
      total: "0",
      vat: reverseCharge ? "0" : "7",
      discount: "0"
    });
    this.setState({ positions });
  };

  /**
   * Resolves the type of the invoice
   * @returns { string } Representing the invoice type
   */
  resolveType = () => {
    let { invoiceType, partial } = this.state;
    if (invoiceType === I_INVOICE && partial) return I_PARTIALINVOICE;
    else if (invoiceType !== I_INVOICE) return invoiceType;
    else return I_FINALINVOICE;
  };

  /**
   * Resolves the correct value for the given key
   * @param key Key of the PDF content
   * @returns { string } Correct value dependent on the invoice type
   */
  resolvePDFDataValue = (key: keyof PDFContent) => {
    const { invoice, invoiceType } = this.state;
    if (invoiceType === I_CANCELATION && invoice.cancelation) {
      return invoice.cancelation.content[key];
    } else if (invoiceType === I_REMINDER && invoice.reminder.length > 0) {
      return invoice.reminder[invoice.reminder.length - 1].content[key];
    } else {
      return invoice.pdfData.content[key];
    }
  };

  /**
   * Render the pdf preview component
   * @param subTotal total amount without VAT
   * @param vatList list with values for all VATs
   * @param total total amount with VAT
   * @returns {JSX.Element} the pdf preview component to render
   */
  renderPreview = (subTotal: number, vatList: Array<{ vat: string; value: number; total: number }>, total: number) => {
    const { invoice, invoiceType, reminderLevel } = this.state;
    const { title } = invoice.pdfData.content;
    const fileName =
      `ENTWURF_${
        invoiceType === I_REMINDER ? (reminderLevel === "0" ? "Zahlungserinnerung" : "Mahnung") : "Rechnung"
      }-TBD` +
      (title ? "_" + baseUtils.encodeString(title.replace(/\s/g, "_").replace(/#/g, "Nr-")) : "") +
      "_" +
      dateUtils.timeStampDate() +
      ".pdf";
    return <PDFPreview createPDF={() => this.doCreatePDF(subTotal, vatList, total, true)} fileName={fileName} />;
  };

  render() {
    const { order, disabled, extendedTitle, invoice: existingInvoice, reminderButton, type } = this.props;
    const { show, uploading, customerData, invoice, positions, reverseCharge, error, invoiceType, reminderLevel } =
      this.state;
    const subTotal = this.getSubTotal();
    const vatList = this.getVatAmount();
    const total = subTotal + vatList.reduce((a, b) => a + b.value, 0);
    const isCancelationReminder = invoiceType !== I_INVOICE;
    const canCreate = accessUtils.canCreateData(CREATELOCATIONS.INVOICE);
    const dueDate = new Date(invoice.invoiceDate);
    if (invoice.dueIn > 0) dueDate.setDate(dueDate.getDate() + invoice.dueIn);

    return (
      <>
        {(!type || reminderButton) && (
          <button
            className={
              "btn btn-sm btn-bold mr-3 my-1 " +
              (disabled || error || !canCreate ? "btn-disabled" : "") +
              (reminderButton ? " btn-secondary" : " btn-label-brand")
            }
            onClick={!disabled && !error && canCreate ? this.handleShow : undefined}
            disabled={disabled || error || !canCreate}
          >
            <i className="fa fa-file-invoice-dollar" />
            {extendedTitle ? "Create Invoice" : reminderButton ? "Reminder" : "Invoice"}
          </button>
        )}
        {type && existingInvoice && !reminderButton && (
          <button
            className={"btn btn-xs btn-secondary mr-2 " + (disabled || error || !canCreate ? "btn-disabled" : "")}
            style={{ fontSize: "0.9em" }}
            onClick={!disabled && !error && canCreate ? this.handleShow : undefined}
            disabled={disabled || error || !canCreate}
          >
            <i
              style={{ fontSize: "0.9em" }}
              className={type === I_CANCELATION ? "fa fa-times-circle" : "fa fa-exclamation-circle"}
            />
            {type === I_CANCELATION ? "Cancel Invoice" : "Payment Reminder"}
          </button>
        )}
        <Modal show={show} onHide={this.handleClose} size={"xl"} centered name={"createInvoiceModal"}>
          <Modal.Header closeButton>
            <Modal.Title>
              <i className="kt-font-brand fa fa-file-invoice-dollar mr-2" />
              {`${!type ? "Create" : type === I_CANCELATION ? "Cancel" : "Dunning"} Invoice`}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <CustomerDataComponent
                customerData={customerData}
                onCustomerChange={this.handleCustomerChange}
                disabled={isCancelationReminder}
              />
              <div className="col-6">
                <div className="input-group row mb-2">
                  <label className="col-lg-3 col-form-label kt-widget24__stats kt-font-dark ml-3 kt-font-bold">
                    Order
                  </label>
                  <div className="input-group-prepend">
                    <span className="input-group-text">AT-</span>
                  </div>
                  <input type="text" className="form-control" disabled value={order.identifier} />
                </div>
                {!isCancelationReminder && (
                  <div className="input-group row mb-2">
                    <label className="col-lg-3 col-form-label kt-widget24__stats kt-font-dark ml-3 kt-font-bold">
                      Delivery
                    </label>
                    <DateInput value={invoice.deliveryDate} onBlur={this.handleDateBlur} name="deliveryDate" />
                  </div>
                )}
                {!isCancelationReminder && (
                  <div className="input-group row mb-2">
                    <label className="col-lg-3 col-form-label kt-widget24__stats kt-font-dark ml-3 kt-font-bold">
                      Invoice
                    </label>
                    <DateInput value={invoice.invoiceDate} onBlur={this.handleDateBlur} name="invoiceDate" />
                  </div>
                )}
                <div className="input-group row mb-2">
                  <label className="col-lg-3 col-form-label kt-widget24__stats kt-font-dark ml-3 kt-font-bold">
                    VAT-ID
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="VAT-ID"
                    value={invoice.vatID}
                    name={"vatID"}
                    onChange={this.handleInvoiceChange}
                  />
                </div>
                <div className="input-group row mb-2">
                  <label className="col-lg-3 col-form-label kt-widget24__stats kt-font-dark ml-3 kt-font-bold">
                    Type
                  </label>
                  <select
                    className="form-control "
                    value={this.resolveType()}
                    name={"type"}
                    onChange={this.handleTypeChange}
                    disabled={!!existingInvoice}
                  >
                    {!existingInvoice && (
                      <>
                        <option value={I_FINALINVOICE}>Final Invoice</option>
                        <option value={I_PARTIALINVOICE}>Partial Invoice</option>
                      </>
                    )}
                    {!!existingInvoice && (
                      <>
                        <option value={I_CANCELATION}>Cancelation</option>
                        <option value={I_REMINDER}>Reminder</option>
                      </>
                    )}
                  </select>
                </div>
                {invoiceType === I_REMINDER && (
                  <div className="input-group row mb-2">
                    <label className="col-lg-3 col-form-label kt-widget24__stats kt-font-dark ml-3 kt-font-bold">
                      Reminder Level
                    </label>
                    <select
                      className="form-control "
                      value={reminderLevel}
                      name={"level"}
                      onChange={this.handleReminderLevelChange}
                      disabled={new Date() < dueDate || this.hasReminderWithDunningFees()}
                    >
                      <>
                        <option value="0">No interest</option>
                        <option value="1" disabled={new Date() < dueDate}>
                          Interest (40€ + 9% above ECB base interest ({I_INTEREST}%))
                        </option>
                      </>
                    </select>
                  </div>
                )}
                <LanguageSelectionDropdown
                  labelPosition="front"
                  wrapperClasses={"input-group row mb-2"}
                  labelColumnClasses={"col-lg-3 col-form-label ml-3"}
                  labelClasses={"kt-widget24__stats kt-font-dark kt-font-bold mb-0"}
                  selectClasses={"select-default border-radius-left-0 col px-0"}
                  languageChangeCallback={this.handleLanguageChange}
                />
              </div>
            </div>
            <hr />
            <div className="row">
              <div className="col-12">
                <div>
                  <div className="input-group row mb-2">
                    <label className="col-lg-2 col-form-label kt-widget24__stats kt-font-dark ml-3 kt-font-bold">
                      Title
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Title"
                      value={this.resolvePDFDataValue("title")}
                      name={"title"}
                      onChange={this.handlePDFDataChange}
                    />
                  </div>
                  <div className="input-group row mb-2">
                    <label className="col-lg-2 col-form-label kt-widget24__stats kt-font-dark ml-3 kt-font-bold">
                      Subtitle
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Subtitle"
                      value={this.resolvePDFDataValue("subtitle")}
                      name={"subtitle"}
                      onChange={this.handlePDFDataChange}
                    />
                  </div>
                  <div className="input-group row mb-2">
                    <label className="col-lg-2 col-form-label kt-widget24__stats kt-font-dark ml-3 kt-font-bold">
                      Text
                    </label>
                    <textarea
                      className="form-control"
                      placeholder="Text"
                      rows={2}
                      value={this.resolvePDFDataValue("header")}
                      name={"header"}
                      onChange={this.handlePDFDataChange}
                    />
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div className="row">
              <div className="col-12">
                <Table className="table-responsive">
                  <thead>
                    <tr>
                      <th className="kt-datatable__cell" style={{ width: "3%" }}>
                        #
                      </th>
                      <th className="kt-datatable__cell w-25">Description</th>
                      <th className="kt-datatable__cell" style={{ width: "10%" }}>
                        Amount
                      </th>
                      <th className="kt-datatable__cell" style={{ width: "10%" }}>
                        Unit
                      </th>
                      <th className="kt-datatable__cell" style={{ width: "12%" }}>
                        Price
                      </th>
                      <th className="kt-datatable__cell" style={{ width: "10%" }}>
                        VAT
                      </th>
                      <th className="kt-datatable__cell" style={{ width: "10%" }}>
                        Discount
                      </th>
                      <th className="kt-datatable__cell" style={{ width: "15%" }}>
                        Total
                      </th>
                      <th className="kt-datatable__cell" style={{ width: "5%" }} />
                    </tr>
                  </thead>
                  <tbody className="kt-datatable__body">
                    {positions.map((position, index) => (
                      <PositionRow
                        key={position.id.toString()}
                        position={position}
                        index={index}
                        disable={
                          positions.filter(p => p.type !== I_REMINDER).length < 2 || position.type === I_REMINDER
                        }
                        reverseCharge={reverseCharge}
                        onChange={position.type === I_REMINDER ? () => () => true : this.handlePositionValueChange}
                        onRemove={this.handlePositionRemove}
                        disableInput={position.type === I_REMINDER}
                      />
                    ))}
                  </tbody>
                </Table>
                {type !== "reminder" && (
                  <div className="row">
                    <div className="col-6">
                      <button className="btn btn-secondary" onClick={this.handleAddFreePosition}>
                        Add Position
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <hr />
            <div className="row">
              <div className="col-6">
                <div className="kt-checkbox-list">
                  <label className="kt-checkbox kt-checkbox--solid">
                    <input type="checkbox" checked={reverseCharge} onChange={this.handleReverseCharge} />
                    Reverse-Charge
                    <span />
                  </label>
                </div>
              </div>
              <div className="col-6">
                <div className="">
                  <div className="input-group row">
                    <label className="col-lg-3 col-form-label kt-widget24__stats kt-font-dark kt-font-bold">
                      Subtotal
                    </label>
                    <label
                      className="col-lg-9 col-form-label kt-widget24__stats kt-font-dark kt-font-bold"
                      style={{ textAlign: "right" }}
                    >
                      {baseUtils.formatEuro(subTotal)}
                    </label>
                  </div>
                  {vatList.map(vat => (
                    <div key={vat.vat} className="input-group row">
                      <label className="col-lg-3 col-form-label kt-widget24__stats kt-font-dark  kt-font-bold">
                        VAT ({vat.vat}%)
                      </label>
                      <label
                        className="col-lg-9 col-form-label kt-widget24__stats kt-font-dark kt-font-bold"
                        style={{ textAlign: "right" }}
                      >
                        {baseUtils.formatEuro(vat.value)}
                      </label>
                    </div>
                  ))}
                  <div className="input-group row">
                    <label className="col-lg-3 col-form-label kt-widget24__stats kt-font-dark kt-font-bold">
                      Total
                    </label>
                    <label
                      className="col-lg-9 col-form-label kt-widget24__stats kt-font-dark kt-font-bold"
                      style={{ textAlign: "right" }}
                    >
                      <u>{baseUtils.formatEuro(total)}</u>
                    </label>
                  </div>
                  {invoice.payments.length > 0 && type !== I_CANCELATION && (
                    <>
                      {invoice.payments.map(p => (
                        <div className="input-group row" key={p._id.toString()}>
                          <label className="col-lg-5 col-form-label kt-widget24__stats kt-font-dark kt-font-bold">
                            Payment on {baseUtils.formatDate(p.date)}
                          </label>
                          <label
                            className="col-lg-7 col-form-label kt-widget24__stats kt-font-dark kt-font-bold"
                            style={{ textAlign: "right" }}
                          >
                            {baseUtils.formatEuro(-p.amount)}
                          </label>
                        </div>
                      ))}
                      <div className="input-group row">
                        <label className="col-lg-3 col-form-label kt-widget24__stats kt-font-dark kt-font-bold">
                          Remaining
                        </label>
                        <label
                          className="col-lg-9 col-form-label kt-widget24__stats kt-font-dark kt-font-bold"
                          style={{ textAlign: "right" }}
                        >
                          <u>{baseUtils.formatEuro(total - invoice.payments.reduce((sum, p) => sum + p.amount, 0))}</u>
                        </label>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
            <hr />
            <div className="row">
              <div className="col-12">
                <div>
                  <BioNumberSelection
                    bioNumber={invoice.bioNumber}
                    onChange={this.handleInvoiceChange}
                    wrapperClasses="input-group row mb-2"
                    labelColumnClasses="col-lg-2 col-form-label ml-3"
                    labelClasses="kt-widget24__stats kt-font-dark kt-font-bold mb-0"
                  />
                  {invoiceType !== I_CANCELATION && (
                    <PaymentTargetSelect
                      label="Due in"
                      name="dueIn"
                      value={invoice.dueIn}
                      onChange={e => this.handleInvoiceChange(e, true)}
                      wrapperClasses="input-group row mb-2"
                      labelColumnClasses="col-lg-2 col-form-label ml-3"
                      labelClasses="kt-widget24__stats kt-font-dark kt-font-bold mb-0"
                      disablePaymentInAdvance={invoiceType === I_REMINDER}
                    />
                  )}
                  <div className="input-group row mb-2">
                    <label className="col-lg-2 col-form-label kt-widget24__stats kt-font-dark ml-3 kt-font-bold">
                      Payment
                    </label>
                    <textarea
                      className="form-control"
                      placeholder="Payment Note"
                      rows={1}
                      value={this.resolvePDFDataValue("paymentInfo")}
                      name={"pdfData.content.paymentInfo"}
                      onChange={this.handleInvoiceChange}
                    />
                  </div>
                  <div className="input-group row mb-2">
                    <label className="col-lg-2 col-form-label kt-widget24__stats kt-font-dark ml-3 kt-font-bold">
                      Note
                    </label>
                    <textarea
                      className="form-control"
                      placeholder="Note"
                      rows={3}
                      value={this.resolvePDFDataValue("footer")}
                      name={"pdfData.content.footer"}
                      onChange={this.handlePDFDataChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-secondary" onClick={this.handleClose}>
              Close
            </button>
            {this.renderPreview(subTotal, vatList, total)}
            {!type && (
              <button
                className={!uploading ? "btn btn-success" : "btn btn-success disabled"}
                disabled={uploading}
                onClick={this.handleUpload(subTotal, vatList, total)}
              >
                {uploading && (
                  <div className="button-splash-spinner d-inline pr-3 pl-0 mx-0">
                    <svg className="button-splash-spinner" viewBox="0 0 50 50">
                      <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5" />
                    </svg>
                  </div>
                )}
                Create Invoice
              </button>
            )}
            {type && existingInvoice && (
              <button
                className={!uploading ? "btn btn-success" : "btn btn-success disabled"}
                disabled={uploading}
                onClick={this.handleUpload(subTotal, vatList, total)}
              >
                {uploading && (
                  <div className="button-splash-spinner d-inline pr-3 pl-0 mx-0">
                    <svg className="button-splash-spinner" viewBox="0 0 50 50">
                      <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5" />
                    </svg>
                  </div>
                )}
                {type === I_CANCELATION ? "Cancel Invoice" : "Create Payment Reminder"}
              </button>
            )}
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default InvoiceModal;
