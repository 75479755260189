import React, { PureComponent } from "react";
import { toast } from "react-toastify";
import { DataContext } from "../../context/dataContext";
import pdfUtils from "../../utils/pdf/pdfUtils";
import config from "../../config/config.json";

interface OrderConfirmationPreviewProps {
  createPDF: () => string;
  fileName: string;
  pdfOptions?: any;
  alternativeButtonName?: string;
}

interface OrderConfirmationPreviewState {
  loading: boolean;
}

class OrderConfirmationPreview extends PureComponent<OrderConfirmationPreviewProps, OrderConfirmationPreviewState> {
  static contextType = DataContext;
  context!: React.ContextType<typeof DataContext>;
  constructor(props: OrderConfirmationPreviewProps) {
    super(props);
    this.state = { loading: false };
  }

  convertHtmlToPdf = async () => {
    this.setState({ loading: true });
    const { createPDF, fileName, pdfOptions } = this.props;
    const pdfData: any = {
      html: createPDF(),
      fileName: fileName
    };
    if (pdfOptions) pdfData.options = pdfOptions;
    const data = JSON.stringify(pdfData);
    let path;
    try {
      path = await pdfUtils.uploadAndReturnPath(data);
    } catch (e) {
      toast.error(
        <b>
          <i className="fa fa-exclamation mr-2" />
          {"An unexpected error occurred: " + e.message}
        </b>
      );
    }
    if (path) {
      window.open((process.env.REACT_APP_MEDIAHUB_BASE || "") + path, "_blank");
    } else {
      toast.error(
        <b>
          <i className="fa fa-exclamation mr-2" />
          Preview could not be created
        </b>
      );
    }
    this.setState({ loading: false });
  };

  render() {
    const { loading } = this.state;
    const { alternativeButtonName } = this.props;
    return (
      <button
        className={loading ? "btn btn-secondary btn-disabled" : "btn btn-secondary"}
        disabled={loading}
        onClick={this.convertHtmlToPdf}
      >
        {loading && (
          <div className="button-splash-spinner d-inline pr-3 pl-0 mx-0">
            <svg className="button-splash-spinner" viewBox="0 0 50 50">
              <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5" />
            </svg>
          </div>
        )}
        {loading ? "Creating Preview..." : alternativeButtonName ? alternativeButtonName : "Show Preview"}
      </button>
    );
  }
}

export default OrderConfirmationPreview;
