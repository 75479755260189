import React from "react";
import { BatchLocationInformation } from "../../../../model/warehouse/batch.types";
import Tooltip from "../../../common/Tooltip";
import { resolveTranslation } from "../../../../utils/translationUtils";

interface WarehouseInformationProps {
  location: BatchLocationInformation;
}

const WarehouseInformation: React.FC<WarehouseInformationProps> = ({ location }) => {
  return (
    <div className="kt-user-card-v2 pointer">
      <div className="kt-user-card-v2__details">
        <Tooltip
          tooltipText={`${resolveTranslation(location.warehouseSnapshot.warehouseName)} - ${resolveTranslation(
            location.warehouseArea.warehouseName
          )}`}
        >
          <span className="kt-user-card-v2__name text-black font-weight-300">
            <span>
              {location.warehouseSnapshot.shortName} - {location.warehouseArea.shortName}
            </span>
          </span>
        </Tooltip>
      </div>
    </div>
  );
};

export default WarehouseInformation;
