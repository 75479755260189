import _ from "lodash";
import i18n from "../translations/i18n";
import { Batch, BatchLocation, BatchTimelineType, PackagingUnit } from "../model/warehouse/batch.types";
import { OrdersDocument } from "../model/orders.types";
import { ExtendedBatchTimelineEntry } from "../components/order/CustomTypes";
import { LanguageObject, NumValue } from "../model/common.types";
import { resolveTranslation } from "./translationUtils";
import baseUtils, { formatCurrencyValueLocale, formatNumValueLocale } from "./baseUtils";
import { getFormattedPackagingUnitAmount } from "./warehouseUtils";
import { subtractNumValueWeight } from "./warehouseCalculationUtils";
import { NestedPartial } from "../model/warehouse/common.types";
import { getLocationString, getPUString } from "./batchUtils";

enum RemovalMode {
  BOOK_OUT,
  SEND
}

/**
 * Checks the type of the timeline entry and returns the corresponding description as string
 * @param t the extended batch timeline entry including the lot
 * @param orders all orders to resolve the CO name by id
 * @return { text: string; tooltip: JSX.Element | string } an object containing the basic timeline text and a tooltip or an empty string if no tooltip text is defined
 */
export const resolveWarehouseTimelineText = (
  t: ExtendedBatchTimelineEntry,
  orders: Array<OrdersDocument>
): { text: string; tooltip: JSX.Element | string } => {
  let text: string;
  let tooltip: string | JSX.Element = "";
  switch (t.type) {
    case BatchTimelineType.BATCHCREATED:
      tooltip = resolveBatchCreationTooltipText(t.diff.post as Batch); // whole batch is included in post on creation
      text = i18n.t("warehouse:batchCreated", { lot: t.lot, interpolation: { escapeValue: false } });
      break;
    case BatchTimelineType.BATCHADDITIONALBOOKIN:
      tooltip = resolveAdditionalBookInTooltipText(t.diff.pre, t.diff.post);
      text = i18n.t("warehouse:batchAdditionalBookIn", { lot: t.lot, interpolation: { escapeValue: false } });
      break;
    case BatchTimelineType.BATCHLOCATIONCHANGE:
      tooltip = resolveLocationChangeTooltipText(t.diff.pre.locations, t.diff.post.locations);
      text = i18n.t("warehouse:batchLocationChange", { lot: t.lot, interpolation: { escapeValue: false } });
      break;
    case BatchTimelineType.BATCHDATACHANGED:
      tooltip = resolveDataChangeTooltipText(t.diff.pre, t.diff.post);
      text = i18n.t("warehouse:batchDataChanged", { lot: t.lot, interpolation: { escapeValue: false } });
      break;
    case BatchTimelineType.BATCHBBDCORRECTED:
      tooltip = resolveBBDChangeTooltipText(t.diff.pre, t.diff.post, t.payload.comment);
      text = i18n.t("warehouse:batchBBDCorrected", { lot: t.lot, interpolation: { escapeValue: false } });
      break;
    case BatchTimelineType.BATCHBOOKEDOUT:
      tooltip = resolveBatchRemovalTooltipText(t.diff.pre, t.diff.post, RemovalMode.BOOK_OUT);
      text = i18n.t("warehouse:batchBookedOut", { lot: t.lot, interpolation: { escapeValue: false } });
      break;
    case BatchTimelineType.BATCHCUSTOMERORDERREMOVED:
      const order = t.payload.customerOrder ? baseUtils.getDocFromCollection(orders, t.payload.customerOrder) : "";
      const orderIdentifier = order ? `AT-${order.identifier}` : "";
      text = i18n.t("warehouse:batchCustomerOrderRemoved", {
        order: orderIdentifier,
        lot: t.lot,
        interpolation: { escapeValue: false }
      });
      break;
    case BatchTimelineType.BATCHFILEUPLOADED:
      text = i18n.t("warehouse:batchFileUploaded", {
        fileName: t.payload.file?.title,
        lot: t.lot,
        interpolation: { escapeValue: false }
      });
      break;
    case BatchTimelineType.BATCHDAMAGEREPORTED:
      tooltip = resolveDamageChangeTooltipText(t.diff.pre, t.diff.post);
      text = i18n.t("warehouse:batchDamageReported", { lot: t.lot, interpolation: { escapeValue: false } });
      break;
    case BatchTimelineType.BATCHSENT:
      tooltip = resolveBatchRemovalTooltipText(t.diff.pre, t.diff.post, RemovalMode.SEND);
      text = i18n.t("warehouse:batchSent", { lot: t.lot, interpolation: { escapeValue: false } });
      break;
    case BatchTimelineType.BATCHINTERNALDELIVERYBOOKIN:
      tooltip = resolveAdditionalBookInTooltipText(t.diff.pre, t.diff.post);
      text = i18n.t("warehouse:batchInternalDeliveryBookIn", { lot: t.lot });
      break;
    default:
      text = `${i18n.t("warehouse:unknownAction")} "${t.type}"`;
  }
  return {
    text,
    tooltip
  };
};

/**
 * Resolves the new batch creation change based on the post-object
 * @param post a partial batch location (after change)
 * @returns {JSX.Element | string} the new batch creation change as displayable element, or an empty string if no changes were found
 */
const resolveBatchCreationTooltipText = (post: Batch): JSX.Element | string => {
  const content = resolveTranslation(post.content.details.title);
  const amountString = formatNumValueLocale(post.totalAmount);
  const pUAtLocation: Array<string> = [];
  if (post.locations && post.locations.length > 0) {
    for (let location of post.locations) {
      const locationString = getLocationString(location.location);
      const locationAmount = location.amountAtLocation ? formatNumValueLocale(location.amountAtLocation) : "";
      const pUArray: Array<string> = [];
      if (location.packagingUnits && location.packagingUnits.length > 0) {
        for (let pU of location.packagingUnits) {
          pUArray.push(getFormattedPackagingUnitAmount(pU, true, true));
        }
      }
      if (pUArray.length > 0) {
        pUAtLocation.push(
          i18n.t("warehouse:batchCreatedAtLocation", {
            amount: `${locationAmount} (${pUArray.join(", ")})`,
            location: locationString
          })
        );
      }
    }
  }
  if (!content && !amountString && pUAtLocation.length === 0) return "";
  return (
    <div className="text-left">
      {amountString && content && (
        <div>{i18n.t("warehouse:batchBookedIn", { totalAmount: amountString, content })}</div>
      )}
      {pUAtLocation && pUAtLocation.length > 0 && (
        <div className="mt-2">
          {pUAtLocation.map((pU, idx) => (
            <div key={idx}>{pU}</div>
          ))}
        </div>
      )}
    </div>
  );
};

/**
 * Resolves the additional book in change(s) based on the pre- and post-objects
 * @param pre a partial batch location (before change)
 * @param post a partial batch location (after change)
 * @returns {string | JSX.Element} the additional book in change(s) as displayable element or an empty string if no changes were found
 */
const resolveAdditionalBookInTooltipText = (
  pre: NestedPartial<Batch>,
  post: NestedPartial<Batch>
): string | JSX.Element => {
  if (!pre.locations || !post.locations || post.locations.length === 0) return "";
  const pUAddedToStrings = [];
  for (let postLoc of post.locations) {
    const toLocationString = getLocationString(postLoc.location);
    const preLoc = pre.locations.find(preLoc => preLoc._id?.toString() === postLoc._id?.toString());
    if (postLoc.packagingUnits && postLoc.packagingUnits.length > 0) {
      for (let postPU of postLoc.packagingUnits) {
        const prePU = preLoc && preLoc.packagingUnits?.find(prePU => prePU._id?.toString() === postPU._id?.toString());
        const addedQuantity = (postPU.quantity || 0) - (prePU?.quantity || 0);
        const addedPU = getPUString(postPU.puSnapshot?.label, addedQuantity);
        pUAddedToStrings.push(i18n.t("warehouse:batchDataChangedPUAdded", { pU: addedPU, location: toLocationString }));
      }
    }
  }
  return (
    <div className="text-left">
      {pre.totalAmount && post.totalAmount && (
        <div>
          {i18n.t("warehouse:batchDataChangedTotalAmount", {
            preAmount: formatNumValueLocale(pre.totalAmount),
            postAmount: formatNumValueLocale(post.totalAmount)
          })}
        </div>
      )}
      <div>
        {pUAddedToStrings.map((pUAddedToString, idx) => {
          return <div key={idx}>{pUAddedToString}</div>;
        })}
      </div>
      {pre.unitPrice && post.unitPrice && !_.isEqual(pre.unitPrice, post.unitPrice) && (
        <div>
          {i18n.t("warehouse:batchDataChangedUnitPrice", {
            preAmount: formatCurrencyValueLocale(pre.unitPrice),
            postAmount: formatCurrencyValueLocale(post.unitPrice)
          })}
        </div>
      )}
    </div>
  );
};

/**
 * Updates the given array with the movements of parts or all packaging units to a new location, as formatted string
 * @param pUMovedToStrings the array to which the strings should be pushed to
 * @param locationsPost the post location to retrieve the PU information from
 */
const updateNewLocationPU = (pUMovedToStrings: Array<string>, locationsPost: Array<NestedPartial<BatchLocation>>) => {
  for (const postLocation of locationsPost) {
    if (!postLocation.packagingUnits) continue;
    const locationTo = postLocation.location;
    const toLocationString = getLocationString(locationTo);
    for (const pU of postLocation.packagingUnits) {
      const movedPU = getPUString(pU.puSnapshot?.label, pU.quantity);
      pUMovedToStrings.push(i18n.t("warehouse:toLocation", { object: movedPU, location: toLocationString }));
    }
  }
};

/**
 * Updates the given array with the movement of all packaging units to an existing location, as formatted string
 * @param pUMovedToStrings the array to which the strings should be pushed to
 * @param commonLocationsPost the locations of post which are also included in pre, to retrieve the PU information from
 * @param pre all pre-locations to compare against
 */
const updateEverythingToExistingPU = (
  pUMovedToStrings: Array<string>,
  commonLocationsPost: Array<NestedPartial<BatchLocation>>,
  pre: Array<NestedPartial<BatchLocation>>
) => {
  for (const postLocation of commonLocationsPost) {
    if (!postLocation.packagingUnits || postLocation.packagingUnits.length === 0) continue; // should never happen
    const toLocationString = getLocationString(postLocation.location);
    const preLocation = pre.find(bL => bL._id?.toString() === postLocation._id?.toString());
    if (!preLocation) continue; // should never happen
    for (const postPU of postLocation.packagingUnits) {
      if (!postPU.quantity) continue;
      const prePU = preLocation.packagingUnits?.find(prePU => prePU._id?.toString() === postPU._id?.toString());
      const movedQuantity = prePU?.quantity ? postPU.quantity - prePU.quantity : postPU.quantity;
      const movedPU = getPUString(postPU.puSnapshot?.label, movedQuantity);
      pUMovedToStrings.push(i18n.t("warehouse:toLocation", { object: movedPU, location: toLocationString }));
    }
  }
};

/**
 * Updates the given string and array with the movement of parts of packaging units to an existing location, as formatted string
 * @param pUMovedToStrings the array to which the strings should be pushed to
 * @param pre all pre-locations
 * @param post all post-locations
 * @returns {string} the location from which the packaging units are moved from, as formatted string
 */
const updatePartsToExistingPU = (
  pUMovedToStrings: Array<string>,
  pre: Array<NestedPartial<BatchLocation>>,
  post: Array<NestedPartial<BatchLocation>>
): string => {
  let fromLocationString = "";
  for (const preLocation of pre) {
    if (!preLocation.packagingUnits) continue; // should never happen
    const postLocation = post.find(bL => bL._id?.toString() === preLocation._id?.toString());
    if (!postLocation || !postLocation.packagingUnits || postLocation.packagingUnits.length === 0) continue; // should never happen
    const toLocationString = getLocationString(postLocation.location);
    for (const postPU of postLocation.packagingUnits) {
      const prePU = preLocation.packagingUnits.find(pU => pU._id?.toString() === postPU._id?.toString());
      if (prePU && prePU.quantity && (!postPU.quantity || prePU.quantity > postPU.quantity)) {
        fromLocationString = getLocationString(preLocation.location);
      } else {
        const movedQuantity = (postPU.quantity || 0) - (prePU?.quantity || 0);
        const movedPU = getPUString(postPU.puSnapshot?.label, movedQuantity);
        pUMovedToStrings.push(i18n.t("warehouse:toLocation", { object: movedPU, location: toLocationString }));
      }
    }
  }
  return fromLocationString;
};

/**
 * Resolves the location change(s) based on the pre- and post-objects
 * @param pre a partial batch location (before change)
 * @param post a partial batch location (after change)
 * @returns {string | JSX.Element} the location changes as displayable element or an empty string if no changes were found
 */
const resolveLocationChangeTooltipText = (
  pre?: Array<NestedPartial<BatchLocation>>,
  post?: Array<NestedPartial<BatchLocation>>
): string | JSX.Element => {
  if (!pre || !post) return "";
  const preLocationIds = pre.map(bL => bL._id?.toString() ?? "");
  const postLocationIds = post.map(bL => bL._id?.toString() ?? "");
  const onlyInPre = _.difference(preLocationIds, postLocationIds);
  const onlyInPost = _.difference(postLocationIds, preLocationIds);
  const inPreAndPost = _.intersection(preLocationIds, postLocationIds);
  let fromLocationString = "";
  let pUMovedToStrings: Array<string> = [];

  if (inPreAndPost.length === 0 && onlyInPre.length === 1 && onlyInPost.length > 0) {
    // Everything moved to new location(s)
    const preLocations = pre.filter(bL => onlyInPre.includes(bL._id?.toString() || ""));
    const postLocations = post.filter(bL => onlyInPost.includes(bL._id?.toString() || ""));
    const preLocation = preLocations[0];
    fromLocationString = getLocationString(preLocation.location);
    updateNewLocationPU(pUMovedToStrings, postLocations);
  } else if (inPreAndPost.length === 1 && onlyInPre.length === 0 && onlyInPost.length > 0) {
    // Parts(s) moved to new location(s)
    const commonLocationsPre = pre.filter(bL => inPreAndPost.includes(bL._id?.toString() || ""));
    const postLocations = post.filter(bL => onlyInPost.includes(bL._id?.toString() || ""));
    const commonLocation = commonLocationsPre[0];
    fromLocationString = getLocationString(commonLocation.location);
    updateNewLocationPU(pUMovedToStrings, postLocations);
  } else if (inPreAndPost.length > 0 && onlyInPre.length === 1 && onlyInPost.length === 0) {
    // Everything moved to existing location(s)
    const preLocations = pre.filter(bL => onlyInPre.includes(bL._id?.toString() || ""));
    const commonLocationsPost = post.filter(bL => inPreAndPost.includes(bL._id?.toString() || ""));
    const preLocation = preLocations[0];
    fromLocationString = getLocationString(preLocation.location);
    updateEverythingToExistingPU(pUMovedToStrings, commonLocationsPost, pre);
  } else if (inPreAndPost.length > 0 && onlyInPre.length === 0 && onlyInPost.length === 0) {
    // Part(s) moved to existing location(s)
    fromLocationString = updatePartsToExistingPU(pUMovedToStrings, pre, post);
  } else if (inPreAndPost.length > 0 && onlyInPre.length === 1 && onlyInPost.length > 0) {
    // Everything moved to new and existing locations
    const preLocations = pre.filter(bL => onlyInPre.includes(bL._id?.toString() || ""));
    const postLocations = post.filter(bL => onlyInPost.includes(bL._id?.toString() || ""));
    const commonLocationsPost = post.filter(bL => inPreAndPost.includes(bL._id?.toString() || ""));
    const preLocation = preLocations[0];
    fromLocationString = getLocationString(preLocation.location);
    // Handle entries moved to existing locations
    updateEverythingToExistingPU(pUMovedToStrings, commonLocationsPost, pre);
    // Handle entries moved to new location
    updateNewLocationPU(pUMovedToStrings, postLocations);
  } else if (inPreAndPost.length > 0 && onlyInPre.length === 0 && onlyInPost.length > 0) {
    // Part(s) moved to new and existing locations
    const postLocations = post.filter(bL => onlyInPost.includes(bL._id?.toString() || ""));
    // Handle entries moved to existing locations
    fromLocationString = updatePartsToExistingPU(pUMovedToStrings, pre, post);
    // Handle entries moved to new location
    updateNewLocationPU(pUMovedToStrings, postLocations);
  } else {
    return "";
  }

  return (
    <div className="text-left">
      <div className="mb-2">{i18n.t("warehouse:fromLocation", { location: fromLocationString })}</div>
      <div>
        {pUMovedToStrings.map((pUMovedToString, idx) => {
          return <div key={idx}>{pUMovedToString}</div>;
        })}
      </div>
    </div>
  );
};

/**
 * Resolves the BBD change based on the pre- and post-objects
 * @param pre a partial batch containing the expiry (before change)
 * @param post a partial batch containing the expiry (after change)
 * @param comment optional, the comment given when the BBD change was done
 * @returns {string | JSX.Element} the expiry change as displayable element or an empty string if no change was found
 */
const resolveBBDChangeTooltipText = (
  pre: NestedPartial<Batch>,
  post: NestedPartial<Batch>,
  comment?: string
): string | JSX.Element => {
  if (!pre.expiry || !post.expiry) return "";
  const preDate = baseUtils.formatDate(pre.expiry);
  const postDate = baseUtils.formatDate(post.expiry);
  const uploadedFiles = post.files ? post.files.map(f => f.title).join(", ") : undefined;
  return (
    <div className="text-left">
      <div>{i18n.t("warehouse:batchBBDCorrectedDetail", { preDate, postDate })}</div>
      {comment && (
        <div>
          {i18n.t("warehouse:comments")}: {comment}
        </div>
      )}
      {uploadedFiles && (
        <div>
          {i18n.t("warehouse:filesUploaded")} {uploadedFiles}
        </div>
      )}
    </div>
  );
};

/**
 * Get all packaging units removed from locations as formatted strings
 * @param preLocations the locations before removing
 * @param postLocations the locations after removing
 * @returns {Array<string>} the removed packaging units as formatted strings
 */
const getRemovedPUStrings = (
  preLocations: NestedPartial<Array<BatchLocation>> | undefined,
  postLocations: NestedPartial<Array<BatchLocation>> | undefined
): Array<string> => {
  if (!preLocations || preLocations.length === 0 || !postLocations) return [];
  const pURemovedFromStrings: Array<string> = [];
  for (let preLoc of preLocations) {
    if (!preLoc) continue;
    const fromLocationString = getLocationString(preLoc.location);
    const postLoc = postLocations.find(postLoc => postLoc?._id?.toString() === preLoc!._id?.toString());
    if (preLoc.packagingUnits && preLoc.packagingUnits.length > 0) {
      for (let prePU of preLoc.packagingUnits) {
        const postPU =
          postLoc && postLoc.packagingUnits?.find(postPU => postPU._id?.toString() === prePU._id?.toString());
        const removedQuantity = (prePU.quantity || 0) - (postPU?.quantity || 0);
        const removedPU = getPUString(prePU.puSnapshot?.label, removedQuantity);
        pURemovedFromStrings.push(
          i18n.t("warehouse:batchDataChangedPURemoved", { pU: removedPU, location: fromLocationString })
        );
      }
    }
  }
  return pURemovedFromStrings;
};

/**
 * Resolves the book out change(s) based on the pre- and post-objects
 * @param pre a partial batch containing the state before the change(s)
 * @param post a partial batch containing the state after the change(s)
 * @param removalMode the action (removing, sending, etc.) done with the batch
 * @returns {string | JSX.Element} the book out change(s) as displayable element or an empty string if no change was found
 */
const resolveBatchRemovalTooltipText = (
  pre: NestedPartial<Batch>,
  post: NestedPartial<Batch>,
  removalMode: RemovalMode
): string | JSX.Element => {
  let translation = "";
  switch (removalMode) {
    case RemovalMode.BOOK_OUT:
      translation = "batchBookedOutTotalAmount";
      break;
    case RemovalMode.SEND:
      translation = "batchSentTotalAmount";
      break;
  }
  const totalAmountDiff =
    pre.totalAmount &&
    pre.totalAmount.value &&
    pre.totalAmount.unit &&
    post.totalAmount &&
    post.totalAmount.value &&
    post.totalAmount.unit
      ? subtractNumValueWeight(pre.totalAmount as NumValue, pre.totalAmount.unit, post.totalAmount as NumValue)
      : undefined;
  const pURemovedFromStrings = getRemovedPUStrings(pre.locations, post.locations);
  if (!totalAmountDiff && pURemovedFromStrings.length === 0) return "";
  return (
    <div className="text-left">
      {totalAmountDiff && (
        <div>
          {i18n.t(`warehouse:${translation}`, {
            totalAmount: formatNumValueLocale(totalAmountDiff),
            content: post.content?.details?.title?.de
              ? resolveTranslation(post.content.details.title as LanguageObject)
              : ""
          })}
        </div>
      )}
      <div>
        {pURemovedFromStrings.map((pURemovedFromString, idx) => {
          return <div key={idx}>{pURemovedFromString}</div>;
        })}
      </div>
    </div>
  );
};

/**
 * Resolves the batch data change(s) based on the pre- and post-objects
 * @param pre a partial batch containing the state before the change(s)
 * @param post a partial batch containing the state after the change(s)
 * @returns {string | JSX.Element} the batch data change(s) as displayable element or an empty string if no change was found
 */
const resolveDataChangeTooltipText = (pre: NestedPartial<Batch>, post: NestedPartial<Batch>): string | JSX.Element => {
  const uploadedFiles = post.files ? post.files.map(f => f.title).join(", ") : undefined;
  let prePUType;
  let postPUType;
  let preAmountPerPU;
  let postAmountPerPU;
  // PU can only be changed if batch is in entrance and has only one PU
  if (pre.locations && pre.locations.length > 0 && post.locations && post.locations.length > 0) {
    const preLocation: NestedPartial<BatchLocation> = pre.locations[0];
    const postLocation: NestedPartial<BatchLocation> = post.locations[0];
    if (
      preLocation.packagingUnits &&
      preLocation.packagingUnits.length > 0 &&
      postLocation.packagingUnits &&
      postLocation.packagingUnits.length > 0
    ) {
      const prePU: NestedPartial<PackagingUnit> = preLocation.packagingUnits[0];
      const postPU: NestedPartial<PackagingUnit> = postLocation.packagingUnits[0];
      if (prePU.puSnapshot?.label?.de && postPU.puSnapshot?.label?.de) {
        prePUType = resolveTranslation(prePU.puSnapshot.label as LanguageObject);
        postPUType = resolveTranslation(postPU.puSnapshot.label as LanguageObject);
      }
      if (prePU.amountPerPu && postPU.amountPerPu) {
        preAmountPerPU = prePU.amountPerPu;
        postAmountPerPU = postPU.amountPerPu;
      }
    }
  }
  if (
    !pre.lot &&
    !post.lot &&
    !uploadedFiles &&
    !pre.stocked &&
    !post.stocked &&
    !prePUType &&
    !postPUType &&
    !preAmountPerPU &&
    !postAmountPerPU
  )
    return "";
  return (
    <div className="text-left">
      {pre.lot && post.lot && (
        <div>{i18n.t("warehouse:batchDataChangedLOT", { preLOT: pre.lot, postLOT: post.lot })}</div>
      )}
      {uploadedFiles && (
        <div>
          {i18n.t("warehouse:filesUploaded")} {uploadedFiles}
        </div>
      )}
      {pre.stocked && post.stocked && (
        <div>
          {i18n.t("warehouse:batchDataChangedStocked", {
            preDate: baseUtils.formatDate(pre.stocked),
            postDate: baseUtils.formatDate(post.stocked)
          })}
        </div>
      )}
      {prePUType && postPUType && (
        <div>
          {i18n.t("warehouse:batchDataChangedPU", {
            prePU: prePUType,
            postPU: postPUType
          })}
        </div>
      )}
      {preAmountPerPU && postAmountPerPU && (
        <div>
          {i18n.t("warehouse:batchDataChangedAmountPerPU", {
            preAmount: formatNumValueLocale(preAmountPerPU),
            postAmount: formatNumValueLocale(postAmountPerPU)
          })}
        </div>
      )}
    </div>
  );
};

/**
 * Resolves the damage change based on the pre- and post-objects
 * @param pre a partial batch containing the blocking information (before change)
 * @param post a partial batch containing the blocking information (after change)
 * @returns {string | JSX.Element} the blocking information change as displayable element or an empty string if no change was found
 */
const resolveDamageChangeTooltipText = (
  pre: NestedPartial<Batch>,
  post: NestedPartial<Batch>
): string | JSX.Element => {
  // TODO AC-659 Adjust tooltip if only PUs are blocked / unblocked
  if (!post.blocked) return "";
  const uploadedFiles = post.blocked.files ? post.blocked.files.map(f => f.title).join(", ") : undefined;
  return (
    <div className="text-left">
      <div>{i18n.t("warehouse:batchDamageReportedDetails", { damage: post.blocked.reason })}</div>
      {uploadedFiles && (
        <div>
          {i18n.t("warehouse:filesUploaded")} {uploadedFiles}
        </div>
      )}
    </div>
  );
};
