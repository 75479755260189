import React, { PureComponent, useRef } from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import fileUtils from "../../utils/fileUtils";
import baseUtils from "../../utils/baseUtils";

interface UploadImageWithPreviewProps {
  preview?: string;
  onAddUpload: (path: string) => void;
  onDeleteUpload: () => void;
}
interface UploadImageWithPreviewState {
  file: any;
  showModal: boolean;
  success: boolean;
  error: boolean;
}

class UploadImageWithPreview extends PureComponent<UploadImageWithPreviewProps, UploadImageWithPreviewState> {
  constructor(props: UploadImageWithPreviewProps) {
    super(props);
    this.state = {
      file: null,
      showModal: false,
      success: false,
      error: false
    };
  }

  handleUpload = () => {
    const file = this.state.file;
    if (!file) return;
    const xhr = new XMLHttpRequest();
    let isodate = new Date().toISOString();
    isodate = isodate.split(":").join("-");
    isodate = isodate.split(".").join("-");
    const fileType = fileUtils.getFileExtension(file.name);
    const fileName = "Image-" + isodate + baseUtils.encodeString(file.name) + "." + fileType;
    xhr.open("POST", (process.env.REACT_APP_UPLOAD_ENDPOINT || "") + fileName, true);
    xhr.setRequestHeader("Content-Type", "application/octate-stream");
    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          this.setState({ success: true, error: false, showModal: false });
          this.props.onAddUpload((process.env.REACT_APP_MEDIAHUB_FILE_BASE || "") + xhr.responseText);
        } else {
          this.setState({ success: false, error: true });
        }
      }
    };
    xhr.send(file);
  };

  handleSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) toast.error("No file selected");
    this.setState({ file: e.target.files![0] });
  };
  handleClose = () => this.setState({ showModal: false, file: undefined, success: false, error: false });

  render() {
    const { preview, onDeleteUpload } = this.props;
    const { file, showModal, error, success } = this.state;
    return (
      <>
        {preview ? (
          <div className="kt-section__content d-flex flex-wrap ">
            <img
              src={preview}
              style={{
                width: "200px",
                height: "200px",
                borderRadius: "10px",
                objectFit: "cover",
                boxShadow: "0px 0px 13px 0px rgb(82 63 105 / 10%)"
              }}
              alt={"Preview"}
            />
            <span className="kt-badge kt-badge--light pos-deleteImg" onClick={onDeleteUpload}>
              <i className="fas fa-times" />
            </span>
          </div>
        ) : (
          <div className="kt-widget4__item" style={{ width: 200, height: 200 }}>
            <div className="kt-widget4__info">
              <div
                className="dropzone dropzone-default dz-clickable dz-hover"
                onClick={() => this.setState({ showModal: true })}
              >
                <div className="dropzone-msg dz-message needsclick">
                  <h3 className="dropzone-msg-title">Upload Picture</h3>
                  <span className="dropzone-msg-desc">*.jpg, *.png, *.gif</span>
                </div>
              </div>
            </div>
          </div>
        )}
        <UploadImageModal
          show={showModal}
          file={file}
          success={success}
          error={error}
          onClose={this.handleClose}
          onSelectFile={this.handleSelectFile}
          onUpload={this.handleUpload}
        />
      </>
    );
  }
}

interface UploadImageModalProps {
  show: boolean;
  file: any;
  success: boolean;
  error: boolean;
  onClose: () => void;
  onSelectFile: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onUpload: () => void;
}

const UploadImageModal: React.FunctionComponent<UploadImageModalProps> = ({
  show,
  onClose,
  file,
  onUpload,
  onSelectFile,
  success,
  error
}) => {
  const ref: React.RefObject<any> = useRef(null);
  return (
    <Modal show={show} centered onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          <i className="kt-font-brand fa fa-image mr-1" />
          Upload Image
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <div className="kt-portlet__body">
            <div className="kt-widget4__item">
              <div className="kt-widget4__info" style={{ paddingRight: "0rem" }}>
                <div className="row">
                  <div className="col-12">
                    <div className="mt-3" style={{ height: "25px" }}>
                      <span
                        className="text-center "
                        style={{
                          color: "#595d6e",
                          fontSize: "1rem",
                          fontWeight: 500
                        }}
                      >
                        {fileUtils.getFileDescription(file)}
                        {file && !success && !error ? (
                          <>
                            <br />
                            <span style={{ color: "rgb(191, 197, 212)" }}>ready</span>
                          </>
                        ) : file && success ? (
                          <React.Fragment>
                            <br />
                            <span className="text-success">
                              successfully uploaded
                              <i className={"fa fa-check ml-2"} />
                            </span>
                          </React.Fragment>
                        ) : file && error ? (
                          <React.Fragment>
                            <br />
                            <span className="text-danger">
                              an error occured
                              <i className={"fa fa-exclamation-circle ml-2"} />
                            </span>
                          </React.Fragment>
                        ) : null}
                      </span>
                      <button className="btn btn-success float-right" type="button" onClick={() => ref.current.click()}>
                        Select File
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <input
            type="file"
            ref={ref}
            accept="image/png,image/jpeg,image/gif"
            style={{ display: "none" }}
            onChange={onSelectFile}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button type="button" className="btn btn-secondary" onClick={onClose}>
          Close
        </button>
        <button
          type="button"
          className={file ? "btn btn-success" : "btn btn-success disabled"}
          disabled={!file}
          onClick={onUpload}
        >
          Upload Image
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default UploadImageWithPreview;
