import _ from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import Select from "react-select";
import { Modal } from "react-bootstrap";
import i18n from "../../../translations/i18n";
import { BaseActionModalProps } from "../../../model/warehouse/common.types";
import { useDataContext } from "../../../context/dataContext";
import LanguageSelectionDropdown from "../../common/LanguageSelectionDropdown";
import { NumValue, SelectOption } from "../../../model/common.types";
import { resolveTranslation } from "../../../utils/translationUtils";
import { exportDatasheet } from "../../../utils/excelUtils";
import baseUtils, { formatCurrencyValueLocale, formatNumValue } from "../../../utils/baseUtils";
import { useWarehouseContext } from "../../../context/warehouseContext";
import { getPhysicalWarehouseSelectOptions } from "../../../utils/warehouseUtils";
import { Batch } from "../../../model/warehouse/batch.types";
import { Warehouse } from "../../../model/warehouse/customTypes.types";

const ExportWarehouseModal: React.FC<BaseActionModalProps> = ({ show, onHide }) => {
  const { batch } = useDataContext();
  const warehouseContext = useWarehouseContext();

  const [generating, setGenerating] = useState<boolean>(false);
  const [language, setLanguage] = useState<string>(i18n.language);
  const [warehouse, setWarehouse] = useState<SelectOption<Warehouse>>(
    getPhysicalWarehouseSelectOptions(warehouseContext)[0]
  );

  useEffect(() => {
    setWarehouse(getPhysicalWarehouseSelectOptions(warehouseContext)[0]);
  }, [show]);

  const warehouseSelectOptions = useMemo(() => {
    let options = [{ value: "allWarehouses", label: i18n.t("warehouse:allWarehouses") }];
    options = options.concat(getPhysicalWarehouseSelectOptions(warehouseContext));
    return options;
  }, [warehouseContext.configuration]);

  const properties = useMemo(() => {
    return {
      title: true,
      "batch.lot": true,
      amountValue: true,
      amountUnit: true,
      "batch.sender.name": true,
      "batch.unitPriceValue": true,
      "batch.unitPriceCurrency": true,
      "batch.expiry": true,
      totalPriceValue: true,
      totalPriceUnit: true
    };
  }, []);

  const propertyLabels: Record<string, string> = useMemo(() => {
    return {
      title: i18n.t("warehouse:rawMaterial"),
      "batch.lot": i18n.t("warehouse:lot"),
      amountValue: i18n.t("warehouse:amount"),
      amountUnit: i18n.t("warehouse:unit"),
      "batch.sender.name": i18n.t("warehouse:supplier"),
      "batch.unitPriceValue": i18n.t("warehouse:unitPrice"),
      "batch.unitPriceCurrency": i18n.t("warehouse:unit"),
      "batch.expiry": i18n.t("warehouse:bbd"),
      totalPriceValue: i18n.t("warehouse:totalPrice"),
      totalPriceUnit: i18n.t("warehouse:unit")
    };
  }, [language]);

  const handleHide = useCallback(async () => {
    await i18n.changeLanguage("de");
    onHide();
  }, [onHide, i18n]);

  const handleChangeWarehouse = useCallback((e: any) => setWarehouse(e), []);

  const handleChangeLanguage = useCallback(() => {
    setLanguage(i18n.language);
  }, [i18n]);

  const handleExportXLSX = useCallback(() => {
    setGenerating(true);

    let relevantBatches: Array<Batch>;
    const relevantBatchesAndAmounts: Array<{ batch: Batch; amountInWarehouse: NumValue }> = [];
    if (warehouse.value === "allWarehouses") {
      relevantBatches = batch;
      for (let i = 0; i < relevantBatches.length; i++) {
        const rb = relevantBatches[i];
        relevantBatchesAndAmounts.push({
          batch: rb,
          amountInWarehouse: rb.totalAmount
        });
      }
    } else {
      relevantBatches = batch.filter(b =>
        b.locations.some(l => l.location.warehouseArea._id.toString() === warehouse.value)
      );
      for (let i = 0; i < relevantBatches.length; i++) {
        const rb = relevantBatches[i];
        const amountInWarehouse = rb.locations.reduce(
          (sum, l) =>
            sum + (l.location.warehouseArea._id.toString() === warehouse.value ? l.amountAtLocation.value : 0),
          0
        );
        relevantBatchesAndAmounts.push({
          batch: rb,
          amountInWarehouse: { value: amountInWarehouse, unit: rb.totalAmount.unit }
        });
      }
    }
    const relevantBatchesAndAmountsInOrder = _.orderBy(
      relevantBatchesAndAmounts,
      rbaa => resolveTranslation(rbaa.batch.content.details.title),
      ["asc"]
    );

    const datasheet: Array<Array<string>> = [];
    const selectedProperties = Object.keys(properties);

    // Headers
    const headers = selectedProperties.map(header => propertyLabels[header]);
    datasheet.push(headers);

    // Rows
    relevantBatchesAndAmountsInOrder.forEach(rb => {
      const row: Array<string> = selectedProperties.map(property => {
        switch (property) {
          case "title":
            return `${resolveTranslation(rb.batch.content.details.title)} ${
              rb.batch.content.details.subtitle ? " - " + resolveTranslation(rb.batch.content.details.subtitle) : ""
            }`;
          case "amountValue":
            return rb.amountInWarehouse.value;
          case "amountUnit":
            return rb.amountInWarehouse.unit;
          case "batch.unitPriceValue":
            return rb.batch.unitPrice.value;
          case "batch.unitPriceCurrency":
            return `${rb.batch.unitPrice.currency} / ${rb.amountInWarehouse.unit}`;
          case "batch.expiry":
            return baseUtils.formatDate(rb.batch.expiry);
          case "totalPriceValue":
            return rb.amountInWarehouse.value * rb.batch.unitPrice.value;
          case "totalPriceUnit":
            return rb.batch.unitPrice.currency;
          default:
            const value = _.get(rb, property);
            return value || "";
        }
      });
      datasheet.push(row);
    });
    const dateString = baseUtils.formatDateForFileName(new Date(), true);
    const warehouseString =
      warehouse.value !== "allWarehouses"
        ? warehouse.data.warehouse?.shortName + "-" + warehouse.data.physicalWarehouse?.shortName
        : "allWarehouses";
    exportDatasheet(datasheet, `${i18n.t("warehouse:rawMaterials")}_${warehouseString}_${dateString}`);
    setGenerating(false);
    handleHide();
  }, [batch, warehouse, propertyLabels, properties, language]);

  return (
    <Modal show={show} onHide={generating ? undefined : handleHide} centered>
      <Modal.Header closeButton>
        <Modal.Title as={"h5"}>
          <b>{i18n.t("warehouse:exportXLSX")}</b>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="px-2">
          <h4 className="mb-5 font-weight-bold text-black">{i18n.t("warehouse:exportXLSX")}</h4>
        </div>
        <div className="p-2">
          <Select
            className="select-default"
            placeholder={i18n.t("warehouse:warehouse")}
            onChange={handleChangeWarehouse}
            value={warehouse}
            options={warehouseSelectOptions}
          />
          <span>{i18n.t("warehouse:warehouse")}</span>
        </div>
        <div className="p-2">
          <LanguageSelectionDropdown
            languageChangeCallback={handleChangeLanguage}
            labelPosition={"bottom"}
            label={i18n.t("common:languageSelect")}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          disabled={generating}
          className={"btn btn-secondary " + (generating ? "disabled" : "")}
          onClick={handleHide}
        >
          {i18n.t("common:close")}
        </button>
        <button
          type="button"
          className="btn btn-primary"
          disabled={generating}
          onClick={generating ? undefined : handleExportXLSX}
        >
          <i className="fa fa-download" />
          {generating ? (
            <div className="button-splash-spinner d-inline pr-3 pl-0 mx-0">
              <svg className="button-splash-spinner" viewBox="0 0 50 50">
                <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5" />
              </svg>
            </div>
          ) : (
            i18n.t("warehouse:exportXLSX")
          )}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default ExportWarehouseModal;
