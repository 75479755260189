import _ from "lodash";
import React, { PureComponent } from "react";
import { toAbsoluteUrl } from "../../../_metronic";
import { CustomOrder } from "../CustomTypes";
import InvoiceModal from "../modals/InvoiceModal";
import NotAvailable from "../../common/NotAvailable";
import config from "../../../config/config.json";
import { DataContext } from "../../../context/dataContext";
import accessUtils, { ACTIONS } from "../../../utils/accessUtils";
import invoiceUtils, { I_CANCELED, I_OPEN, I_PAID } from "../../../utils/invoiceUtils";
import orderUtils from "../../../utils/orderUtils";
import baseUtils from "../../../utils/baseUtils";
import { Cancelation, Invoice } from "../../../model/orders.types";
import PayInvoiceModal from "../modals/PayInvoiceModal";

interface OrderFinanceProps {
  order: CustomOrder;
  context: React.ContextType<typeof DataContext>;
}

interface OrderFinanceState {
  invoices: Array<InvoiceData>;
  showPayInvoiceModal: boolean;
  invoiceToShow?: Invoice;
}

interface InvoiceData extends Invoice {
  type: string;
  onInvoicePaid: () => void;
}

class OrderFinance extends PureComponent<OrderFinanceProps, OrderFinanceState> {
  constructor(props: OrderFinanceProps) {
    super(props);
    this.state = { invoices: this.getInvoices(props), showPayInvoiceModal: false };
  }

  componentDidUpdate(prevProps: Readonly<OrderFinanceProps>, prevState: Readonly<OrderFinanceState>, snapshot?: any) {
    if (!_.isEqual(prevProps.order, this.props.order)) this.setState({ invoices: this.getInvoices(this.props) });
  }

  /**
   * Get all invoices
   * @param props order finance props
   * @returns {Array<InvoiceData>} list of invoices
   */
  getInvoices = (props: OrderFinanceProps) => {
    const { order } = props;

    let invoices: Array<InvoiceData> = [];
    if (order.invoices) {
      invoices = order.invoices.map(i => {
        return {
          ...i,
          type: invoiceUtils.resolveInvoiceType(i),
          onInvoicePaid: () => this.handleClickPayInvoice(i)
        };
      });
    }
    invoices = invoices.sort((i1, i2) => +i1.invoiceNumber - +i2.invoiceNumber);
    return invoices as Array<InvoiceData>;
  };

  handleClickPayInvoice = (invoiceToShow: Invoice) => {
    this.setState({ showPayInvoiceModal: true, invoiceToShow });
  };

  handleHidePayInvoice = () => {
    this.setState({ showPayInvoiceModal: false, invoiceToShow: undefined });
  };

  render() {
    const { context, order } = this.props;
    const { invoices, invoiceToShow, showPayInvoiceModal } = this.state;
    if (orderUtils.isOrderState(order.state))
      return (
        <>
          <PayInvoiceModal
            order={order}
            invoice={invoiceToShow}
            onHide={this.handleHidePayInvoice}
            show={showPayInvoiceModal}
          />
          <div className="row">
            <div
              className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--loaded table-responsive"
              style={{ width: "100%" }}
            >
              <table className="kt-datatable__table" style={{ overflow: "visible" }}>
                <thead className="kt-datatable__head">
                  <tr className="kt-datatable__row" style={{ left: 0 }}>
                    <th className="kt-datatable__cell">
                      <span style={{ width: 100 }}>Description</span>
                    </th>
                    <th className="kt-datatable__cell">
                      <span style={{ width: 100 }}>Amount</span>
                    </th>
                    <th className="kt-datatable__cell">
                      <span style={{ width: 115 }}>Brutto</span>
                    </th>
                    <th className="kt-datatable__cell">
                      <span style={{ width: 60 }}>File</span>
                    </th>
                    <th className="kt-datatable__cell">
                      <span style={{ width: 80 }}>Stage</span>
                    </th>
                    <th className="kt-datatable__cell">
                      <span style={{ width: 75 }}>Due</span>
                    </th>
                    <th className="kt-datatable__cell">
                      <span style={{ width: 175 }}>Progress</span>
                    </th>
                    <th className="kt-datatable__cell">
                      <span style={{ width: 140 }}>Action</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="kt-datatable__body">
                  {invoices.map(invoice => (
                    <InvoiceItem key={invoice._id.toString()} invoice={invoice} order={order} context={context} />
                  ))}
                </tbody>
              </table>
              <div className="mb-3 text-center">
                <div className="my-5 ml-5 pb-5">
                  <InvoiceModal {...this.props} extendedTitle={true} />
                </div>
              </div>
            </div>
          </div>
        </>
      );
    else return <NotAvailable />;
  }
}

interface InvoiceItemProps {
  invoice: InvoiceData;
  order: CustomOrder;
  context: React.ContextType<typeof DataContext>;
}

interface InvoiceItemState {}

class InvoiceItem extends PureComponent<InvoiceItemProps, InvoiceItemState> {
  getRemainingDays = (date: Date, current: Date) => {
    const diffTime = current.valueOf() - date.valueOf();
    const diffDays = diffTime / (1000 * 60 * 60 * 24);
    return Math.round(diffDays);
  };

  daysSinceCancelation = (c: Cancelation) => {
    const daysSince = this.getRemainingDays(new Date(), c.date);
    return daysSince < 0 ? "since " + Math.abs(daysSince) + (daysSince === -1 ? " day" : " days") : "today";
  };

  renderProgressBar = (remainingDays: number) => {
    const { invoice } = this.props;
    let bgClass = "bg-success";
    if ([I_PAID, I_CANCELED].includes(invoice.state)) bgClass = "bg-success";
    else if (remainingDays <= 0 && remainingDays > -7) bgClass = "bg-warning";
    else if (remainingDays <= -7) bgClass = "bg-danger";
    const daysSince = this.getRemainingDays(new Date(), invoice.invoiceDate);
    const dueDate = orderUtils.getInvoiceDue(invoice.dueIn, invoice.invoiceDate);
    const daysDue = this.getRemainingDays(invoice.invoiceDate, dueDate);
    const width =
      invoice.state !== I_PAID && remainingDays > 0 ? `${(daysDue === 0 ? 100 : daysSince / daysDue) * 100}%` : "100%";
    return (
      <div className="progress">
        <div className={"progress-bar " + bgClass} style={{ width: width }} />
      </div>
    );
  };

  render() {
    const { context, invoice, order } = this.props;
    let stateClass = "kt-badge--success";
    switch (invoice.state) {
      case I_OPEN:
        stateClass = "kt-badge--success";
        break;
      case I_CANCELED:
        stateClass = "kt-badge--light";
        break;
    }
    const dueDate = orderUtils.getInvoiceDue(invoice.dueIn, invoice.invoiceDate);
    const remainingDays = this.getRemainingDays(new Date(), dueDate);
    const canPay = accessUtils.canPerformAction(ACTIONS.INVOICEPAY);

    return (
      <>
        <tr className="kt-datatable__row">
          <td className="kt-datatable__cell">
            <span style={{ width: 100 }}>
              <div className="kt-user-card-v2">
                <div className="kt-user-card-v2__details">
                  <span className="kt-user-card-v2__name">{"RE-" + invoice.invoiceNumber}</span>
                </div>
              </div>
            </span>
          </td>
          <td className="kt-datatable__cell">
            <span style={{ width: 100 }}>
              <div className="kt-user-card-v2">
                <div className="kt-user-card-v2__details">
                  <span className="kt-user-card-v2__name">{baseUtils.formatEuro(invoice.total)}</span>
                  <span className="kt-user-card-v2__email">{_.upperFirst(invoice.type)}</span>
                </div>
              </div>
            </span>
          </td>
          <td className="kt-datatable__cell">
            <span style={{ width: 115 }}>
              <div className="kt-user-card-v2">
                <div className="kt-user-card-v2__details">
                  <span className="kt-user-card-v2__name">
                    {invoice.totalGross ? baseUtils.formatEuro(invoice.totalGross) : "-"}
                  </span>
                  <span className="kt-user-card-v2__email">
                    Taxes: {invoice.totalGross ? baseUtils.formatEuro(invoice.totalGross - invoice.total) : "-"}
                  </span>
                </div>
              </div>
            </span>
          </td>
          <td className="kt-datatable__cell">
            <span style={{ width: 60 }}>
              <a
                href={(process.env.REACT_APP_MEDIAHUB_BASE || "") + invoice.path}
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="kt-widget4__pic kt-widget4__pic--icon pointer">
                  <img src={toAbsoluteUrl("/media/icons/pdf_icon.png")} alt="Invoice PDF" style={{ width: "30px" }} />
                </div>
              </a>
            </span>
          </td>
          <td className="kt-datatable__cell">
            <span style={{ width: 80 }}>
              <span className={"kt-badge kt-badge--inline kt-badge--pill " + stateClass}>
                {_.upperFirst(invoice.state)}
              </span>
            </span>
          </td>
          <td className="kt-datatable__cell">
            <span style={{ width: 75 }}>
              <div className="kt-user-card-v2">
                <div className="kt-user-card-v2__details">
                  <span className="kt-user-card-v2__name">
                    {invoice.dueIn === -1 ? "In advance" : baseUtils.formatDate(dueDate)}
                  </span>
                  {dueDate !== invoice.invoiceDate && (
                    <span className="kt-user-card-v2__email">
                      {remainingDays > 0
                        ? "in " + remainingDays + (remainingDays === 1 ? " day" : " days")
                        : remainingDays < 0
                        ? "since " + Math.abs(remainingDays) + (remainingDays === -1 ? " day" : " days")
                        : "today"}
                    </span>
                  )}
                </div>
              </div>
            </span>
          </td>
          <td className="kt-datatable__cell">
            <span style={{ width: 175 }}>{this.renderProgressBar(remainingDays)}</span>
          </td>
          <td className="kt-datatable__cell">
            <span style={{ width: 140 }}>
              {invoice.state === I_PAID ? (
                <>
                  <span className="text-success kt-font-bolder">
                    <i className="fa fa-check mr-1" />
                    Paid
                  </span>
                  <InvoiceModal
                    order={order}
                    context={context}
                    type="cancelation"
                    invoice={invoice}
                    disabled={!invoice || !!invoice.cancelation}
                  />
                </>
              ) : (
                <>
                  <button
                    className={
                      "btn btn-success btn-sm" + (!canPay || !invoice || !!invoice.cancelation ? " disabled" : "")
                    }
                    onClick={canPay ? invoice.onInvoicePaid : undefined}
                    disabled={!canPay || !invoice || !!invoice.cancelation}
                    style={{ borderRight: 0, borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                  >
                    Pay invoice
                  </button>
                  <button
                    type="button"
                    className={
                      "btn btn-sm btn-success dropdown-toggle dropdown-toggle-split" +
                      (!canPay || !invoice || !!invoice.cancelation ? " disabled" : "")
                    }
                    data-toggle="dropdown"
                    disabled={!canPay || !invoice || !!invoice.cancelation}
                    style={{ marginLeft: 0, borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                  />
                  <div className="dropdown-menu dropdown-menu-right py-2 pl-2">
                    <InvoiceModal
                      order={order}
                      context={context}
                      type={"cancelation"}
                      invoice={invoice}
                      disabled={!invoice || !!invoice.cancelation}
                    />
                    <InvoiceModal
                      order={order}
                      context={context}
                      type={"reminder"}
                      invoice={invoice}
                      disabled={
                        !invoice ||
                        !!invoice.cancelation ||
                        invoice.dueIn === -1 ||
                        invoice.reminder.some(r => r.level === 2)
                      }
                    />
                  </div>
                </>
              )}
            </span>
          </td>
        </tr>
        {invoice &&
          invoice.reminder.map(r => {
            const daysSince = this.getRemainingDays(r.date, new Date());
            return (
              <tr className="kt-datatable__row" key={r._id.toString()}>
                <td className="kt-datatable__cell">
                  <span style={{ width: 100 }}>
                    <div className="kt-user-card-v2">
                      <div className="kt-user-card-v2__details">
                        <span className="kt-user-card-v2__name">
                          {r.level === 0 ? "Reminder" : r.level === 1 ? "1st Dunning" : "2nd Dunning"}
                        </span>
                      </div>
                    </div>
                  </span>
                </td>
                <td className="kt-datatable__cell">
                  <span style={{ width: 100 }}>
                    <div className="kt-user-card-v2">
                      <div className="kt-user-card-v2__details">
                        <span className="kt-user-card-v2__name">Level: {r.level}</span>
                      </div>
                    </div>
                  </span>
                </td>
                <td className="kt-datatable__cell">
                  <span style={{ width: 115 }}>
                    <div className="kt-user-card-v2">
                      <div className="kt-user-card-v2__details">
                        <span className="kt-user-card-v2__name">
                          {r.position ? baseUtils.formatEuro(r.position.total) : ""}
                        </span>
                        <span className="kt-user-card-v2__email">Interest: {r.interest}%</span>
                      </div>
                    </div>
                  </span>
                </td>
                <td className="kt-datatable__cell">
                  <span style={{ width: 60 }}>
                    <a
                      href={(process.env.REACT_APP_MEDIAHUB_BASE || "") + r.path}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div className="kt-widget4__pic kt-widget4__pic--icon pointer">
                        <img
                          src={toAbsoluteUrl("/media/icons/pdf_icon.png")}
                          alt="Invoice PDF"
                          style={{ width: "30px" }}
                        />
                      </div>
                    </a>
                  </span>
                </td>
                <td className="kt-datatable__cell">
                  <span style={{ width: 80 }} />
                </td>
                <td className="kt-datatable__cell">
                  <span style={{ width: 175 }}>
                    <div className="kt-user-card-v2">
                      <div className="kt-user-card-v2__details">
                        <span className="kt-user-card-v2__name">Date: {baseUtils.formatDate(r.date)}</span>
                        <span className="kt-user-card-v2__email">
                          {daysSince < 0
                            ? "since " + Math.abs(remainingDays) + (remainingDays === -1 ? " day" : " days")
                            : "today"}
                        </span>
                      </div>
                    </div>
                  </span>
                </td>
                <td className="kt-datatable__cell">
                  <span style={{ width: 75 }} />
                </td>
                <td className="kt-datatable__cell">
                  <span style={{ width: 140 }} />
                </td>
              </tr>
            );
          })}
        {invoice && invoice.cancelation && (
          <tr className="kt-datatable__row">
            <td className="kt-datatable__cell">
              <span style={{ width: 100 }}>
                <div className="kt-user-card-v2">
                  <div className="kt-user-card-v2__details">
                    <span className="kt-user-card-v2__name">Cancelation</span>
                  </div>
                </div>
              </span>
            </td>
            <td className="kt-datatable__cell">
              <span style={{ width: 100 }} />
            </td>
            <td className="kt-datatable__cell">
              <span style={{ width: 115 }}>
                <div className="kt-user-card-v2">
                  <div className="kt-user-card-v2__details">
                    <span className="kt-user-card-v2__name">
                      {baseUtils.formatEuro(
                        -invoice.totalGross -
                          invoice.reminder.reduce((sum, r) => (r.position ? r.position.total + sum : sum), 0)
                      )}
                    </span>
                    <span className="kt-user-card-v2__email">
                      Taxes: {baseUtils.formatEuro(invoice.total - invoice.totalGross)}
                    </span>
                  </div>
                </div>
              </span>
            </td>
            <td className="kt-datatable__cell">
              <span style={{ width: 60 }}>
                <a
                  href={(process.env.REACT_APP_MEDIAHUB_BASE || "") + invoice.cancelation.path}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="kt-widget4__pic kt-widget4__pic--icon pointer">
                    <img src={toAbsoluteUrl("/media/icons/pdf_icon.png")} alt="Invoice PDF" style={{ width: "30px" }} />
                  </div>
                </a>
              </span>
            </td>
            <td className="kt-datatable__cell">
              <span style={{ width: 80 }} />
            </td>
            <td className="kt-datatable__cell">
              <span style={{ width: 175 }}>
                <div className="kt-user-card-v2">
                  <div className="kt-user-card-v2__details">
                    <span className="kt-user-card-v2__name">
                      Date: {baseUtils.formatDate(invoice.cancelation.date)}
                    </span>
                    <span className="kt-user-card-v2__email">{this.daysSinceCancelation(invoice.cancelation)}</span>
                  </div>
                </div>
              </span>
            </td>
            <td className="kt-datatable__cell">
              <span style={{ width: 75 }} />
            </td>
            <td className="kt-datatable__cell">
              <span style={{ width: 140 }} />
            </td>
          </tr>
        )}
        {invoice &&
          invoice.payments.map(p => {
            const daysSince = this.getRemainingDays(p.date, new Date());
            return (
              <tr className="kt-datatable__row" key={p._id.toString()}>
                <td className="kt-datatable__cell">
                  <span style={{ width: 100 }}>
                    <div className="kt-user-card-v2">
                      <div className="kt-user-card-v2__details">
                        <span className="kt-user-card-v2__name">Payment</span>
                      </div>
                    </div>
                  </span>
                </td>
                <td className="kt-datatable__cell">
                  <span style={{ width: 100 }}>
                    <div className="kt-user-card-v2">
                      <div className="kt-user-card-v2__details">
                        <span className="kt-user-card-v2__name">
                          {p.amount !== invoice.totalGross ? "Partial" : "Fully"}
                        </span>
                      </div>
                    </div>
                  </span>
                </td>
                <td className="kt-datatable__cell">
                  <span style={{ width: 115 }}>
                    <div className="kt-user-card-v2">
                      <div className="kt-user-card-v2__details">
                        <span className="kt-user-card-v2__name">{baseUtils.formatEuro(p.amount)}</span>
                      </div>
                    </div>
                  </span>
                </td>
                <td className="kt-datatable__cell">
                  <span style={{ width: 60 }}>
                    {p.path && (
                      <a
                        href={(process.env.REACT_APP_MEDIAHUB_BASE || "") + p.path}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <div className="kt-widget4__pic kt-widget4__pic--icon pointer">
                          <img
                            src={toAbsoluteUrl("/media/icons/pdf_icon.png")}
                            alt="Invoice PDF"
                            style={{ width: "30px" }}
                          />
                        </div>
                      </a>
                    )}
                  </span>
                </td>
                <td className="kt-datatable__cell">
                  <span style={{ width: 80 }} />
                </td>
                <td className="kt-datatable__cell">
                  <span style={{ width: 175 }}>
                    <div className="kt-user-card-v2">
                      <div className="kt-user-card-v2__details">
                        <span className="kt-user-card-v2__name">Date: {baseUtils.formatDate(p.date)}</span>
                        <span className="kt-user-card-v2__email">
                          {daysSince < 0
                            ? Math.abs(remainingDays) + (remainingDays === -1 ? " day" : " days") + " ago"
                            : "today"}
                        </span>
                      </div>
                    </div>
                  </span>
                </td>
                <td className="kt-datatable__cell">
                  <span style={{ width: 75 }} />
                </td>
                <td className="kt-datatable__cell">
                  <span style={{ width: 140 }} />
                </td>
              </tr>
            );
          })}
      </>
    );
  }
}

export default OrderFinance;
