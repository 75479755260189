import _ from "lodash";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { Typeahead } from "react-bootstrap-typeahead";
import i18n from "../../../../translations/i18n";
import { useDataContext } from "../../../../context/dataContext";
import { useWarehouseContext } from "../../../../context/warehouseContext";
import dbService, { BATCH } from "../../../../services/dbService";
import { BaseActionModalProps, Content } from "../../../../model/warehouse/common.types";
import { OrdersDocument } from "../../../../model/orders.types";
import { SelectOption } from "../../../../model/common.types";
import { Batch, BatchLocation } from "../../../../model/warehouse/batch.types";
import { WarehouseTypes } from "../../../../model/configuration/warehouseConfiguration.types";
import { BookOutBatch, ExtendedBatchLocation, Warehouse } from "../../../../model/warehouse/customTypes.types";
import toastUtils from "../../../../utils/toastUtils";
import { resolveTranslation } from "../../../../utils/translationUtils";
import baseUtils, { formatNumValue } from "../../../../utils/baseUtils";
import { isSelectedBatchEntries, isSelectedCommodityEntries } from "../../../../utils/warehouseActionUtils";
import orderUtils, { ARCHIVE, CREATEINVOICE, DECLINED, FULFILLMENT } from "../../../../utils/orderUtils";
import {
  getOrdersMatchingBatch,
  getWarehouseOption,
  SelectedCommodityEntryType
} from "../../../../utils/warehouseUtils";
import ErrorOverlayButton from "../../../common/ErrorOverlayButton";
import BookOutSpecificSelection from "./BookOutSpecificSelection";
import { T_CUSTOM, T_SERVICE, T_SOFTGEL } from "../../../order/OrderHelper";

enum BookOutModalStep {
  GENERAL_SELECTION,
  SPECIFIC_SELECTION,
  ORDER_SELECTION
}

interface BookOutModalState {
  step: BookOutModalStep;
  selectedArticle: SelectOption<Content> | null;
  selectedBatch: SelectOption<Batch> | null;
  selectedWarehouse: SelectOption<Warehouse> | null;
  bookOutBatch: BookOutBatch | null;
  selectedOrder: SelectOption<OrdersDocument> | null;
}

const getBatchOption = (batch: Batch) => {
  return {
    value: batch._id.toString(),
    label: `${batch.sender.name} - ${batch.lot} - ${formatNumValue(batch.totalAmount, 2)}`,
    data: batch
  };
};

const getArticleOption = (batch: Batch) => {
  const title = resolveTranslation(batch.content.details.title);
  const subtitle = resolveTranslation(batch.content.details.subtitle);
  return {
    value: batch.content.details._id.toString(),
    label: baseUtils.truncateString(title + (subtitle !== "" ? ` (${subtitle})` : ""), 55),
    data: batch.content
  };
};

const getOrderOption = (order: OrdersDocument) => {
  return {
    value: order._id.toString(),
    label: baseUtils.truncateString(`AT-${order.identifier} ${order.title} ${order.subtitle}`, 55),
    data: order
  };
};

const getBookOutBatch = (batch: Batch, batchLocations: Array<BatchLocation>): BookOutBatch => {
  const extendedBatchLocations: Array<ExtendedBatchLocation> = batchLocations.map(l => {
    if (l.location.warehouseArea.type === WarehouseTypes.REMOTE) {
      return {
        ...l,
        packagingUnits: l.packagingUnits.map(pU => ({
          ...pU,
          amountToKeep: "",
          remainingQuantity: ""
        })),
        reservations: null,
        remoteWarehouseData: {
          amountToKeep: l.amountAtLocation.value.toString()
        }
      };
    } else {
      return {
        ...l,
        packagingUnits: l.packagingUnits.map(pU => ({
          ...pU,
          amountToKeep: (pU.amountPerPu.value * (pU.quantity || 0)).toString(),
          remainingQuantity: (pU.quantity || 0).toString()
        })),
        reservations: null,
        remoteWarehouseData: null
      };
    }
  });
  return { ...batch, locations: extendedBatchLocations };
};

const getDefaultState = (): BookOutModalState => {
  return {
    step: BookOutModalStep.GENERAL_SELECTION,
    selectedArticle: null,
    selectedBatch: null,
    selectedWarehouse: null,
    bookOutBatch: null,
    selectedOrder: null
  };
};

const BookOutModal: React.FC<BaseActionModalProps> = ({ show, onHide }) => {
  const articleRef = useRef<any>(null);
  const batchRef = useRef<any>(null);

  const dataContext = useDataContext();
  const warehouseContext = useWarehouseContext();
  const { batch, orders } = dataContext;
  const { selectedEntries, configuration } = warehouseContext;

  const [saving, setSaving] = useState<boolean>(false);
  const [state, setState] = useState<BookOutModalState>(getDefaultState());
  const { step, selectedArticle, selectedBatch, selectedWarehouse, bookOutBatch, selectedOrder } = state;

  useEffect(() => {
    if (!show) return;
    let updatedState = getDefaultState();

    if (selectedEntries && selectedEntries.length > 0 && isSelectedBatchEntries(selectedEntries)) {
      // Batch entry was selected, take article and batch from it
      const selectedBatch = batch.find(b => b._id.toString() === selectedEntries[0].batchId);
      if (selectedBatch) {
        updatedState.selectedBatch = getBatchOption(selectedBatch);
        updatedState.selectedArticle = getArticleOption(selectedBatch);
      }
    } else if (selectedEntries && selectedEntries.length > 0 && isSelectedCommodityEntries(selectedEntries)) {
      // Commodity entry was selected, take article from it
      const batchWithSelectedArticle = batch.find(
        b => b.content.details._id.toString() === selectedEntries[0].commodityId
      );
      const selectedBatches = selectedEntries.filter(s => s.type === SelectedCommodityEntryType.BATCH_LOCATION);
      if (batchWithSelectedArticle) {
        updatedState.selectedArticle = getArticleOption(batchWithSelectedArticle);
      }
      // If all batches listed under the commodity have the same id, preselect batch as well
      if (selectedBatches.length > 0) {
        const uniqueBatches = Array.from(new Set(selectedBatches.map(sB => sB.batchId)));
        if (uniqueBatches.length === 1 && uniqueBatches[0]) {
          const selectedBatch = batch.find(b => b._id.toString() === uniqueBatches[0]);
          if (selectedBatch) updatedState.selectedBatch = getBatchOption(selectedBatch);
        }
      }
    }
    // Skip to second step if both article and batch could be loaded from selection
    if (updatedState.selectedArticle && updatedState.selectedBatch) {
      updatedState.step = BookOutModalStep.SPECIFIC_SELECTION;
    }
    // Preselect warehouse from first location with physical warehouse if batch was found
    if (updatedState.selectedBatch && configuration) {
      const firstBatchLocation = updatedState.selectedBatch.data.locations.find(l => l.location.warehouseArea);
      const logicalWarehouse = configuration.values.warehouseStructure.find(
        lWH => lWH._id.toString() === firstBatchLocation?.location.warehouseSnapshot._id.toString()
      );
      const physicalWarehouse = logicalWarehouse?.physicalWarehouses.find(
        pWH => pWH._id.toString() === firstBatchLocation?.location.warehouseArea._id.toString()
      );
      if (logicalWarehouse && physicalWarehouse)
        updatedState.selectedWarehouse = getWarehouseOption(logicalWarehouse, physicalWarehouse);
    }
    // Prefill bookOutInfo with necessary data for display
    if (updatedState.selectedWarehouse && updatedState.selectedBatch) {
      const relevantLocations = updatedState.selectedBatch.data.locations.filter(
        l => l.location.warehouseArea._id.toString() === updatedState.selectedWarehouse?.value
      );
      if (relevantLocations.length > 0) {
        updatedState.bookOutBatch = getBookOutBatch(updatedState.selectedBatch.data, relevantLocations);
      }
    }
    setState(updatedState);
  }, [show, selectedEntries, batch, configuration]);

  const [errors, warnings] = useMemo(() => {
    const errors: Array<string> = [];
    const warnings: Array<string> = [];
    if (step === BookOutModalStep.GENERAL_SELECTION) {
      if (!selectedBatch || !selectedArticle) errors.push(i18n.t("warehouse:bookOutBatchOrArticleMissingError"));
    } else if (step === BookOutModalStep.SPECIFIC_SELECTION) {
      if (!selectedWarehouse) errors.push(i18n.t("warehouse:bookOutWarehouseMissingError"));
      if (!bookOutBatch) {
        errors.push(i18n.t("warehouse:bookOutDataLoadError"));
      } else {
        const amountWithoutPU = [];
        const emptyPU = [];
        const pUAmountTooHigh = [];
        const pURemainingAmountTooHigh = [];
        let positionCounter = 1;
        for (let i = 0; i < bookOutBatch.locations.length; i++) {
          const currentLocation = bookOutBatch.locations[i];
          if (currentLocation.location.warehouseArea.type === WarehouseTypes.REMOTE) {
            if (
              currentLocation.remoteWarehouseData !== null &&
              +currentLocation.remoteWarehouseData.amountToKeep > currentLocation.amountAtLocation.value
            )
              errors.push(i18n.t("warehouse:bookOutAmountTooHighError"));
          } else {
            for (let j = 0; j < currentLocation.packagingUnits.length; j++) {
              const currentPU = currentLocation.packagingUnits[j];
              if (+currentPU.amountToKeep > 0 && +currentPU.remainingQuantity === 0) {
                amountWithoutPU.push(`Position ${positionCounter}`);
              }
              if (+currentPU.amountToKeep === 0 && +currentPU.remainingQuantity > 0) {
                emptyPU.push(`Position ${positionCounter}`);
              }
              if (+currentPU.amountToKeep > (currentPU.quantity || 0) * currentPU.amountPerPu.value) {
                pUAmountTooHigh.push(`Position ${positionCounter}`);
              }
              if (+currentPU.amountToKeep > +currentPU.remainingQuantity * currentPU.amountPerPu.value)
                pURemainingAmountTooHigh.push(`Position ${positionCounter}`);
              positionCounter++;
            }
          }
        }
        if (amountWithoutPU.length > 0) {
          errors.push(`${i18n.t("warehouse:bookOutNoPackagingUnitError")} (${amountWithoutPU.join(", ")})`);
        }
        if (emptyPU.length > 0) {
          errors.push(`${i18n.t("warehouse:bookOutEmptyPackagingUnitError")} (${emptyPU.join(", ")})`);
        }
        if (pUAmountTooHigh.length > 0) {
          errors.push(`${i18n.t("warehouse:bookOutAmountTooHighError")} (${pUAmountTooHigh.join(", ")})`);
        }
        if (pURemainingAmountTooHigh.length > 0) {
          errors.push(`${i18n.t("warehouse:bookOutAmountMismatchError")} (${pURemainingAmountTooHigh.join(", ")})`);
        }
        if (
          bookOutBatch.locations.every(l => {
            return l.location.warehouseArea.type === WarehouseTypes.REMOTE
              ? l.remoteWarehouseData !== null && +l.remoteWarehouseData.amountToKeep === l.amountAtLocation.value
              : l.packagingUnits.every(
                  pU => pU.quantity === +pU.remainingQuantity && +pU.amountToKeep === pU.amountPerPu.value * pU.quantity
                );
          })
        ) {
          errors.push(i18n.t("warehouse:bookOutNoAmountChangeError"));
        }
      }
    } else if (step === BookOutModalStep.ORDER_SELECTION) {
      if (!selectedOrder) errors.push(i18n.t("warehouse:bookOutNoOrderSelected"));
    }
    return [errors, warnings];
  }, [state]);

  const articleSelectOptions = useMemo(() => {
    let options: Array<SelectOption<Content>> = [];
    batch.forEach(b => {
      if (!options.some(o => o.value === b.content.details._id.toString()) && b.totalAmount.value > 0) {
        options.push(getArticleOption(b));
      }
    });
    if (selectedBatch) {
      const matchingArticle = options.find(
        o => o.data.details._id.toString() === selectedBatch.data.content.details._id.toString()
      );
      options = matchingArticle ? [matchingArticle] : [];
    }
    options.sort((a, b) => a.label.localeCompare(b.label));
    return options;
  }, [batch, selectedBatch]);

  const batchSelectOptions = useMemo(() => {
    let options: Array<SelectOption<Batch>> = [];
    batch.forEach(b => {
      if (b.totalAmount.value > 0) options.push(getBatchOption(b));
    });
    if (selectedArticle) {
      options = options.filter(o => o.data.content.details._id.toString() === selectedArticle.value);
    }
    options.sort((a, b) => a.label.localeCompare(b.label));
    return options;
  }, [batch, selectedArticle]);

  const warehouseSelectOptions = useMemo(() => {
    const options: Array<SelectOption<Warehouse>> = [];
    if (!selectedBatch || !configuration) return options;
    // Only show warehouses which match locations from selected batch
    const batchPhysicalWarehouses = selectedBatch.data.locations.map(l => l.location.warehouseArea._id.toString());
    for (let logicalWarehouse of configuration.values.warehouseStructure) {
      for (let physicalWarehouse of logicalWarehouse.physicalWarehouses) {
        if (batchPhysicalWarehouses.includes(physicalWarehouse._id.toString()))
          options.push(getWarehouseOption(logicalWarehouse, physicalWarehouse));
      }
    }
    return options;
  }, [configuration, selectedBatch]);

  const orderSelectOptions = useMemo(() => {
    const options: Array<SelectOption<OrdersDocument>> = [];
    if (!bookOutBatch) return options;
    const filteredOrders = orders.filter(
      o =>
        orderUtils.isOrderState(o.state) &&
        ![ARCHIVE, DECLINED, FULFILLMENT, CREATEINVOICE].includes(o.state) &&
        ![T_CUSTOM, T_SERVICE, T_SOFTGEL].includes(o.settings.type)
    );
    const matchingOrders = getOrdersMatchingBatch(bookOutBatch, filteredOrders);
    matchingOrders.forEach(o => options.push(getOrderOption(o)));
    return options;
  }, [bookOutBatch, orders]);

  const handleNext = useCallback(() => {
    setState(prevState => {
      const nextStep = prevState.step + 1;
      let { selectedWarehouse, bookOutBatch, selectedOrder } = prevState;

      if (nextStep === BookOutModalStep.SPECIFIC_SELECTION) {
        if (warehouseSelectOptions && warehouseSelectOptions.length > 0) selectedWarehouse = warehouseSelectOptions[0];
        if (prevState.selectedBatch) {
          const relevantLocations = prevState.selectedBatch.data.locations.filter(
            l => l.location.warehouseArea._id.toString() === selectedWarehouse?.value
          );
          if (relevantLocations.length > 0)
            bookOutBatch = getBookOutBatch(prevState.selectedBatch.data, relevantLocations);
        }
      }
      if (nextStep === BookOutModalStep.ORDER_SELECTION && orderSelectOptions.length === 0)
        selectedOrder = orderSelectOptions[0];
      return {
        ...prevState,
        step: nextStep,
        selectedWarehouse,
        bookOutBatch,
        selectedOrder
      };
    });
  }, [warehouseSelectOptions, orderSelectOptions]);

  const handleBack = useCallback(() => setState(prevState => ({ ...prevState, step: prevState.step - 1 })), []);

  const handleClose = useCallback(() => {
    if (saving) return;
    onHide();
  }, [onHide, saving]);

  const handleChangeArticle = useCallback(
    (selected: any) => {
      setState(prevState => {
        const updatedSelectedArticle = selected && selected.length > 0 ? selected[0] : null;
        if (updatedSelectedArticle && batchRef.current && !prevState.selectedBatch) {
          batchRef.current.focus();
        }
        return {
          ...prevState,
          selectedArticle: updatedSelectedArticle
        };
      });
    },
    [batchRef]
  );

  const handleChangeBatch = useCallback(
    (selected: any) => {
      setState(prevState => {
        const updatedSelectedBatch = selected && selected.length > 0 ? selected[0] : null;
        if (updatedSelectedBatch && articleRef.current && !prevState.selectedArticle) {
          articleRef.current.focus();
        }
        return {
          ...prevState,
          selectedBatch: updatedSelectedBatch
        };
      });
    },
    [articleRef]
  );

  const handleChangeWarehouse = useCallback((selected: any) => {
    setState(prevState => {
      const relevantLocations = prevState.selectedBatch?.data.locations.filter(
        l => l.location.warehouseArea._id.toString() === selected?.value
      );
      const updatedBookOutBatchInfo =
        prevState.selectedBatch && relevantLocations && relevantLocations.length > 0
          ? getBookOutBatch(prevState.selectedBatch.data, relevantLocations)
          : null;
      return { ...prevState, selectedWarehouse: selected, bookOutBatch: updatedBookOutBatchInfo };
    });
  }, []);

  const handleChangeData = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, locationId: string, packagingUnitId: string | null) => {
      if (!bookOutBatch) return;
      const { name, value } = e.target;
      const batchLocationsEdit = _.cloneDeep(bookOutBatch.locations);
      const batchLocationEdit = batchLocationsEdit.find(l => l._id.toString() === locationId);
      if (!batchLocationEdit) return;
      // Amount set for remote warehouse card
      if (!packagingUnitId && name === "amountToKeep") {
        if (!batchLocationEdit.remoteWarehouseData) {
          batchLocationEdit.remoteWarehouseData = { amountToKeep: "" };
        }
        batchLocationEdit.remoteWarehouseData.amountToKeep = value;
      } else if (packagingUnitId) {
        const packagingUnitEdit = batchLocationEdit.packagingUnits.find(pU => pU._id.toString() === packagingUnitId);
        if (packagingUnitEdit) {
          _.set(packagingUnitEdit, name, value);
        }
      }
      const updatedBookOutBatch = { ...bookOutBatch, locations: batchLocationsEdit };
      setState(prevState => ({
        ...prevState,
        bookOutBatch: updatedBookOutBatch
      }));
    },
    [bookOutBatch]
  );

  const handleChangeOrder = useCallback((selected: any) => {
    setState(prevState => ({ ...prevState, selectedOrder: selected && selected.length > 0 ? selected[0] : null }));
  }, []);

  const handleBookOut = useCallback(async () => {
    if (!bookOutBatch) return;
    try {
      setSaving(true);
      const modifiedLocations: Array<ExtendedBatchLocation> = [];
      for (let location of bookOutBatch.locations) {
        if (
          location.remoteWarehouseData !== null &&
          +location.remoteWarehouseData.amountToKeep !== location.amountAtLocation.value
        ) {
          modifiedLocations.push(location);
        } else {
          const modifiedPackagingUnits = location.packagingUnits.filter(
            pU =>
              pU.quantity !== +pU.remainingQuantity || +pU.amountToKeep !== pU.amountPerPu.value * (pU.quantity || 0)
          );
          if (modifiedPackagingUnits.length > 0)
            modifiedLocations.push({ ...location, packagingUnits: modifiedPackagingUnits });
        }
      }
      if (modifiedLocations.length > 0) {
        // booking out for orders not yet included, since workflow needs to be adjusted to use warehouse stock in order to work and recalculate properly
        const res = await dbService.callFunction<boolean>(
          "bookOutBatch",
          [bookOutBatch._id, modifiedLocations, selectedOrder?.data._id],
          true
        );
        await toastUtils.databaseOperationToast(
          res,
          i18n.t("warehouse:successBookOut"),
          i18n.t("warehouse:errorBookOut"),
          () => {
            onHide();
            dataContext.updateDocumentInContext(BATCH, bookOutBatch._id);
          }
        );
      }
    } catch (e) {
      console.error(e);
      toast.error("An unexpected error occurred: " + e.message);
    } finally {
      setSaving(false);
    }
  }, [bookOutBatch, selectedOrder]);

  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      size={step === BookOutModalStep.SPECIFIC_SELECTION ? "lg" : undefined}
    >
      <Modal.Header closeButton>
        <Modal.Title as={"h5"}>
          <b>
            {step < BookOutModalStep.ORDER_SELECTION
              ? i18n.t("warehouse:bookOutCommodity")
              : i18n.t("warehouse:bookOutOrderSelection")}
          </b>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className=" px-2"></div>
        {step === BookOutModalStep.GENERAL_SELECTION && (
          <>
            <h5 className="mb-10 font-weight-bold text-black">{i18n.t("common:article")}:</h5>
            <div className="form-group" style={{ marginBottom: 5 }}>
              <Typeahead
                id="article"
                ref={articleRef}
                inputProps={{ className: "form-control form-control-solid form-control-lg text-black" }}
                labelKey="label"
                highlightOnlyResult={true}
                onChange={handleChangeArticle}
                options={articleSelectOptions}
                selected={selectedArticle ? [selectedArticle] : []}
                placeholder={i18n.t("warehouse:commoditySearchPlaceholder")}
              />
            </div>
            <div className="d-block mt-2">
              <h5 className="mb-10 font-weight-bold text-black">{i18n.t("warehouse:batch")}:</h5>
              <Typeahead
                id="batch"
                ref={batchRef}
                inputProps={{ className: "form-control form-control-solid form-control-lg text-black" }}
                labelKey="label"
                highlightOnlyResult={true}
                onChange={handleChangeBatch}
                options={batchSelectOptions}
                selected={selectedBatch ? [selectedBatch] : []}
                placeholder={i18n.t("warehouse:batchOrSupplierSearchPlaceholder")}
              />
            </div>
          </>
        )}
        {step === BookOutModalStep.SPECIFIC_SELECTION && selectedBatch && (
          <BookOutSpecificSelection
            bookOutBatch={bookOutBatch}
            selectedWarehouse={selectedWarehouse}
            warehouseSelectOptions={warehouseSelectOptions}
            onChangeWarehouse={handleChangeWarehouse}
            onChangeData={handleChangeData}
          />
        )}
        {step === BookOutModalStep.ORDER_SELECTION && bookOutBatch && (
          <>
            <h4 className="font-weight-bold text-black">{i18n.t("warehouse:selectOrder")}:</h4>
            <div className="row mt-4">
              <div className="col-12">
                <Typeahead
                  id="order"
                  inputProps={{ className: "form-control form-control-solid form-control-lg text-black" }}
                  labelKey="label"
                  highlightOnlyResult={true}
                  options={orderSelectOptions}
                  selected={selectedOrder ? [selectedOrder] : []}
                  placeholder={i18n.t("warehouse:searchForOrders")}
                  onChange={handleChangeOrder}
                />
              </div>
            </div>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <ErrorOverlayButton
          saving={saving}
          buttonText={i18n.t(
            `common:${
              step === BookOutModalStep.GENERAL_SELECTION || step === BookOutModalStep.ORDER_SELECTION
                ? "close"
                : "back"
            }`
          )}
          className="btn btn-secondary"
          onClick={
            step === BookOutModalStep.GENERAL_SELECTION || step === BookOutModalStep.ORDER_SELECTION
              ? handleClose
              : handleBack
          }
        />
        {step === BookOutModalStep.GENERAL_SELECTION && (
          <ErrorOverlayButton
            errors={errors}
            warnings={warnings}
            saving={saving}
            className="btn btn-success"
            buttonText={i18n.t("common:next")}
            onClick={handleNext}
          />
        )}
        {step === BookOutModalStep.SPECIFIC_SELECTION && (
          <>
            <ErrorOverlayButton
              errors={
                orderSelectOptions.length === 0 ? [i18n.t("warehouse:bookOutNoOrderForBatchError"), ...errors] : errors
              }
              warnings={warnings}
              saving={saving}
              className="btn btn-secondary"
              buttonText={i18n.t("warehouse:bookOutForOrder")}
              onClick={handleNext}
            />
            <ErrorOverlayButton
              errors={errors}
              warnings={warnings}
              saving={saving}
              className="btn btn-success"
              buttonText={i18n.t("warehouse:bookOutOnly")}
              onClick={handleBookOut}
            />
          </>
        )}
        {step === BookOutModalStep.ORDER_SELECTION && (
          <ErrorOverlayButton
            errors={errors}
            warnings={warnings}
            saving={saving}
            className="btn btn-success"
            buttonText={i18n.t("warehouse:bookOut")}
            onClick={handleBookOut}
          />
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default BookOutModal;
