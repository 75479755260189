import React, { PureComponent } from "react";
import { BSON } from "realm-web";
import fileUtils from "../../utils/fileUtils";
import baseUtils from "../../utils/baseUtils";

interface UploadImageProps {
  title?: string;
  onUploadFile: (path: string) => void;
}

interface UploadImageState {
  message: string | JSX.Element;
  note: string | JSX.Element;
}

class UploadImage extends PureComponent<UploadImageProps, UploadImageState> {
  ref: any;

  constructor(props: UploadImageProps) {
    super(props);
    this.ref = React.createRef();
    this.state = { message: "Click here to upload a file", note: "*.jpg, *.png, *.gif, max. 128 MB" };
  }

  handleUploadFile = (e: any) => {
    this.setState({
      message: "Uploading...",
      note: "This may take a few seconds"
    });
    const file = e.target.files[0];
    if (file.size > 128 * 1000 * 1000) {
      this.setState({
        message: (
          <span className="text-danger">File too large: {Math.round((file.size / 1000 / 1000) * 100) / 100} MB.</span>
        )
      });
      return;
    }
    const xhr = new XMLHttpRequest();
    let isodate = new Date().toISOString();
    isodate = isodate.split(":").join("-");
    isodate = isodate.split(".").join("-");
    const fileType = fileUtils.getFileExtension(file.name);
    const fileName =
      "Image-" +
      baseUtils.encodeString(this.props.title ? this.props.title : new BSON.ObjectId().toString()) +
      "-" +
      isodate +
      "." +
      fileType;
    xhr.open("POST", (process.env.REACT_APP_UPLOAD_ENDPOINT || "") + fileName, true);
    xhr.setRequestHeader("Content-Type", "application/octate-stream");
    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          this.setState({
            message: (
              <span className="text-success">
                File successfully uploaded!&nbsp;
                <i className="far fa-check-circle text-success" />
              </span>
            ),
            note: "More files to upload? Click to continue"
          });
          this.props.onUploadFile((process.env.REACT_APP_MEDIAHUB_FILE_BASE || "") + xhr.responseText);
        } else {
          this.setState({
            message: <span className="text-danger">Sorry! We can't upload your file at the moment.</span>,
            note: "This may be an error. Feel free to report it."
          });
        }
      }
    };
    xhr.send(file);
  };

  render() {
    const { message, note } = this.state;
    return (
      <>
        <div className="kt-widget4__item">
          <div className="kt-widget4__info">
            <div className="dropzone dropzone-default dz-clickable dz-hover" onClick={() => this.ref.current.click()}>
              <div className="dropzone-msg dz-message needsclick">
                <h3 className="dropzone-msg-title">{message}</h3>
                <span className="dropzone-msg-desc">{note}</span>
              </div>
            </div>
          </div>
        </div>
        <input
          type="file"
          ref={this.ref}
          accept="image/*"
          style={{ display: "none" }}
          onChange={this.handleUploadFile}
        />
      </>
    );
  }
}

export default UploadImage;
