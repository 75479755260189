import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import i18n from "../../../translations/i18n";
import { BaseActionModalProps } from "../../../model/warehouse/common.types";
import BaseListing from "../../listings/BaseListing";
import { useDataContext } from "../../../context/dataContext";
import baseUtils from "../../../utils/baseUtils";
import { Movement } from "../../../model/warehouse/movement.types";
import { paginate } from "../../common/Pagination";
import { resolveTranslation } from "../../../utils/translationUtils";
import { getPackagingUnitAmount } from "../../../utils/warehouseUtils";
import ErrorOverlayButton from "../../common/ErrorOverlayButton";
import dbService, { MOVEMENT } from "../../../services/dbService";
import toastUtils from "../../../utils/toastUtils";
import { useWarehouseDispatch, WarehouseActionType } from "../../../context/warehouseContext";

const FinishMovementsModal: React.FC<BaseActionModalProps> = ({ show, actionTrigger, onHide }) => {
  const dataContext = useDataContext();
  const dispatch = useWarehouseDispatch();
  const { movement, updateDocumentInContext } = dataContext;

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [saving, setSaving] = useState<boolean>(false);
  const [checkedMovements, setCheckedMovements] = useState<Array<string>>([]);

  useEffect(() => {
    if (show) {
      setCurrentPage(1);
      setPageSize(10);
      setCheckedMovements([]);
    }
  }, [show]);

  const movementObjects = useMemo(() => {
    // Get all movements from the same picklist
    const referenceMovementId =
      actionTrigger?.movementIds && actionTrigger.movementIds.length > 0 ? actionTrigger.movementIds[0] : null;
    if (!referenceMovementId) return null;
    const referenceMovementObj: Movement | null = baseUtils.getDocFromCollection(movement, referenceMovementId);
    const picklistId = referenceMovementObj?.file?._id.toString();
    const movements = movement.filter(m => m.file?._id.toString() === picklistId);

    // select all movements which were included in the action trigger, but not yet closed
    const checkedMovements = movements
      .filter(m => !m.closed && actionTrigger?.movementIds?.includes(m._id.toString()))
      .map(m => m._id.toString());
    setCheckedMovements(checkedMovements);
    return movements;
  }, [actionTrigger, movement]);

  const headerDefinition = useMemo(
    () => [
      { title: "#", size: 1 },
      { title: i18n.t("warehouse:rawMaterial"), size: 24 },
      { title: i18n.t("warehouse:batch"), size: 15 },
      { title: i18n.t("warehouse:amount"), size: 20 },
      { title: i18n.t("warehouse:fromLocationShort"), size: 20 },
      { title: i18n.t("warehouse:toLocationShort"), size: 20 }
    ],
    []
  );

  const errors = useMemo(() => {
    const errors: Array<string> = [];
    if (movementObjects?.every(m => m.closed)) errors.push(i18n.t("warehouse:allOperationsFinished"));
    else if (checkedMovements.length === 0) errors.push(i18n.t("warehouse:noOperationSelectedError"));
    return errors;
  }, [checkedMovements, movementObjects]);

  const handleChangePage = useCallback(currentPage => setCurrentPage(currentPage), []);

  const handleChangePageSize = useCallback(pageSize => {
    setPageSize(pageSize);
    setCurrentPage(1);
  }, []);

  const handleCheckMovement = useCallback(
    (movementId: string) => {
      const updatedCheckedMovements = checkedMovements.slice();
      const foundMovementIndex = updatedCheckedMovements.findIndex(m => m === movementId);
      if (foundMovementIndex === -1) {
        updatedCheckedMovements.push(movementId);
      } else {
        updatedCheckedMovements.splice(foundMovementIndex, 1);
      }
      setCheckedMovements(updatedCheckedMovements);
    },
    [checkedMovements]
  );

  const handleSave = useCallback(async () => {
    if (checkedMovements.length === 0) return;
    setSaving(true);
    try {
      const res = await dbService.callFunction<boolean>("changeMovementState", [checkedMovements], true);
      await toastUtils.databaseOperationToast(
        res,
        i18n.t("warehouse:finishMovementSuccess"),
        i18n.t("warehouse:finishMovementError"),
        async () => {
          for (let i = 0; i < checkedMovements.length; i++) {
            await updateDocumentInContext(MOVEMENT, checkedMovements[i]);
          }
        }
      );
    } catch (e) {
      console.error(e);
      toast.error("An unexpected error occurred: " + e.message);
    } finally {
      setSaving(false);
      handleHide();
    }
  }, [checkedMovements]);

  const handleHide = useCallback(() => {
    if (saving) return;
    if (actionTrigger?.keepAction)
      dispatch({ type: WarehouseActionType.TRIGGER_ACTION, payload: { actionNumber: null, keepAction: false } });
    onHide();
  }, [saving, actionTrigger]);

  return (
    <Modal show={show} onHide={handleHide} centered name="FinishMovementModal" size="xl">
      <Modal.Header closeButton>
        <Modal.Title as={"h5"}>
          <b>{i18n.t("warehouse:finishOperations")}</b>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="px-2">
          <h4 className="mb-5 font-weight-bold text-black">
            {i18n.t("warehouse:finishOperations")}
            {movementObjects && movementObjects.length > 0
              ? ` - ${i18n.t("warehouse:picklist")} ${movementObjects[0].file?.title || ""}`
              : ""}
          </h4>
          {movementObjects && movementObjects.length > 0 ? (
            <BaseListing
              headerDefinition={headerDefinition}
              documents={movementObjects}
              bodyContent={
                <>
                  {paginate(movementObjects, currentPage, pageSize).map(movementObj => (
                    <FinishMovementRow
                      key={movementObj._id.toString()}
                      movementObj={movementObj}
                      checkedMovements={checkedMovements}
                      onCheckMovement={handleCheckMovement}
                    />
                  ))}
                </>
              }
              currentPage={currentPage}
              pageSize={pageSize}
              onPageChange={handleChangePage}
              onPageSizeChange={handleChangePageSize}
            ></BaseListing>
          ) : (
            <div className="text-muted">{i18n.t("warehouse:picklistNoMovementsFoundError")}</div>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          disabled={saving}
          className={"btn btn-secondary " + (saving ? "disabled" : "")}
          onClick={handleHide}
        >
          {i18n.t("common:close")}
        </button>
        <ErrorOverlayButton
          buttonText={i18n.t("common:save")}
          className="btn btn-success"
          errors={errors}
          saving={saving}
          onClick={handleSave}
        />
      </Modal.Footer>
    </Modal>
  );
};

interface FinishMovementRowProps {
  movementObj: Movement;
  checkedMovements: Array<string>;
  onCheckMovement: (movementId: string) => void;
}

const FinishMovementRow: React.FC<FinishMovementRowProps> = ({ movementObj, checkedMovements, onCheckMovement }) => {
  const checked = useMemo(() => checkedMovements.includes(movementObj._id.toString()), [movementObj, checkedMovements]);

  const handleCheckbox = useCallback(() => {
    onCheckMovement(movementObj._id.toString());
  }, [movementObj, onCheckMovement]);

  return (
    <tr className={"kt-datatable__row d-table-row nopadding " + (movementObj.closed && "opacity-25")}>
      <td className="kt-datatable__cell d-table-cell">
        <div className="kt-user-card-v2">
          <div className="kt-user-card-v2__details">
            <input
              type="checkbox"
              className="ml-0 kt-checkbox--solid"
              disabled={!!movementObj.closed}
              checked={!!movementObj.closed || checked}
              onChange={movementObj.closed ? undefined : handleCheckbox}
            />
          </div>
        </div>
      </td>
      <td className="kt-datatable__cell d-table-cell ">
        <div className="kt-user-card-v2">
          <div className="kt-user-card-v2__details">
            <span className="kt-user-card-v2__name text-black kt-font-bold">
              {baseUtils.truncateString(resolveTranslation(movementObj.batch.content.details.title), 30)}
            </span>
          </div>
        </div>
      </td>
      <td className="kt-datatable__cell d-table-cell ">
        <div className="kt-user-card-v2">
          <div className="kt-user-card-v2__details text-black">{movementObj.batch.lot}</div>
        </div>
      </td>
      <td className="kt-datatable__cell d-table-cell ">
        <div className="kt-user-card-v2">
          <div className="kt-user-card-v2__details text-black">
            {getPackagingUnitAmount(movementObj.movement.movedPackagingUnits)}
          </div>
        </div>
      </td>
      <td className="kt-datatable__cell d-table-cell ">
        <div className="kt-user-card-v2">
          <div className="kt-user-card-v2__details">
            <span className="kt-user-card-v2__name text-black kt-font-bold">
              {movementObj.movement.fromLocation.warehouseSnapshot.shortName} -{" "}
              {movementObj.movement.fromLocation.warehouseArea.shortName}{" "}
              {movementObj.movement.fromLocation.storageSpace?.storageSpaceNo ?? i18n.t("warehouse:entrance")}
            </span>
          </div>
        </div>
      </td>
      <td className="kt-datatable__cell d-table-cell ">
        <div className="kt-user-card-v2">
          <div className="kt-user-card-v2__details">
            <span className="kt-user-card-v2__name text-black kt-font-bold">
              {movementObj.movement.toLocation
                ? `${movementObj.movement.toLocation.warehouseSnapshot.shortName} - ${
                    movementObj.movement.toLocation.warehouseArea.shortName
                  } ${movementObj.movement.toLocation.storageSpace?.storageSpaceNo ?? i18n.t("warehouse:entrance")}`
                : `${movementObj.movement.fromLocation.warehouseSnapshot.shortName} - ${
                    movementObj.movement.fromLocation.warehouseArea.shortName
                  } ${i18n.t("warehouse:outgoing")}`}
            </span>
          </div>
        </div>
      </td>
    </tr>
  );
};

export default FinishMovementsModal;
