import { BSON } from "realm-web";
import { BaseConfiguration } from "./configuration.types";
import { NumValue, LanguageObject } from "../common.types";

export interface WarehouseConfiguration extends BaseConfiguration {
  values: WarehouseConfigurationValues;
  timeline: Array<WarehouseConfigurationTimelineEntry>;
}

export interface WarehouseConfigurationValues {
  packagingUnitDefinitions: Array<PackagingUnitDefinition>;
  warehouseStructure: Array<WarehouseDefinition>;
  fileTypes: Array<FileType>;
  generalValues: WarehouseGeneralValues;
}

export interface WarehouseConfigurationTimelineEntry {
  _id: BSON.ObjectId;
  date: Date;
  person: string;
  pre: WarehouseConfigurationValues;
  post: WarehouseConfigurationValues;
}

export interface PackagingUnitDefinition {
  _id: BSON.ObjectId;
  label: LanguageObject;
  dimensions: Dimensions;
  deadWeight: NumValue;
  maxFillingWeight?: NumValue;
}

export enum PackagingUnitShape {
  ROUND = "round",
  ANGULAR = "angular"
}

export interface Dimensions {
  length: number;
  height: number;
  width: number;
  shape: PackagingUnitShape;
  unit: string;
}

export interface WarehouseDefinition {
  _id: BSON.ObjectId;
  shortName: string;
  warehouseName: LanguageObject;
  physicalWarehouses: Array<PhysicalWarehouse>;
}

export interface PhysicalWarehouse {
  _id: BSON.ObjectId;
  shortName: string;
  warehouseName: LanguageObject;
  type: WarehouseTypes;
  address: WarehouseAddress;
  contactPerson?: string;
  storageSpaces?: Array<StorageSpace>;
}

export interface WarehouseAddress {
  name: string; // name of the company e.g.
  street: string;
  houseNo: string;
  postalCode: string;
  city: string;
  country: string; // country code
}

export interface StorageSpace {
  _id: BSON.ObjectId;
  storageSpaceNo: string;
  dimensions?: Dimensions;
  maxWeight?: NumValue;
}

export interface FileType {
  _id: BSON.ObjectId;
  type: LanguageObject;
  shortName: string;
  subtype: FileTypes;
}

export enum FileTypes {
  BATCH = "batch",
  PURCHASEORDER = "purchaseOrder"
}

export enum WarehouseTypes {
  REMOTE = "remote",
  DIRECTLYMANAGED = "directlyManaged"
}

export interface WarehouseGeneralValues {
  defaultStorageSpace?: Pick<StorageSpace, "dimensions" | "maxWeight">;
}
