import React, { useCallback, useMemo } from "react";
import Select from "react-select";
import i18n from "../../../../translations/i18n";
import { NumValue, SelectOption } from "../../../../model/common.types";
import {
  BookOutBatch,
  ExtendedBatchLocation,
  ExtendedPackagingUnit,
  Warehouse
} from "../../../../model/warehouse/customTypes.types";
import { WarehouseTypes } from "../../../../model/configuration/warehouseConfiguration.types";
import baseUtils from "../../../../utils/baseUtils";
import { resolveTranslation } from "../../../../utils/translationUtils";
import { getPackagingUnitLabel } from "../../../../utils/warehouseUtils";
import { Input } from "../../../common/Input";

interface BookOutSpecificSelectionProps {
  bookOutBatch: BookOutBatch | null;
  selectedWarehouse: SelectOption<Warehouse> | null;
  warehouseSelectOptions: SelectOption<Warehouse>[] | undefined;
  onChangeWarehouse: (selected: any) => void;
  onChangeData: (e: React.ChangeEvent<HTMLInputElement>, locationId: string, packagingUnitId: string | null) => void;
}

const BookOutSpecificSelection: React.FC<BookOutSpecificSelectionProps> = ({
  bookOutBatch,
  selectedWarehouse,
  warehouseSelectOptions,
  onChangeWarehouse,
  onChangeData
}) => {
  return (
    <>
      <h5 className="mb-10 font-weight-bold text-black">{i18n.t("warehouse:bookOutFrom")}:</h5>
      <Select
        className="select-warehouse w-100"
        classNamePrefix="select-warehouse"
        placeholder={i18n.t("warehouse:warehouse")}
        onChange={onChangeWarehouse}
        value={selectedWarehouse}
        options={warehouseSelectOptions}
      />
      <div className="kt-portlet__body pt-2">
        {bookOutBatch &&
          bookOutBatch.locations.map(batchLocation => {
            return batchLocation.location.warehouseArea.type === WarehouseTypes.REMOTE ? (
              <BookOutCard
                key={batchLocation._id.toString()}
                bookOutBatch={bookOutBatch}
                batchLocation={batchLocation}
                onChangeData={onChangeData}
              />
            ) : (
              <React.Fragment key={batchLocation._id.toString()}>
                {batchLocation.packagingUnits.map(pU => (
                  <BookOutCard
                    key={pU._id.toString()}
                    bookOutBatch={bookOutBatch}
                    batchLocation={batchLocation}
                    packagingUnit={pU}
                    onChangeData={onChangeData}
                  />
                ))}
              </React.Fragment>
            );
          })}
      </div>
    </>
  );
};

interface BookOutCardProps {
  bookOutBatch: BookOutBatch;
  batchLocation: ExtendedBatchLocation;
  packagingUnit?: ExtendedPackagingUnit;
  onChangeData: (e: React.ChangeEvent<HTMLInputElement>, locationId: string, packagingUnitId: string | null) => void;
}

const BookOutCard: React.FC<BookOutCardProps> = ({ bookOutBatch, batchLocation, packagingUnit, onChangeData }) => {
  const remote = useMemo(() => batchLocation.location.warehouseArea.type === WarehouseTypes.REMOTE, [batchLocation]);

  const warnings = useMemo(() => {
    const warnings: Array<string> = [];
    if (packagingUnit && +packagingUnit.amountToKeep % +packagingUnit.amountPerPu.value !== 0) {
      warnings.push(i18n.t("warehouse:bookOutPartialPackagingUnitWarning"));
    }
    if (warnings.length > 0) warnings.push(i18n.t("warehouse:dataValidityWarning"));
    return warnings;
  }, [packagingUnit]);

  const handleChangeData = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onChangeData(e, batchLocation._id.toString(), packagingUnit?._id.toString() ?? null);
    },
    [onChangeData, batchLocation, packagingUnit]
  );

  return (
    <div
      className="card card-custom card-fit card-border my-3 bg-light border-none"
      style={{
        boxShadow: "0px 0px 30px 0px rgba(0,0,0,0.05)"
      }}
    >
      <div className="card-header card-header-transparent">
        <div className="row">
          <div className="col-8 card-title d-block my-3" style={{ lineHeight: 1.2 }}>
            <span className="navi-text font-weight-bold font-size-h5 text-black">
              {baseUtils.truncateString(resolveTranslation(bookOutBatch.content.details.title), 30)}&nbsp;
              <small>{baseUtils.truncateString(resolveTranslation(bookOutBatch.content.details.subtitle), 30)}</small>
            </span>
            <br />
            <span className="navi-text font-weight-medium font-size-lg text-black">
              {baseUtils.truncateString(bookOutBatch.sender.name, 50)}
            </span>
          </div>
          <div className="col-4 card-toolbar-warehouse">
            <span className={"label label-secondary label-lg kt-font-bolder label-rounded w-auto px-2"}>
              {remote
                ? i18n.t("warehouse:remote")
                : `${i18n.t("warehouse:storageSpace")} ${
                    batchLocation.location.storageSpace?.storageSpaceNo ?? i18n.t("warehouse:entrance")
                  }`}
            </span>
          </div>
        </div>
      </div>
      <div className="card card-custom">
        <div className="card-body card-body-warehouse" style={{ border: "none" }}>
          <AmountRow batchLocation={batchLocation} packagingUnit={packagingUnit} onChangeAmount={handleChangeData} />
          {packagingUnit && <PackagingUnitRow packagingUnit={packagingUnit} onChangeQuantity={handleChangeData} />}
          {warnings.length > 0 && (
            <div className="row">
              <div className="col-12 font-size-sm">
                <i className="fa fa-info-circle text-danger mr-2" />
                <span className="text-black">{warnings.join(". ")}</span>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

interface AmountRowProps {
  batchLocation: ExtendedBatchLocation;
  packagingUnit: ExtendedPackagingUnit | undefined;
  onChangeAmount: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const AmountRow: React.FC<AmountRowProps> = ({ batchLocation, packagingUnit, onChangeAmount }) => {
  const originalAmount: NumValue = useMemo(() => {
    return {
      value: packagingUnit
        ? packagingUnit.amountPerPu.value * (packagingUnit.quantity || 0)
        : batchLocation.amountAtLocation.value,
      unit: packagingUnit ? packagingUnit.amountPerPu.unit : batchLocation.amountAtLocation.unit
    };
  }, [packagingUnit, batchLocation]);

  const updatedAmount = useMemo(
    () =>
      batchLocation.remoteWarehouseData?.amountToKeep ?? packagingUnit?.amountToKeep ?? originalAmount.value.toString(),
    [batchLocation, packagingUnit, originalAmount]
  );

  return (
    <div className="form-group row mb-3">
      <div className="col-5">
        <div className="input-group">
          <Input
            type="number"
            disabled
            name="amount"
            className="form-control form-control-solid hide-arrows"
            value={originalAmount.value}
          />
          <div className="input-group-append">
            <span className="input-group-text input-group-solid">{originalAmount.unit}</span>
          </div>
        </div>
      </div>
      <div className="col-2 d-flex align-items-center justify-content-center">
        <i className="fa fa-arrow-right text-black mx-2" />
      </div>
      <div className="col-5">
        <div className="input-group">
          <Input
            type="number"
            name="amountToKeep"
            className="form-control form-control-solid hide-arrows"
            min={0}
            max={originalAmount.value}
            value={updatedAmount}
            onChange={onChangeAmount}
          />
          <div className="input-group-append">
            <span className="input-group-text input-group-solid">{originalAmount.unit}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

interface PackagingUnitRowProps {
  packagingUnit: ExtendedPackagingUnit;
  onChangeQuantity: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const PackagingUnitRow: React.FC<PackagingUnitRowProps> = ({ packagingUnit, onChangeQuantity }) => {
  return (
    <div className="form-group row mb-3">
      <div className="col-5">
        <div className="input-group">
          <Input
            type="number"
            name="packagingUnit"
            className="form-control form-control-solid hide-arrows"
            disabled
            value={packagingUnit.quantity || 0}
          />
          <div className="input-group-append">
            <span className="input-group-text input-group-solid">{getPackagingUnitLabel(packagingUnit)}</span>
          </div>
        </div>
      </div>
      <div className="col-2 d-flex align-items-center justify-content-center">
        <i className="fa fa-arrow-right text-black mx-2" />
      </div>
      <div className="col-5">
        <div className="input-group">
          <Input
            type="number"
            name="remainingQuantity"
            className="form-control form-control-solid hide-arrows"
            min={0}
            value={packagingUnit.remainingQuantity}
            onChange={onChangeQuantity}
          />
          <div className="input-group-append">
            <span className="input-group-text input-group-solid">{getPackagingUnitLabel(packagingUnit)}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookOutSpecificSelection;
