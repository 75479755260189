import React from "react";
import i18n from "../../../../translations/i18n";
import { OutgoingSnapshot } from "../../../../model/warehouse/delivery.types";
import { resolveTranslation } from "../../../../utils/translationUtils";
import { formatNumValue } from "../../../../utils/baseUtils";
import { getFormattedPackagingUnitAmount } from "../../../../utils/warehouseUtils";

interface DeliveryCardProps {
  deliveryPart: OutgoingSnapshot;
  newMovedAmount?: number;
  overview?: boolean;
}

const DeliveryCard: React.FC<DeliveryCardProps> = ({ deliveryPart, newMovedAmount, overview }) => {
  return (
    <div className="mt-5 px-2 text-black">
      <h5>
        {resolveTranslation(deliveryPart.batch.content.details.title)}
        {deliveryPart.batch.content.details.subtitle
          ? `- ${resolveTranslation(deliveryPart.batch.content.details.subtitle)}`
          : ""}
      </h5>
      <div>
        {i18n.t("warehouse:batch")}: {deliveryPart.batch.lot}
      </div>
      <div>
        <span className="font-weight-bolder">
          {overview
            ? `${newMovedAmount ?? 0} ${deliveryPart.movedAmount.unit} (${i18n.t("warehouse:before")}: ${formatNumValue(
                deliveryPart.movedAmount
              )})`
            : formatNumValue(deliveryPart.movedAmount)}
        </span>{" "}
        {i18n.t("warehouse:containedIn")}
        <br />
        {deliveryPart.movedPackagingUnits.map(mpu => getFormattedPackagingUnitAmount(mpu, true, true)).join(", ")}
      </div>
    </div>
  );
};

export default DeliveryCard;
