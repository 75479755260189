import _ from "lodash";
import React, { PureComponent } from "react";
import Select from "react-select";
import { Nav, OverlayTrigger, Tab, Tooltip } from "react-bootstrap";
import { Link, RouteComponentProps } from "react-router-dom";
import { BSON } from "realm-web";
import countryList from "i18n-iso-countries";
import Joi from "joi";
import CompanyOrdersTab from "./CompanyOrdersTab";
import PersonWidget from "../common/PersonWidget";
import RatingInfo from "../common/RatingInfo";
import SelectEmployee from "../common/SelectEmployee";
import { DataContext } from "../../context/dataContext";
import { CompaniesAddress, CompaniesDocument } from "../../model/companies.types";
import { UserdataDocument } from "../../model/userdata.types";
import dbService, { UpdateAction, COMPANIES, USERDATA } from "../../services/dbService";
import toastUtils from "../../utils/toastUtils";
import baseUtils from "../../utils/baseUtils";
import SplashScreen from "../common/SplashScreen";
import PaymentTargetSelect from "../common/PaymentTargetSelect";
import CompanyUsers from "./CompanyUsers";
import accessUtils, { ACCESS_AREAS } from "../../utils/accessUtils";
import SupplyCentralFeatures from "./SupplyCentralFeatures";
import CompanyOposTab from "../finance/opos/CompanyOposTab";
import TrustworthinessInfo from "../finance/TrustworthinessInfo";

interface CompanyParams {
  id: string;
}

interface CompanyProps extends RouteComponentProps<CompanyParams, {}, {}> {}

interface CompanyState {
  company?: CompaniesDocument;
  activeTab: string;
  contacts: Array<UserdataDocument | null>;
  owner: UserdataDocument | undefined;
  saving: boolean;
}

class Company extends PureComponent<CompanyProps, CompanyState> {
  static contextType = DataContext;
  context!: React.ContextType<typeof DataContext>;
  contactsPreChange: Array<UserdataDocument> = [];
  company: CompaniesDocument | undefined;
  _id: string | undefined;
  emailSchema = Joi.string()
    .email({ tlds: { allow: false }, minDomainSegments: 1 })
    .required()
    .label("Email");

  constructor(props: CompanyProps, context: React.ContextType<typeof DataContext>) {
    super(props, context);
    this._id = props.match.params.id;
    this.state = {
      company: undefined,
      activeTab: "orders",
      contacts: [],
      owner: undefined,
      saving: false
    };
  }

  componentDidMount() {
    this.initializeData();
  }

  componentDidUpdate = async (prevProps: Readonly<CompanyProps>, prevState: Readonly<CompanyState>, snapshot?: any) => {
    const company = this.context.companies.find(c => c._id.toString() === this.props.match.params.id);
    if (prevProps.match.params.id !== this.props.match.params.id) {
      this._id = this.props.match.params.id;
      await this.initializeData();
    } else if (!_.isEqual(company, this.company)) {
      // Update if company differs
      this.company = company;
    }
  };

  initializeData = async () => {
    const { history } = this.props;
    const { companies } = this.context;
    if (!this._id || !BSON.ObjectId.isValid(this._id)) {
      history.push("/companies");
      return;
    }
    let company = baseUtils.getDocFromCollection(companies, this._id);
    if (!company) {
      company = await dbService.getDocumentFromCollection(COMPANIES, this._id);
      if (!company) {
        console.error("No company could be loaded for id", this._id);
        history.push("/companies");
        return;
      }
      this.context.addDocuments(COMPANIES, [company]);
    }
    const contacts: Array<UserdataDocument> = company
      ? this.context.userdata.filter(u => u.company_id && u.company_id.toString() === company._id.toString())
      : [];
    const owner = company ? this.context.userdata.find(u => u._id.toString() === company.owner.toString()) : undefined;
    const contactPerson =
      company && company.contactPerson
        ? this.context.userdata.find(u => u._id.toString() === company.contactPerson.toString())
        : undefined;
    this.contactsPreChange = _.cloneDeep(contacts);
    if (contactPerson) {
      const findIndex = contacts.findIndex(c => c._id.toString() === contactPerson._id.toString());
      if (findIndex >= 0) {
        contacts.splice(findIndex, 1);
      }
      contacts.unshift(contactPerson);
    } else if (!contactPerson) {
      // @ts-ignore
      contacts.unshift(null);
    }
    this.company = company;
    this.setState({ company, contacts, owner });
  };

  handleSelect = (value: { value: string; label: string }) => {
    const company = _.cloneDeep(this.state.company);
    //@ts-ignore
    company.rating = value.value;
    this.setState({ company });
  };

  /**
   * Method to change the trustworthy flag of the corresponding company.
   * @param value that represent the selected user input for trustworthiness.
   */
  handleTrustworthyChange = (value: { value: boolean; label: string }) => {
    const company = _.cloneDeep(this.state.company);

    company!.isTrustworthy = value.value;
    this.setState({ company });
  };

  handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const company = _.cloneDeep(this.state.company);
    // @ts-ignore
    company[e.target.name] = e.target.value;
    this.setState({ company });
  };

  handlePaymentChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const company = _.cloneDeep(this.state.company);
    if (!company) return;
    company.paymentTarget = +e.target.value;
    this.setState({ company });
  };

  handleCompanyOwner = (owner: UserdataDocument | undefined) => {
    const company = _.cloneDeep(this.state.company);
    company!.owner = owner ? owner._id.toString() : undefined;
    this.setState({ company });
  };

  handleChangeAddress = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>, idx: number) => {
    const company = _.cloneDeep(this.state.company);
    // @ts-ignore
    company!.address[idx][e.target.name] = e.target.value;
    this.setState({ company });
  };

  handleAddAddress = () => {
    const company = _.cloneDeep(this.state.company);
    company!.address.push({
      _id: new BSON.ObjectId(),
      street: "",
      streetnr: "",
      zip: "",
      city: "",
      country: "Germany"
    });
    this.setState({ company });
  };

  handleDeleteAddress = (idx: number) => {
    const company = _.cloneDeep(this.state.company);
    company!.address.splice(idx, 1);
    this.setState({ company });
  };

  handleChangeContact = (idx: number, value: string) => {
    const { userdata } = this.context;
    const contacts = _.cloneDeep(this.state.contacts);
    const contact = userdata.find(u => u._id.toString() === value);
    contacts[idx] = contact ? contact : null;
    this.setState({ contacts });
  };

  handleAddContact = () => {
    const contacts = _.cloneDeep(this.state.contacts);
    contacts.push(null);
    this.setState({ contacts });
  };

  handleDeleteContact = (idx: number) => {
    const contacts = _.cloneDeep(this.state.contacts);
    contacts.splice(idx, 1);
    this.setState({ contacts });
  };

  /**
   * Reset the data of the settings tab.
   */
  resetData = () => {
    const { companies, userdata } = this.context;
    const company = companies.find(c => c._id.toString() === this.props.match.params.id)!;
    const contacts = company
      ? userdata.filter(u => u.company_id && u.company_id.toString() === company._id.toString())
      : [];
    const contactPerson =
      company && company.contactPerson
        ? userdata.find(u => u._id.toString() === company.contactPerson!.toString())
        : undefined;
    if (contactPerson) {
      const findIndex = contacts.findIndex(c => c._id.toString() === contactPerson._id.toString());
      // Remove if contact is already there, since the contact has to be index 0
      if (findIndex > 0) {
        contacts.splice(findIndex, 1);
        contacts.unshift(contactPerson);
      } else if (findIndex < 0) contacts.unshift(contactPerson);
    } else if (!contactPerson) {
      // @ts-ignore
      contacts.unshift(null);
    }
    this.setState({
      company,
      contacts,
      owner: company ? userdata.find(u => u._id.toString() === company.owner.toString()) : undefined
    });
  };

  submitChanges = async () => {
    const { owner } = this.state;
    const { updateDocumentInContext, userdata } = this.context;
    const company = _.cloneDeep(this.state.company);
    const contacts = _.cloneDeep(this.state.contacts.filter(c => !!c)) as Array<UserdataDocument>;
    const contactPerson = contacts[0];
    if (!company) return;
    this.setState({ saving: true });
    if (owner) company.owner = owner._id;
    if (contactPerson) company.contactPerson = contactPerson._id;

    // prohibit empty strings for deviating company names
    const filteredAddresses: Array<CompaniesAddress> = company!.address.map(address => {
      if (!address.deviatingCompanyName || address.deviatingCompanyName.trim() === "") {
        return _.omit(address, ["deviatingCompanyName"]);
      }
      return _.cloneDeep(address);
    });
    company!.address = filteredAddresses;

    let actions: Array<UpdateAction> = [
      { collection: COMPANIES, filter: { _id: company._id }, update: _.omit(company, "_id") }
    ];

    const contactsStr = contacts.map(c => c && c._id.toString());
    const contactsPreChangeStr = this.contactsPreChange.map(c => c && c._id.toString());
    // Check which contacts were added
    const contactsAdded: Array<BSON.ObjectId> = contacts
      .filter(c => c && !contactsPreChangeStr.includes(c._id.toString()))
      .map(u => u && u._id);
    // Check which contacts were removed
    const contactsRemoved: Array<BSON.ObjectId> = this.contactsPreChange
      .filter(c => !contactsStr.includes(c._id.toString()))
      .map(u => u._id);
    if (contactsAdded.length > 0) {
      contactsAdded.forEach(c => {
        actions.push({
          collection: USERDATA,
          filter: { _id: c },
          update: { company_id: company._id }
        });
      });
    }
    if (contactsRemoved.length > 0) {
      contactsRemoved.forEach(c => {
        actions.push({
          collection: USERDATA,
          filter: { _id: c },
          update: { company_id: "" }
        });
      });
    }
    const result = await dbService.updatesAsTransaction(actions);

    contactsAdded.concat(contactsRemoved).forEach(c => updateDocumentInContext(USERDATA, c));
    updateDocumentInContext(COMPANIES, company._id);
    await toastUtils.databaseOperationToast(
      result,
      "Company and users updated successfully",
      "Updating company or users failed",
      () => {
        contactsAdded.concat(contactsRemoved).forEach(c => updateDocumentInContext(USERDATA, c));
        updateDocumentInContext(COMPANIES, company._id);
      }
    );
    if (result) {
      this.contactsPreChange = userdata.filter(u => u.company_id && u.company_id.toString() === company._id.toString());
      if (result) this.company = company;
      const companyOwner = company
        ? this.context.userdata.find(u => u._id.toString() === company.owner.toString())
        : undefined;
      this.setState({ saving: false, owner: companyOwner, contacts: contacts.filter(c => !!c) });
    } else {
      this.setState({ saving: false });
    }
  };

  checkForErrors = () => {
    const { company, contacts } = this.state;
    const errors: Array<string> = [];
    if (!company) return errors;
    if (company.name.trim().length < 2) errors.push("Company name to short");
    if (!company.address[0].street.trim()) errors.push("Primary address street missing");
    if (!company.address[0].streetnr.trim()) errors.push("Primary address street number missing");
    if (!company.address[0].zip.trim()) errors.push("Primary address zip missing");
    if (!company.address[0].city.trim()) errors.push("Primary address city missing");
    if (!company.address[0].country.trim()) errors.push("Primary address country missing");
    if (!contacts[0]) errors.push("Primary contact person missing");
    if (company.contactMail && this.emailSchema.validate(company.contactMail).error)
      errors.push("Contact email is invalid");
    if (company.accountingEmail && this.emailSchema.validate(company.accountingEmail).error)
      errors.push("Accounting email is invalid");
    if (company.address.length > 1) {
      for (let i = 1; i < company.address.length; i++) {
        if (!company.address[i].street.trim()) errors.push("Address " + i + ": Street missing");
        if (!company.address[i].streetnr.trim()) errors.push("Address " + i + ": Street number missing");
        if (!company.address[i].zip.trim()) errors.push("Address " + i + ": Zip missing");
        if (!company.address[i].city.trim()) errors.push("Address " + i + ": City missing");
        if (!company.address[i].country.trim()) errors.push("Address " + i + ": Country missing");
      }
    }
    return errors;
  };

  render() {
    const { activeTab, company, contacts, owner } = this.state;
    const { orders, userdata } = this.context;
    const possibleContacts = userdata
      .filter(u => !u.company_id && !contacts.some(c => c && c._id.toString() === u._id.toString()))
      .sort((u1, u2) => u1.surname.localeCompare(u2.surname));
    const errors = this.checkForErrors();
    const canAccessSCData = accessUtils.canAccessOneOfAreas([ACCESS_AREAS.SALES, ACCESS_AREAS.FINANCE]);
    const canAccessFinance = accessUtils.canAccessArea(ACCESS_AREAS.FINANCE);
    if (!this.company || !company)
      return (
        <div className="kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid ">
          <div className="kt-portlet ">
            <div className="kt-portlet__body" style={{ minHeight: "80vh" }}>
              <SplashScreen
                additionalSVGStyle={{
                  height: "80px",
                  width: "80px"
                }}
              />
            </div>
          </div>
        </div>
      );
    else {
      const nameSplit = this.company.name ? this.company.name.split(/[ -]+/) : "?";
      const contactPerson = this.company.contactPerson
        ? userdata.find(u => u._id.toString() === this.company!.contactPerson!.toString())
        : null;
      return (
        <div className="kt-container kt-container--fluid  kt-grid__item kt-grid__item--fluid">
          <div className="kt-portlet">
            <div className="kt-portlet__body">
              <div className="kt-widget kt-widget--user-profile-3">
                <div className="kt-widget__top">
                  <div className="kt-widget__media">
                    <div className="kt-badge kt-badge--xl kt-badge--primary">
                      {(nameSplit.length > 1
                        ? nameSplit[0][0] + nameSplit[1][0]
                        : this.company.name.slice(0, 2)
                      ).toUpperCase()}
                    </div>
                  </div>
                  <div className="kt-widget__content">
                    <div className="kt-widget__head">
                      <div className="kt-widget__user">
                        <span className="kt-widget__username">{this.company ? this.company.name : "Not set"}</span>
                        <span className="kt-badge kt-badge--bolder kt-badge kt-badge--inline kt-badge--unified-success">
                          Customer
                        </span>
                      </div>
                    </div>
                    <div className="kt-widget__subhead">
                      <span>
                        <i className="flaticon2-placeholder pr-2" />
                        {this.company.address[0].street +
                          " " +
                          this.company.address[0].streetnr +
                          ", " +
                          this.company.address[0].zip +
                          " " +
                          this.company.address[0].city +
                          ", " +
                          this.company.address[0].country}
                      </span>
                    </div>
                    <div className="kt-user-card-v2 inline-flex mr-3 mt-1">
                      {contactPerson && <PersonWidget person={contactPerson} />}
                    </div>
                    <div className="kt-user-card-v2 inline-flex mt-1">{!!owner && <PersonWidget person={owner} />}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-4">
              <div className="kt-portlet kt-portlet--head-noborder">
                <div className="kt-portlet__head">
                  <div className="kt-portlet__head-label">
                    <h3 className="kt-portlet__head-title">Note</h3>
                  </div>
                </div>
                <div className="kt-portlet__body kt-portlet__body--fit-top">
                  <div className="kt-section kt-section--space-sm">
                    {this.company.additionalInformation
                      ? this.company.additionalInformation
                      : "Important information about the company can be stored here so that this information is visible to everyone. This is also the right place to record ideas for the future, or the contact person's wishes."}
                  </div>
                  <div className="kt-section kt-section--last">
                    <button
                      onClick={() => this.setState({ activeTab: "settings" })}
                      className="btn btn-label-brand btn-sm btn-bold"
                    >
                      Update Note
                    </button>
                  </div>
                </div>
              </div>
              <div className="kt-portlet">
                <div className="kt-portlet__head">
                  <div className="kt-portlet__head-label">
                    <h3 className="kt-portlet__head-title">Company</h3>
                  </div>
                  <div className="kt-portlet__head-toolbar">
                    <div
                      onClick={() => this.setState({ activeTab: "settings" })}
                      className="btn btn-label-brand btn-sm btn-bold"
                    >
                      Update Information
                    </div>
                  </div>
                </div>
                <div className="kt-form kt-form--label-right">
                  <div className="kt-portlet__body">
                    <div className="form-group form-group-xs row">
                      <label className="col-4 col-form-label">Name:</label>
                      <div className="col-8">
                        <span className="form-control-plaintext kt-font-bolder">{this.company.name}</span>
                      </div>
                    </div>
                    <div className="form-group form-group-xs row">
                      <label className="col-4 col-form-label">Location:</label>
                      <div className="col-8">
                        <span className="form-control-plaintext kt-font-bolder">
                          {this.company.address[0].city + ", " + this.company.address[0].country}
                        </span>
                      </div>
                    </div>
                    <div className="form-group form-group-xs row">
                      <label className="col-4 col-form-label">Rating:</label>
                      <div className="col-8">
                        <span className="form-control-plaintext kt-font-bolder">
                          <RatingInfo rating={this.company.rating} />
                        </span>
                      </div>
                    </div>
                    <div className="form-group form-group-xs row">
                      <label className="col-4 col-form-label">Trustworthiness</label>
                      <div className="col-8">
                        <span className="form-control-plaintext kt-font-bolder">
                          <TrustworthinessInfo isTrustworthy={this.company.isTrustworthy} />
                        </span>
                      </div>
                    </div>
                    <div className="form-group form-group-xs row">
                      <label className="col-4 col-form-label">General Email:</label>
                      <div className="col-8">
                        <span className="form-control-plaintext kt-font-bolder">
                          {this.company.contactMail ? this.company.contactMail : "Not set"}
                        </span>
                      </div>
                    </div>
                    <div className="form-group form-group-xs row">
                      <label className="col-4 col-form-label">Contact Person:</label>
                      <div className="col-8">
                        <span className="form-control-plaintext kt-font-bolder">
                          {contactPerson ? (
                            <Link
                              to={"/user/" + contactPerson._id.toString()}
                              className="kt-user-card-v2__name kt-link"
                              style={{ color: "#595d6e" }}
                            >
                              {contactPerson.prename + " " + contactPerson.surname}
                            </Link>
                          ) : (
                            "Not set"
                          )}
                        </span>
                      </div>
                    </div>
                    <div className="form-group form-group-xs row">
                      <label className="col-4 col-form-label">Contact Person Email:</label>
                      <div className="col-8">
                        <span className="form-control-plaintext kt-font-bolder">
                          {contactPerson && contactPerson.email.length > 0 ? contactPerson.email[0] : "Not set"}
                        </span>
                      </div>
                    </div>
                    <div className="form-group form-group-xs row">
                      <label className="col-4 col-form-label">Contact Person Telephone:</label>
                      <div className="col-8">
                        <span className="form-control-plaintext kt-font-bolder">
                          {contactPerson && contactPerson.telephone.length > 0 ? contactPerson.telephone[0] : "Not set"}
                        </span>
                      </div>
                    </div>
                    <div className="form-group form-group-xs row">
                      <label className="col-4 col-form-label">Default Payment Target:</label>
                      <div className="col-8">
                        <span className="form-control-plaintext kt-font-bolder">
                          {this.company && this.company.paymentTarget !== undefined
                            ? this.company.paymentTarget === -1
                              ? "Payment in advance"
                              : this.company.paymentTarget === 0
                              ? "Immediately after delivery"
                              : `${this.company.paymentTarget} days after invoice date`
                            : "Not set"}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-8">
              <Tab.Container
                id="companyTabs"
                activeKey={activeTab}
                onSelect={e => e && this.setState({ activeTab: e })}
              >
                <div className="kt-portlet kt-portlet--tabs">
                  <div className="kt-portlet__head">
                    <div className="kt-portlet__head-toolbar">
                      <ul
                        className="nav nav-tabs nav-tabs-space-lg nav-tabs-line nav-tabs-bold nav-tabs-line-3x nav-tabs-line-brand"
                        role="tablist"
                      >
                        <li className="nav-item">
                          <Nav.Link role="tab" id="orders" eventKey="orders" active={activeTab === "orders"}>
                            <i className="flaticon2-chart" /> Orders
                          </Nav.Link>
                        </li>
                        {canAccessFinance && (
                          <li className="nav-item">
                            <Nav.Link role="tab" id="opos" eventKey="opos" active={activeTab === "opos"}>
                              <i className="flaticon2-list-1" /> Opos
                            </Nav.Link>
                          </li>
                        )}
                        <li className="nav-item">
                          <Nav.Link role="tab" id="settings" eventKey="settings" active={activeTab === "settings"}>
                            <i className="flaticon2-gear" /> Settings
                          </Nav.Link>
                        </li>
                        <li className="nav-item">
                          <Nav.Link role="tab" id="user" eventKey="user" active={activeTab === "user"}>
                            <i className="flaticon2-user" /> User
                          </Nav.Link>
                        </li>
                        {canAccessSCData && (
                          <li className="nav-item">
                            <Nav.Link
                              role="tab"
                              id="scFeatures"
                              eventKey="scFeatures"
                              active={activeTab === "scFeatures"}
                            >
                              <i className="flaticon2-rocket-1" /> SupplyCentral Features
                            </Nav.Link>
                          </li>
                        )}
                      </ul>
                    </div>
                  </div>
                  <div className="kt-portlet__body">
                    <Tab.Content>
                      <Tab.Pane key="orders" eventKey="orders" transition={false}>
                        <CompanyOrdersTab company={this.company} orders={orders} />
                      </Tab.Pane>
                      <Tab.Pane key="opos" eventKey="opos" transition={false}>
                        <CompanyOposTab orders={orders} company={this.company} context={this.context} />
                      </Tab.Pane>
                      <Tab.Pane key="settings" eventKey="settings" transition={false}>
                        <PaymentTargetSelect
                          value={company.paymentTarget}
                          onChange={this.handlePaymentChange}
                          wrapperClasses={"form-group row"}
                          labelColumnClasses={"col-3 col-form-label"}
                          labelClasses={"mb-0"}
                          selectColumnClasses={"col-9 col-xl-6"}
                        />
                        <div className="form-group row">
                          <label className="col-3 col-form-label">Note</label>
                          <div className="col-9 col-xl-6">
                            <textarea
                              className="form-control"
                              onChange={this.handleChange}
                              value={company.additionalInformation}
                              name="additionalInformation"
                              rows={3}
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <label className="col-3 col-form-label">Contact Mail</label>
                          <div className="col-9 col-xl-6">
                            <input
                              className="form-control"
                              type="text"
                              onChange={this.handleChange}
                              value={company.contactMail}
                              name="contactMail"
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <label className="col-3 col-form-label">Accounting E-mail</label>
                          <div className="col-9 col-xl-6">
                            <input
                              className="form-control"
                              type="text"
                              onChange={this.handleChange}
                              value={company.accountingEmail}
                              name="accountingEmail"
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <label className="col-3 col-form-label">Rating</label>
                          <div className="col-9 col-xl-6">
                            <Select
                              className="select-default"
                              options={[
                                { value: "", label: "Not Set" },
                                { value: "A", label: "A" },
                                { value: "B", label: "B" },
                                { value: "C", label: "C" }
                              ]}
                              value={
                                company.rating
                                  ? { value: company.rating, label: company.rating.toString() }
                                  : {
                                      value: "",
                                      label: "Not Set"
                                    }
                              }
                              onChange={(value: any) => this.handleSelect(value)}
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <label className="col-3 col-form-label">Trustworthiness</label>
                          <div className="col-9 col-xl-6">
                            <Select
                              className="select-default"
                              value={
                                company.isTrustworthy
                                  ? { value: true, label: "Trustworthy (will not appear at dunning listing)" }
                                  : {
                                      value: false,
                                      label: "Not trustworthy (will appear at dunning listing)"
                                    }
                              }
                              options={[
                                { value: true, label: "Trustworthy (will not appear at dunning listing)" },
                                { value: false, label: "Not trustworthy (will appear at dunning listing)" }
                              ]}
                              onChange={(value: any) => this.handleTrustworthyChange(value)}
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <label className="col-3 col-form-label">Name & Address:</label>
                        </div>
                        <div className="form-group row">
                          <label className="col-3 col-form-label">Company Name</label>
                          <div className="col-9 col-xl-6">
                            <input
                              className="form-control"
                              type="text"
                              onChange={this.handleChange}
                              value={company.name}
                              name="name"
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <label className="col-3 col-form-label">Primary Address</label>
                          <div className="col-9 col-xl-6">
                            <input
                              className="form-control"
                              type="text"
                              onChange={e => this.handleChangeAddress(e, 0)}
                              value={company.address[0].careOf}
                              placeholder="(Optional) c/o"
                              name="careOf"
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <label className="col-3 col-form-label" />
                          <div className="col-4">
                            <input
                              className="form-control"
                              type="text"
                              onChange={e => this.handleChangeAddress(e, 0)}
                              value={company.address[0].street}
                              name="street"
                            />
                          </div>
                          <div className="col-2">
                            <input
                              className="form-control"
                              type="text"
                              onChange={e => this.handleChangeAddress(e, 0)}
                              value={company.address[0].streetnr}
                              name="streetnr"
                            />
                          </div>
                        </div>
                        <div className="form-group row mb-0">
                          <label className="col-3 col-form-label" />
                          <div className="col-2">
                            <input
                              className="form-control"
                              type="text"
                              onChange={e => this.handleChangeAddress(e, 0)}
                              value={company.address[0].zip}
                              name="zip"
                            />
                          </div>
                          <div className="col-2">
                            <input
                              className="form-control"
                              type="text"
                              onChange={e => this.handleChangeAddress(e, 0)}
                              value={company.address[0].city}
                              name="city"
                            />
                          </div>
                          <div className="col-2">
                            <select
                              className="form-control"
                              name="country"
                              value={company.address[0].country}
                              onChange={e => this.handleChangeAddress(e, 0)}
                            >
                              {Object.values(countryList.getNames("en")).map((item: string) => (
                                <option key={item}>{item}</option>
                              ))}
                            </select>
                          </div>
                        </div>
                        <div className="form-group row">
                          <label className="col-3 col-form-label" />
                          <div className="col-9 col-xl-6">
                            <span className="form-text text-muted">
                              Primary address for quotes and invoices. Subsequent addresses could be different delivery
                              addresses.
                            </span>
                          </div>
                        </div>
                        {company.address.map((a, key) => {
                          return (
                            key > 0 && (
                              <React.Fragment key={key}>
                                <div className="form-group row">
                                  <label className="col-3 col-form-label">Address #{key + 1}</label>
                                  <div className="col-5">
                                    <input
                                      className="form-control"
                                      type="text"
                                      onChange={e => this.handleChangeAddress(e, key)}
                                      value={a.deviatingCompanyName ? a.deviatingCompanyName : ""}
                                      placeholder="(Optional) Deviating company name"
                                      name="deviatingCompanyName"
                                    />
                                  </div>
                                  <div className="col-1 text-right">
                                    <button className="btn btn-danger" onClick={() => this.handleDeleteAddress(key)}>
                                      <i className="fa fa-trash pr-0" />
                                    </button>
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label className="col-3 col-form-label" />
                                  <div className="col-6">
                                    <input
                                      className="form-control"
                                      type="text"
                                      onChange={e => this.handleChangeAddress(e, key)}
                                      value={a.careOf ? a.careOf : ""}
                                      placeholder="(Optional) c/o"
                                      name="careOf"
                                    />
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label className="col-3 col-form-label" />
                                  <div className="col-4">
                                    <input
                                      className="form-control"
                                      type="text"
                                      onChange={e => this.handleChangeAddress(e, key)}
                                      value={a.street}
                                      placeholder="Street"
                                      name="street"
                                    />
                                  </div>
                                  <div className="col-2">
                                    <input
                                      className="form-control"
                                      type="text"
                                      onChange={e => this.handleChangeAddress(e, key)}
                                      value={a.streetnr}
                                      placeholder="Nr"
                                      name="streetnr"
                                    />
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label className="col-3 col-form-label" />
                                  <div className="col-2">
                                    <input
                                      className="form-control"
                                      type="text"
                                      onChange={e => this.handleChangeAddress(e, key)}
                                      value={a.zip}
                                      placeholder="ZIP"
                                      name="zip"
                                    />
                                  </div>
                                  <div className="col-2">
                                    <input
                                      className="form-control"
                                      type="text"
                                      onChange={e => this.handleChangeAddress(e, key)}
                                      value={a.city}
                                      placeholder="City"
                                      name="city"
                                    />
                                  </div>
                                  <div className="col-2">
                                    <select
                                      className="form-control"
                                      name="country"
                                      value={a.country}
                                      onChange={e => this.handleChangeAddress(e, 0)}
                                    >
                                      {Object.values(countryList.getNames("en")).map((item: string) => (
                                        <option key={item}>{item}</option>
                                      ))}
                                    </select>
                                  </div>
                                </div>
                              </React.Fragment>
                            )
                          );
                        })}
                        <div className="form-group row">
                          <label className="col-3 col-form-label" />
                          <div className="col-9 col-xl-6 text-right">
                            <button className="btn btn-success" onClick={this.handleAddAddress}>
                              <i className="fa fa-plus pr-0" />
                            </button>
                          </div>
                        </div>
                        <div className="form-group row">
                          <label className="col-3 col-form-label">Contacts:</label>
                        </div>
                        <div className="form-group row">
                          <label className="col-3 col-form-label">Primary Contact Person</label>
                          <div className="col-9 col-xl-6">
                            <div className="input-group">
                              <select
                                className="form-control"
                                onChange={e => this.handleChangeContact(0, e.target.value)}
                                value={contacts.length > 0 && contacts[0] ? contacts[0]._id.toString() : "Not set"}
                                name="person"
                              >
                                <option value="Not set" disabled>
                                  Not set
                                </option>
                                {contacts.concat(possibleContacts).map(
                                  u =>
                                    u && (
                                      <option key={u._id.toString()} value={u._id.toString()}>
                                        {u.prename + " " + u.surname}
                                      </option>
                                    )
                                )}
                              </select>
                            </div>
                          </div>
                        </div>
                        {contacts.map((c, key) => {
                          return (
                            key > 0 && (
                              <div key={key} className="form-group row">
                                <label className="col-3">Contact #{key + 1}</label>
                                <div className="col-8 col-xl-5">
                                  <div className="input-group">
                                    <select
                                      className="form-control"
                                      onChange={e => this.handleChangeContact(key, e.target.value)}
                                      value={c ? c._id.toString() : "Not set"}
                                      name="person"
                                    >
                                      <option value="Not set" disabled>
                                        Not set
                                      </option>
                                      {contacts.concat(possibleContacts).map(
                                        u =>
                                          u && (
                                            <option key={u._id.toString()} value={u._id.toString()}>
                                              {u.prename + " " + u.surname}
                                            </option>
                                          )
                                      )}
                                    </select>
                                  </div>
                                </div>
                                <div className="col-1 text-right">
                                  <button className="btn btn-danger" onClick={() => this.handleDeleteContact(key)}>
                                    <i className="fa fa-trash pr-0" />
                                  </button>
                                </div>
                              </div>
                            )
                          );
                        })}
                        <div className="form-group row">
                          <div className="col-3" />
                          <div className="col-9 col-xl-6 text-right">
                            <button className="btn btn-success" onClick={this.handleAddContact}>
                              <i className="fa fa-plus pr-0" />
                            </button>
                          </div>
                        </div>
                        <div className="row mb-4">
                          <label className="col-3 col-form-label">Owner:</label>
                          <div className="col-9 col-xl-6">
                            <SelectEmployee
                              userdata={userdata}
                              selectedUser={owner}
                              onSelectUser={owner => this.setState({ owner })}
                            />
                          </div>
                        </div>
                        <div className="kt-portlet__foot text-right">
                          <button className="btn btn-secondary mr-2" onClick={this.resetData}>
                            Reset
                          </button>
                          {errors.length > 0 ? (
                            <OverlayTrigger
                              overlay={
                                <Tooltip id="company-invalid">
                                  {errors.map((e, key) => {
                                    return (
                                      <React.Fragment key={key}>
                                        <span className="text-danger">
                                          <b>{e}</b>
                                        </span>
                                        <br />
                                      </React.Fragment>
                                    );
                                  })}
                                </Tooltip>
                              }
                              placement="left"
                            >
                              <button className={"btn btn-success disabled"}>Save</button>
                            </OverlayTrigger>
                          ) : (
                            <button className={"btn btn-success"} onClick={this.submitChanges}>
                              Save
                            </button>
                          )}
                        </div>
                      </Tab.Pane>
                      <Tab.Pane key="user" eventKey="user" transition={false}>
                        <CompanyUsers company={company} context={this.context} {...this.props} />
                      </Tab.Pane>
                      {canAccessSCData && (
                        <Tab.Pane key="scFeatures" eventKey="scFeatures" transition={false}>
                          <SupplyCentralFeatures company={company} />
                        </Tab.Pane>
                      )}
                    </Tab.Content>
                  </div>
                </div>
              </Tab.Container>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default Company;
