import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { toAbsoluteUrl } from "../../_metronic";
import { OrdersDocument } from "../../model/orders.types";
import { DataContext } from "../../context/dataContext";
import baseUtils from "../../utils/baseUtils";
import orderUtils from "../../utils/orderUtils";
import timelineUtils, { T_DECLINED, T_FULFILLMENT } from "../../utils/timelineUtils";
import { MarginBadge, OrderWidget, PriorityBadge } from "./common/BaseListingComponents";
import CompanyWidget from "../common/CompanyWidget";
import PersonWidget from "../common/PersonWidget";
import orderCalculationUtils from "../../utils/orderCalculationUtils";
import dateUtils from "../../utils/dateUtils";
import accessUtils from "../../utils/accessUtils";

interface OrdersRowBaseProps extends RouteComponentProps {
  order: OrdersDocument;
  dateType?: { value: string; label: string };
  context: React.ContextType<typeof DataContext>;
}

export const OrdersDefaultRow: React.FunctionComponent<OrdersRowBaseProps> = ({
  order,
  dateType,
  context,
  history
}) => {
  const { userdata, companies, manufacturers } = context;
  const company = baseUtils.getDocFromCollection(companies, order.createdFor);
  const owner = baseUtils.getDocFromCollection(userdata, order.createdFrom);
  const manufacturer = baseUtils.getDocFromCollection(manufacturers, order.settings.manufacturer);
  const averageTurnover = orderUtils.getAverageValue(order, "totalprice");
  const [totalInvoices, paidInvoices] = timelineUtils.getInvoiceStats(order);
  const invoices = order.invoices;
  const canSeeFinanceData = accessUtils.canSeeFinanceData();
  let date: Date | null = null;
  if (dateType) {
    if (dateType.value === "ordered") date = order.createdOn;
    if (dateType.value === "invoice")
      date =
        order.invoices?.reduce((min: Date | null, i) => (!min || i.invoiceDate < min ? i.invoiceDate : min), null) ||
        null;
    if (dateType.value === "delivery") date = order.delivery ?? order.targetDate;
  }

  const forwardOrder = () => history.push("/order/" + order._id.toString());

  return (
    <tr className="kt-datatable__row table-hover d-table-row">
      <td className="kt-datatable__cell d-table-cell" onClick={forwardOrder}>
        <OrderWidget document={order} prefix={orderUtils.isOrder(order) ? "AT-" : "AN-"} showSubtitle={true} />
      </td>
      <td className="kt-datatable__cell d-table-cell">
        <span>
          <CompanyWidget company={company} type={"company"} />
        </span>
      </td>
      <td className="kt-datatable__cell d-table-cell">
        <span>
          <PersonWidget person={owner} />
        </span>
      </td>
      <td className="kt-datatable__cell d-table-cell" onClick={forwardOrder}>
        <span>
          <b>{orderUtils.getStateDescription(order.state)}</b>
        </span>
      </td>
      <td className="kt-datatable__cell d-table-cell" onClick={forwardOrder}>
        <div className="kt-font-dark kt-font-bold">{date ? baseUtils.formatDate(date) : "-"}</div>
        {dateType && dateType.value === "delivery" && date && `CW ${dateUtils.getCW(date)}-${date.getFullYear()}`}
      </td>
      <td className="kt-datatable__cell d-table-cell" onClick={forwardOrder}>
        <span>
          {order.calculations[0].prices.length +
            (order.calculations[0].prices.length === 1 ? " commodity" : " commodities")}
        </span>
      </td>
      {canSeeFinanceData && (
        <>
          <td className="kt-datatable__cell d-table-cell" onClick={forwardOrder}>
            <span>
              {invoices && invoices.length > 0
                ? invoices.map(invoice => (
                    <img
                      key={invoice.invoiceDate.toISOString()}
                      src={toAbsoluteUrl("/media/icons/pdf_icon.png")}
                      alt="Invoice PDF"
                      className="mr-1"
                      style={{ width: "25px" }}
                      onClick={() => window.open((process.env.REACT_APP_MEDIAHUB_BASE || "") + invoice.path, "_blank")}
                    />
                  ))
                : "-"}
            </span>
          </td>
          <td className="kt-datatable__cell d-table-cell" onClick={forwardOrder}>
            <span>{paidInvoices + " of " + totalInvoices}</span>
          </td>
        </>
      )}
      <td className="kt-datatable__cell d-table-cell" onClick={forwardOrder}>
        <span>
          {order.calculations.length === 1
            ? order.calculations[0].units
            : order.calculations.map(c => (c.units >= 1000 ? c.units / 1000 : c.units)).join(", ") + " tsd."}
        </span>
      </td>
      {canSeeFinanceData && (
        <>
          <td className="kt-datatable__cell d-table-cell" onClick={forwardOrder}>
            <span>{averageTurnover[0] + baseUtils.formatEuro(averageTurnover[1])}</span>
          </td>
          <td className="kt-datatable__cell d-table-cell" onClick={forwardOrder}>
            <span>{manufacturer ? manufacturer.name : "-"}</span>
          </td>
        </>
      )}
    </tr>
  );
};

export const OrderCommoditiesRow: React.FunctionComponent<OrdersRowBaseProps> = ({ order, context, history }) => {
  const { userdata, companies } = context;
  const company = baseUtils.getDocFromCollection(companies, order.createdFor);
  const owner = baseUtils.getDocFromCollection(userdata, order.createdFrom);
  const canSeeFinanceData = accessUtils.canSeeFinanceData();

  const forwardOrder = () => history.push("/order/" + order._id.toString());

  return (
    <tr className="kt-datatable__row table-hover d-table-row">
      <td className="kt-datatable__cell d-table-cell" onClick={forwardOrder}>
        <OrderWidget document={order} prefix={"Order AT-"} showSubtitle={false} />
      </td>
      <td className="kt-datatable__cell d-table-cell">
        <span>
          <CompanyWidget company={company} type={"company"} />
        </span>
      </td>
      <td className="kt-datatable__cell d-table-cell" onClick={forwardOrder}>
        <span>
          <span className="kt-font-dark kt-font-bold">
            {order.createdOn.toLocaleDateString("de-DE", {
              month: "numeric",
              day: "numeric",
              year: "numeric"
            })}
          </span>
        </span>
      </td>
      <td className="kt-datatable__cell d-table-cell" onClick={forwardOrder}>
        <PriorityBadge document={order} />
      </td>
      {canSeeFinanceData && (
        <>
          <td className="kt-datatable__cell d-table-cell" onClick={forwardOrder}>
            <MarginBadge order={order} />
          </td>
          <td className="kt-datatable__cell d-table-cell" onClick={forwardOrder}>
            <span>{baseUtils.formatEuro(orderCalculationUtils.getBoundCapital(order))}</span>
          </td>
        </>
      )}
      <td className="kt-datatable__cell d-table-cell" onClick={forwardOrder}>
        <span>
          <div className="progress" style={{ maxWidth: "200px" }}>
            <div
              className="progress-bar bg-success"
              role="progressbar"
              style={{
                width: orderUtils.getCommodityPercentageDone(order.calculations[0].prices, "ordered")
              }}
            />
          </div>
        </span>
      </td>
      <td className="kt-datatable__cell d-table-cell">
        <span>
          <PersonWidget person={owner} />
        </span>
      </td>
    </tr>
  );
};

export const OrderPackagingRow: React.FunctionComponent<OrdersRowBaseProps> = ({ order, context, history }) => {
  const { userdata, companies, packagings } = context;
  const company = baseUtils.getDocFromCollection(companies, order.createdFor);
  const owner = baseUtils.getDocFromCollection(userdata, order.createdFrom);
  const calculation = order.calculations[0];
  let ordered = 0;
  let delivered = 0;
  const totalPackaging = calculation.packagings.length;
  for (let price of calculation.packagings) {
    if (price && price.ordered) ordered += 1;
    if (price && price.delivered) delivered += 1;
  }
  const printingFileReq = orderUtils.getPrintingFileReq(order, packagings);

  const forwardOrder = () => history.push("/order/" + order._id.toString());

  return (
    <tr className="kt-datatable__row table-hover d-table-row">
      <td className="kt-datatable__cell d-table-cell" onClick={forwardOrder}>
        <OrderWidget document={order} prefix={"Order AT-"} showSubtitle={false} />
      </td>
      <td className="kt-datatable__cell d-table-cell">
        <span>
          <CompanyWidget company={company} type="company" />
        </span>
      </td>
      <td className="kt-datatable__cell d-table-cell" onClick={forwardOrder}>
        <span>
          {printingFileReq.required <= printingFileReq.uploaded ? (
            <span className="text-success kt-font-bold"> All files uploaded</span>
          ) : printingFileReq.required === 0 ? (
            <span> No files required</span>
          ) : (
            <React.Fragment>
              <span className="text-danger kt-font-bold">
                {printingFileReq.required -
                  printingFileReq.uploaded +
                  (printingFileReq.required - printingFileReq.uploaded === 1 ? " file pending" : " files pending")}
              </span>
              <br />
              <span>{printingFileReq.uploaded + " of " + printingFileReq.required} uploaded</span>
            </React.Fragment>
          )}
        </span>
      </td>
      <td className="kt-datatable__cell d-table-cell" onClick={forwardOrder}>
        <span>
          {ordered >= totalPackaging ? (
            <span className="text-success kt-font-bold"> All ordered</span>
          ) : totalPackaging === 0 ? (
            <span> No order required</span>
          ) : (
            <>
              <span className="text-danger kt-font-bold">
                {totalPackaging -
                  ordered +
                  (totalPackaging - ordered === 1 ? " packaging pending" : " packagings pending")}
              </span>
              <br />
              <span>{ordered + " of " + totalPackaging} ordered</span>
            </>
          )}
        </span>
      </td>
      <td className="kt-datatable__cell d-table-cell" onClick={forwardOrder}>
        <span>
          {delivered >= totalPackaging ? (
            <span className="text-success kt-font-bold"> All delivered</span>
          ) : totalPackaging === 0 ? (
            <span> No order required</span>
          ) : (
            <>
              <span className="text-danger kt-font-bold">
                {totalPackaging -
                  delivered +
                  (totalPackaging - delivered === 1 ? " packaging pending" : " packagings pending")}
              </span>
              <br />
              <span>{delivered + " of " + totalPackaging} delivered</span>
            </>
          )}
        </span>
      </td>
      <td className="kt-datatable__cell d-table-cell" onClick={forwardOrder}>
        <span>
          <div className="progress" style={{ maxWidth: "200px" }}>
            <div
              className="progress-bar bg-success"
              role="progressbar"
              style={{
                width: orderUtils.getCommodityPercentageDone(
                  order.calculations[0].prices,
                  "ordered",
                  order.calculations[0].packagings
                )
              }}
            />
          </div>
        </span>
      </td>
      <td className="kt-datatable__cell d-table-cell">
        <PersonWidget person={owner} />
      </td>
    </tr>
  );
};

interface OrderProductionRowProps extends OrdersRowBaseProps {
  hideUpdate?: boolean;
}

export const OrderProductionRow: React.FunctionComponent<OrderProductionRowProps> = ({
  order,
  context,
  history,
  hideUpdate
}) => {
  const { userdata, companies } = context;
  const company = baseUtils.getDocFromCollection(companies, order.createdFor);
  const owner = baseUtils.getDocFromCollection(userdata, order.createdFrom);
  const canSeeFinanceData = accessUtils.canSeeFinanceData();

  const forwardOrder = () => history.push("/order/" + order._id.toString());

  return (
    <tr className="kt-datatable__row table-hover d-table-row" onClick={forwardOrder}>
      <td className="kt-datatable__cell d-table-cell">
        <OrderWidget document={order} prefix={"Order AT-"} showSubtitle={false} />
      </td>
      <td className="kt-datatable__cell d-table-cell">
        <span>
          <CompanyWidget company={company} type={"company"} />
        </span>
      </td>
      <td className="kt-datatable__cell d-table-cell" onClick={forwardOrder}>
        <span>
          <span className="kt-font-dark kt-font-bold">
            {order.createdOn.toLocaleDateString("de-DE", {
              month: "numeric",
              day: "numeric",
              year: "numeric"
            })}
          </span>
        </span>
      </td>
      <td className="kt-datatable__cell d-table-cell" onClick={forwardOrder}>
        <PriorityBadge document={order} />
      </td>
      {canSeeFinanceData && (
        <td className="kt-datatable__cell d-table-cell" onClick={forwardOrder}>
          <MarginBadge order={order} />
        </td>
      )}
      <td className="kt-datatable__cell d-table-cell" onClick={forwardOrder}>
        <span>
          <b>{order.delivery ? dateUtils.getKW(order.delivery) : "not set"}</b>
        </span>
      </td>
      {!hideUpdate && (
        <td className="kt-datatable__cell d-table-cell">
          <span>
            <button
              className="button btn btn-sm btn-secondary"
              onClick={e => {
                e.stopPropagation();
                history.replace("/productionplan");
              }}
            >
              Update
            </button>
          </span>
        </td>
      )}
      <td className="kt-datatable__cell d-table-cell" onClick={forwardOrder}>
        <span>
          <PersonWidget person={owner} />
        </span>
      </td>
    </tr>
  );
};

export const OrderInvoiceRow: React.FunctionComponent<OrdersRowBaseProps> = ({ order, context, history }) => {
  const { userdata, companies } = context;
  const company = baseUtils.getDocFromCollection(companies, order.createdFor);
  const owner = baseUtils.getDocFromCollection(userdata, order.createdFrom);
  const hasFfInfo = orderUtils.hasFulfillmentPriceInfo(order);
  const totalUnits = hasFfInfo ? order.fulfillment?.totalUnits! : order.calculations[0].units;
  const invoices = order.invoices || [];
  const totalInvoiced = orderUtils.totalInvoicedUnits(invoices);
  const totalInvoicedTurnover = orderUtils.totalInvoiceTurnover(invoices);
  const totalPaidTurnover = orderUtils.totalInvoiceTurnover(invoices, true);
  const fulfillmentEntry = order.timeline
    .slice()
    .reverse()
    .find(e => e.type === T_FULFILLMENT);

  const canSeeFinanceData = accessUtils.canSeeFinanceData();
  const forwardOrder = () => history.push("/order/" + order._id.toString());

  return (
    <tr className="kt-datatable__row table-hover d-table-row">
      <td className="kt-datatable__cell d-table-cell" onClick={forwardOrder}>
        <OrderWidget document={order} prefix={"Order AT-"} />
      </td>
      <td className="kt-datatable__cell d-table-cell">
        <span>
          <CompanyWidget company={company} type={"company"} />
        </span>
      </td>
      <td className="kt-datatable__cell d-table-cell" onClick={forwardOrder}>
        <span className="kt-font-dark kt-font-bold">
          {fulfillmentEntry
            ? fulfillmentEntry.date.toLocaleDateString("de-DE", {
                month: "numeric",
                day: "numeric",
                year: "numeric"
              })
            : "not set"}
        </span>
      </td>
      {canSeeFinanceData && (
        <>
          <td className="kt-datatable__cell d-table-cell" onClick={forwardOrder}>
            <span className="kt-font-bolder">
              {baseUtils.formatEuro(
                hasFfInfo ? order.fulfillment?.priceInfo?.totalPrice! : order.calculations[0].info.totalprice
              )}
            </span>
          </td>
          <td className="kt-datatable__cell d-table-cell" onClick={forwardOrder}>
            <span className="kt-font-bolder">
              {baseUtils.formatEuro(
                hasFfInfo ? order.fulfillment?.priceInfo?.totalMargin! : order.calculations[0].info.totalmargin
              )}
            </span>
          </td>
          <td className="kt-datatable__cell d-table-cell" onClick={forwardOrder}>
            <span className="kt-font-bolder">
              <div className="kt-user-card-v2">
                <div className="kt-user-card-v2__details">
                  <span
                    className={
                      totalInvoiced >= (hasFfInfo ? totalUnits : totalUnits * 0.8)
                        ? "kt-user-card-v2__name text-success"
                        : "kt-user-card-v2__name text-danger"
                    }
                  >
                    {totalInvoiced}
                  </span>
                  <span className="kt-user-card-v2__email kt-link"> of {totalUnits} units</span>
                </div>
              </div>
            </span>
          </td>
          <td className="kt-datatable__cell d-table-cell" onClick={forwardOrder}>
            <span className={"kt-font-bolder"}>
              <div className="kt-user-card-v2">
                <div className="kt-user-card-v2__details">
                  <span
                    className={
                      totalPaidTurnover >= totalInvoicedTurnover * 0.9
                        ? "kt-user-card-v2__name text-success"
                        : "kt-user-card-v2__name text-danger"
                    }
                  >
                    {baseUtils.formatEuro(totalPaidTurnover)}
                  </span>
                  <span className="kt-user-card-v2__email kt-link">
                    {" "}
                    of {baseUtils.formatEuro(totalInvoicedTurnover)}
                  </span>
                </div>
              </div>
            </span>
          </td>
        </>
      )}
      <td className="kt-datatable__cell d-table-cell">
        <span>
          <PersonWidget person={owner} />
        </span>
      </td>
    </tr>
  );
};

export const OrderDeclinedRow: React.FunctionComponent<OrdersRowBaseProps> = ({ order, context, history }) => {
  const { userdata, companies } = context;
  const company = baseUtils.getDocFromCollection(companies, order.createdFor);
  const owner = baseUtils.getDocFromCollection(userdata, order.createdFrom);
  const declinedEntry = order.timeline
    .slice()
    .reverse()
    .find(e => e.type === T_DECLINED);
  const decliner =
    declinedEntry && declinedEntry.person ? baseUtils.getDocFromCollection(userdata, declinedEntry.person) : null;

  const forwardOrder = () => history.push("/order/" + order._id.toString());

  return (
    <tr className="kt-datatable__row table-hover d-table-row">
      <td className="kt-datatable__cell d-table-cell" onClick={forwardOrder}>
        <OrderWidget document={order} prefix={"Order AT-"} />
      </td>
      <td className="kt-datatable__cell d-table-cell">
        <span>
          <CompanyWidget company={company} type={"company"} />
        </span>
      </td>
      <td className="kt-datatable__cell d-table-cell" onClick={forwardOrder}>
        <span className="kt-font-dark kt-font-bold">
          {order.createdOn.toLocaleDateString("de-DE", {
            month: "numeric",
            day: "numeric",
            year: "numeric"
          })}
        </span>
      </td>
      <td className="kt-datatable__cell d-table-cell" onClick={forwardOrder}>
        <span className="kt-font-dark kt-font-bold">
          {declinedEntry
            ? declinedEntry.date.toLocaleDateString("de-DE", {
                month: "numeric",
                day: "numeric",
                year: "numeric"
              })
            : "not set"}
        </span>
      </td>
      <td className="kt-datatable__cell d-table-cell">
        <span>{decliner ? <PersonWidget person={decliner} /> : "unknown"}</span>
      </td>
      <td className="kt-datatable__cell d-table-cell">
        <span>
          <PersonWidget person={owner} />
        </span>
      </td>
    </tr>
  );
};
