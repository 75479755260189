import _ from "lodash";
import React, { PureComponent } from "react";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { toast } from "react-toastify";
import { BSON } from "realm-web";
import { CustomOrder, ShippingLabelData } from "../CustomTypes";
import { DataContext } from "../../../context/dataContext";
import shippingLabelGeneration, {
  CUSTOMERFONT,
  CUSTOMERSIZE,
  TITLEFONT,
  TITLESIZE
} from "../../../utils/pdf/shippingLabelGeneration";
import dateUtils from "../../../utils/dateUtils";
import pdfUtils from "../../../utils/pdf/pdfUtils";
import config from "../../../config/config.json";
import orderUtils from "../../../utils/orderUtils";
import { T_SHIPPINGLABEL } from "../../../utils/timelineUtils";
import PDFPreview from "../../common/PDFPreview";
import baseUtils from "../../../utils/baseUtils";
import userService from "../../../services/userService";
import dbOrderService from "../../../services/dbServices/dbOrderService";

interface CreateShippingLabelModalProps {
  order: CustomOrder;
  disabled?: boolean;
  context: React.ContextType<typeof DataContext>;
}

interface CreateShippingLabelModalState {
  show: boolean;
  loading: boolean;
  pdfData: ShippingLabelData;
}

class CreateShippingLabelModal extends PureComponent<CreateShippingLabelModalProps, CreateShippingLabelModalState> {
  pdfOptions = {
    orientation: "landscape",
    pageSize: "A7",
    marginTop: 0,
    marginRight: 0,
    marginBottom: 0,
    marginLeft: 0
  };
  constructor(props: CreateShippingLabelModalProps) {
    super(props);
    this.state = {
      show: false,
      loading: false,
      pdfData: this.getDefaultData(props)
    };
  }

  componentDidUpdate(
    prevProps: Readonly<CreateShippingLabelModalProps>,
    prevState: Readonly<CreateShippingLabelModalState>,
    snapshot?: any
  ) {
    // Update on order changes and on show
    if ((this.state.show && !_.isEqual(prevProps.order, this.props.order)) || (!prevState.show && this.state.show)) {
      this.setState({ pdfData: this.getDefaultData(this.props) });
    }
  }

  /**
   * Get the default shipping label data
   * @param props the modal properties
   * @returns {object} default state values for pdf data and customer data
   */
  getDefaultData = (props: CreateShippingLabelModalProps) => {
    const { order } = props;
    const pdfData: ShippingLabelData = {
      title: order.title,
      lot: order.fulfillment ? order.fulfillment.lot : "",
      bestBefore:
        order.fulfillment && !_.isEqual(order.fulfillment.exp, new Date(0))
          ? `${("0" + order.fulfillment.exp.getMonth() + 1).slice(-2)}/${order.fulfillment.exp.getFullYear()}`
          : "",
      customer: order.createdFor.name,
      amount:
        order.fulfillment && order.fulfillment.shippingGroups.length > 0
          ? order.fulfillment.shippingGroups.reduce((a, b) => a + b.boxes * b.items, 0) + " items"
          : ""
    };
    return pdfData;
  };

  createShippingLabel = async () => {
    const { order } = this.props;
    const { pdfData } = this.state;
    const data = JSON.stringify({
      html: shippingLabelGeneration.createShippingLabel(order, pdfData),
      fileName:
        "ShippingLabel_" +
        order.createdFor._id.toString() +
        "_V" +
        orderUtils.getPDFVersion(order, T_SHIPPINGLABEL) +
        "_" +
        dateUtils.timeStampDate() +
        ".pdf",
      options: this.pdfOptions
    });
    let path;
    try {
      path = await pdfUtils.uploadAndReturnPath(data);
    } catch (e) {
      return { result: false, message: e.message };
    }
    return { result: true, path: path };
  };

  /**
   * Validate the length of a given text
   * @param text the text to validate
   * @param font the font to validate for, e.g. '22px Helvetica'
   * @param targetSize the maximum size the text should reach
   * @returns {boolean} True if the text is smaller than the target size, else False
   */
  validateTextLength = (text: string, font: string, targetSize: number) => {
    let c = document.getElementById("canvas");
    if (!c) return false;
    const size = baseUtils.measureTextSize(c, font, text);
    return size < targetSize;
  };

  handleCreate = async () => {
    const { order } = this.props;
    this.setState({ loading: true });
    try {
      const pdfResult = await this.createShippingLabel();
      if (!pdfResult.result || !pdfResult.path) {
        toast.error("Shipping label creation failed: " + pdfResult.message);
        this.setState({ loading: false });
        return;
      }
      window.open((process.env.REACT_APP_MEDIAHUB_BASE || "") + pdfResult.path, "_blank");
      const timelineEntry = {
        id: new BSON.ObjectId(),
        type: T_SHIPPINGLABEL,
        date: new Date(),
        path: (process.env.REACT_APP_MEDIAHUB_BASE || "") + pdfResult.path,
        person: userService.getUserId()
      };
      const result = await dbOrderService.pushToTimeline(order._id, timelineEntry);
      if (result && result.modifiedCount) {
        toast.success("Shipping label successfully created");
        this.setState({ loading: false, show: false });
      } else toast.error("Adding of timeline entry failed. " + pdfResult.path);
    } catch (e) {
      toast.error("Error:", e.message);
      console.error(e);
    } finally {
      this.setState({ loading: false });
    }
  };

  handleShow = () => this.setState({ show: true });
  handleClose = () => this.setState({ show: false });

  handlePDFDataChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const pdfData = { ...this.state.pdfData };
    const key = e.target.name as keyof ShippingLabelData;
    pdfData[key] = e.target.value;
    this.setState({ pdfData });
  };

  /**
   * Render the pdf preview
   * @returns {JSX.Element} the pdf preview with required data
   */
  renderPreview = () => {
    const { order } = this.props;
    const { pdfData } = this.state;
    const fileName = "ENTWURF_ShippingLabel_AT-" + order.identifier + "_" + dateUtils.timeStampDate() + ".pdf";
    return (
      <PDFPreview
        createPDF={() => shippingLabelGeneration.createShippingLabel(order, pdfData)}
        fileName={fileName}
        pdfOptions={this.pdfOptions}
      />
    );
  };

  render() {
    const { disabled, order } = this.props;
    const { loading, show, pdfData } = this.state;
    const titleValid = this.validateTextLength(pdfData.title, TITLEFONT, TITLESIZE);
    const customerValid = this.validateTextLength(pdfData.customer, CUSTOMERFONT, CUSTOMERSIZE);
    const amountValid = this.validateTextLength(pdfData.amount, CUSTOMERFONT, CUSTOMERSIZE);
    const lotValid = this.validateTextLength(pdfData.lot, CUSTOMERFONT, CUSTOMERSIZE);
    const expiryValid = this.validateTextLength(pdfData.bestBefore, CUSTOMERFONT, CUSTOMERSIZE);
    return (
      <>
        <canvas id="canvas" className="d-none" />
        <button
          className={"btn btn-label-brand btn-sm btn-bold mr-3 my-1 " + (disabled ? "btn-disabled" : "")}
          onClick={this.handleShow}
          disabled={disabled}
        >
          <i className="fas fa-file-export" />
          Shipping Label
        </button>
        <Modal show={show} onHide={this.handleClose} centered name={"createShippingLabelModal"}>
          <Modal.Header closeButton>
            <Modal.Title>
              <i className="kt-font-brand fas fa-file-export mr-2" />
              Create Shipping Label
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-12">
                <div className="input-group row mb-2">
                  <label className="col-lg-3 col-form-label kt-widget24__stats kt-font-dark kt-font-bold">Order:</label>
                  <div className="input-group-prepend">
                    <span className="input-group-text">AT-</span>
                  </div>
                  <input type="text" className="form-control" disabled value={order.identifier} />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="input-group row mb-2">
                  <label className="col-lg-3 col-form-label kt-widget24__stats kt-font-dark kt-font-bold">Title:</label>
                  <OverlayTrigger
                    show={titleValid ? false : undefined}
                    overlay={
                      <Tooltip id="titleSizeWarning">
                        <span>Text is too large and will be cropped on the shipping label</span>
                      </Tooltip>
                    }
                  >
                    <input
                      type="text"
                      className={"form-control " + (titleValid ? "" : "is-invalid")}
                      placeholder="Title"
                      value={pdfData.title}
                      name={"title"}
                      onChange={this.handlePDFDataChange}
                    />
                  </OverlayTrigger>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="input-group row mb-2">
                  <label className="col-lg-3 col-form-label kt-widget24__stats kt-font-dark kt-font-bold">
                    Amount:
                  </label>
                  <OverlayTrigger
                    show={amountValid ? false : undefined}
                    overlay={
                      <Tooltip id="amountSizeWarning">
                        <span>Text is too large. Shipping label may be malformed</span>
                      </Tooltip>
                    }
                  >
                    <input
                      type="text"
                      className={"form-control " + (amountValid ? "" : "is-invalid")}
                      placeholder="e.g. 300 items"
                      value={pdfData.amount}
                      name={"amount"}
                      onChange={this.handlePDFDataChange}
                    />
                  </OverlayTrigger>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="input-group row mb-2">
                  <label className="col-lg-3 col-form-label kt-widget24__stats kt-font-dark kt-font-bold">LOT:</label>
                  <OverlayTrigger
                    show={lotValid ? false : undefined}
                    overlay={
                      <Tooltip id="lotSizeWarning">
                        <span>Text is too large. Shipping label may be malformed</span>
                      </Tooltip>
                    }
                  >
                    <input
                      type="text"
                      className={"form-control " + (lotValid ? "" : "is-invalid")}
                      placeholder="LOT"
                      value={pdfData.lot}
                      name={"lot"}
                      onChange={this.handlePDFDataChange}
                    />
                  </OverlayTrigger>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="input-group row mb-2">
                  <label className="col-lg-3 col-form-label kt-widget24__stats kt-font-dark kt-font-bold">
                    Best before:
                  </label>
                  <OverlayTrigger
                    show={expiryValid ? false : undefined}
                    overlay={
                      <Tooltip id="expirySizeWarning">
                        <span>Text is too large. Shipping label may be malformed</span>
                      </Tooltip>
                    }
                  >
                    <input
                      type="text"
                      className={"form-control " + (expiryValid ? "" : "is-invalid")}
                      placeholder="e.g. 03/2024"
                      value={pdfData.bestBefore}
                      name={"bestBefore"}
                      onChange={this.handlePDFDataChange}
                    />
                  </OverlayTrigger>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="input-group row mb-2">
                  <label className="col-lg-3 col-form-label kt-widget24__stats kt-font-dark kt-font-bold">
                    Customer:
                  </label>
                  <OverlayTrigger
                    show={customerValid ? false : undefined}
                    overlay={
                      <Tooltip id="customerSizeWarning">
                        <span>Text is too large and will be cropped on the shipping label</span>
                      </Tooltip>
                    }
                  >
                    <input
                      type="text"
                      className={"form-control " + (customerValid ? "" : "is-invalid")}
                      placeholder="customer"
                      value={pdfData.customer}
                      name={"customer"}
                      onChange={this.handlePDFDataChange}
                    />
                  </OverlayTrigger>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-secondary" onClick={this.handleClose}>
              Close
            </button>
            {this.renderPreview()}
            <button
              className={
                "btn btn-success " +
                (loading || Object.values(pdfData).some(val => val.trim() === "") ? "disabled" : "")
              }
              disabled={loading || Object.values(pdfData).some(val => val.trim() === "")}
              onClick={this.handleCreate}
            >
              {loading && (
                <div className="button-splash-spinner d-inline pr-3 pl-0 mx-0">
                  <svg className="button-splash-spinner" viewBox="0 0 50 50">
                    <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5" />
                  </svg>
                </div>
              )}
              Create Shipping Label
            </button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default CreateShippingLabelModal;
