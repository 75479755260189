import React, { useCallback, useMemo } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import i18n from "../../../translations/i18n";
import { useDataContext } from "../../../context/dataContext";
import { resolveWarehouseTimelineText } from "../../../utils/warehouseTimelineUtils";
import { BatchTimelineSubType } from "../../../model/warehouse/batch.types";
import { ExtendedBatchTimelineEntry } from "../../order/CustomTypes";
import { OrdersDocument } from "../../../model/orders.types";
import baseUtils from "../../../utils/baseUtils";
import { useWarehouseDispatch, WarehouseActionType } from "../../../context/warehouseContext";

interface WarehouseUpdatesPanelProps {}

const WarehouseUpdatesPanel: React.FC<WarehouseUpdatesPanelProps> = () => {
  const dataContext = useDataContext();
  const { batch, orders } = dataContext;

  const [tab, setTab] = React.useState<BatchTimelineSubType>(BatchTimelineSubType.BATCH);

  const handleChangeTab = useCallback((tab: BatchTimelineSubType) => {
    setTab(tab);
  }, []);

  const timelineEntries = useMemo(() => {
    const currentDate = new Date();
    const cutoffDate = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, currentDate.getDate()); // one month before
    const entries: Array<ExtendedBatchTimelineEntry> = [];
    for (let i = 0; i < batch.length; i++) {
      const currentBatch = batch[i];
      if (currentBatch.timeline.length === 0) continue;
      for (let j = 0; j < currentBatch.timeline.length; j++) {
        const currentTimelineEntry = currentBatch.timeline[j];
        if (currentTimelineEntry && currentTimelineEntry.date > cutoffDate) {
          entries.push({ ...currentTimelineEntry, lot: currentBatch.lot });
        }
      }
    }
    return entries;
  }, [batch]);

  const filteredTimelineEntries = useMemo(() => {
    return timelineEntries.filter(e => e.subtype === tab).sort((a, b) => b.date.getTime() - a.date.getTime());
  }, [timelineEntries, tab]);

  return (
    <div className="kt-portlet">
      <div className="kt-portlet__head">
        <div className="kt-portlet__head-label">
          <h4 className="kt-portlet__head-title kt-font-bolder">{i18n.t("warehouse:activities")}</h4>
        </div>
        <div className="kt-portlet__head-toolbar">
          <ul className="nav nav-tabs nav-bold nav-tabs-line">
            <li className="nav-item cursorhand">
              <span
                className={"nav-link" + (tab === BatchTimelineSubType.PACKAGINGUNIT ? " active" : "")}
                data-toggle="tab"
                onClick={() => handleChangeTab(BatchTimelineSubType.PACKAGINGUNIT)}
              >
                {i18n.t("warehouse:unit")}
              </span>
            </li>
            <li className="nav-item cursorhand">
              <span
                className={"nav-link" + (tab === BatchTimelineSubType.BATCH ? " active" : "")}
                data-toggle="tab"
                onClick={() => handleChangeTab(BatchTimelineSubType.BATCH)}
              >
                {i18n.t("warehouse:batch")}
              </span>
            </li>
            <li className="nav-item cursorhand">
              <span
                className={"nav-link" + (tab === BatchTimelineSubType.DOCUMENT ? " active" : "")}
                data-toggle="tab"
                onClick={() => handleChangeTab(BatchTimelineSubType.DOCUMENT)}
              >
                {i18n.t("warehouse:document")}
              </span>
            </li>
          </ul>
        </div>
      </div>
      <div className="kt-portlet__body overflow-auto" style={{ height: "600px" }}>
        <WarehouseUpdatesTimeline batchWithTimelineEntries={filteredTimelineEntries} orders={orders} />
      </div>
    </div>
  );
};

interface TimelineEntryData {
  timeLabel: string;
  content: { text: string; tooltip: JSX.Element | string };
  lot: string;
  person: string;
}

interface WarehouseUpdatesTimeline {
  batchWithTimelineEntries: Array<ExtendedBatchTimelineEntry>;
  orders: Array<OrdersDocument>;
}

const WarehouseUpdatesTimeline: React.FC<WarehouseUpdatesTimeline> = ({ batchWithTimelineEntries, orders }) => {
  const timelineEntries: Array<TimelineEntryData> = useMemo(() => {
    return batchWithTimelineEntries.map(t => {
      const timeLabel = baseUtils.formatDate(t.date);
      const content = resolveWarehouseTimelineText(t, orders);
      return {
        timeLabel,
        content,
        lot: t.lot,
        person: `${t.person.prename} ${t.person.surname}`
      };
    });
  }, [batchWithTimelineEntries, orders]);

  return (
    <div className="timeline timeline-5">
      {timelineEntries.map((entry, idx) => (
        <WarehouseUpdatesTimelineEntry key={idx} entry={entry} />
      ))}
    </div>
  );
};

interface WarehouseUpdatesTimelineEntryProps {
  entry: TimelineEntryData;
}

const WarehouseUpdatesTimelineEntry: React.FC<WarehouseUpdatesTimelineEntryProps> = ({ entry }) => {
  const dispatch = useWarehouseDispatch();

  const handleSearchBatch = useCallback(() => {
    window.scrollTo({ left: 0, top: 0, behavior: "auto" });
    dispatch({
      type: WarehouseActionType.SEARCH,
      payload: { query: entry.lot }
    });
  }, [entry]);

  return (
    <div className="timeline-item">
      <div className="timeline-label">{entry.timeLabel}</div>
      <div className="timeline-badge">
        <span />
      </div>
      {entry.content.tooltip ? (
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip id="tooltip">
              <div className="text-left font-weight-bold">{entry.person}</div>
              {entry.content.tooltip}
            </Tooltip>
          }
        >
          <div className="timeline-content text-black" onClick={handleSearchBatch}>
            {entry.content.text}
          </div>
        </OverlayTrigger>
      ) : (
        <div className="timeline-content text-black" onClick={handleSearchBatch}>
          {entry.content.text}
        </div>
      )}
    </div>
  );
};

export default WarehouseUpdatesPanel;
