import { BSON } from "realm-web";
import React, { PureComponent } from "react";
import { CommoditiesDocument, CommodityOrder } from "../../../../model/commodities.types";
import { DataContext } from "../../../../context/dataContext";
import { OrdersDocument } from "../../../../model/orders.types";
import { ExternalManufacturerOrdersDocument } from "../../../../model/externalManufacturerOrders.types";
import commodityUtils from "../../../../utils/commodityUtils";

interface CommodityOrderDistributionProps {
  commodity: CommoditiesDocument;
  context: React.ContextType<typeof DataContext>;
  commodityOrder: CommodityOrder;
  relatedOrders: Array<OrdersDocument>;
  relatedExternalOrders: Array<ExternalManufacturerOrdersDocument>;
  removedOrders: Array<BSON.ObjectId>;
  removedExternalOrders: Array<BSON.ObjectId>;
  usageMap: { [key: string]: number | string };
  onUpdateUsageMap: (manufacturer: string, amount: React.ChangeEvent<HTMLInputElement>) => void;
  warehouseDestination: string;
}

class CommodityOrderDistribution extends PureComponent<CommodityOrderDistributionProps> {
  getRelevantOrders = () => {
    const { relatedOrders, relatedExternalOrders, removedOrders, removedExternalOrders } = this.props;
    const rOIDs = removedOrders.map(rO => rO.toString());
    const rEMOIDs = removedExternalOrders.map(rEMO => rEMO.toString());

    const orders = relatedOrders.filter(rO => !rOIDs.includes(rO._id.toString()));
    const externalOrders = relatedExternalOrders.filter(rEMO => !rEMOIDs.includes(rEMO._id.toString()));
    return { orders, externalOrders };
  };

  getRequiredAmount = (orders: Array<OrdersDocument>, externalOrders: Array<ExternalManufacturerOrdersDocument>) => {
    const { commodity } = this.props;
    return (
      orders.reduce(
        (sum, o) =>
          sum +
          commodityUtils.calculateUsage(
            o,
            o.calculations[0].prices.find(p => p._id.toString() === commodity._id.toString())!,
            commodity.type,
            false
          ).raw,
        0
      ) + externalOrders.reduce((a, b) => a + b.amount, 0)
    );
  };

  render() {
    const { commodity, usageMap, warehouseDestination, onUpdateUsageMap } = this.props;
    const { manufacturers } = this.props.context;
    const { orders, externalOrders } = this.getRelevantOrders();
    const warehouse = manufacturers.find(m => m._id.toString() === warehouseDestination);

    return (
      <>
        <div className="h5 text-dark mb-5">Distribution Settings</div>
        {(orders.length > 0 || externalOrders.length > 0) && (
          <div className="form-group row mb-2">
            <label className="col-3 col-form-label kt-font-dark kt-font-bold text-right">Total Required</label>
            <div className="col-9 align-self-center">
              <span className="text-dark font-weight-bold">
                {commodityUtils.resolveStockUnit(this.getRequiredAmount(orders, externalOrders), commodity.type)}
              </span>
            </div>
          </div>
        )}
        {warehouse && (
          <>
            <div className="h6 text-dark">Warehouse</div>
            <div className="form-group row mb-2">
              <label className="col-3 col-form-label kt-font-dark kt-font-bold text-right">{warehouse.name}</label>
              <div className="col-9">
                <div className="input-group">
                  <input
                    className="form-control"
                    type="number"
                    value={(usageMap[warehouse._id.toString()] || 0).toString()}
                    name={warehouse.name}
                    disabled={true}
                  />
                  <div className="input-group-append">
                    <span className="input-group-text">kg</span>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        {externalOrders.length > 0 && (
          <>
            <div className="h6 text-dark">External Manufacturing Orders</div>
            {externalOrders.map(emo => (
              <div key={emo._id.toString()} className="form-group row mb-2">
                <label className="col-3 col-form-label kt-font-dark kt-font-bold text-right">
                  EMO-{emo.identifier}
                </label>
                <div className="col-9">
                  <div className="input-group">
                    <input
                      className="form-control"
                      type="number"
                      value={emo.amount}
                      name={emo._id.toString()}
                      disabled={true}
                    />
                    <div className="input-group-append">
                      <span className="input-group-text">kg</span>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </>
        )}
        <div className="h6 text-dark">Manufacturers</div>
        {manufacturers
          .filter(m => m._id.toString() !== warehouseDestination && m._id.toString() in usageMap)
          .map(m => (
            <div key={m._id.toString()} className="form-group row mb-2">
              <label className="col-3 col-form-label kt-font-dark kt-font-bold text-right">{m.name}</label>
              <div className="col-9">
                <div className="input-group">
                  <input
                    className="form-control"
                    type="number"
                    value={(usageMap[m._id.toString()] || 0).toString()}
                    name={m.name}
                    min={0}
                    onChange={e => onUpdateUsageMap(m._id.toString(), e)}
                  />
                  <div className="input-group-append">
                    <span className="input-group-text">kg</span>
                  </div>
                </div>
              </div>
            </div>
          ))}
      </>
    );
  }
}

export default CommodityOrderDistribution;
