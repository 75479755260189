import _ from "lodash";
import React, { PureComponent } from "react";
import Select from "react-select";
import { Modal } from "react-bootstrap";
import { BSON } from "realm-web";
import { CommoditiesDocument, Forecast } from "../../model/commodities.types";
import commodityReportPDFGeneration from "../../utils/pdf/commodityReportPDFGeneration";
import pdfUtils from "../../utils/pdf/pdfUtils";
import config from "../../config/config.json";

interface CreateCommodityReportProps {
  commodities: Array<CommoditiesDocument>;
}
interface CreateCommodityReportState {
  period: number;
  background: string;
  summary: string;
  results: Array<{ _id: BSON.ObjectId; forecast: Forecast }>;
  show: boolean;
  loading: boolean;
}

class CreateCommodityReport extends PureComponent<CreateCommodityReportProps, CreateCommodityReportState> {
  constructor(props: CreateCommodityReportProps) {
    super(props);
    this.state = {
      period: 30,
      background:
        "https://images.unsplash.com/photo-1633990017009-4d1eab0bf0d2?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1",
      summary: "",
      results: [],
      show: false,
      loading: false
    };
  }

  handleShow = () => this.setState({ show: true });
  handleClose = () => this.setState({ show: false });

  handleChangeSelect = (entry: number) => {
    this.getForecasts(entry);
    this.setState({ period: entry });
  };

  handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    // @ts-ignore
    this.setState({ [e.target.name]: e.target.value });
  };

  /**
   * Collects all forecasts for the specified time period.
   * @param specificPeriod: Optional, period that should be checked for forecasts
   */
  getForecasts = (specificPeriod?: number) => {
    const { commodities } = this.props;
    const { period } = this.state;
    const periodShown = specificPeriod || period;
    let results = [];
    for (let i = 0; i < commodities.length; i++) {
      if (commodities[i].forecast.length > 0) {
        let forecast = _.sortBy(commodities[i].forecast, "date").reverse();
        for (let j = 0; j < forecast.length; j++) {
          if (forecast[j].date.getTime() > new Date().setDate(new Date().getDate() - periodShown)) {
            results.push({ _id: commodities[i]._id, forecast: forecast[j] });
            break;
          }
        }
      }
    }
    this.setState({ results });
  };

  /**
   * Execute the PDF generation in backend and return the link to the report.
   * @param html: Content of commodity report as HTML
   * @returns { Promise<string> } Path to the processed report
   */
  generateReportPDF = (html: string) => {
    return pdfUtils.uploadAndReturnPath(html);
  };

  /**
   * Generate the commodity report and opens it in a new tab.
   */
  createCommodityReport = async () => {
    const { commodities } = this.props;
    const { background, results, summary } = this.state;
    this.setState({ loading: true });
    const reportPDF = JSON.stringify({
      html: commodityReportPDFGeneration.createCommodityReport(results, commodities, summary, background),
      options: {
        marginLeft: 0,
        marginRight: 0,
        marginTop: 0,
        marginBottom: 0
      },
      fileName: "Rohstoffreport_" + new Date().toLocaleString("de-DE", { month: "long", year: "numeric" }) + ".pdf"
    });
    let path = await this.generateReportPDF(reportPDF);
    this.setState({ loading: false, show: false });
    window.open((process.env.REACT_APP_MEDIAHUB_BASE || "") + path, "_blank");
  };

  componentDidMount() {
    this.getForecasts();
  }

  render() {
    const { background, loading, period, results, show, summary } = this.state;
    return (
      <>
        <button type="button" className="btn btn-secondary mr-2" onClick={this.handleShow}>
          Create Report
        </button>
        <Modal show={show} onHide={this.handleClose} centered size="lg">
          <Modal.Header closeButton>
            <Modal.Title>Create Commodity Report</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group row">
              <label className="col-lg-3 col-form-label kt-font-dark kt-font-bold">Period:</label>
              <div className="col-lg-9 kt-form__control">
                <Select
                  className="select-default"
                  options={[
                    { value: 7, label: " 7 Days" },
                    { value: 30, label: "30 Days" },
                    { value: 60, label: "60 Days" },
                    { value: 90, label: "90 Days" },
                    { value: 180, label: "180 Days" },
                    { value: 365, label: "365 Days" }
                  ]}
                  value={{ value: period, label: period + " Days" }}
                  onChange={(value: any) => this.handleChangeSelect(value.value || 30)}
                />
                <span className="form-text kt-font-dark">
                  <span className="text-success">{results.length} commodities</span> were updated during this period.
                </span>
              </div>
            </div>
            <div className="form-group row">
              <label className="col-lg-3 col-form-label kt-font-dark kt-font-bold">Background:</label>
              <div className="col-lg-9">
                <input
                  className="form-control"
                  type="text"
                  name="background"
                  value={background}
                  onChange={this.handleChange}
                />
                <span className="form-text kt-font-dark">
                  The background is used for the first page of the commodity report.{" "}
                  <span className="text-danger">Please provide a link to the image.</span>
                </span>
              </div>
            </div>
            <div className="form-group row">
              <label className="col-lg-3 col-form-label kt-font-dark kt-font-bold">Summary (DE):</label>
              <div className="col-lg-9">
                <textarea
                  className="form-control"
                  name="summary"
                  rows={7}
                  value={summary}
                  onChange={e => this.handleChange(e)}
                  placeholder="Details on general developments on the commodity market and the impact on price development"
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button type="button" className="btn btn-secondary" onClick={this.handleClose}>
              Cancel
            </button>
            <button
              type="button"
              className={"btn btn-success " + (loading ? "disabled" : "")}
              disabled={loading}
              onClick={this.createCommodityReport}
            >
              {loading && (
                <div className="button-splash-spinner d-inline pr-3 pl-0 mx-0">
                  <svg className="button-splash-spinner" viewBox="0 0 50 50">
                    <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5" />
                  </svg>
                </div>
              )}
              {loading ? "Please wait..." : "Create Commodity Report"}
            </button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default CreateCommodityReport;
