import baseUtils from "./baseUtils";
import config from "../config/config.json";
import { OrderFileTypes } from "../model/orders.types";

const COMMODITYDOCUMENTS = [
  "specification",
  "flowChart",
  "MSDS",
  "testReport",
  "compositionStatement",
  "nutritionalValues",
  "naturalStatement",
  "rawMaterialOriginStatement",
  "CoA"
];

export const DEFAULT_SEPARATOR = ";";

/**
 * Check whether the given file type is a commodity related type
 * @param type: Type of the file
 * @returns {boolean} True if commodity document, false if not
 */
function isCommodityDocument(type: string) {
  return COMMODITYDOCUMENTS.includes(type);
}

/**
 * Resolves the file extension of the given file name.
 * @param filename: Name whose extension should be resolved
 * @return {string} Extension or empty string
 */
function getFileExtension(filename: string) {
  const ext = /^.+\.([^.]+)$/.exec(filename);
  return ext == null ? "" : ext[1];
}

/**
 * Get file description with file size for a file
 * @param file file or null/undefined
 * @returns {string} file description including size
 */
function getFileDescription(file?: File) {
  if (!file) return "No file selected";
  const fileExtension = getFileExtension(file.name);
  const fileName = file.name.length > 25 ? file.name.slice(0, 22) + "..." + fileExtension : file.name;
  const fileSize =
    file.size < 1024 * 1024
      ? Math.round((file.size / 1024) * 100) / 100 + " KB"
      : Math.round((file.size / (1024 * 1024)) * 100) / 100 + " MB";
  return `${fileName} (${fileSize})`;
}

/**
 * Resolves the displayable text for the given file type
 * @param type: File type
 * @returns {string} Displayable name or type if none matching
 */
function getDisplayableText(type: string) {
  switch (type) {
    case "specification":
      return "Specification";
    case "flowChart":
      return "Flow Chart";
    case "testReport":
      return "Test Report";
    case "compositionStatement":
      return "Composition Statement";
    case "nutritionalValues":
      return "Nutritional Values";
    case "naturalStatement":
      return "Natural Statement";
    case "rawMaterialOriginStatement":
      return "Raw Material Origin Statement";
    case OrderFileTypes.PRODUCTION_REPORT:
      return "Production Report";
    case OrderFileTypes.QR_CODE:
      return "QR Code";
    case "MSDS":
    case "CoA":
    default:
      return type;
  }
}

/**
 * Generate a CSV from the given data.
 * @param headers: Headers of the CSV, separate so that length mismatches can be detected
 * @param content: Content of the CSV
 * @param separator: Optional, separator for the values
 * @param withExcelWorkaround: Optional, if set the sep=<separator> line is added
 * @returns { string } Data as CSV
 */
function exportAsCSV(
  headers: Array<string>,
  content: Array<Array<string>>,
  separator?: string,
  withExcelWorkaround?: boolean
) {
  if (!separator) separator = ",";
  // Quotes are added to wrap entries
  let csv = "";
  if (withExcelWorkaround) csv = "sep=" + separator + "\n";
  csv += headers.join(`${separator}`) + "\n";
  for (let i = 0; i < content.length; i++) {
    const c = content[i];
    if (headers.length !== c.length) console.warn("Length of content line", i, "not equal to header length");
    csv += c.join(`${separator}`) + "\n";
  }
  return csv;
}

/**
 * Download the given content as a file with the given name.
 * Source: https://stackoverflow.com/a/68146412/14564983
 * @param content: Content of the file
 * @param fileName: Name of the file
 * @param contentType: Type of the content
 */
function downloadFile(content: string, fileName: string, contentType: string) {
  const blob = new Blob([content], { type: contentType });
  const url = URL.createObjectURL(blob);

  // Create a link to download it
  const a = document.createElement("a");
  a.href = url;
  a.setAttribute("download", fileName);
  a.click();
}

/**
 * Parses the given csv.
 * @param csv: Fields that are separated by line
 * @param separator: Optional, if set the given one is used, else a comma is used
 * @returns { Array<Array<string>> } Parsed csv
 */
export function parseCSV(csv: Array<string>, separator?: string) {
  const content: Array<Array<string>> = [];
  for (let i = 0; i < csv.length; i++) {
    content.push(csv[i].split(separator ?? ","));
  }
  return content;
}

/**
 * Removes the desired separator from the given content.
 * @param content String that should be purged for usage in CSV
 * @param separator Separator of the CSV
 * @returns { string } Purged string
 */
export function purgeStringForCSV(content: string, separator?: string): string {
  if (!separator) separator = ",";
  return content.replaceAll(separator, separator === "," ? ";" : ",").replaceAll(/[\r\n]+/g, " ");
}

/**
 * Upload a file to mediahub
 * @param file the file to upload
 * @param fileName optional, file name
 * @returns {string|false} path to file in case of success or false if an error occurred
 */
function uploadFile(file: File, fileName?: string): string | false {
  let name = fileName;
  if (!name) {
    const fileExtension = getFileExtension(file.name);
    name = baseUtils.encodeString(file.name.split(".")[0]) + "." + fileExtension;
  }
  const xhr = new XMLHttpRequest();
  xhr.open("POST", (process.env.REACT_APP_UPLOAD_ENDPOINT || "") + name, false);
  xhr.setRequestHeader("Content-Type", "application/octate-stream");
  xhr.send(file);
  if (xhr.status === 200) {
    return (process.env.REACT_APP_MEDIAHUB_FILE_BASE || "") + xhr.responseText;
  } else {
    console.error("Status code:", xhr.status, xhr.responseText);
    return false;
  }
}

export default {
  getDisplayableText,
  getFileExtension,
  getFileDescription,
  downloadFile,
  exportAsCSV,
  uploadFile
};
