import React, { PureComponent } from "react";
import { toast } from "react-toastify";
import {
  CalculationType,
  ExtendedCapsule,
  ExtendedCustomer,
  Preferences,
  SelectedCommoditiesDocument,
  SelectedPackagingsDocument
} from "./CustomTypes";
import { DataContext } from "../../context/dataContext";
import pdfUtils from "../../utils/pdf/pdfUtils";
import offerPDFGeneration from "../../utils/pdf/offerPDFGeneration";
import ConfiguratorHelper from "./ConfiguratorHelper";
import config from "../../config/config.json";
import dateUtils from "../../utils/dateUtils";
import baseUtils from "../../utils/baseUtils";

interface OfferPdfPreviewProps {
  activeType: string;
  preferences: Preferences;
  calculations: Array<CalculationType>;
  recipe: Array<SelectedCommoditiesDocument>;
  selectedPackaging: Array<SelectedPackagingsDocument>;
  selectedCapsule: ExtendedCapsule | null;
  customer: ExtendedCustomer | null;
}

interface OfferPdfPreviewState {
  loading: boolean;
}

class OfferPdfPreview extends PureComponent<OfferPdfPreviewProps, OfferPdfPreviewState> {
  static contextType = DataContext;
  context!: React.ContextType<typeof DataContext>;
  constructor(props: OfferPdfPreviewProps) {
    super(props);
    this.state = { loading: false };
  }

  convertHtmlToPdf = async () => {
    const { activeType, preferences, calculations, recipe, selectedPackaging, selectedCapsule, customer } = this.props;
    if (!customer) return;
    this.setState({ loading: true });
    const order = ConfiguratorHelper.createOrderObject(
      activeType,
      preferences,
      recipe,
      selectedPackaging,
      selectedCapsule,
      calculations,
      customer
    );
    if (!order) {
      toast.error("Something went wrong. Please try again later");
      return;
    }
    const data = JSON.stringify({
      html: offerPDFGeneration.createOffer(
        order,
        order.settings,
        customer as any,
        preferences.bulk || order.calculations[0].packagings.length === 0,
        true,
        this.context,
        true,
        false
      ),
      fileName:
        "ENTWURF_Produktspezifikation" +
        (order.title ? "_" + baseUtils.encodeString(order.title) : "") +
        "_" +
        dateUtils.timeStampDate() +
        ".pdf"
    });
    let path;
    try {
      path = await pdfUtils.uploadAndReturnPath(data);
    } catch (e) {
      toast.error(
        <b>
          <i className="fa fa-exclamation mr-2" />
          {"An unexpected error occurred: " + e.message}
        </b>
      );
    }

    if (path) {
      window.open((process.env.REACT_APP_MEDIAHUB_BASE || "") + path, "_blank");
    } else {
      toast.error(
        <b>
          <i className="fa fa-exclamation mr-2" />
          Offer preview pdf could not be created
        </b>
      );
    }
    this.setState({ loading: false });
  };

  render() {
    const { loading } = this.state;
    return (
      <button
        type="button"
        className={loading ? "btn btn-secondary mr-2 disabled" : "btn btn-secondary mr-2 "}
        disabled={loading}
        onClick={this.convertHtmlToPdf}
      >
        {loading && (
          <div className="button-splash-spinner d-inline pr-3 pl-0 mx-0">
            <svg className="button-splash-spinner" viewBox="0 0 50 50">
              <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5" />
            </svg>
          </div>
        )}
        {loading ? "Creating Preview..." : "Show Preview"}
      </button>
    );
  }
}

export default OfferPdfPreview;
