import Joi from "joi";
import React, { Component } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import countryList from "i18n-iso-countries";
import SelectEmployee from "../common/SelectEmployee";
import { DataContext } from "../../context/dataContext";
import { UserdataDocument } from "../../model/userdata.types";
import dbService, { COMPANIES, USERDATA } from "../../services/dbService";
import userService from "../../services/userService";
import toastUtils from "../../utils/toastUtils";
import PaymentTargetSelect from "../common/PaymentTargetSelect";

interface CreateCompanyProps extends RouteComponentProps<{}, {}, {}> {}

interface CreateCompanyState {
  companyName: string;
  street: string;
  streetNr: string;
  zip: string;
  city: string;
  country: string;
  contactMail: string;
  accountingEmail: string;
  owner: UserdataDocument | undefined;
  rating: string;
  additionalInformation: string;
  primaryContact: string;
  personTitle: string;
  personPrename: string;
  personSurname: string;
  personEmail: string;
  personPosition: string;
  personPhone: string;
  saving: boolean;
  paymentTarget: number;
}

class CreateCompany extends Component<CreateCompanyProps, CreateCompanyState> {
  static contextType = DataContext;
  context!: React.ContextType<typeof DataContext>;
  emailSchema = Joi.string()
    .email({ tlds: { allow: false }, minDomainSegments: 1 })
    .required()
    .label("Email");

  constructor(props: CreateCompanyProps) {
    super(props);
    this.state = {
      companyName: "",
      street: "",
      streetNr: "",
      zip: "",
      city: "",
      country: "Germany",
      contactMail: "",
      accountingEmail: "",
      owner: userService.getUserData(),
      rating: "?",
      additionalInformation: "",
      primaryContact: "new",
      personTitle: "Mr",
      personPrename: "",
      personSurname: "",
      personEmail: "",
      personPosition: "",
      personPhone: "",
      saving: false,
      paymentTarget: 0
    };
  }

  handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    // @ts-ignore
    this.setState({ [e.target.name]: e.target.value });
  };

  submitCompany = async () => {
    const { history } = this.props;
    const {
      companyName,
      street,
      streetNr,
      zip,
      city,
      country,
      owner,
      rating,
      additionalInformation,
      contactMail,
      accountingEmail,
      primaryContact,
      personTitle,
      personPrename,
      personSurname,
      personEmail,
      personPosition,
      personPhone,
      paymentTarget
    } = this.state;
    const { updateDocumentInContext, userdata } = this.context;
    this.setState({ saving: true });

    const company = {
      companyName,
      street,
      streetnr: streetNr,
      zip,
      city,
      country,
      owner: owner ? owner : userService.getUserData(),
      rating,
      contactMail,
      accountingEmail,
      additionalInformation,
      paymentTarget
    };
    let contactPerson: any = {
      title: personTitle,
      prename: personPrename,
      surname: personSurname,
      email: personEmail,
      telephone: personPhone,
      position: personPosition
    };
    if (primaryContact !== "new") {
      contactPerson = userdata.find(u => u._id.toString() === primaryContact);
      if (!contactPerson) {
        toast.error("Contact person could not be found");
        return;
      }
    }
    const result: any = await dbService.callFunction("saveCreateCompanyAndUser", [
      company,
      contactPerson,
      primaryContact === "new"
    ]);
    await toastUtils.databaseOperationToast(
      result && result.result,
      "Company '" + company.companyName + "' created successfully",
      result.message,
      () => {
        updateDocumentInContext(COMPANIES, result.insertedCompany);
        updateDocumentInContext(USERDATA, result.insertedUser);
      }
    );

    this.setState({ saving: false });
    if (result && result.result) history.push("/company/" + result.insertedCompany.toString());
  };

  /**
   * Check the current input for errors.
   * @returns { Array<string> } Contains all validation errors
   */
  checkForErrors = () => {
    const {
      companyName,
      street,
      streetNr,
      zip,
      city,
      owner,
      contactMail,
      accountingEmail,
      primaryContact,
      personPrename,
      personSurname,
      personEmail
    } = this.state;
    const errors: Array<string> = [];
    if (companyName.trim().length < 2) errors.push("Company name too short");
    if (!street.trim()) errors.push("Primary address street missing");
    if (!streetNr.trim()) errors.push("Primary address street number missing");
    if (!zip.trim()) errors.push("Primary address zip missing");
    if (!city.trim()) errors.push("Primary address city missing");
    if (contactMail && this.emailSchema.validate(contactMail).error) errors.push("Contact email is invalid");
    if (accountingEmail && this.emailSchema.validate(accountingEmail).error) errors.push("Accounting email is invalid");
    if (!owner) errors.push("No owner assigned");
    if (primaryContact === "new") {
      if (!personPrename.trim()) errors.push("Contact prename missing");
      if (!personSurname.trim()) errors.push("Contact surname missing");
      if (this.emailSchema.validate(personEmail).error) errors.push("Contact email invalid");
    }
    return errors;
  };

  /**
   * Renders the customer rating summaries
   * @param rating: Rating whose summary should be rendered
   * @returns { JSX.Element } Customer rating summary
   */
  renderCustomerRatingSummary = (rating: string) => {
    let header, content;
    switch (rating) {
      case "A":
        header = "A-Customer";
        content = "Regular orders with high volume, good payment behavior, uncomplicated cooperation";
        break;
      case "B":
        header = "B-Customer";
        content = "Lucrative orders, normal payment behavior, but sometimes complicated cooperation";
        break;
      case "C":
        header = "C-Customer";
        content = "Small orders, little profit, high workload due to inefficient collaboration";
        break;
      default:
        header = "Unknown";
        content = "New customer for whom no experience has yet been gained";
    }
    return (
      <span className="kt-option__label">
        <span className="kt-option__head">
          <span className="kt-option__title">{header}</span>
        </span>
        <span className="kt-option__body">{content}</span>
      </span>
    );
  };

  render() {
    const { history } = this.props;
    const {
      companyName,
      street,
      streetNr,
      zip,
      city,
      country,
      owner,
      rating,
      additionalInformation,
      primaryContact,
      contactMail,
      accountingEmail,
      personTitle,
      personPrename,
      personSurname,
      personEmail,
      personPosition,
      personPhone,
      saving,
      paymentTarget
    } = this.state;
    const { userdata } = this.context;

    const possibleContacts = userdata.filter(u => !u.company_id).sort((u1, u2) => u1.surname.localeCompare(u2.surname));
    const errors = this.checkForErrors();

    return (
      <div className="kt-portlet kt-portlet--mobile">
        <div className="kt-portlet__head kt-portlet__head--lg">
          <div className="kt-portlet__head-label">
            <span className="kt-portlet__head-icon">
              <i className="kt-font-brand fa fa-building" />
            </span>
            <h3 className="kt-portlet__head-title">Create a New Company</h3>
          </div>
          <div className="kt-portlet__head-toolbar">
            <button onClick={() => history.goBack()} className="btn btn-clean kt-margin-r-10">
              <i className="la la-arrow-left" />
              <span className="kt-hidden-mobile">Back</span>
            </button>
          </div>
        </div>
        <div className="kt-portlet__body">
          <div className="row">
            <div className="col-xl-2" />
            <div className="col-xl-8">
              <div className="kt-section kt-section--first">
                <div className="kt-section__body">
                  <h3 className="kt-section__title kt-section__title-lg">Company Info:</h3>
                  <div className="form-group row">
                    <label className="col-3 col-form-label">Company name</label>
                    <div className="col-9">
                      <input
                        className="form-control"
                        type="text"
                        name="companyName"
                        value={companyName}
                        onChange={this.handleChange}
                        placeholder="Company"
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-3 col-form-label">Primary Address</label>
                    <div className="col-2">
                      <input
                        className="form-control"
                        type="text"
                        name="street"
                        value={street}
                        onChange={this.handleChange}
                        placeholder="Street"
                      />
                    </div>
                    <div className="col-1">
                      <input
                        className="form-control"
                        type="text"
                        name="streetNr"
                        value={streetNr}
                        onChange={this.handleChange}
                        placeholder="Nr."
                      />
                    </div>
                    <div className="col-2">
                      <input
                        className="form-control"
                        type="text"
                        name="zip"
                        value={zip}
                        onChange={this.handleChange}
                        placeholder="Zip"
                      />
                    </div>
                    <div className="col-2">
                      <input
                        className="form-control"
                        type="text"
                        name="city"
                        value={city}
                        onChange={this.handleChange}
                        placeholder="City"
                      />
                    </div>
                    <div className="col-2">
                      <select className="form-control" name="country" value={country} onChange={this.handleChange}>
                        {Object.values(countryList.getNames("en")).map((item: string) => (
                          <option key={item}>{item}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-3 col-form-label">Owner</label>
                    <div className="col-9">
                      <SelectEmployee
                        userdata={userdata}
                        selectedUser={owner}
                        onSelectUser={owner => this.setState({ owner })}
                      />
                    </div>
                  </div>
                  <PaymentTargetSelect
                    value={paymentTarget}
                    onChange={e => this.setState({ paymentTarget: +e.target.value })}
                    wrapperClasses={"form-group row"}
                    labelClasses={"mb-0"}
                    labelColumnClasses={"col-3 col-form-label"}
                    selectColumnClasses={"col-9"}
                  />
                  <h3 className="kt-section__title kt-section__title-lg">Optional Data:</h3>
                  <div className="form-group row mt-4">
                    <label className="col-3 col-form-label">Contact Mail</label>
                    <div className="col-9">
                      <input
                        className="form-control"
                        type="text"
                        name="contactMail"
                        value={contactMail}
                        onChange={this.handleChange}
                        placeholder="Primary contact email address"
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-3 col-form-label">Accounting E-mail</label>
                    <div className="col-9">
                      <input
                        className="form-control"
                        type="text"
                        name="accountingEmail"
                        value={accountingEmail}
                        onChange={this.handleChange}
                        placeholder="Accounting email address"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-3 equal">
                      <label className="kt-option">
                        <span className="kt-option__control">
                          <span className="kt-radio">
                            <input
                              type="radio"
                              name="rating"
                              value="A"
                              checked={rating === "A"}
                              onChange={this.handleChange}
                            />
                            <span />
                          </span>
                        </span>
                        {this.renderCustomerRatingSummary("A")}
                      </label>
                    </div>
                    <div className="col-lg-3 equal">
                      <label className="kt-option">
                        <span className="kt-option__control">
                          <span className="kt-radio">
                            <input
                              type="radio"
                              name="rating"
                              value="B"
                              checked={rating === "B"}
                              onChange={this.handleChange}
                            />
                            <span />
                          </span>
                        </span>
                        {this.renderCustomerRatingSummary("B")}
                      </label>
                    </div>
                    <div className="col-lg-3 equal">
                      <label className="kt-option">
                        <span className="kt-option__control">
                          <span className="kt-radio">
                            <input
                              type="radio"
                              name="rating"
                              value="C"
                              checked={rating === "C"}
                              onChange={this.handleChange}
                            />
                            <span />
                          </span>
                        </span>
                        {this.renderCustomerRatingSummary("C")}
                      </label>
                    </div>
                    <div className="col-lg-3 equal">
                      <label className="kt-option">
                        <span className="kt-option__control">
                          <span className="kt-radio">
                            <input
                              type="radio"
                              name="rating"
                              value="?"
                              checked={rating === "?"}
                              onChange={this.handleChange}
                            />
                            <span />
                          </span>
                        </span>
                        {this.renderCustomerRatingSummary("?")}
                      </label>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 mt-4">
                      <div className="form-group form-group-last">
                        <textarea
                          className="form-control"
                          rows={3}
                          name="additionalInformation"
                          value={additionalInformation}
                          onChange={this.handleChange}
                          placeholder="Additional Information"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="kt-separator kt-separator--border-dashed kt-separator--space-lg" />
              <div className="kt-section">
                <div className="kt-section__body">
                  <h3 className="kt-section__title kt-section__title-lg">Contact Person:</h3>
                  <div className="form-group row">
                    <label className="col-3 col-form-label">Primary contact person</label>
                    <div className="col-9">
                      <select
                        className="form-control"
                        name="primaryContact"
                        value={primaryContact}
                        onChange={this.handleChange}
                      >
                        <option value="new">Create new</option>
                        {possibleContacts.map(c => (
                          <option key={c._id.toString()} value={c._id.toString()}>
                            {c.prename + " " + c.surname}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  {primaryContact === "new" && (
                    <>
                      <div className="form-group row">
                        <label className="col-3 col-form-label">Name</label>
                        <div className="col-1">
                          <select
                            className="form-control p-0"
                            name="personTitle"
                            value={personTitle}
                            onChange={this.handleChange}
                          >
                            <option value="Mr">Mr.</option>
                            <option value="Mrs">Mrs.</option>
                          </select>
                        </div>
                        <div className="col-4">
                          <input
                            className="form-control"
                            type="text"
                            name="personPrename"
                            value={personPrename}
                            onChange={this.handleChange}
                            placeholder="Prename"
                          />
                        </div>
                        <div className="col-4">
                          <input
                            className="form-control"
                            type="text"
                            name="personSurname"
                            value={personSurname}
                            onChange={this.handleChange}
                            placeholder="Surname"
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="col-3 col-form-label">Primary Email Address</label>
                        <div className="col-9">
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <span className="input-group-text">
                                <i className="la la-at" />
                              </span>
                            </div>
                            <input
                              type="text"
                              className="form-control"
                              name="personEmail"
                              value={personEmail}
                              onChange={this.handleChange}
                              placeholder="Email"
                            />
                          </div>
                        </div>
                      </div>
                      <h3 className="kt-section__title kt-section__title-lg">Optional Data:</h3>
                      <div className="form-group row">
                        <label className="col-3 col-form-label">Position</label>
                        <div className="col-9">
                          <input
                            className="form-control"
                            type="text"
                            name="personPosition"
                            value={personPosition}
                            onChange={this.handleChange}
                            placeholder="Position"
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="col-3 col-form-label">Telephone Number</label>
                        <div className="col-9">
                          <input
                            className="form-control"
                            type="text"
                            name="personPhone"
                            value={personPhone}
                            onChange={this.handleChange}
                            placeholder="Telephone Number"
                          />
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="kt-portlet__foot text-right">
          {errors.length > 0 ? (
            <OverlayTrigger
              overlay={
                <Tooltip id="company-invalid">
                  {errors.map((e, key) => {
                    return (
                      <React.Fragment key={key}>
                        <span className="text-danger">
                          <b>{e}</b>
                        </span>
                        <br />
                      </React.Fragment>
                    );
                  })}
                </Tooltip>
              }
              placement="left"
            >
              <button className="btn btn-success disabled">Save</button>
            </OverlayTrigger>
          ) : (
            <button className="btn btn-success" disabled={saving} onClick={this.submitCompany}>
              Save
            </button>
          )}
        </div>
      </div>
    );
  }
}

export default withRouter(CreateCompany);
