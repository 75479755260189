import React, { useCallback, useState } from "react";
import { toast } from "react-toastify";
import i18n from "../../../../translations/i18n";
import { Movement } from "../../../../model/warehouse/movement.types";
import baseUtils from "../../../../utils/baseUtils";
import { resolveTranslation } from "../../../../utils/translationUtils";
import WarehouseInformation from "../common/WarehouseInformation";
import { getPackagingUnitAmount } from "../../../../utils/warehouseUtils";
import { MovementContextMenu } from "../../common/MovementContextMenu";
import { useDataContext } from "../../../../context/dataContext";
import dbService, { MOVEMENT } from "../../../../services/dbService";
import toastUtils from "../../../../utils/toastUtils";
import { useWarehouseDispatch, WarehouseActionType } from "../../../../context/warehouseContext";
import { WarehouseActionNumber } from "../../../../utils/warehouseActionUtils";
import Tooltip from "../../../common/Tooltip";

interface DefaultMovementRowProps {
  movementObj: Movement;
}

const DefaultMovementRow: React.FC<DefaultMovementRowProps> = ({ movementObj }) => {
  const dataContext = useDataContext();
  const { movement, updateDocumentInContext } = dataContext;
  const dispatch = useWarehouseDispatch();

  const [saving, setSaving] = useState<boolean>(false);

  const handleChangeMovementState = useCallback(async () => {
    const picklist = movementObj.file;
    if (!picklist || saving || movementObj.closed) return;
    setSaving(true);
    try {
      const groupedMovements = movement.filter(m => m.file?.path === picklist.path);
      if (groupedMovements.length === 1) {
        // Instantly finish single selected movement
        const res = await dbService.callFunction<boolean>("changeMovementState", [[movementObj._id.toString()]], true);
        await toastUtils.databaseOperationToast(
          res,
          i18n.t("warehouse:finishMovementSuccess"),
          i18n.t("warehouse:finishMovementError"),
          () => {
            updateDocumentInContext(MOVEMENT, movementObj._id.toString());
          }
        );
      } else if (groupedMovements.length > 1) {
        // Open modal to pick which related movements should be marked as finished (clicked movement is pre-checked)
        dispatch({
          type: WarehouseActionType.TRIGGER_ACTION,
          payload: { actionNumber: WarehouseActionNumber.FINISH_MOVEMENTS, movementIds: [movementObj._id.toString()] }
        });
      }
    } catch (e) {
      console.error(e);
      toast.error("An unexpected error occurred: " + e.message);
    } finally {
      setSaving(false);
    }
  }, [movement, movementObj.file, saving]);

  return (
    <tr className="kt-datatable__row d-table-row nopadding table-hover">
      <td className={"kt-datatable__cell d-table-cell " + (movementObj.closed && "opacity-25")}>
        <div className="kt-user-card-v2">
          <div className="kt-user-card-v2__details">
            <span className="label label-box label-light-warning label-inline" style={{ width: 80 }}>
              {i18n.t("warehouse:movement")}
            </span>
          </div>
        </div>
      </td>
      <td className={"kt-datatable__cell d-table-cell " + (movementObj.closed && "opacity-25")}>
        <div className="kt-user-card-v2">
          <div className="kt-user-card-v2__details">
            <span className="kt-user-card-v2__name text-black kt-font-bold">
              {baseUtils.truncateString(resolveTranslation(movementObj.batch.content.details.title), 30)}
            </span>
          </div>
        </div>
      </td>
      <td className={"kt-datatable__cell d-table-cell text-black " + (movementObj.closed && "opacity-25")}>
        {movementObj.batch.lot}
      </td>
      <td className={"kt-datatable__cell d-table-cell text-black " + (movementObj.closed && "opacity-25")}>
        {getPackagingUnitAmount(movementObj.movement.movedPackagingUnits)}
      </td>
      <td className={"kt-datatable__cell d-table-cell " + (movementObj.closed && "opacity-25")}>
        <WarehouseInformation location={movementObj.movement.fromLocation} />
      </td>
      <td className={"kt-datatable__cell d-table-cell " + (movementObj.closed && "opacity-25")}>
        <div className="kt-user-card-v2">
          <div className="kt-user-card-v2__details">
            <span className="kt-user-card-v2__name text-black kt-font-bold">
              {movementObj.movement.fromLocation.storageSpace?.storageSpaceNo ?? i18n.t("warehouse:entrance")}
            </span>
          </div>
        </div>
      </td>
      <td className={"kt-datatable__cell d-table-cell " + (movementObj.closed && "opacity-25")}>
        {movementObj.movement.toLocation ? (
          <WarehouseInformation location={movementObj.movement.toLocation} />
        ) : (
          // If no toLocation is given, it is moved to exit of warehouse from fromLocation
          <WarehouseInformation location={movementObj.movement.fromLocation} />
        )}
      </td>
      <td className={"kt-datatable__cell d-table-cell " + (movementObj.closed && "opacity-25")}>
        <div className="kt-user-card-v2">
          <div className="kt-user-card-v2__details">
            <span className="kt-user-card-v2__name text-black kt-font-bold">
              {movementObj.movement.toLocation
                ? movementObj.movement.toLocation.storageSpace?.storageSpaceNo ?? i18n.t("warehouse:entrance")
                : i18n.t("warehouse:outgoing")}
            </span>
          </div>
        </div>
      </td>
      <td className={"kt-datatable__cell d-table-cell " + (movementObj.closed && "opacity-25")}>
        <MovementContextMenu file={movementObj.file} />
      </td>
      <td className={"kt-datatable__cell d-table-cell " + (!movementObj.file && "opacity-25")}>
        <Tooltip tooltipText={i18n.t("warehouse:picklistMissingError")} show={!movementObj.file ? undefined : false}>
          <span className={"kt-user-card-v2__email mt-0 text-warning"}>
            <button
              className={"btn btn-sm " + (movementObj.closed ? "btn-success" : "btn-warning")}
              style={{ paddingTop: 0, paddingBottom: 0, marginTop: 0, marginBottom: 0, width: 70 }}
              onClick={handleChangeMovementState}
            >
              {i18n.t(`warehouse:${movementObj.closed ? "done" : "open"}`)}
            </button>
          </span>
        </Tooltip>
      </td>
    </tr>
  );
};

export default DefaultMovementRow;
