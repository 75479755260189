import React, { PureComponent } from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { BSON } from "realm-web";
import { CustomOrder } from "../CustomTypes";
import SplashScreen from "../../common/SplashScreen";
import userService from "../../../services/userService";
import orderUtils from "../../../utils/orderUtils";
import dateUtils from "../../../utils/dateUtils";
import pdfUtils from "../../../utils/pdf/pdfUtils";
import config from "../../../config/config.json";
import { T_QRCODECREATED } from "../../../utils/timelineUtils";
import orderQRCodeGeneration from "../../../utils/pdf/orderQRCodeGeneration";
import dbService, { ORDERS, UpdateAction } from "../../../services/dbService";
import { OrderFileTypes } from "../../../model/orders.types";

interface CreateQRCodeModalProps {
  order: CustomOrder;
  disabled?: boolean;
}

interface CreateQRCodeModalState {
  show: boolean;
  saving: boolean;
}

class CreateQRCodeModal extends PureComponent<CreateQRCodeModalProps, CreateQRCodeModalState> {
  constructor(props: CreateQRCodeModalProps) {
    super(props);
    this.state = {
      show: false,
      saving: false
    };
  }

  handleShow = () => this.setState({ show: true });
  handleClose = () => this.setState({ show: false });

  handleUpload = async () => {
    const { order } = this.props;
    this.setState({ saving: true });
    const pdfResult = await this.createQRCodePDF();
    if (!pdfResult.result || !pdfResult.path) {
      toast.error("QR code creation failed: " + pdfResult.message);
      this.setState({ saving: false });
      return;
    }
    window.open((process.env.REACT_APP_MEDIAHUB_BASE || "") + pdfResult.path, "_blank");
    const timelineEntry = {
      _id: new BSON.ObjectId(),
      type: T_QRCODECREATED,
      date: new Date(),
      person: userService.getUserId(),
      path: pdfResult.path
    };
    const file = orderUtils.getOrderPDFFile(pdfResult.path, OrderFileTypes.QR_CODE);
    try {
      const action: UpdateAction = {
        collection: ORDERS,
        filter: { _id: order._id },
        push: { timeline: timelineEntry, files: file }
      };
      const result = await dbService.transaction([action]);
      if (result) {
        toast.success("QR code created successfully");
        this.setState({ show: false });
      } else toast.error("Updating order failed. PDF: " + pdfResult.path);
    } catch (e) {
      toast.error("An unexpected error occurred: " + e.message);
    } finally {
      this.setState({ saving: false });
    }
  };

  createQRCodePDF = async () => {
    const { order } = this.props;
    const data = JSON.stringify({
      html: orderQRCodeGeneration.createOrderQRCode(order),
      fileName: "QR_AT-" + order.identifier + "_" + dateUtils.timeStampDate() + ".pdf",
      options: {
        pageSize: "A8",
        marginTop: 0,
        marginRight: 0,
        marginBottom: 0,
        marginLeft: 0
      }
    });
    let path;
    try {
      path = await pdfUtils.uploadAndReturnPath(data);
    } catch (e) {
      return { result: false, message: e.message };
    }
    return { result: true, path: path };
  };

  render() {
    const { disabled } = this.props;
    const { show, saving } = this.state;
    return (
      <>
        <button
          className={"btn btn-label-brand btn-sm btn-bold mr-3 my-1 " + (disabled ? "btn-disabled" : "")}
          onClick={this.handleShow}
          disabled={disabled}
        >
          <i className="fas fa-qrcode" />
          QR Code
        </button>
        <Modal show={show} onHide={this.handleClose} centered name={"createQRCodeModal"}>
          <Modal.Header closeButton>
            <Modal.Title>
              <i className="kt-font-brand fas fa-qrcode mr-2" />
              Create QR Code
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {saving ? (
              <>
                <SplashScreen additionalSVGStyle={{ height: "80px", width: "80px" }} />
                <h5 className="text-center">Generating QR Code. Please Wait...</h5>
              </>
            ) : (
              <h5 className="text-center">Are you sure you want to create a QR code?</h5>
            )}
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-secondary" onClick={this.handleClose}>
              Close
            </button>
            <button
              className={!saving ? "btn btn-success" : "btn btn-success disabled"}
              disabled={saving}
              onClick={saving ? undefined : this.handleUpload}
            >
              {saving && (
                <div className="button-splash-spinner d-inline pr-3 pl-0 mx-0">
                  <svg className="button-splash-spinner" viewBox="0 0 50 50">
                    <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5" />
                  </svg>
                </div>
              )}
              Create QR Code
            </button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default CreateQRCodeModal;
