import _ from "lodash";
import { Batch, BatchLocation, BatchLocationInformation, SenderType } from "../model/warehouse/batch.types";
import {
  AdditionalProductFilter,
  WarehouseContext,
  WarehouseListingTabNames,
  WarehouseLocation
} from "../context/warehouseContext";
import { BatchContentSpecificType, CommoditySpecificTypeObject, ContentType } from "../model/warehouse/common.types";
import { WarehouseConfiguration, WarehouseTypes } from "../model/configuration/warehouseConfiguration.types";
import { DataContextType } from "../context/dataContext";
import { Reservation, ReservationState } from "../model/warehouse/reservation.types";
import {
  CommodityWithBatches,
  DestinationWithOutgoing,
  TabDocument,
  TabDocuments
} from "../model/warehouse/customTypes.types";
import { CommoditiesDocument } from "../model/commodities.types";
import baseUtils from "./baseUtils";
import { language, resolveTranslation } from "./translationUtils";
import { AvisType, DeliveryAnnouncement, NotificationState } from "../model/warehouse/deliveryAnnouncement.types";
import { OrdersDocument } from "../model/orders.types";
import orderUtils, { ARCHIVE, CREATEINVOICE, DECLINED, FULFILLMENT } from "./orderUtils";
import { T_CUSTOM, T_SERVICE, T_SOFTGEL } from "../components/order/OrderHelper";
import { Delivery } from "../model/warehouse/delivery.types";
import { Outgoing } from "../model/warehouse/outgoing.types";
import { Movement } from "../model/warehouse/movement.types";

export enum ProductFilterValuesEnum {
  ALL = "allWares",
  ALL_RAW_MATERIALS = "allRawMaterials",
  ALL_PACKAGING = "allPackaging"
}

/**
 * A filter function that returns truthy or falsy whether the batch should be kept or not
 * @param batch a batch
 * @param selectedLocation a selected location
 * @param productFilter a product type filter
 * @param additionalProductFilter additional product filters, e.g. organic
 * @param excludeLocation optional, if given, the location will not be included in filtering
 * @returns {boolean} true if batch matches the filter, else false
 */
export const filterBatch = (
  batch: Batch,
  selectedLocation: WarehouseLocation | null,
  productFilter: string,
  additionalProductFilter: AdditionalProductFilter,
  excludeLocation?: boolean
): boolean => {
  if (excludeLocation) {
    return filterByProductFilters(batch, productFilter, additionalProductFilter);
  }
  return (
    filterBySelectedLocation(batch, selectedLocation) &&
    filterByProductFilters(batch, productFilter, additionalProductFilter)
  );
};

/**
 * A filter function that returns truthy or falsy whether the delivery announcement should be kept or not
 * @param deliveryAnnouncement a delivery announcement
 * @param productFilter a product type filter
 * @param additionalProductFilter additional product filters, e.g. organic
 * @returns {boolean} true if delivery announcement matches the filter, else false
 */
function filterDeliveryAnnouncement(
  deliveryAnnouncement: DeliveryAnnouncement,
  productFilter: string,
  additionalProductFilter: AdditionalProductFilter
): boolean {
  let result = true;
  if (
    productFilter === ProductFilterValuesEnum.ALL_RAW_MATERIALS &&
    deliveryAnnouncement.notification.every(n => n.content.type !== AvisType.COMMODITY)
  )
    result = false;
  else if (
    productFilter === ProductFilterValuesEnum.ALL_PACKAGING &&
    deliveryAnnouncement.notification.every(n => n.content.type !== AvisType.PACKAGING)
  )
    result = false;
  else if (additionalProductFilter.providedByCustomer && deliveryAnnouncement.sender.type !== SenderType.CUSTOMER)
    result = false;
  return result;
}

/**
 * A filter function that returns truthy or falsy whether the batch should be kept or not
 * @param movement a movement
 * @param selectedLocation a selected location
 * @param productFilter a product type filter
 * @param additionalProductFilter additional product filters, e.g. organic
 * @param excludeLocation optional, if given, the location will not be included in filtering
 * @returns {boolean} true if movement matches the filter, else false
 */
function filterMovement(
  movement: Movement,
  selectedLocation: WarehouseLocation | null,
  productFilter: string,
  additionalProductFilter: AdditionalProductFilter,
  excludeLocation?: boolean
): boolean {
  if (excludeLocation) {
    return filterByProductFilters(movement, productFilter, additionalProductFilter);
  }
  return (
    filterBySelectedLocation(movement, selectedLocation) &&
    filterByProductFilters(movement, productFilter, additionalProductFilter)
  );
}

/**
 * A filter function that returns truthy or falsy whether the document should be kept or not
 * @param document a batch or movement
 * @param selectedLocation a selected location
 * @returns {boolean} true if document matches the filter, else false
 */
const filterBySelectedLocation = (document: Batch | Movement, selectedLocation: WarehouseLocation | null): boolean => {
  if (!selectedLocation) return true;
  if (isBatch(document)) {
    return document.locations.some(
      l =>
        selectedLocation?.warehouse === l.location.warehouseSnapshot._id.toString() &&
        (!selectedLocation.warehouseArea || selectedLocation.warehouseArea === l.location.warehouseArea._id.toString())
    );
  } else {
    return (
      selectedLocation?.warehouse === document.movement.fromLocation.warehouseSnapshot._id.toString() &&
      (!selectedLocation.warehouseArea ||
        selectedLocation.warehouseArea === document.movement.fromLocation.warehouseArea._id.toString())
    );
  }
};

/**
 * Check if the given specificType is a BatchContentSpecificType or not
 * @param specificType the specific type to check
 * @returns { boolean } true if specificType is a BatchContentSpecificType, false if not
 */
const isBatchContentSpecificType = (
  specificType: BatchContentSpecificType | CommoditySpecificTypeObject
): specificType is BatchContentSpecificType => {
  return typeof specificType === "string" && Object.values(BatchContentSpecificType).includes(specificType);
};

/**
 * Check if the given specificType is a CommoditySpecificTypeObject or not
 * @param specificType the specific type to check
 * @returns { boolean } true if specificType is a CommoditySpecificTypeObject, false if not
 */
const isCommoditySpecificTypeObject = (
  specificType: BatchContentSpecificType | CommoditySpecificTypeObject
): specificType is CommoditySpecificTypeObject => {
  return typeof specificType !== "string" && "composition" in specificType;
};

/**
 * A filter function that returns true or false whether the document should be kept or not
 * @param document a batch or movement
 * @param productFilter a product type filter
 * @param additionalProductFilter additional product filters, e.g. organic
 * @return {boolean} true if document matches the filters, else false
 */
const filterByProductFilters = (
  document: Batch | Movement,
  productFilter: string,
  additionalProductFilter: AdditionalProductFilter
): boolean => {
  const type = isBatch(document) ? document.content.type : document.batch.content.type;
  const specificType = isBatch(document)
    ? document.content.details.specificType
    : document.batch.content.details.specificType;
  const isOrganic = isBatch(document) ? !!document.content.details.organic : !!document.batch.content.details.organic;

  const checkProductType = () => {
    // General product type filtering
    if (productFilter === ProductFilterValuesEnum.ALL) return true;
    if (productFilter === ProductFilterValuesEnum.ALL_RAW_MATERIALS) return type === ContentType.COMMODITY;

    // Specific product type filtering
    if (isBatchContentSpecificType(specificType)) {
      if (productFilter === BatchContentSpecificType.SOFTGELS)
        return type === ContentType.COMMODITY && specificType === BatchContentSpecificType.SOFTGELS;
    } else if (isCommoditySpecificTypeObject(specificType)) {
      return (
        type === ContentType.COMMODITY &&
        (specificType.category._id.toString() === productFilter ||
          specificType.composition._id.toString() === productFilter)
      );
    }
    return false;
  };

  return (
    checkProductType() &&
    (!additionalProductFilter.organic || (additionalProductFilter.organic && isOrganic)) &&
    (!additionalProductFilter.providedByCustomer ||
      (additionalProductFilter.providedByCustomer && isBatch(document) && document.sender.type === SenderType.CUSTOMER))
  );
};

/**
 * A filter function that returns truthy or falsy whether the batch should be kept or not
 * @param batch a single batch
 * @param activeTab the currently active tab
 * @param reservations list of reservations
 * @param configuration the warehouse configuration
 * @returns {boolean} true if batch should be displayed for the tab, else false
 */
export function isBatchRelevantForTab(
  batch: Batch,
  activeTab: WarehouseListingTabNames,
  reservations: Array<Reservation>,
  configuration: WarehouseConfiguration | null
): boolean {
  switch (activeTab) {
    case WarehouseListingTabNames.INCOMING:
      return isBatchIncoming(batch, configuration);
    case WarehouseListingTabNames.RESERVED:
      return reservations.some(
        r =>
          r.state === ReservationState.OPEN &&
          r.materials.some(m => m.material.details._id.toString() === batch.content.details._id.toString())
      );
    case WarehouseListingTabNames.AVAILABLE:
      return (
        !batch.blocked &&
        !reservations.some(
          r =>
            r.state === ReservationState.OPEN &&
            r.materials.some(m => m.material.details._id.toString() === batch.content.details._id.toString())
        )
      );
  }
  return true;
}

/**
 * A filter function that returns truthy or falsy whether the location should be kept or not
 * @param location a specific location object of a batch
 * @param activeTab the currently active tab
 * @param warehouseContext the warehouse context
 * @returns {boolean} true if batch location should be displayed for the tab, else false
 */
export function filterLocations(
  location: BatchLocation,
  activeTab: WarehouseListingTabNames,
  warehouseContext: WarehouseContext
): boolean {
  const { configuration, selectedLocation } = warehouseContext;
  const matchesLocation = selectedLocation
    ? location.location.warehouseSnapshot._id.toString() === selectedLocation.warehouse &&
      (!selectedLocation.warehouseArea ||
        location.location.warehouseArea._id.toString() === selectedLocation.warehouseArea)
    : true;
  switch (activeTab) {
    case WarehouseListingTabNames.INCOMING:
      return matchesLocation && isBatchLocationIncoming(location.location, configuration);
  }
  return matchesLocation;
}

/**
 * Get documents for a specific listing tab
 * @param tab name of the listing tab
 * @param dataContext the data context
 * @param warehouseContext the warehouse context
 * @returns {Array<TabDocument>} list of documents based on the selected tab
 */
export function getDocumentsForTab(
  tab: WarehouseListingTabNames,
  dataContext: DataContextType,
  warehouseContext: WarehouseContext
): Array<TabDocument> {
  switch (tab) {
    case WarehouseListingTabNames.RAW_MATERIAL:
    case WarehouseListingTabNames.AVAILABLE:
    case WarehouseListingTabNames.RESERVED:
      return getBatchesPerCommodityForTab(tab, dataContext, warehouseContext);
    case WarehouseListingTabNames.AVIS:
      return getAvisEntriesForTab(dataContext);
    case WarehouseListingTabNames.ORDERS:
      return getOrdersForTab(dataContext);
    case WarehouseListingTabNames.OUTGOING:
      return getOutgoingPerDestinationForTab(dataContext);
    case WarehouseListingTabNames.TRANSFER:
      return getTransferForTab(dataContext);
    case WarehouseListingTabNames.MOVEMENT:
      return getMovementsForTab(dataContext);
    default:
      return getBatchesForTab(tab, dataContext, warehouseContext);
  }
}

/**
 * Filter documents from a tab
 * @param documents list of documents to filter
 * @param warehouseContext the warehouse context with selected filters
 * @param excludeLocation optional, flag to skip the location check
 * @returns {Array<TabDocument>} list of filtered documents
 */
export function filterListingDocuments(
  documents: Array<TabDocument>,
  warehouseContext: WarehouseContext,
  excludeLocation?: boolean
): Array<TabDocument> {
  const { selectedLocation, productFilter, additionalProductFilter, query } = warehouseContext;
  if (isBatches(documents)) {
    let filteredDocuments: Array<Batch> = searchBatches(documents, query);
    filteredDocuments = filteredDocuments.filter((b: Batch) =>
      filterBatch(b, selectedLocation, productFilter, additionalProductFilter, excludeLocation)
    );
    return filteredDocuments;
  } else if (isCommoditiesWithBatches(documents)) {
    const filteredDocuments: Array<CommodityWithBatches> = [];
    documents.forEach(c => {
      let filteredBatches: Array<Batch> = searchBatches(c.batches, query);
      filteredBatches = filteredBatches.filter((b: Batch) =>
        filterBatch(b, selectedLocation, productFilter, additionalProductFilter, excludeLocation)
      );
      if (filteredBatches.length > 0)
        filteredDocuments.push({ ...c, batches: filteredBatches } as CommodityWithBatches);
    });
    return filteredDocuments;
  } else if (isDeliveryAnnouncements(documents)) {
    let filteredDocuments: Array<DeliveryAnnouncement> = searchDeliveryAnnouncement(documents, query);
    filteredDocuments = filteredDocuments.filter((dA: DeliveryAnnouncement) =>
      filterDeliveryAnnouncement(dA, productFilter, additionalProductFilter)
    );
    return filteredDocuments;
  } else if (isOrdersDocuments(documents)) {
    return searchOrders(documents, query);
  } else if (isDestinationsWithOutgoing(documents)) {
    const filteredDocuments: Array<DestinationWithOutgoing> = [];
    documents.forEach(d => {
      const filteredOutgoing: Array<Outgoing> = searchOutgoing(d.outgoing, query);
      if (filteredOutgoing.length > 0) filteredDocuments.push({ ...d, outgoing: filteredOutgoing });
    });
    return filteredDocuments;
  } else if (isDeliveries(documents)) {
    return searchDeliveries(documents, query);
  } else if (isMovements(documents)) {
    let filteredDocuments: Array<Movement> = searchMovements(documents, query);
    filteredDocuments = filteredDocuments.filter((m: Movement) =>
      filterMovement(m, selectedLocation, productFilter, additionalProductFilter, excludeLocation)
    );
    return filteredDocuments;
  }
  return [];
}

/**
 * Get batches grouped by commodities
 * @param tab name of the listing tab
 * @param dataContext the data context
 * @param warehouseContext the warehouse context
 * @returns {Array<CommodityWithBatches>} list of commodities with batches
 */
export function getBatchesPerCommodityForTab(
  tab: WarehouseListingTabNames,
  dataContext: DataContextType,
  warehouseContext: WarehouseContext
): Array<CommodityWithBatches> {
  const { commodities, batch, reservation } = dataContext;
  const { configuration } = warehouseContext;
  const commodityMap: {
    [id: string]: { batches: Array<Batch>; reservations: Array<Reservation>; commodity: CommoditiesDocument };
  } = {};
  batch.forEach(b => {
    if (b.content.type === ContentType.COMMODITY && isBatchRelevantForTab(b, tab, reservation, configuration)) {
      const commodityId = b.content.details._id.toString();
      if (commodityId in commodityMap) commodityMap[commodityId].batches.push(b);
      else {
        const commodityDoc = baseUtils.getDocFromCollection(commodities, commodityId);
        if (commodityDoc) commodityMap[commodityId] = { commodity: commodityDoc, batches: [b], reservations: [] };
      }
    }
  });
  reservation.forEach(r => {
    if (r.state === ReservationState.OPEN)
      r.materials.forEach(m => {
        if (m.material.type === ContentType.COMMODITY) {
          const reservationCopy = _.cloneDeep(r);
          reservationCopy.materials = [m];
          const commodityId = m.material.details._id.toString();
          if (commodityId in commodityMap) commodityMap[commodityId].reservations.push(reservationCopy);
          else {
            const commodityDoc = baseUtils.getDocFromCollection(commodities, commodityId);
            if (commodityDoc)
              commodityMap[commodityId] = { commodity: commodityDoc, batches: [], reservations: [reservationCopy] };
          }
        }
      });
  });

  const commoditiesWithBatches = Object.values(commodityMap).map(({ batches, commodity, reservations }) => ({
    ...commodity,
    batches,
    reservations
  })) as Array<CommodityWithBatches>;
  return _.orderBy(commoditiesWithBatches, c => resolveTranslation(c.title), ["asc"]);
}

/**
 * Get all batches relevant for a tab
 * @param tab the warehouse listing tab this function is called for
 * @param dataContext the complete data context
 * @param warehouseContext the complete warehouse Context
 * @returns {Array<Batch>} list of batches relevant for the tab
 */
export function getBatchesForTab(
  tab: WarehouseListingTabNames,
  dataContext: DataContextType,
  warehouseContext: WarehouseContext
): Array<Batch> {
  const { batch, reservation } = dataContext;
  const { configuration } = warehouseContext;
  return _.orderBy(
    batch.filter(b => isBatchRelevantForTab(b, tab, reservation, configuration)),
    b => resolveTranslation(b.content.details.title),
    ["asc"]
  );
}

/**
 * Get all delivery announcement entries that are relevant for the avis tab
 * @param dataContext the complete data context
 * @returns {Array<DeliveryAnnouncement>} Relevant delivery announcements
 */
export function getAvisEntriesForTab(dataContext: DataContextType): Array<DeliveryAnnouncement> {
  return dataContext.deliveryAnnouncement.filter(dA =>
    dA.notification.some(n => n.state !== NotificationState.ARRIVED)
  );
}

/**
 * Get all order documents that are relevant for the orders tab
 * @param dataContext the complete data context
 * @returns {Array<OrdersDocument>} Relevant order documents
 */
export function getOrdersForTab(dataContext: DataContextType): Array<OrdersDocument> {
  return dataContext.orders.filter(
    o =>
      orderUtils.isOrderState(o.state) &&
      ![ARCHIVE, DECLINED, FULFILLMENT, CREATEINVOICE].includes(o.state) &&
      ![T_CUSTOM, T_SERVICE, T_SOFTGEL].includes(o.settings.type)
  );
}

/**
 * Get all outgoing documents grouped by destination that are relevant for the outgoing tab
 * @param dataContext the complete data context
 * @returns {Array<DestinationWithOutgoing>} Relevant outgoing documents grouped by destination
 */
export function getOutgoingPerDestinationForTab(dataContext: DataContextType): Array<DestinationWithOutgoing> {
  const { outgoing } = dataContext;
  const destinationMap: Record<string, DestinationWithOutgoing> = {};
  outgoing.forEach(o => {
    if (o.movedPackagingUnits.length > 0 && o.movedAmount.value > 0) {
      const destinationId = o.destination._id.toString();
      if (!destinationMap[destinationId]) {
        destinationMap[destinationId] = { _id: o.destination._id, outgoing: [o] };
      } else {
        destinationMap[destinationId].outgoing.push(o);
      }
    }
  });
  const sortedDestinationMap: Record<string, DestinationWithOutgoing> = {};
  Object.entries(destinationMap).forEach(([key, value]) => {
    sortedDestinationMap[key] = {
      ...value,
      outgoing: _.orderBy(value.outgoing, o => resolveTranslation(o.batch.content.details.title), ["asc"])
    };
  });
  return _.orderBy(Object.values(sortedDestinationMap), d => d.outgoing[0].destination.type, ["asc"]);
}

/**
 * Get all delivery documents that are relevant for the delivery tab
 * @param dataContext the complete data context
 * @returns {Array<OrdersDocument>} Relevant delivery documents
 */
export function getTransferForTab(dataContext: DataContextType): Array<Delivery> {
  return dataContext.delivery;
}

/**
 * Get all movement documents that are relevant for the movement tab
 * @param dataContext the complete data context
 * @returns {Array<Movement>} Relevant movement documents
 */
export function getMovementsForTab(dataContext: DataContextType): Array<Movement> {
  return _.orderBy(
    dataContext.movement,
    [
      m => m.closed,
      m => m.movement.fromLocation.warehouseArea.shortName,
      m => m.movement.fromLocation.warehouseSnapshot.shortName,
      m => m.created
    ],
    ["desc", "asc", "asc", "asc"]
  );
}

/**
 * Get all batches relevant for current tab and applied filters
 * @param tab the warehouse listing tab this function is called for
 * @param dataContext the complete data context
 * @param warehouseContext the complete warehouse Context
 * @param excludeLocation optional, if given, the location will not be included in filtering
 * @returns {Array<Batch>} list of batches matching the tab and filters
 */
export function getBatches(
  tab: WarehouseListingTabNames,
  dataContext: DataContextType,
  warehouseContext: WarehouseContext,
  excludeLocation?: boolean
): Array<Batch> {
  const { batch, reservation } = dataContext;
  const { configuration, selectedLocation, productFilter, additionalProductFilter, query } = warehouseContext;
  const searchedBatches = searchBatches(batch, query);
  return searchedBatches.filter(
    b =>
      isBatchRelevantForTab(b, tab, reservation, configuration) &&
      filterBatch(b, selectedLocation, productFilter, additionalProductFilter, excludeLocation)
  );
}

/**
 * Get the ids of all unique destinations from the outgoing tab after search and filtering
 * @param dataContext the complete data context
 * @param warehouseContext the complete warehouse context
 * @returns {Array<string>} the ids of the searched and filtered destinations
 */
export function getUniqueDestinations(dataContext: DataContextType, warehouseContext: WarehouseContext): Array<string> {
  const { outgoing } = dataContext;
  const { query } = warehouseContext;

  const filteredOutgoing = outgoing.filter(o => o.movedPackagingUnits.length > 0 && o.movedAmount.value > 0);
  const searchedOutgoing = searchOutgoing(filteredOutgoing, query).map(o => o.destination._id.toString());
  return Array.from(new Set<string>(searchedOutgoing));
}

/**
 * Check if given array contains batches
 * @param documents list of tab documents
 * @returns {boolean} true if list contains batches else false
 */
export function isBatches(documents: TabDocuments): documents is Array<Batch> {
  return documents.length === 0 || "content" in documents[0];
}

/**
 * Check if given array contains commodities with batches
 * @param documents list of tab documents
 * @returns {boolean} true if list contains commodities with batches else false
 */
export function isCommoditiesWithBatches(documents: TabDocuments): documents is Array<CommodityWithBatches> {
  return documents.length === 0 || "hs_code" in documents[0];
}

/**
 * Check if the given array contains delivery announcements
 * @param documents list of tab documents
 * @returns {boolean} true if list contains delivery announcements else false
 */
export function isDeliveryAnnouncements(documents: TabDocuments): documents is Array<DeliveryAnnouncement> {
  return documents.length === 0 || "shipmentCode" in documents[0];
}

/**
 * Check if the given array contains order documents
 * @param documents list of tab documents
 * @returns {boolean} true if list contains order documents else false
 */
export function isOrdersDocuments(documents: TabDocuments): documents is Array<OrdersDocument> {
  return documents.length === 0 || "recipe" in documents[0];
}

/**
 * Check if the given array contains destination with outgoing objects
 * @param documents list of tab documents
 * @returns {boolean} true if list contains destination with outgoing objects else false
 */
export function isDestinationsWithOutgoing(documents: TabDocuments): documents is Array<DestinationWithOutgoing> {
  return documents.length === 0 || "outgoing" in documents[0];
}

/**
 * Check if the given array contains deliveries
 * @param documents list of tab documents
 * @returns {boolean} true if list contains deliveries else false
 */
export function isDeliveries(documents: TabDocuments): documents is Array<Delivery> {
  return documents.length === 0 || "deliveries" in documents[0];
}

/**
 * Check if the given array contains movements
 * @param documents list of tab documents
 * @returns {boolean} true of list contains movements else false
 */
export function isMovements(documents: TabDocuments): documents is Array<Movement> {
  return documents.length === 0 || "movement" in documents[0];
}

/**
 * Check if given document is a batch
 * @param document a tab document
 * @returns {boolean} true if document is a batch else false
 */
export function isBatch(document: TabDocument): document is Batch {
  return "content" in document;
}

/**
 * Check if given document is a commodity with batches
 * @param document a tab document
 * @returns {boolean} true if document is a commodity with batches else false
 */
export function isCommodityWithBatches(document: TabDocument): document is CommodityWithBatches {
  return "hs_code" in document;
}

/**
 * Check if given document is a delivery announcement
 * @param document a tab document
 * @returns {boolean} true if document is a delivery announcement else false
 */
export function isDeliveryAnnouncement(document: TabDocument): document is DeliveryAnnouncement {
  return "shipmentCode" in document;
}

/**
 * Check if given document is an order document
 * @param document a tab document
 * @returns {boolean} true if document is an order document else false
 */
export function isOrderDocument(document: TabDocument): document is OrdersDocument {
  return "recipe" in document;
}

/**
 * Check if given document is an order document
 * @param document a tab document
 * @returns {boolean} true if document is a destination with outgoing objects else false
 */
export function isDestinationWithOutgoing(document: TabDocument): document is DestinationWithOutgoing {
  return "outgoing" in document;
}

/**
 * Check if given document is a Delivery
 * @param document a tab document
 * @returns {boolean} true if document is a delivery else false
 */
export function isDelivery(document: TabDocument): document is Delivery {
  return "deliveries" in document;
}

/**
 * Check if given document is a Movement
 * @param document a tab document
 * @returns {boolean} true if docuemnt is a movement else false
 */
export function isMovement(document: TabDocument): document is Movement {
  return "movement" in document;
}

/**
 * Check is a batch is (partially) "incoming"
 * @param batch any batch document
 * @param configuration the warehouse configuration
 * @returns {boolean} true if batch is (partially) "incoming", else false
 */
export function isBatchIncoming(batch: Batch, configuration: WarehouseConfiguration | null): boolean {
  return batch.locations.some(l => isBatchLocationIncoming(l.location, configuration));
}

/**
 * Check if a batch location is "incoming", i.e. not assigned to a storage space in a directly managed warehouse
 * @param location a batch location
 * @param configuration the warehouse configuration
 * @returns {boolean} true if location is "incoming", else false
 */
export function isBatchLocationIncoming(
  location: BatchLocationInformation,
  configuration: WarehouseConfiguration | null
): boolean {
  if (!configuration || location.storageSpace) return false;
  const { warehouseStructure } = configuration.values;
  return warehouseStructure.some(
    w =>
      w._id.toString() === location.warehouseSnapshot._id.toString() &&
      w.physicalWarehouses.some(
        p => p._id.toString() === location.warehouseArea._id.toString() && p.type === WarehouseTypes.DIRECTLYMANAGED
      )
  );
}

/**
 * Search a list of batches with the given query
 * @param batch list of batches
 * @param query a search query to filter batches with
 * @returns {Array<Batch>} list of batches matching the query
 */
export function searchBatches(batch: Array<Batch>, query: string): Array<Batch> {
  if (!query.trim()) return batch;
  const lang = language();
  return baseUtils.doFuseSearch(
    batch,
    query,
    [
      "lot",
      "content.details.articleNo",
      `content.details.title.${lang}`,
      `content.details.subtitle.${lang}`,
      "content.details.title.de", // Make sure to always search DE and EN as well
      "content.details.subtitle.de",
      "content.details.title.en",
      "content.details.subtitle.en",
      "sender.name",
      "sender.type"
    ],
    { threshold: 0.15 }
  );
}

/**
 * Search a list of delivery announcements with the given query
 * @param delivery list of delivery announcements
 * @param query a search query to filter delivery announcements with
 * @returns {Array<DeliveryAnnouncement>} list of delivery announcements matching the query
 */
export function searchDeliveryAnnouncement(
  delivery: Array<DeliveryAnnouncement>,
  query: string
): Array<DeliveryAnnouncement> {
  if (!query.trim()) return delivery;
  const lang = language();
  return baseUtils.doFuseSearch(delivery, query, [
    "shipmentCode",
    "notification.order.reference",
    "notification.content.type",
    `notification.content.details.title.${lang}`,
    `notification.content.details.subtitle.${lang}`,
    "sender.name",
    "sender.type"
  ]);
}

/**
 * Search a list of orders with the given query
 * @param orders list of orders
 * @param query a search query to filter orders with
 * @returns {Array<OrdersDocument>} list of orders matching the query
 */
export function searchOrders(orders: Array<OrdersDocument>, query: string): Array<OrdersDocument> {
  if (!query.trim()) return orders;
  const lang = language();
  return baseUtils.doFuseSearch(orders, query, [`title.${lang}`, `subtitle.${lang}`, "identifier", "settings.type"]);
}

/**
 * Search a list of outgoing objects with the given query
 * @param outgoing list of outgoing objects
 * @param query a search query to filter outgoing objects with
 * @returns {Array<Outgoing>} list of outgoing objects matching the query
 */
export function searchOutgoing(outgoing: Array<Outgoing>, query: string): Array<Outgoing> {
  if (!query.trim()) return outgoing;
  const lang = language();
  return baseUtils.doFuseSearch(outgoing, query, [
    "destination.address.destinationName",
    "batch.lot",
    `batch.content.details.title.${lang}`,
    `batch.content.details.subtitle.${lang}`,
    "batch.content.details.title.de", // DE as fallback if lang is a not yet supported one
    "batch.content.details.subtitle.de",
    "batch.sender.name",
    "batch.sender.type"
  ]);
}

/**
 * Search a list of deliveries with the given query
 * @param delivery list of deliveries
 * @param query a search query to filter deliveries with
 * @returns {Array<Outgoing>} list of deliveries matching the query
 */
export function searchDeliveries(delivery: Array<Delivery>, query: string): Array<Delivery> {
  if (!query.trim()) return delivery;
  const lang = language();
  return baseUtils.doFuseSearch(delivery, query, [
    "identifier",
    "deliveries.destination.address.destinationName",
    `deliveries.batch.content.details.title.${lang}`,
    `deliveries.batch.content.details.subtitle.${lang}`,
    `deliveries.batch.content.details.title.de`,
    `deliveries.batch.content.details.subtitle.de`
  ]);
}

/**
 * Search a list of movements with the given query
 * @param movements list of movements
 * @param query a search query to filter movements with
 * @returns {Array<Movement>} list of movements matching the query
 */
export function searchMovements(movements: Array<Movement>, query: string): Array<Movement> {
  if (!query.trim()) return movements;
  const lang = language();
  return baseUtils.doFuseSearch(movements, query, [
    "batch.lot",
    `batch.content.details.title.${lang}`,
    `batch.content.details.subtitle.${lang}`,
    `batch.content.details.title.de`,
    `batch.content.details.subtitle.de`
  ]);
}
