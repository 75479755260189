import _ from "lodash";
import React, { PureComponent } from "react";
import { Modal, Image } from "react-bootstrap";
import { BSON } from "realm-web";
import { toast } from "react-toastify";
import ImageUploader from "react-images-upload";
import { toAbsoluteUrl } from "../../_metronic";
import { CalculationType, Preferences, SelectedCommoditiesDocument, SelectedPackagingsDocument } from "./CustomTypes";
import userService from "../../services/userService";
import calculationUtils from "../../utils/calculationUtils";
import dbService from "../../services/dbService";
import http from "../../services/httpService";
import config from "../../config/config.json";
import { ProductTypes } from "./configuratorConstants";

interface CreateSampleRecipeModalProps {
  activeType: string;
  preferences: Preferences;
  calculations: Array<CalculationType>;
  recipe: Array<SelectedCommoditiesDocument>;
  selectedPackaging: Array<SelectedPackagingsDocument>;
}

interface CreateSampleRecipeModalState {
  loading: boolean;
  show: boolean;
  title: { de: string; en: string };
  subtitle: { de: string; en: string };
  note: { de: string; en: string };
  img_url: string;
}

class CreateSampleRecipeModal extends PureComponent<CreateSampleRecipeModalProps, CreateSampleRecipeModalState> {
  constructor(props: CreateSampleRecipeModalProps) {
    super(props);
    this.state = {
      show: false,
      title: { de: "", en: "" },
      subtitle: { de: "", en: "" },
      note: { de: "", en: "" },
      loading: false,
      img_url: ""
    };
  }

  handleShow = () => this.setState({ show: true });
  handleClose = () => this.setState({ show: false });

  /**
   * Handle title change
   * @param lang the language to edit
   * @param value the new value
   */
  handleTitleChange = (lang: string, value: string) => {
    const title = { ...this.state.title };
    _.set(title, lang, value);
    this.setState({ title: title });
  };
  /**
   * Handle subtitle change
   * @param lang the language to edit
   * @param value the new value
   */
  handleSubtitleChange = (lang: string, value: string) => {
    const subtitle = { ...this.state.subtitle };
    _.set(subtitle, lang, value);
    this.setState({ subtitle: subtitle });
  };
  /**
   * Handle note change
   * @param lang the language to edit
   * @param value the new value
   */
  handleNoteChange = (lang: string, value: string) => {
    const note = { ...this.state.note };
    _.set(note, lang, value);
    this.setState({ note: note });
  };

  /**
   * Handle image Upload
   * @param files: the selected file(s)
   */
  handleImageUpload = async (files: File[]) => {
    const { title } = this.state;
    // Only one file is allowed
    if (files.length === 1) {
      const file = files.pop()!;
      // file name consisting of the userdata id, current timestamp and the given local file name
      const fileName = encodeURIComponent(`${title.en}_${Date.now()}_${file.name}`);
      const header = {
        headers: { "Content-Type": "application/octet-stream" }
      };
      try {
        const { data: name } = await http.post(
          (process.env.REACT_APP_UPLOAD_ENDPOINT || "").concat(fileName),
          file,
          header
        );
        const img_url = (process.env.REACT_APP_MEDIAHUB_FILE_BASE || "").concat(encodeURIComponent(name));
        this.setState({ img_url: img_url });
      } catch (ex) {
        console.error(ex.message);
      }
    }
  };

  /**
   * Get selected tablet or capsule
   */
  getSelectedPreferenceId = () => {
    const { preferences, activeType } = this.props;
    switch (activeType) {
      case ProductTypes.CAPSULES:
        return preferences.selectedCapsule!._id;
      case ProductTypes.TABLETS:
        return preferences.selectedTablet!._id;
      default:
        return "";
    }
  };

  /**
   * Create sample recipe
   */
  handleCreateSampleRecipe = async () => {
    const { activeType, preferences, recipe, selectedPackaging } = this.props;
    const { title, subtitle, note, img_url } = this.state;
    this.setState({ loading: true });
    let sampleRecipe = {
      title: title,
      subtitle: subtitle,
      note: note,
      createdFrom: new BSON.ObjectId(userService.getUserData()._id),
      date: new Date(),
      image_url: img_url,
      used: 0,
      settings: {
        type: calculationUtils.getTypeForTab(activeType),
        perUnit: calculationUtils.getAmountPerUnit(activeType, preferences, recipe),
        id: this.getSelectedPreferenceId()
      },
      recipe: recipe.map(comm => {
        return { _id: comm._id, amount: +comm.amount! };
      }),
      packaging: selectedPackaging.map(pack => {
        return { _id: pack._id, amount: +pack.amount! };
      })
    };
    try {
      let res = await dbService.callFunction("createSampleRecipe", [sampleRecipe]);
      if (!res) toast.error("An unexpected error occurred");
      if (res && res.insertedId) {
        toast.success("Sample recipe successfully created");
      }
    } catch (e) {
      toast.error("An unexpected error occurred: " + e.message);
    } finally {
      this.handleClose();
      this.setState({ loading: false });
    }
  };

  /**
   * Add default image
   * @param ev: The change event
   */
  addDefaultSrc(ev: React.ChangeEvent<HTMLImageElement>) {
    ev.target.src = toAbsoluteUrl("/media/products/placeholder.png");
  }

  render() {
    const { show, title, subtitle, note, loading, img_url } = this.state;
    const isComplete = title.de !== "" && title.en !== "";
    return (
      <>
        <button
          className="btn btn-secondary mr-2"
          onClick={e => {
            e.preventDefault();
            this.handleShow();
          }}
        >
          Create Sample
        </button>
        <Modal show={show} id="confirmationModal" onHide={this.handleClose} centered>
          <Modal.Header closeButton>
            <Modal.Title>Sample Recipe Settings</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="p-4">
              <div className="kt-section kt-section--first">
                <div className="kt-section__body">
                  <div className="form-group">
                    <div className="m-auto d-block text-center">
                      <Image
                        src={img_url}
                        className="m-auto"
                        style={{ maxWidth: "150px", maxHeight: "150px" }}
                        onError={this.addDefaultSrc}
                      />
                    </div>
                    <ImageUploader
                      withIcon={false}
                      withLabel={false}
                      buttonText={"Upload product image"}
                      imgExtension={[".jpg", ".png"]}
                      maxFileSize={104857600}
                      singleImage
                      errorClass="px-3"
                      fileContainerStyle={{
                        boxShadow: "None",
                        background: "None",
                        padding: "0",
                        margin: "0 auto"
                      }}
                      fileSizeError={"Image too large. Max 10 MB"}
                      fileTypeError={"Image has wrong format. Only .jpg and .png are allowed"}
                      onChange={this.handleImageUpload}
                    />
                  </div>
                  <div className="form-group ">
                    <div className="input-group mb-2">
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <img src="media/icons/icon-de.png" className="country-icon" />
                        </span>
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        id="title_de"
                        placeholder="Titel"
                        onChange={e => this.handleTitleChange("de", e.target.value.trimStart())}
                        value={title.de}
                        style={title.de === "" ? { border: "1px solid red" } : {}}
                      />
                    </div>
                    <div className="input-group mb-2">
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <img src="media/icons/icon-en.png" className="country-icon" />
                        </span>
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        id="title_en"
                        placeholder="Title"
                        onChange={e => this.handleTitleChange("en", e.target.value.trimStart())}
                        value={title.en}
                        style={title.en === "" ? { border: "1px solid red" } : {}}
                      />
                    </div>
                  </div>
                  <div className="form-group ">
                    <div className="input-group mb-2">
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <img src="media/icons/icon-de.png" className="country-icon" />
                        </span>
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        id="subtitle_de"
                        placeholder="Untertitel"
                        onChange={e => this.handleSubtitleChange("de", e.target.value.trimStart())}
                        value={subtitle.de}
                      />
                    </div>
                    <div className="input-group mb-2">
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <img src="media/icons/icon-en.png" className="country-icon" />
                        </span>
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        id="subtitle_en"
                        placeholder="Subtitle"
                        onChange={e => this.handleSubtitleChange("en", e.target.value.trimStart())}
                        value={subtitle.en}
                      />
                    </div>
                  </div>
                  <div className="form-group ">
                    <div className="input-group mb-2">
                      <textarea
                        className="form-control"
                        id="note_de"
                        placeholder="Beschreibung (deutsch)"
                        onChange={e => this.handleNoteChange("de", e.target.value.trimStart())}
                        autoComplete="off"
                        value={note.de}
                      />
                    </div>
                    <div className="input-group">
                      <textarea
                        className="form-control"
                        id="note_en"
                        placeholder="Description (english)"
                        onChange={e => this.handleNoteChange("en", e.target.value.trimStart())}
                        autoComplete="off"
                        value={note.en}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-secondary" onClick={this.handleClose}>
              Close
            </button>
            <button
              className={`btn ${isComplete ? "btn-success" : "btn-danger disabled"}`}
              disabled={!isComplete || loading}
              onClick={this.handleCreateSampleRecipe}
            >
              {loading && (
                <div className="button-splash-spinner d-inline pr-3 pl-0 mx-0">
                  <svg className="button-splash-spinner" viewBox="0 0 50 50">
                    <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5" />
                  </svg>
                </div>
              )}
              Create Sample Recipe
            </button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default CreateSampleRecipeModal;
