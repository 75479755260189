import React, { useMemo } from "react";
import Select from "react-select";
import i18n from "../../../../translations/i18n";
import { Input } from "../../../common/Input";
import { Batch, BatchLocation } from "../../../../model/warehouse/batch.types";
import { resolveTranslation } from "../../../../utils/translationUtils";
import { SelectOption } from "../../../../model/common.types";
import { useWarehouseContext } from "../../../../context/warehouseContext";
import { getWarehouseOption } from "../../../../utils/warehouseUtils";
import { Warehouse } from "../../../../model/warehouse/customTypes.types";

interface SendModalSelectionProps {
  selectedBatch: Batch;
  selectedAmount: number;
  selectedLocation: BatchLocation;
  amount: string;
  selectedDestination?: SelectOption<Warehouse>;
  onChangeAmount: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onSelectedDestination: (e: SelectOption<Warehouse>) => void;
}

const SendModalSelection: React.FC<SendModalSelectionProps> = ({
  selectedBatch,
  selectedAmount,
  selectedLocation,
  amount,
  selectedDestination,
  onChangeAmount,
  onSelectedDestination
}) => {
  const { configuration } = useWarehouseContext();

  const { product, productType, batchSelect } = useMemo(() => {
    return {
      product: {
        value: selectedBatch.content.details._id.toString(),
        label: resolveTranslation(selectedBatch.content.details.title)
      },
      productType: { value: selectedBatch.content.type, label: i18n.t(`warehouse:${selectedBatch.content.type}`) },
      batchSelect: { value: selectedBatch._id.toString(), label: selectedBatch.lot }
    };
  }, [selectedBatch]);

  const warehouseSelect = useMemo(() => {
    if (!configuration) return [];
    const { warehouseStructure } = configuration.values;
    const warehouseSelect: Array<SelectOption<Warehouse>> = [];
    for (let i = 0; i < warehouseStructure.length; i++) {
      const wS = warehouseStructure[i];
      if (selectedLocation.location.warehouseSnapshot._id.toString() === wS._id.toString()) continue;
      for (let j = 0; j < wS.physicalWarehouses.length; j++) {
        const pW = wS.physicalWarehouses[j];
        warehouseSelect.push(getWarehouseOption(wS, pW));
      }
    }
    return warehouseSelect;
  }, [configuration, selectedLocation]);

  return (
    <div className="px-2">
      <span className=" font-size-lg text-black d-block mt-3 mb-2" style={{ fontWeight: 500 }}>
        {i18n.t("warehouse:bookOutSelection")}
      </span>
      <hr className="w-100" />
      <div className="row mb-2">
        <div className="col-4">
          <span className="  text-black d-block mt-2" style={{ fontWeight: 500 }}>
            {i18n.t("warehouse:productType")}
          </span>
        </div>
        <div className="col-8">
          <div className="not-allowed-cursor">
            <Select
              className="select-warehouse"
              classNamePrefix="select-warehouse"
              value={productType}
              isDisabled={true}
            />
          </div>
        </div>
      </div>
      <div className="row mb-2">
        <div className="col-4">
          <span className="  text-black d-block mt-2" style={{ fontWeight: 500 }}>
            {i18n.t(`warehouse:${productType.value}`)}
          </span>
        </div>
        <div className="col-8">
          <div className="not-allowed-cursor">
            <Select className="select-warehouse" classNamePrefix="select-warehouse" value={product} isDisabled={true} />
          </div>
        </div>
      </div>
      <div className="row mb-2">
        <div className="col-4">
          <span className="text-black d-block mt-2" style={{ fontWeight: 500 }}>
            {i18n.t("warehouse:batch")}
          </span>
        </div>
        <div className="col-8">
          <div className="not-allowed-cursor">
            <Select
              className="select-warehouse"
              classNamePrefix="select-warehouse"
              isDisabled={true}
              value={batchSelect}
            />
          </div>
        </div>
      </div>
      <div className="row mb-2">
        <div className="col-4">
          <span className="text-black d-block mt-2" style={{ fontWeight: 500 }}>
            {i18n.t("warehouse:amount")}
          </span>
        </div>
        <div className="col-8">
          <div className="input-group">
            <Input
              type="number"
              min={0}
              className={
                "form-control form-control-solid hide-arrows " +
                ((+amount > (selectedAmount || Infinity) || amount === "0") && "is-invalid")
              }
              value={amount}
              onChange={onChangeAmount}
            />
            <div className="input-group-append">
              <span className="input-group-text input-group-solid">
                {selectedBatch.totalAmount.unit || i18n.t("warehouse:units")}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="row mb-2">
        <div className="col-4">
          <span className="  text-black d-block mt-2" style={{ fontWeight: 500 }}>
            {i18n.t(`warehouse:destination`)}
          </span>
        </div>
        <div className="col-8">
          <Select
            className="select-warehouse"
            classNamePrefix="select-warehouse"
            placeholder={`${i18n.t("warehouse:destination")}...`}
            value={selectedDestination}
            options={warehouseSelect}
            onChange={(e: any) => onSelectedDestination(e)}
          />
        </div>
      </div>
    </div>
  );
};

export default SendModalSelection;
