import React, { PureComponent } from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { BSON } from "realm-web";
import { CustomOrder } from "../CustomTypes";
import SplashScreen from "../../common/SplashScreen";
import LanguageSelectionDropdown from "../../common/LanguageSelectionDropdown";
import { DataContext } from "../../../context/dataContext";
import userService from "../../../services/userService";
import dbOrderService from "../../../services/dbServices/dbOrderService";
import orderUtils from "../../../utils/orderUtils";
import pdfUtils from "../../../utils/pdf/pdfUtils";
import config from "../../../config/config.json";
import { T_NONBINDINGOFFERPDF } from "../../../utils/timelineUtils";

interface CreateNonBindingOfferPDFModalProps {
  order: CustomOrder;
  disabled?: boolean;
  context: React.ContextType<typeof DataContext>;
}

interface CreateNonBindingOfferPDFModalState {
  show: boolean;
  uploading: boolean;
}

class CreateNonBindingOfferPDFModal extends PureComponent<
  CreateNonBindingOfferPDFModalProps,
  CreateNonBindingOfferPDFModalState
> {
  constructor(props: CreateNonBindingOfferPDFModalProps) {
    super(props);
    this.state = {
      show: false,
      uploading: false
    };
  }

  handleShow = () => this.setState({ show: true });
  handleClose = () => this.setState({ show: false });

  handleUpload = async () => {
    const { order, context } = this.props;
    this.setState({ uploading: true });
    const pdfResult = await pdfUtils.createNonBindingOfferPDF(order, context);
    if (!pdfResult.result || !pdfResult.path) {
      toast.error("PDF creation failed: " + pdfResult.message);
      this.setState({ uploading: false });
      return;
    }
    window.open((process.env.REACT_APP_MEDIAHUB_BASE || "") + pdfResult.path, "_blank");
    const timelineEntry = {
      id: new BSON.ObjectId(),
      type: T_NONBINDINGOFFERPDF,
      date: new Date(),
      path: pdfResult.path,
      person: userService.getUserId(),
      version: orderUtils.getPDFVersion(order, T_NONBINDINGOFFERPDF),
      offernumber: order.identifier
    };
    try {
      const result = await dbOrderService.pushToTimeline(order._id, timelineEntry);
      // fire and forget
      await dbOrderService.incrementVersion(order._id);
      if (result && result.modifiedCount) {
        toast.success("Non-binding offer PDF created successfully");
        this.setState({ uploading: false, show: false });
      } else toast.error("Adding of timeline entry failed. " + pdfResult.path);
    } catch (e) {
      toast.error("An unexpected error occurred: " + e.message);
    } finally {
      this.setState({ uploading: false });
    }
  };

  render() {
    const { disabled } = this.props;
    const { show, uploading } = this.state;
    return (
      <>
        <button
          className={"btn btn-label-brand btn-sm btn-bold mr-3 my-1 " + (disabled ? "btn-disabled" : "")}
          onClick={this.handleShow}
          disabled={disabled}
        >
          <i className="flaticon2-list-1" style={{ fontSize: "0.9rem" }} />
          Non-Binding Offer
        </button>
        <Modal show={show} onHide={this.handleClose} centered name={"CreateNonBindingOfferPDFModal"}>
          <Modal.Header closeButton>
            <Modal.Title>
              <i className="kt-font-brand flaticon2-list-1 mr-2" />
              Create Non-Binding Offer
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {uploading ? (
              <>
                <SplashScreen additionalSVGStyle={{ height: "80px", width: "80px" }} />
                <h5 className="text-center text-dark">Generating PDF. Please Wait...</h5>
              </>
            ) : (
              <>
                <h5 className="text-center text-dark mb-3">Are you sure you want to create an non-binding offer?</h5>
                <LanguageSelectionDropdown
                  labelPosition="front"
                  additionalWrapperStyles={{ width: "340px" }}
                  labelColumnClasses={"col-form-label"}
                  labelClasses={"ml-3 kt-font-dark"}
                  wrapperClasses={"form-group row mx-auto mb-2"}
                />
              </>
            )}
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-secondary" onClick={this.handleClose}>
              Close
            </button>
            <button
              className={!uploading ? "btn btn-success" : "btn btn-success disabled"}
              disabled={uploading}
              onClick={this.handleUpload}
            >
              {uploading && (
                <div className="button-splash-spinner d-inline pr-3 pl-0 mx-0">
                  <svg className="button-splash-spinner" viewBox="0 0 50 50">
                    <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5" />
                  </svg>
                </div>
              )}
              Create Non-Binding Offer
            </button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default CreateNonBindingOfferPDFModal;
