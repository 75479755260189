import React, { PureComponent } from "react";
import { OverlayTrigger, Table, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";
import { BSON } from "realm-web";
import config from "../../../../config/config.json";
import { CommoditiesDocument } from "../../../../model/commodities.types";
import { DataContext } from "../../../../context/dataContext";
import { ExternalManufacturerOrdersDocument } from "../../../../model/externalManufacturerOrders.types";
import orderUtils from "../../../../utils/orderUtils";
import { OrdersDocument } from "../../../../model/orders.types";
import { CompaniesDocument } from "../../../../model/companies.types";
import baseUtils from "../../../../utils/baseUtils";
import CompanyWidget from "../../../common/CompanyWidget";
import dateUtils from "../../../../utils/dateUtils";
import commodityUtils from "../../../../utils/commodityUtils";
import PriorityWidget from "../../../common/PriorityWidget";
import CustomTooltip from "../../../common/Tooltip";

interface CommodityOrderSelectProps {
  commodity: CommoditiesDocument;
  context: React.ContextType<typeof DataContext>;
  orders: Array<{ order: OrdersDocument; selected: boolean }>;
  relatedExternalOrders: Array<{ order: ExternalManufacturerOrdersDocument; selected: boolean }>;
  selectedManufacturer: string;
  onChangeSelectedManufacturer: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  onCheckAllExternalOrders: () => void;
  onCheckExternalOrder: (_id: BSON.ObjectId) => void;
  onCheckAllOrder: () => void;
  onCheckOrder: (_id: BSON.ObjectId) => void;
}

interface CommodityOrderSelectState {
  showOffers: boolean;
  selectedManufacturer: string;
}

class CommodityOrderSelect extends PureComponent<CommodityOrderSelectProps, CommodityOrderSelectState> {
  constructor(props: CommodityOrderSelectProps) {
    super(props);
    this.state = { showOffers: false, selectedManufacturer: "" };
  }

  /**
   * Resolves all open commodity orders for the current commodity
   * @returns { offers: Array<{ order: OrdersDocument, selected: boolean }>,
   *            orders: Array<{ order: OrdersDocument, selected: boolean }> } All offers and orders that need the
   * commodity and do not already contain an order for it
   */
  resolveOpenOrdersForCommodity = () => {
    const { orders: ordersProps } = this.props;
    const orders = [],
      offers = [];
    for (let i = 0; i < ordersProps.length; i++) {
      const order = ordersProps[i];
      if (orderUtils.isOrder(order.order)) orders.push(order);
      else offers.push(order);
    }
    return { offers, orders };
  };

  render() {
    const {
      commodity,
      context,
      relatedExternalOrders,
      selectedManufacturer,
      onChangeSelectedManufacturer,
      onCheckAllExternalOrders,
      onCheckExternalOrder,
      onCheckAllOrder,
      onCheckOrder
    } = this.props;
    const { showOffers } = this.state;
    const { colors } = context;
    const colorImage = commodity.color ? colors.find(c => c._id.toString() === commodity.color!.toString()) : null;
    const { offers, orders } = this.resolveOpenOrdersForCommodity();

    return (
      <>
        <div className="kt-widget kt-widget--user-profile-3 mb-4">
          <div className="kt-widget__top">
            <div className="kt-widget__media d-none d-sm-block">
              <img
                src={
                  (process.env.REACT_APP_MEDIAHUB_BASE || "") +
                  (colorImage && colorImage.image ? colorImage.image : "files/unknown.png")
                }
                alt="color"
              />
            </div>
            <div className="kt-widget__content align-self-center">
              <div className="kt-widget__head">
                <div className="kt-widget__user">
                  <span className="kt-widget__username kt-font-bold kt-font-dark">
                    {commodity.title.en ? commodity.title.en : "undefined"}
                  </span>
                </div>
              </div>
              <div className="kt-widget__info mb-2">
                <div style={{ color: "#8a8a8a" }}>
                  {commodity.title.de ? commodity.title.de : "German title undefined"}
                  {commodity.article_number && (
                    <span className="kt-font-dark kt-font-bold mr-3">
                      <br />
                      Art. Nr: {commodity.article_number}
                    </span>
                  )}
                </div>
              </div>
              <div className="kt-widget__info">
                <span style={{ color: "#8a8a8a" }}>Identifier: </span>
                <span className="kt-font-dark kt-font-bold ml-1 mr-3">{commodity.identifier || "-"}</span>
                <span style={{ color: "#8a8a8a" }}>HS Code: </span>
                <span className="kt-font-dark kt-font-bold ml-1 mr-3">{commodity.hs_code || "-"}</span>
                <span style={{ color: "#8a8a8a" }}>CAS Number: </span>
                <span className="kt-font-dark kt-font-bold ml-1 mr-3">{commodity.cas_number || "-"}</span>
                <span style={{ color: "#8a8a8a" }}>
                  Internal Code
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id="allocatedInfo">This is a legacy value and shouldn't be used anymore.</Tooltip>
                    }
                  >
                    <i className="fa fa-info-circle ml-1 text-info" />
                  </OverlayTrigger>
                  :{" "}
                </span>
                <span className="kt-font-dark ml-1 mr-3">{commodity.internal_code || "-"}</span>
                {commodity.organic && (
                  <>
                    <span style={{ color: "#8a8a8a" }}>
                      Organic Code
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip id="allocatedInfo">This is a legacy value and shouldn't be used anymore.</Tooltip>
                        }
                      >
                        <i className="fa fa-info-circle ml-1 text-info" />
                      </OverlayTrigger>
                      :{" "}
                    </span>
                    <span className="kt-font-dark ml-1 mr-3">{commodity.organic_code || " -"}</span>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        {orders.length > 0 ? (
          <>
            <div className="float-right w-50">
              <select className="form-control" value={selectedManufacturer} onChange={onChangeSelectedManufacturer}>
                <option value="">All ({orders.length})</option>
                {context.manufacturers.map(m => (
                  <option key={m._id.toString()} value={m._id.toString()}>
                    {m.name} ({orders.filter(o => o.order.settings.manufacturer.toString() === m._id.toString()).length}
                    )
                  </option>
                ))}
              </select>
            </div>
            <OrderTable
              orders={orders}
              context={context}
              commodity={commodity}
              offers={false}
              selectable={true}
              selectedManufacturer={selectedManufacturer}
              onCheckOrder={onCheckOrder}
              onCheckAllOrders={onCheckAllOrder}
            />
          </>
        ) : (
          <div className="alert alert-warning">
            <div className="alert-icon">
              <i className="flaticon-warning" />
            </div>
            <div className="alert-text">
              There are currently no orders that use this commodity. <br />
              However, the commodity can be ordered without assigning orders.
            </div>
          </div>
        )}
        {relatedExternalOrders.length > 0 && !commodity.type ? (
          <div className="table-responsive">
            <Table>
              <thead>
                <tr>
                  <th style={{ width: "5%" }}>
                    <label className="kt-checkbox" style={{ marginBottom: "15px" }}>
                      <input
                        type="checkbox"
                        checked={!relatedExternalOrders.some(o => !o.selected)}
                        onChange={onCheckAllExternalOrders}
                      />
                      <span />
                    </label>
                  </th>
                  <th style={{ width: "25%" }}>{"Ext. Order"}</th>
                  <th style={{ width: "33%" }}>Company</th>
                  <th style={{ width: "10%" }}>Required</th>
                  <th style={{ width: "14%" }}>Price</th>
                  <th style={{ width: "13%" }}>Delivery</th>
                </tr>
              </thead>
              <tbody>
                {relatedExternalOrders.map(o => {
                  const company: CompaniesDocument = baseUtils.getDocFromCollection(
                    context.companies,
                    o.order.company
                  )!;
                  const required = o.order.amount;
                  return (
                    <tr key={o.order._id.toString()} className="table-hover">
                      <td className="align-middle">
                        <label className="kt-checkbox">
                          <input
                            type="checkbox"
                            checked={o.selected}
                            onChange={() => onCheckExternalOrder(o.order._id)}
                          />
                          <span />
                        </label>
                      </td>
                      <td className="align-middle">
                        <span>
                          <div className="kt-user-card-v2">
                            <div className="kt-user-card-v2__details">
                              <Link
                                to={"/externalOrder/" + o.order._id.toString()}
                                className="kt-user-card-v2__name kt-link"
                              >
                                {"EMO-" + o.order.identifier}
                              </Link>
                              <span className="kt-user-card-v2__email">{o.order.reference}</span>
                            </div>
                          </div>
                        </span>
                      </td>
                      <td className="align-middle">
                        <CompanyWidget company={company} />
                      </td>
                      <td className="align-middle">
                        <span className="text-success font-weight-bold">{required + "kg"}</span>
                      </td>
                      <td className="align-middle">
                        {o.order.actualPrice ? baseUtils.formatEuro(o.order.actualPrice) : "-"}
                      </td>
                      <td className="align-middle">
                        {o.order.actualDeliveryDate ? (
                          <>
                            {baseUtils.formatDate(o.order.actualDeliveryDate)}
                            <br />
                            <span className="kt-font-bold">
                              {Math.round(dateUtils.getDaysBetween(new Date(), o.order.actualDeliveryDate))}
                            </span>{" "}
                            days left
                          </>
                        ) : (
                          "-"
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        ) : (
          <div />
        )}
        {offers.length > 0 && (
          <>
            <button className="btn btn-sm btn-secondary" onClick={() => this.setState({ showOffers: !showOffers })}>
              {showOffers ? "Hide offers" : "Show Offers (" + offers.length + ")"}
            </button>
            {showOffers && (
              <OrderTable
                orders={offers}
                commodity={commodity}
                context={context}
                offers={true}
                selectable={false}
                selectedManufacturer={selectedManufacturer}
                onCheckOrder={onCheckOrder}
                onCheckAllOrders={onCheckAllOrder}
              />
            )}
          </>
        )}
      </>
    );
  }
}

export default CommodityOrderSelect;

interface OrderTableProps {
  commodity: CommoditiesDocument;
  context: React.ContextType<typeof DataContext>;
  orders: Array<{ order: OrdersDocument; selected: boolean }>;
  selectedManufacturer: string;
  offers: boolean;
  selectable: boolean;
  onCheckAllOrders: () => void;
  onCheckOrder: (_id: BSON.ObjectId) => void;
}

interface OrderTableState {}

class OrderTable extends PureComponent<OrderTableProps, OrderTableState> {
  render() {
    const { commodity, context, selectedManufacturer, selectable, orders, offers, onCheckOrder, onCheckAllOrders } =
      this.props;
    return (
      <div className="table-responsive">
        <Table>
          <thead>
            <tr>
              {selectable && (
                <th style={{ width: "5%" }}>
                  <label className="kt-checkbox" style={{ marginBottom: "15px" }}>
                    <input
                      type="checkbox"
                      checked={
                        !orders.some(
                          o =>
                            (!selectedManufacturer ||
                              o.order.settings.manufacturer.toString() === selectedManufacturer) &&
                            !o.selected
                        )
                      }
                      onChange={onCheckAllOrders}
                    />
                    <span />
                  </label>
                </th>
              )}
              <th style={{ width: selectable ? "25%" : "30%" }}>{offers ? "Offer" : "Order"}</th>
              <th style={{ width: "30%" }}>Company</th>
              <th style={{ width: "14%" }}>Required</th>
              <th style={{ width: "13%" }}>Priority</th>
              <th style={{ width: "13%" }}>Target Date</th>
            </tr>
          </thead>
          <tbody>
            {orders
              .filter(o => !selectedManufacturer || o.order.settings.manufacturer.toString() === selectedManufacturer)
              .map(o => {
                const company: CompaniesDocument = baseUtils.getDocFromCollection(
                  context.companies,
                  o.order.createdFor
                )!;
                const price = o.order.calculations[0].prices.find(p => p._id.toString() === commodity._id.toString())!;
                const required = commodityUtils.calculateUsage(o.order, price, commodity.type, false);
                return (
                  <tr key={o.order._id.toString()}>
                    {selectable && (
                      <td className="align-middle">
                        <label className="kt-checkbox">
                          <input type="checkbox" checked={o.selected} onChange={() => onCheckOrder(o.order._id)} />
                          <span />
                        </label>
                      </td>
                    )}
                    <td className="align-middle">
                      <span>
                        <div className="kt-user-card-v2">
                          <div className="kt-user-card-v2__details">
                            <Link to={"/order/" + o.order._id.toString()} className="kt-user-card-v2__name kt-link">
                              {(orderUtils.isOrder(o.order) ? "AT-" : "AN-") + o.order.identifier}
                            </Link>
                            <span className="kt-user-card-v2__email">{o.order.title}</span>
                          </div>
                        </div>
                      </span>
                    </td>
                    <td className="align-middle">
                      <CompanyWidget company={company} />
                    </td>
                    <td className="align-middle">
                      <div className="text-success font-weight-bold">{required.unit}</div>
                      {price && price.orderquantity && price.orderquantity !== required.raw ? (
                        <CustomTooltip tooltipText="Order quantity set during commodity check">
                          <span className="text-muted font-weight-normal">
                            {commodityUtils.resolveStockUnit(price.orderquantity, commodity.type, true)}
                          </span>
                        </CustomTooltip>
                      ) : (
                        ""
                      )}
                    </td>
                    <td className="align-middle">
                      <PriorityWidget priority={o.order.priority} />
                    </td>
                    <td className="align-middle">
                      {o.order.targetDate ? baseUtils.formatDate(o.order.targetDate) : "-"}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
      </div>
    );
  }
}
